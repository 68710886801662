import React, { useState, useEffect } from 'react';
import Sidebar from '../layouts/Sidebar';
import Header from '../layouts/Header';
import axios from 'axios';
import {
    CustomDropDownField, CustomInputField, CustomTextArea
} from "../CustomComponents/ReusableComponents";
import { useAuth } from '../contexts/AuthContext';
import { useHistory, useParams } from "react-router-dom";
import Swal from 'sweetalert2';

const AddAccountComponent = () => {
    const { auth } = useAuth();
    const history = useHistory();
    const { id } = useParams();

    const API_BASE_URL = process.env.REACT_APP_API_V1_BASE_URL;

    const [menu, setMenu] = useState(false);
    const [accountTypes, setAccountTypes] = useState([]);
    const [customers, setCustomers] = useState([]);
    const [vendors, setVendors] = useState([]);
    const [errors, setErrors] = useState({});
    
    const [formData, setFormData] = useState({
        account_type_id: '',
        code: '',
        customer_id: '',
        description: '',
        is_active: '1',
        name: '',
        vendor_id: ''
    })
    
    const toggleMobileMenu = () => {
        setMenu(!menu);
    };

    const handleToggle = () => {
        setFormData(prevFormData => ({
            ...prevFormData,
            is_active: prevFormData.is_active === '1' ? '0' : '1'
        }));
    };

    const handleChange = e => {
        const { name, value } = e.target;
        setFormData(prevFormData => ({
            ...prevFormData,
            [name]: value
        }));
    };


    useEffect(() => {
        const fetchDropdownData = async () => {
            try {
                const response = await axios.post(
                    `${process.env.REACT_APP_API_V1_BASE_URL}/get_dropdown_data`,
                    {
                        models: [
                            { model_name: "Customer" },
                            { model_name: "AccountType" },
                            { model_name: "Vendor" },
                        ]
                    },
                    {
                        headers: {
                            Authorization: `Bearer ${auth}`
                        }
                    }
                );
                setAccountTypes(response.data.content.AccountType || []);
                setCustomers(response.data.content.Customer || []);
                setVendors(response.data.content.Vendor || []);
            } catch (err) {
                // setError("Failed to load dropdown data");
            }
        };

        const fetchAccountData = async () => {
            if (id) {
                try {
                    const response = await axios.get(
                        `${process.env.REACT_APP_API_V1_BASE_URL}/accounts/${id}`,
                        {
                            headers: {
                                Authorization: `Bearer ${auth}`
                            }
                        }
                    );
                    setFormData(response.data);
                } catch (err) {
                    // Handle error
                }
            }
        };

        fetchDropdownData();
        fetchAccountData();
    }, [auth, id]);

    const validateForm = () => {
        const validationErrors = {};

        if (!formData.account_type_id) {
            validationErrors.account_type_id = "Select an account";
        }

        if (!formData.name) {
            validationErrors.name = "Enter a name";
        } else if (formData.name.length < 3) {
            validationErrors.name = "Name must be at least 3 characters long";
        } else if (formData.name.length > 15) {
            validationErrors.name = "Name must not exceed 10 characters";
        }


        if (!formData.code) {
            validationErrors.code = "Enter an account code";
        } else if (formData.code.length < 4) {
            validationErrors.code = "Code must be at least 4 characters long";
        } else if (formData.code.length > 20) {
            validationErrors.code = "Code must not exceed 20 characters";
        }

        setErrors(validationErrors);
        return Object.keys(validationErrors).length === 0;
    };


    const handleSubmit = async e => {
        e.preventDefault();

        if (!validateForm()) {
            return;
        }
        try {
            const accountsData = { ...formData };
            const apiReqHeaders = {
                headers: {
                    Authorization: `Bearer ${auth}`,
                    "Content-Type": "application/json"
                }
            };

            if (id) {
                await axios.put(
                    `${API_BASE_URL}/accounts/${id}`,
                    accountsData,
                    apiReqHeaders
                );
                Swal.fire({
                    title: 'Success!',
                    text: 'Account updated successfully.',
                    icon: 'success',
                    confirmButtonText: 'OK',
                    customClass: {
                        popup: 'custom-swal-popup',  // Use your class for custom positioning
                        icon: 'custom-swal-icon',    // Use this class to style the icon
                      }
                });
            } else {
                await axios.post(
                    `${API_BASE_URL}/accounts`,
                    accountsData,
                    apiReqHeaders
                );
                Swal.fire({
                    title: 'Success!',
                    text: 'Account created successfully.',
                    icon: 'success',
                    confirmButtonText: 'OK',
                    customClass: {
                        popup: 'custom-swal-popup',  // Use your class for custom positioning
                        icon: 'custom-swal-icon',    // Use this class to style the icon
                      }
                });
            }

            history.push("/accounts");
        } catch (error) {
            Swal.fire({
                title: 'Error!',
                text: 'Failed to save the Account.',
                icon: 'error',
                confirmButtonText: 'OK',
                customClass: {
                    popup: 'custom-swal-popup',  // Use your class for custom positioning
                    icon: 'custom-swal-icon',    // Use this class to style the icon
                  }
            });
        }
    };

    const handleCancel = () => {
        history.push('/accounts');
    };

    return (
        <>
            <div className={`main-wrapper ${menu ? "slide-nav" : ""}`}>
                <Header onMenuClick={toggleMobileMenu} />
                <Sidebar />
                <div className="page-wrapper">
                    <div className="content container-fluid">
                        <div className="card mb-0">
                            <div className="card-body">
                                <div className="page-header">
                                    <div className="content-page-header">
                                        <h5>{id ? 'Edit Account' : 'Add Account'}</h5>
                                    </div>
                                </div>

                                <form onSubmit={handleSubmit}>
                                    <div className="col-lg-4 ">
                                        <div className="">
                                            <label className="input-block">Active</label>
                                            <div className="form-check form-switch">
                                                <input
                                                    className="form-check-input"
                                                    type="checkbox"
                                                    id="isActiveSwitch"
                                                    checked={formData.is_active === '1'}
                                                    onChange={handleToggle}
                                                />
                                                <label className="text-secondary-emphasis" htmlFor="isActiveSwitch">
                                                    {formData.is_active === '1' ? 'Active' : 'Inactive'}
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <CustomDropDownField
                                            options={accountTypes.map(c => ({
                                                value: c.id,
                                                label: c.name
                                            }))}
                                            labelText="Account Type"
                                            name="account_type_id"
                                            onChange={handleChange}
                                            required={true}
                                            errorMessage={errors.account_type_id}
                                            value={formData.account_type_id}
                                        />
                                        {/* </div> */}
                                        {/* setCustomers */}
                                        {/* <div className="row"> */}
                                        <CustomDropDownField
                                            options={customers.map(c => ({
                                                value: c.id,
                                                label: c.name
                                            }))}
                                            labelText="Customers"
                                            name="customer_id"
                                            onChange={handleChange}
                                            value={formData.customer_id}
                                        />
                                        <CustomDropDownField
                                            options={vendors.map(c => ({
                                                value: c.id,
                                                label: c.name
                                            }))}
                                            labelText="Vendors"
                                            name="vendor_id"
                                            onChange={handleChange}
                                            value={formData.vendor_id}
                                        />
                                        {/* </div> */}

                                        {/* <div className="row"> */}
                                        <CustomInputField
                                            inputType="text"
                                            labelText="Account Name"
                                            placeholder="E.g John Doe"
                                            name="name"
                                            value={formData.name}
                                            onChange={handleChange}
                                            required={true}
                                            errorMessage={errors.name}

                                        />
                                        <CustomInputField
                                            inputType="text"
                                            labelText="Account Code"
                                            placeholder="E.g Acc-457"
                                            name="code"
                                            value={formData.code}
                                            onChange={handleChange}
                                            required={true}
                                            errorMessage={errors.code}
                                        />

                                        <div className="col-lg-6 my-3">
                                            <CustomTextArea
                                                rows={5}
                                                name="description"
                                                labelText="Description"
                                                value={formData.description}
                                                onChange={handleChange}
                                                placeholder="Description"
                                            />
                                        </div>
                                    </div>





                                    <div className="text-end">
                                        <button
                                            type="reset"
                                            className="btn btn-primary cancel me-2"
                                            onClick={handleCancel}
                                        >
                                            Cancel
                                        </button>
                                        <button
                                            type="submit"
                                            className="btn btn-primary"
                                        >
                                            Save
                                        </button>
                                    </div>

                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default AddAccountComponent;