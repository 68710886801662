// src/components/ItemFormFields.js
import React, { useState, useEffect } from 'react';
import axios from 'axios';
// import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useAuth } from '../contexts/AuthContext';
import { useHistory, useParams } from 'react-router-dom';
import { CustomDropDownField, CustomInputField, CustomTextArea } from '../CustomComponents/ReusableComponents';
import Header from '../layouts/Header';
import Sidebar from '../layouts/Sidebar';
import Swal from 'sweetalert2';

const AddItems = ({ hideLayout, closeItemModal }) => {
    const { auth } = useAuth();
    const { id } = useParams();


    const API_BASE_URL = process.env.REACT_APP_API_V1_BASE_URL;

    const history = useHistory();
    const [menu, setMenu] = useState(false);

    const itemTypes = [
        { id: 1, name: "Goods" },
        { id: 2, name: "Services" }
    ];
    const [errors, setErrors] = useState({});
    const [formData, setFormData] = useState({
        type: null,
        item_code: '',
        name: '',
        unit: 1,
        selling_price: '',
        selling_desc: '',
        sales_account_id: '',
        purchase_cost: '',
        purchase_desc: '',
        purchase_account_id: '',
        description: ''
    });

    const [salesAccount, setSalesAccount] = useState([]);
    const [purchaseAccount, setPurchaseAccount] = useState([]);

    const validateForm = () => {
        const validationErrors = {};
        // Basic required field validations
        if (!formData.name) validationErrors.name = " please enter a Name.";
        if (!formData.item_code) validationErrors.item_code = " please enter a Item Code.";
        if (!formData.unit) validationErrors.unit = "please enter unit.";
        if (!formData.selling_price) validationErrors.selling_price = "Please mention Selling Price.";
        if (!formData.type) validationErrors.type = "please Select the Account type .";

        // if (!formData.registration_number) validationErrors.registration_number = "Registration number is required.";
        setErrors(validationErrors);
        return Object.keys(validationErrors).length === 0;
    };

    const toggleMobileMenu = () => {
        setMenu(!menu);
    };

    const handleChange = e => {
        const { name, value } = e.target;
        setFormData(prevFormData => ({
            ...prevFormData,
            [name]: value
        }));
    };

    const handleCancel = () => {
        if(!hideLayout){
            history.push("/items");
        }
        else{
            closeItemModal();
        }
    };

    useEffect(() => {

        const fetchDropdownData = async () => {
            try {
                const response = await axios.post(
                    `${process.env.REACT_APP_API_V1_BASE_URL}/get_dropdown_data`,
                    {
                        models: [
                            { model_name: "Account", params: "expense" },
                        ]
                    },
                    {
                        headers: {
                            Authorization: `Bearer ${auth}`
                        }
                    }
                );
                setPurchaseAccount(response.data.content.Account || []);
            }
            catch (err) {
                console.log("Failed to load dropdown data");
            }
        };

        const fetchDropdownData2 = async () => {
            try {
                const response = await axios.post(
                    `${process.env.REACT_APP_API_V1_BASE_URL}/get_dropdown_data`,
                    {
                        models: [
                            { model_name: "Account", params: "income" },
                        ]
                    },
                    {
                        headers: {
                            Authorization: `Bearer ${auth}`
                        }
                    }
                );
                setSalesAccount(response.data.content.Account || []);
            }
            catch (err) {
                console.log("Failed to load dropdown data");
            }
        };

        const fetchAccountData = async () => {
            if (id) {
                try {
                    const response = await axios.get(
                        `${process.env.REACT_APP_API_V1_BASE_URL}/items/${id}`,
                        {
                            headers: {
                                Authorization: `Bearer ${auth}`
                            }
                        }
                    );
                    setFormData(response.data);
                } catch (err) {
                    // Handle error
                }
            }
        };

        fetchDropdownData();
        fetchDropdownData2();
        fetchAccountData();
    }, [auth, id]);

    const handleSubmit = async e => {
        e.preventDefault();

        if (!validateForm()) {
            return;
        }
        try {
            const itemData = { ...formData };
            const apiReqHeaders = {
                headers: {
                    Authorization: `Bearer ${auth}`,
                    "Content-Type": "application/json"
                }
            };

            if (id) {
                await axios.put(
                    `${API_BASE_URL}/items/${id}`,
                    itemData,
                    apiReqHeaders
                );
                Swal.fire({
                    title: 'Success!',
                    text: 'Item updated successfully.',
                    icon: 'success',
                    confirmButtonText: 'OK',
                    customClass: {
                        popup: 'custom-swal-popup',
                        icon: 'custom-swal-icon',  
                    }
                });
            } else {
                await axios.post(
                    `${API_BASE_URL}/items`,
                    itemData,
                    apiReqHeaders
                );
                Swal.fire({
                    title: 'Success!',
                    text: 'Item created successfully.',
                    icon: 'success',
                    confirmButtonText: 'OK',
                    customClass: {
                        popup: 'custom-swal-popup', 
                        icon: 'custom-swal-icon',   
                    }
                });
            }

            if(!hideLayout){
                history.push("/items");
            }
            else{
                closeItemModal();
            }

        } catch (error) {
            Swal.fire({
                title: 'Error!',
                text: 'Failed to Save the Item.',
                icon: 'error',
                confirmButtonText: 'OK',
                customClass: {
                    popup: 'custom-swal-popup',  // Use your class for custom positioning
                    icon: 'custom-swal-icon',    // Use this class to style the icon
                }
            });
        }
    };

    return (
        <>
            <div className={`main-wrapper ${!hideLayout && menu ? "slide-nav" : ""}`}>
                {!hideLayout && <Header onMenuClick={toggleMobileMenu} />}
                {!hideLayout && <Sidebar />}
                <div className={!hideLayout ? "page-wrapper" : ""}>
                    <div className="content container-fluid">
                        <div className={!hideLayout ? "card mb-0": ""}>
                            <div className={!hideLayout ? "card-body": ""}>
                                <div className={!hideLayout ? "page-header": ""}>
                                    {!hideLayout && 
                                        <div className="content-page-header">
                                            <h5>{id ? 'Edit Item' : 'Add Item'}</h5>
                                        </div>
                                    }  
                                </div>

                                <form onSubmit={handleSubmit}>
                                    <div className='form-group-customer'>
                                        <h5 className="form-title">Basic Details</h5>
                                        <div className="row">
                                            <CustomInputField
                                                inputType="text"
                                                labelText="Code"
                                                placeholder="Eg. PROD-123"
                                                name="item_code"
                                                value={formData.item_code}
                                                onChange={handleChange}
                                                errorMessage={errors.item_code}
                                                required={true}
                                                inputWrapper= {hideLayout && 'col-lg-6'}
                                            />
                                            <CustomInputField
                                                inputType="text"
                                                labelText="Name"
                                                placeholder="Eg. Software Development Resource"
                                                name="name"
                                                value={formData.name}
                                                onChange={handleChange}
                                                errorMessage={errors.name}
                                                required={true}
                                                inputWrapper= {hideLayout && 'col-lg-6'}
                                            />

                                            <CustomDropDownField
                                                options={itemTypes.map(c => ({
                                                    value: c.name,
                                                    label: c.name
                                                }))}
                                                labelText="Item Type"
                                                name="type"
                                                value={formData.type}
                                                onChange={handleChange}
                                                errorMessage={errors.type}
                                                required={true}
                                                dropDownSize= {hideLayout && 'col-lg-6'}
                                            />

                                            <CustomInputField
                                                inputType="number"
                                                labelText="Unit"
                                                placeholder="Eg. 1"
                                                name="unit"
                                                value={formData.unit}
                                                onChange={handleChange}
                                                errorMessage={errors.unit}
                                                required={true}
                                                inputWrapper= {hideLayout && 'col-lg-6'}
                                            />
                                        </div>
                                        {!hideLayout &&
                                            <>
                                            <label className='input-block'> Description </label>
                                            <CustomTextArea
                                                rows={5}
                                                name="description"
                                                value={formData.description}
                                                onChange={handleChange}
                                                placeholder="Item Description"
                                            />
                                            </>
                                        }
                                    </div>
                                    
                                    {/* Sells Details */}
                                    <div className='form-group-customer'>
                                        <h5 className="form-title">Sells Details</h5>
                                    
                                        <div className="row">
                                            <CustomInputField
                                                inputType="number"
                                                labelText="Selling Price"
                                                placeholder="Eg. 100"
                                                name="selling_price"
                                                value={formData.selling_price}
                                                onChange={handleChange}
                                                inputWrapper= {hideLayout && 'col-lg-6'}
                                            />

                                            <CustomDropDownField
                                                options={salesAccount.map(c => ({
                                                    value: c.id,
                                                    label: c.name
                                                }))}
                                                labelText="Sales Account"
                                                name="sales_account_id"
                                                value={formData.sales_account_id}
                                                onChange={handleChange}
                                                dropDownSize= {hideLayout && 'col-lg-6'}
                                            />

                                        </div>
                                        {!hideLayout &&
                                            <>
                                            <label className='input-block'> Description </label>
                                            <CustomTextArea
                                                rows={5}
                                                name="selling_desc"
                                                value={formData.selling_desc}
                                                onChange={handleChange}
                                                placeholder="Sell Description"
                                            />
                                            </>
                                        }
                                    </div>

                                    {/* Purchase Details */}
                                    {!hideLayout &&
                                    <div className='form-group-customer'>
                                        <h5 className="form-title">Purchase Details</h5>

                                        <div className="row">
                                            <CustomInputField
                                                inputType="number"
                                                labelText="Purchase Cost"
                                                placeholder="Eg. 100"
                                                name="purchase_cost"
                                                value={formData.purchase_cost}
                                                onChange={handleChange}
                                            />

                                            <CustomDropDownField
                                                options={purchaseAccount.map(c => ({
                                                    value: c.id,
                                                    label: c.name
                                                }))}
                                                labelText="Purchase Account"
                                                name="purchase_account_id"
                                                value={
                                                    formData.purchase_account_id
                                                }
                                                onChange={handleChange}
                                            />
                                        </div>

                                        <label className='input-block'> Description </label>
                                        <CustomTextArea
                                            rows={5}
                                            name="purchase_desc"
                                            value={formData.purchase_desc}
                                            onChange={handleChange}
                                            placeholder="Purchase Description"
                                        />

                                    </div>
                                    }

                                    <div className="modal-footer">
                                        <button
                                            type="reset"
                                            className="btn btn-primary cancel me-2"
                                            onClick={handleCancel}
                                        >
                                            Cancel
                                        </button>
                                        <button
                                            type="submit"
                                            className="btn btn-primary"
                                        >
                                            Save
                                        </button>
                                    </div>

                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

AddItems.propTypes = {
    id: PropTypes.number,
    hideLayout: PropTypes.bool,
    closeItemModal: PropTypes.func.isRequired
};

export default AddItems;
