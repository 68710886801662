import React from 'react';
import PageComponent from '../CustomComponents/PageComponent';

const JournalTypesList = () => {
  return(
    <PageComponent listAPI='journal_types' label='Journal Type' addType='popUp' addLabel='Journal Type'/>
  )
};

export default JournalTypesList;
