import React, { useState } from "react";
import { Route, BrowserRouter as Router } from "react-router-dom";
import { Switch } from "react-router-dom";
// import AddCustomerForm from "./customers/CustomerMenu/AddCustomerForm";
import ItemFormFields from "./items/itemlist";
import AddItems from "./items/addItems";
import PurchaseInvoice from "./purchaseInvoice/purchaseInvoice";
import ProtectedRoute from "./authentication/ProtectedRoute";
import Header from "./layouts/Header";
import Sidebar from "./layouts/Sidebar";
import Login from "./authentication/Login";
import PropTypes from "prop-types";
import Dashboard from "./main/dashboard/Index";
import Customers from "./customers/CustomerMenu/Index";
import Vendors from "./customers/vendors";
import InvoiceList from "./sales/invoices/invoice-list";
import AddCustomerForm from "./CustomComponents/AddCustomerForm";
import AddInvoice from "./sales/invoices/AddInvoice";
import addVendorForm from "./customers/vendors/addVendor";
import AddPurchaseInvoice from "./purchaseInvoice/addPurchaseInvoice";
import AccountComponent from "./accountsfinance/AccountComponent";
import AccountTypes from "./accountsfinance/AccountTypes";

import TransactionComponent from "./accountsfinance/TransactionComponent";
import PlaidTransactionComponent from "./accountsfinance/PlaidTransactionComponent";
import TransactionForm from "./settings/TransactionForm";
import AddAccountComponent from "./accountsfinance/AddAccountComponent";
import JournalList from "./journal/journalList";
import AddJournals from "./journal/addJournal";
import JournalTypesList from "./journal/journalTypesList";
import BranchList from "./branches/branchList";
import AddBranch from "./branches/addBranch";

import ExpensesList from "./expenses/ExpensesList";
import AddExpenses from "./expenses/addExpenses";
import BankAccounts from "./accountsfinance/BankAccount";
import AddBankAccount from "./accountsfinance/AddBankAccount";

// import Ledger from "./customers/vendors/ledger";
import TrialBalance from "./reports-component/reports/trialBalanceReport";
import ProfitLossList from "./reports-component/reports/profitlossReport";
import BalanceSheetReport from "./reports-component/reports/balanceSheetReport";
import expenseReport from "./reports-component/reports/expenseReport";
import Taxreport from "./reports-component/reports/taxreport";
import PurchaseReport from "./reports-component/reports/purchaseReport";
import SalesReport from "./reports-component/reports/salesreport";
import PurchaseReturnReport from "./reports-component/reports/purchaseReturnReport";
import SalesReturn from "./reports-component/reports/salesReturn";
import AccountSettings from "./settings/AccountSettings";
// import Expenses from "./finance-accounts/expenses/expenses";
// import RecurringInvoice from "./sales/invoices/recurring";
// import AddPurchaseReturn from "./purchases-component/purchases/add-purchase-return";
import Calendar from "./main/application/calendar";
// import DomainRequest from "./main/superadmin/domainrequest";
// // import StickySidebar from "./layouts/StickySidebar";
// import AddCustomer from "./customers/add-customer";
// import EditCustomer from "./customers/edit-customer";
// import EditExpenses from "./finance-accounts/expenses/edit-expenses";
import Packages from "./main/superadmin/packages";
import Subscription from "./main/superadmin/subscription";
// import FontAwesome from "./ui-interface/icons/font-awesome";
// import Feather from "./ui-interface/icons/feather";
// import IconicIcon from "./ui-interface/icons/iconic";
// import MaterialIcons from "./ui-interface/icons/material";
// import Pe7 from "./ui-interface/icons/pe7";
// // import Invoices from "./sales/invoices/Index";
// import Invoicedetails from "./sales/invoices/Invoice-details";

// // import EditInvoice from "./sales/invoices/edit-invoice";
// import InvoicePaid from "./sales/invoices/Invoice-paid";
// import ParticallyPaid from "./sales/invoices/partically-paid";
// import InvoiceUnpaid from "./sales/invoices/Invoice-unpaid";
// import RefundedInvoice from "./sales/invoices/refunded-Invoice";
// import InvoiceOverdue from "./sales/invoices/Invoice-overdue";
// import Invoicesettings from "./sales/invoices/Invoice-settings";
// import InvoiceDraft from "./sales/invoices/Invoice-draft";
// import Invoicecancelled from "./sales/invoices/Invoice-cancelled";
import Chat from "./main/application/chat";
// import Alert from "./ui-interface/base-ui/alerts";
// import Accordions from "./ui-interface/base-ui/accordions";
// import Avatar from "./ui-interface/base-ui/avatar";
// import Badges from "./ui-interface/base-ui/badges";
// import Buttons from "./ui-interface/base-ui/buttons";
// import Buttongroup from "./ui-interface/base-ui/button-group";
// import Breadcrumbs from "./ui-interface/base-ui/breadcrumbs";
// import Cards from "./ui-interface/base-ui/cards";
// import Carousel from "./ui-interface/base-ui/carousel";
// import Dropdowns from "./ui-interface/base-ui/dropdowns";
// import Grid from "./ui-interface/base-ui/grid";
// import Images from "./ui-interface/base-ui/images";
// import Lightbox from "./ui-interface/base-ui/lightbox";
// import Media from "./ui-interface/base-ui/media";
// import Modals from "./ui-interface/base-ui/modals";
// import Offcanvas from "./ui-interface/base-ui/offcanvas";
// import Pagination from "./ui-interface/base-ui/pagination";
// import Popover from "./ui-interface/base-ui/popover";
// import Progress from "./ui-interface/base-ui/progress";
// import Placeholder from "./ui-interface/base-ui/placeholders";
// import RangeSlides from "./ui-interface/base-ui/rangeslider";
// import Spinners from "./ui-interface/base-ui/spinners";
// import Sweetalerts from "./ui-interface/base-ui/sweeetalerts";
// import Tap from "./ui-interface/base-ui/tab";
// import Toasts from "./ui-interface/base-ui/toasts";
// import Tooltip from "./ui-interface/base-ui/tooltip";
// import Typography from "./ui-interface/base-ui/typography";
// import Videos from "./ui-interface/base-ui/videos";
// import Ribbon from "./ui-interface/elements/ribbon";
// import DragDrop from "./ui-interface/elements/drag&drop";
// import Rating from "./ui-interface/elements/rating";

// import Texteditor from "./ui-interface/elements/texteditor";
// import Counter from "./ui-interface/elements/counter";
// import Scrollbar from "./ui-interface/elements/scrollbar";
// import Stickynote from "./ui-interface/elements/stickynote";
// import Timeline from "./ui-interface/elements/timeline";
// import Horizontaltimeline from "./ui-interface/elements/horizontaltimeline";
// import Formwizard from "./ui-interface/elements/formwizard";
// import { Clipboard } from "./ui-interface/elements/clipboard";
// import InvoiceOnea from "./sales/invoices/invoice-one-a";
// import InvoiceFourA from "./sales/invoices/invoice-four-a";
import Companies from "./main/superadmin/companies";

// import ContactMessage from "./support/contactMessage";
// import customersLedger from "./customers/customersLedger";
import BankSetting from "./settings/BankSetting";
import PaymentMethod from "./settings/PaymentMethod";
// import Error404 from "./pages/error404";
import DeleteAccounts from "./settings/deleteAccount";
// import VectorMaps from "./pages/vectormaps/Index";
// import TypiconIcons from "./ui-interface/icons/typicon";
// import SimpleLine from "./ui-interface/icons/simpleLine";
// import Themify from "./ui-interface/icons/themify";
// import WeatherIcons from "./ui-interface/icons/weather";
// import Flags from "./ui-interface/icons/flags";
// import Apexchart from "./ui-interface/charts/apexcharts";
// import ChartJs from "./ui-interface/charts/chartjs";
// import Tickets from "./support/tickets";
// import TicketRecurring from "./support/tickets/ticketRecurring";
// import TicketCancelled from "./support/tickets/ticketCancelled";
// import TicketList from "./support/tickets/ticketlist";
// import TicketListPending from "./support/tickets/ticketlist/ticketListPending";
// import TicketListOverdue from "./support/tickets/ticketlist/ticketListOverdue";
// import TicketListDraft from "./support/tickets/ticketlist/ticketListdraft";
// import TicketListRecurring from "./support/tickets/ticketlist/ticketListRecurring";
// import TicketListCancelled from "./support/tickets/ticketlist/ticketListCancelled";
// import TicketKanban from "./support/tickets/ticketKanban";
// import TicketDetails from "./support/tickets/ticketOverview";
// import AddMembership from "./membership/addMembership";
// import EditTestimonials from "./content/testimonials/editTestimonials";
// import AddTestimonials from "./content/testimonials/addTestimonials";
// import Faq from "./content/faq";
// import Cities from "./content/location/cities";
// import States from "./content/location/states";
// import Countries from "./content/location";
// import BlogComments from "./content/blog/blog-comments";
// import AddCategories from "./content/blog/add-categories";
// import Categories from "./content/blog/categories";
// import AddBlog from "./content/blog/add-blog";
// import InactiveBlog from "./content/blog/inactive-blog";
// import AllBlogs from "./content/blog/blog";
// import Testimonials from "./content/testimonials";
// import Pages from "./pages/page";
// import AddPage from "./pages";
// import Transaction from "./membership/transaction";
import Payments from "./finance-accounts/payments/payments";

import CompanySettings from "./settings/CompanySettings";
import InvoiceSetting from "./settings/InvoiveSetting";
import InvoiceTemplateSettings from "./settings/InvoiceTemplateSettings";
import Inbox from "./main/application/inbox";
// import RecurringHead from "./sales/invoices/recurring/recurringHead";
// import CreditPending from "./sales/creditNotes/creditPending";
// import CreditOverdue from "./sales/creditNotes/creditOverdue";
// import CreditDraft from "./sales/creditNotes/creditDraft";
// import CreditRecurring from "./sales/creditNotes/creditRecurring";
// import CreditCancelled from "./sales/creditNotes/creditCancelled";
// import AddCredit from "./sales/creditNotes/addCredit";
// import CreditDetails from "./sales/creditNotes/creditDetails";
// import Purchases from "./purchases-component/purchases/purchases";
// import AddPurchases from "./purchases-component/purchases/add-purchases";
// import EditPurchase from "./purchases-component/purchases/edit-purchases";
// import PurchaseDetails from "./purchases-component/purchases/purchase-details";
// import PurchaseOrders from "./purchases-component/purchases/purchase-orders";
// import DebitNotes from "./purchases-component/purchases/debit-notes";
import Quotations from "./quotation-component/quotations";
import AddQuotations from "./quotation-component/quotations/addQuotations";
import EditQuotations from "./quotation-component/quotations/editQuotations";
import DeliveryChallans from "./quotation-component/deliveryChallans";
import EditChallans from "./quotation-component/deliveryChallans/editChallans";
import AddChallans from "./quotation-component/deliveryChallans/addChallans";
import PaymentSummary from "./reports-component/paymentSummary";
// import AddUser from "./user-management/manageUser";
import RolesPermission from "./user-management/rolePermission";
// import Permission from "./user-management/rolePermission/permission";
// import DeleteAccount from "./user-management/deleteAccount";
// import MembershipPlan from "./membership";
// import MembershipAddons from "./membership/membershipAddons";
// import Subscribers from "./membership/subscribers";
// import BasicInputs from "./ui-interface/forms/basic-inputs";
// import FormInputGroups from "./ui-interface/forms/input-groups";
// import HorizontalForm from "./ui-interface/forms/horizontal-form";
// import VerticalForm from "./ui-interface/forms/vertical-form";
// import FormMask from "./ui-interface/forms/FormMask";
// import FormValidation from "./ui-interface/forms/FormValidation";
// import Fileupload from "./ui-interface/forms/File-upload";
// import Formselect2 from "./ui-interface/forms/form-select2";
// import BasicTables from "./ui-interface/tables/BasicTables";
// import Datatables from "./ui-interface/tables/DataTables";

// import Register from "./authentication/Register";
// import ForgotPassword from "./authentication/forgot-password";
// import LockScreen from "./authentication/lock-screen";
// import AddProduct from "./inventory/products/addProduct";
// import EditProduct from "./inventory/products/editProduct";
// import ActiveCustomers from "./customers/activeCustomers";
// import DeactiveCustomers from "./customers/deactivateCustomers";
// import CustomerDetails from "./customers/CustomerMenu/customerDetails";

import ProductList from "./inventory/products/productList";
import Units from "./inventory/products/units";
import Inventory from "./inventory";

// import InvoiceTemplate from "./sales/invoices/invoice-template";
// import InvoiceDetailsAdmin from "./sales/invoices/Invoice-details-admin";
import Category from "./inventory/products/category";
// import Components from "./components/Index";

// import ProfitlossReport from "./reports-component/reports/profitloss";

import EmailSettings from "./settings/EmailSettings";
// import ExpenseCategory from "./settings/ExpenseCategory";
import Notifications from "./settings/Notifications";
import ChangePassword from "./settings/ChangePassword";
import Page404 from "./pages/errorpages/404";
// import Page500 from "./pages/errorpages/500";
// import BlankPage from "./pages/blank-page";
// import CreditNotes from "./sales/creditNotes";
import Domain from "./main/superadmin/domain";
import PurchaseTransaction from "./main/superadmin/purchase-transaction";
// import AddPurchaseOrder from "./purchases-component/purchases/add-purchase-order";
// import EditPurchaseOrder from "./purchases-component/purchases/edit-purchase-order";
// import EditPurchaseReturn from "./purchases-component/purchases/edit-purchse-return";
import SignatureList from "./signature/signature-list";
import SignatureInvoice from "./signature/signature-invoice";

// import InvoiceThree from "./sales/invoices/Invoice-three";
// import InvoiceFive from "./sales/invoices/Invoice-five";

import QuotationReport from "./reports-component/reports/quotationReport";
import PaymentReport from "./reports-component/reports/paymentReport";
import StockReport from "./reports-component/reports/stockReport";
import LowstockReport from "./reports-component/reports/lowstockReport";
import IncomeReport from "./reports-component/reports/incomeReport";
// import TaxReport from "./reports-component/reports/taxreport";

// import CashRecepitOne from "./sales/invoices/cashrecepitone";
// import CashRecepitFour from "./sales/invoices/CashRecepitFour";
import TwoFactor from "./settings/two-factor";
import CustomField from "./settings/custom-field";
import PlanBilling from "./settings/plan-billing";
import TaxRates from "./settings/tax-rates";
// import SignaturePreviewInvoice from "./signature/signature-preview-invoice";
// import TicketsOpen from "./support/tickets/ticketPending";
// import TicketsResolved from "./support/tickets/ticketOverdue";
// import TicketPending from "./support/tickets/ticketDraft";
// import CashRecepitThree from "./sales/invoices/CashRecepitThree";
// import CashRecepitTwo from "./sales/invoices/CashRecepitTwo";
import Preferences from "./settings/Preferences";
// import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
// import EditCreditnote from "./sales/creditNotes/edit-creditnote";
// import SassLogin from "./authentication/sass-login";
// import SassRegister from "./authentication/sass-register";
import EmailTemplates from "./settings/email-templates";
import SeoSettings from "./settings/seosettings";
import SaasSettings from "./settings/saassettings";
// import Superdashbord from "./main/superadmin/dashboard";
// import PackagesList from "./main/superadmin/planlist";
// import InvoiceSubscription from "./main/superadmin/invoicesubscription";

// import Journal from "./finance-accounts/journal";

import TransactionTypes from "./accountsfinance/TransactionTypes";

import LedgerComponent from "./accountsfinance/ledger";

// import PlaidLinkComponent from "./reports-component/reports/plaidLinkComponent";
import UserComponent from "./users/UserComponent";
import AddUserComponent from "./users/AddUserComponent";
import UserProfile from "./settings/UserProfile";
import RegistrationVerificationForm from "./authentication/registration-verification-form";

// import VendorMain from "./customers/vendors/index";
// // import Vendors from "./customers/vendors";

import ShowInvoice from "./sales/invoices/show-invoice";
// import PlaidDashboard from "./reports-component/reports/PlaidDashboard";
import CPAClientList from "./cpa/clients/list";

import Contacts from "./CustomComponents/Contacts";
import AddContacts from "./CustomComponents/AddContacts";
import BillSetting from "./settings/BillSetting";
import RoleComponent from "./roles/RoleComponent";
import AddRoleComponent from "./roles/AddRoleComponent";
import Permission from "./user-management/rolePermission/permission";
import QuickBooksIntegration from "./third-party-integration/quickbook/integration";
import InvoiceViewOne from "./sales/invoices/invoice-view-one";
import InvoiceTwo from "./sales/invoices/Invoice-two";
import CustomInvoiceTwo from "./sales/invoices/CustomInvoiceTwo";
// import invoiceTwoTemplate from "./sales/invoices/InvoiceTwoTemplate";
// import Test from "./CustomComponents/Test";
import TransactionRuleComponent from "./accountsfinance/TransactionRuleComponent";
import AddTransactionRuleComponent from "./accountsfinance/AddTransactionRuleComponent";
import XeroIntegration from "./third-party-integration/xero/integration";

const MainLayout = ({ children }) => {
    const [menu, setMenu] = useState(false);
    const toggleMobileMenu = () => {
        setMenu(!menu);
    };

    return (
        <div className={`main-wrapper ${menu ? "slide-nav" : ""}`}>
            <Header onMenuClick={() => toggleMobileMenu()} />
            <Sidebar />
            <div className="row">
                <main className="">{children}</main>
            </div>
        </div>
    );
};

MainLayout.propTypes = {
    children: PropTypes.node.isRequired // Ensure 'children' is required and can be any renderable content (node)
};

const AppContainer = () => {
    return (
        <Router basename="">
            <Switch>
                {/* Login route, no Header or Sidebar */}
                <Route exact path="/" component={Login} />
                <Route exact path="/login" component={Login} />
                <Route
                    path="/verify_registration"
                    component={RegistrationVerificationForm}
                />
                <ProtectedRoute path="/inv-2" component={InvoiceTwo} />
                <ProtectedRoute
                    path="/cust-inv-2"
                    component={CustomInvoiceTwo}
                />

                {/* <ProtectedRoute
                    path="/inv-2-template"
                    component={invoiceTwoTemplate}
                /> */}
                {/* <Route
                            path="/edit-bills/:id"
                            render={props => (
                                <ProtectedRoute
                                    {...props}
                                    component={AddPurchaseInvoice}
                                />
                            )}
                        /> */}

                {/* Protected routes wrapped in MainLayout */}
                <Route
                    path="/"
                    render={({ props }) => (
                        <MainLayout>
                            <Switch>
                                <ProtectedRoute
                                    path="/index"
                                    component={Dashboard}
                                />
                                <ProtectedRoute
                                    path="/items"
                                    component={ItemFormFields}
                                />
                                <ProtectedRoute
                                    path="/add-items"
                                    component={AddItems}
                                />
                                <ProtectedRoute
                                    path="/edit-items/:id"
                                    component={AddItems}
                                />

                                <ProtectedRoute
                                    path="/bills"
                                    component={PurchaseInvoice}
                                />
                                <ProtectedRoute
                                    path="/add-bills"
                                    component={AddPurchaseInvoice}
                                />
                                <ProtectedRoute
                                    path="/edit-bills/:id"
                                    component={AddPurchaseInvoice}
                                />

                                <ProtectedRoute
                                    path="/invoices"
                                    component={InvoiceList}
                                />
                                <ProtectedRoute
                                    path="/add-invoices"
                                    component={AddInvoice}
                                />
                                <ProtectedRoute
                                    path="/edit-invoices/:id"
                                    component={AddInvoice}
                                />

                                <ProtectedRoute
                                    path="/Customers"
                                    component={Customers}
                                />
                                <ProtectedRoute
                                    path="/add-customers"
                                    component={AddCustomerForm}
                                />
                                <ProtectedRoute
                                    path="/edit-customers/:id"
                                    component={AddCustomerForm}
                                />

                                <ProtectedRoute
                                    path="/vendors"
                                    component={Vendors}
                                />
                                <ProtectedRoute
                                    {...props}
                                    path="/add-vendors"
                                    component={addVendorForm}
                                />
                                <ProtectedRoute
                                    path="/edit-vendors/:id"
                                    component={addVendorForm}
                                />

                                <ProtectedRoute
                                    path="/expenses"
                                    component={ExpensesList}
                                />
                                <ProtectedRoute
                                    path="/add-expenses"
                                    component={AddExpenses}
                                />
                                <ProtectedRoute
                                    path="/edit-expenses/:id"
                                    component={AddExpenses}
                                />

                                <ProtectedRoute
                                    path="/accounts"
                                    component={AccountComponent}
                                />
                                <ProtectedRoute
                                    path="/add-accounts"
                                    component={AddAccountComponent}
                                />
                                <ProtectedRoute
                                    path="/edit-accounts/:id"
                                    component={AddAccountComponent}
                                />

                                <ProtectedRoute
                                    path="/account_types"
                                    component={AccountTypes}
                                />

                                <ProtectedRoute
                                    path="/transactions"
                                    component={TransactionComponent}
                                />
                                <ProtectedRoute
                                    path="/plaid-transactions"
                                    component={PlaidTransactionComponent}
                                />
                                <ProtectedRoute
                                    path="/transaction-types"
                                    component={TransactionTypes}
                                />
                                <ProtectedRoute
                                    path="/add-transactions"
                                    component={TransactionForm}
                                />
                                <ProtectedRoute
                                    path="/edit-transactions/:id"
                                    component={TransactionForm}
                                />

                                <ProtectedRoute
                                    path="/journals"
                                    component={JournalList}
                                />
                                <ProtectedRoute
                                    path="/add-journals"
                                    component={AddJournals}
                                />
                                <ProtectedRoute
                                    path="/edit-journals/:id"
                                    component={AddJournals}
                                />

                                <ProtectedRoute
                                    path="/journal_types"
                                    component={JournalTypesList}
                                />

                                <ProtectedRoute
                                    path="/branches"
                                    component={BranchList}
                                />
                                <ProtectedRoute
                                    path="/add-branches"
                                    component={AddBranch}
                                />
                                <ProtectedRoute
                                    path="/edit-branches/:id"
                                    component={AddBranch}
                                />

                                <ProtectedRoute
                                    path="/bank_accounts"
                                    component={BankAccounts}
                                />
                                <ProtectedRoute
                                    path="/add-bank_accounts"
                                    component={AddBankAccount}
                                />
                                <ProtectedRoute
                                    path="/edit-bank_accounts/:id"
                                    component={AddBankAccount}
                                />

                                <ProtectedRoute
                                    path="/roles_permission"
                                    component={RoleComponent}
                                />
                                <ProtectedRoute
                                    path="/add-roles"
                                    component={AddRoleComponent}
                                />
                                <ProtectedRoute
                                    path="/edit-roles/:id"
                                    component={AddRoleComponent}
                                />

                                <ProtectedRoute
                                    path="/permission/:id"
                                    component={Permission}
                                />
                                <ProtectedRoute
                                    path="/ledger/:id"
                                    component={LedgerComponent}
                                />
                                <LedgerComponent
                                    path="/ledger"
                                    component={LedgerComponent}
                                />
                                <ProtectedRoute
                                    path="/trial-balance-list"
                                    component={TrialBalance}
                                />
                                <ProtectedRoute
                                    path="/profit-loss-list"
                                    component={ProfitLossList}
                                />
                                <ProtectedRoute
                                    path="/balance-sheet"
                                    component={BalanceSheetReport}
                                />
                                <ProtectedRoute
                                    path="/expense-report"
                                    component={expenseReport}
                                />
                                <ProtectedRoute
                                    path="/tax-report"
                                    component={Taxreport}
                                />
                                <ProtectedRoute
                                    path="/purchase-report"
                                    component={PurchaseReport}
                                />
                                <ProtectedRoute
                                    path="/purchase-return"
                                    component={PurchaseReturnReport}
                                />
                                <ProtectedRoute
                                    path="/sales-report"
                                    component={SalesReport}
                                />
                                <ProtectedRoute
                                    path="/sales-return-report"
                                    component={SalesReturn}
                                />
                                <ProtectedRoute
                                    path="/users"
                                    component={UserComponent}
                                />
                                <ProtectedRoute
                                    path="/add-users"
                                    component={AddUserComponent}
                                />
                                <ProtectedRoute
                                    path="/edit-users/:id?"
                                    component={AddUserComponent}
                                />
                                <ProtectedRoute
                                    path="/user-profile"
                                    component={UserProfile}
                                />
                                <ProtectedRoute
                                    path="/settings"
                                    component={AccountSettings}
                                />

                                <ProtectedRoute
                                    path="/preferences"
                                    component={Preferences}
                                />
                                <ProtectedRoute
                                    path="/dashbord"
                                    component={Dashboard}
                                />
                                <ProtectedRoute
                                    path="/Companies"
                                    component={Companies}
                                />
                                <ProtectedRoute
                                    path="/subscription"
                                    component={Subscription}
                                />
                                <ProtectedRoute
                                    path="/packages"
                                    component={Packages}
                                />
                                <ProtectedRoute
                                    path="/domain"
                                    component={Domain}
                                />
                                <ProtectedRoute
                                    path="/purchase-transaction"
                                    component={PurchaseTransaction}
                                />
                                <ProtectedRoute
                                    path="/cpa/clients"
                                    component={CPAClientList}
                                />
                                <ProtectedRoute
                                    path="/contacts"
                                    component={Contacts}
                                />
                                <ProtectedRoute
                                    path="/company-settings"
                                    component={CompanySettings}
                                />
                                <ProtectedRoute
                                    path="/invoice-settings"
                                    component={InvoiceSetting}
                                />
                                <ProtectedRoute
                                    path="/change-password"
                                    component={ChangePassword}
                                />
                                <ProtectedRoute
                                    path="/bill-settings"
                                    component={BillSetting}
                                />
                                <ProtectedRoute
                                    path="/invoice-templatesettings"
                                    component={InvoiceTemplateSettings}
                                />
                                <ProtectedRoute
                                    path="/paymentmethod"
                                    component={PaymentMethod}
                                />
                                <ProtectedRoute
                                    path="/banksetting"
                                    component={BankSetting}
                                />
                                <ProtectedRoute
                                    path="/tax-rates"
                                    component={TaxRates}
                                />
                                <ProtectedRoute
                                    path="/plan-pilling"
                                    component={PlanBilling}
                                />
                                <ProtectedRoute
                                    path="/two-factor"
                                    component={TwoFactor}
                                />
                                <ProtectedRoute
                                    path="/custom-field"
                                    component={CustomField}
                                />
                                <ProtectedRoute
                                    path="/email-settings"
                                    component={EmailSettings}
                                />
                                <ProtectedRoute
                                    path="/emailtemplates"
                                    component={EmailTemplates}
                                />

                                <ProtectedRoute
                                    path="/invoice-two"
                                    component={InvoiceTwo}
                                />

                                <ProtectedRoute
                                    path="/seosettings"
                                    component={SeoSettings}
                                />
                                <ProtectedRoute
                                    path="/sasssettings"
                                    component={SaasSettings}
                                />
                                <ProtectedRoute path="/chat" component={Chat} />
                                <ProtectedRoute
                                    path="/calendar"
                                    component={Calendar}
                                />

                                <ProtectedRoute
                                    path="/add-contacts"
                                    component={AddContacts}
                                />
                                <ProtectedRoute
                                    path="/edit-contacts/:id"
                                    component={AddContacts}
                                />

                                <ProtectedRoute
                                    path="/inbox"
                                    component={Inbox}
                                />
                                <ProtectedRoute
                                    path="/login"
                                    component={Login}
                                />
                                <ProtectedRoute
                                    path="/product-list"
                                    component={ProductList}
                                />
                                <ProtectedRoute
                                    path="/category"
                                    component={Category}
                                />
                                <ProtectedRoute
                                    path="/units"
                                    component={Units}
                                />
                                <ProtectedRoute
                                    path="/inventory"
                                    component={Inventory}
                                />
                                <ProtectedRoute
                                    path="/quotations"
                                    component={Quotations}
                                />
                                <ProtectedRoute
                                    path="/add-quotations"
                                    component={AddQuotations}
                                />
                                <ProtectedRoute
                                    path="/edit-quotations"
                                    component={EditQuotations}
                                />
                                {/* <ProtectedRoute path="/signature-list" component={SignaturePreviewInvoice} /> */}
                                <ProtectedRoute
                                    path="/signature-invoice"
                                    component={SignatureInvoice}
                                />

                                <ProtectedRoute
                                    path="/signature-list"
                                    component={SignatureList}
                                />
                                <ProtectedRoute
                                    path="/delivery-challans"
                                    component={DeliveryChallans}
                                />
                                <ProtectedRoute
                                    path="/edit-delivery-challans"
                                    component={EditChallans}
                                />
                                <ProtectedRoute
                                    path="/add-delivery-challans"
                                    component={AddChallans}
                                />
                                <ProtectedRoute
                                    path="/roles-permission"
                                    component={RolesPermission}
                                />
                                <ProtectedRoute
                                    path="/delete-account-request"
                                    component={DeleteAccounts}
                                />
                                <ProtectedRoute
                                    path="/quotation-report"
                                    component={QuotationReport}
                                />
                                <ProtectedRoute
                                    path="/payment-report"
                                    component={PaymentReport}
                                />
                                <ProtectedRoute
                                    path="/payments"
                                    component={Payments}
                                />
                                <ProtectedRoute
                                    path="/notifications"
                                    component={Notifications}
                                />
                                <ProtectedRoute
                                    path="/roles-permission"
                                    component={RoleComponent}
                                />
                                <ProtectedRoute
                                    path="/add-roles"
                                    component={AddRoleComponent}
                                />
                                <ProtectedRoute
                                    path="/edit-roles/:id?"
                                    component={AddRoleComponent}
                                />
                                <ProtectedRoute
                                    path="/stock-report"
                                    component={StockReport}
                                />
                                <ProtectedRoute
                                    path="/low-stock-report"
                                    component={LowstockReport}
                                />
                                <ProtectedRoute
                                    path="/income-report"
                                    component={IncomeReport}
                                />
                                <ProtectedRoute path="/1" component={Page404} />
                                <ProtectedRoute
                                    path="/bank-accounts"
                                    component={BankAccounts}
                                />
                                <ProtectedRoute
                                    path="/view-invoices/:id?"
                                    component={ShowInvoice}
                                />
                                <ProtectedRoute
                                    path="/inv-1"
                                    component={InvoiceViewOne}
                                />

                                {/* ShowInvoice */}
                                <ProtectedRoute
                                    path="/payment-summary"
                                    component={PaymentSummary}
                                />
                                {/* <ProtectedRoute path="/" component={} /> */}
                                {/* <ProtectedRoute path="/edit-customers/:id?" component={AddCustomerForm} /> */}
                                {/* <ProtectedRoute path="/" component={AddPurchaseReturn} />
                                <ProtectedRoute path="/" component={DomainRequest} /> */}
                                {/* Add all your other routes here */}

                                <ProtectedRoute
                                    path="/quick-books-integration"
                                    component={QuickBooksIntegration}
                                />

                                <ProtectedRoute
                                    path="/xero-integration"
                                    component={XeroIntegration}
                                />

                                <ProtectedRoute
                                    path="/transaction-rules"
                                    component={TransactionRuleComponent}
                                />
                                <ProtectedRoute
                                    path="/add-transaction-rules"
                                    component={AddTransactionRuleComponent}
                                />
                                <ProtectedRoute
                                    path="/edit-transaction-rules/:id"
                                    component={AddTransactionRuleComponent}
                                />

                            </Switch>
                        </MainLayout>
                    )}
                />
            </Switch>
        </Router>
    );
};

export default AppContainer;
