import React from 'react';
import PageComponent from '../CustomComponents/PageComponent';

const UserComponent = () => {
  return(
    <PageComponent listAPI='users' label='Users' addType='nextPage' addLabel='User'/>
  )
}

export default UserComponent;
//  import React, { useState, useEffect } from 'react';
// import "../_components/antd.css";
// import { Table, Tooltip } from 'antd';
// import {
//   onShowSizeChange,
//   itemRender,
// } from "../_components/paginationfunction";
// import { Link, useHistory } from "react-router-dom";
// import FeatherIcon from 'feather-icons-react';
// // import AddUser from "./addVendor";
// import { filterIcon } from "../_components/Imagepath";
// import axios from 'axios';
// import { useAuth } from "../contexts/AuthContext";
// import Sidebar from '../layouts/Sidebar';
// import Header from '../layouts/Header';

// const UserComponent = () => {
//   const { auth } = useAuth();
//   const history = useHistory();
//   const [menu, setMenu] = useState(false);
//   const [show, setShow] = useState(false);
//   const [vendorData, setVendorData] = useState([]);
//   const [columns, setColumns] = useState([]);

//   const toggleMobileMenu = () => {
//     setMenu(!menu);
//   };

//   const fetchUserDetails = async () => {
//     try {
//       const response = await axios.get(`${process.env.REACT_APP_API_V1_BASE_URL}/users`, {
//         headers: {
//           Authorization: `Bearer ${auth}`,
//         },
//       });
//       console.log(response.data);
//       const records = response.data.records;
//       const headers = response.data.headers;

//       const dynamicColumns = headers.map(header => ({
//         title: header.display_name,
//         dataIndex: header.field_name,
//         sorter: header.sortable ? (a, b) => a[header.field_name]?.localeCompare(b[header.field_name]) : null,
//         render: (text) => text,
//       }));

//       dynamicColumns.push({
//         title: 'Actions',
//         dataIndex: 'actions',
//         render: (_, record) => (
//           <div className="d-flex align-items-center">
//             <button
//               className="btn btn-action-icon me-2"
//               onClick={() => handleEdit(record.id)}
//             >
//               <i className="far fa-edit me-2" />
//               Edit
//             </button>
//             <button 
//               className="btn btn-action-icon" 
//               onClick={() => handleDelete(record.id)}
//             >
//               <i className="far fa-trash-alt me-2" />
//               Delete
//             </button>
//           </div>
//         ),
//       });

//       setColumns(dynamicColumns);
//       setVendorData(records);
//     } catch (error) {
//       console.error("Error fetching customer details:", error);
//     }
//   };


//   const handleEdit = (id) => {
//     history.push(`/edit-users/${id}`);
//   };


//   const handleDelete = async (id) => {
//     try {
//       const response = await axios.delete(`${process.env.REACT_APP_API_V1_BASE_URL}/users/${id}`, {
//         headers: {
//           Authorization: `Bearer ${auth}`,
//         },
//       });
  
//       if (response.status === 200) {
//         console.log("Account type deleted successfully.");
//         fetchUserDetails();
//       }
//     } catch (error) {
//       console.error("There was an error deleting the account type:", error.response ? error.response.data : error.message);
//     }
//   };

//   const handleAddUserClick = () => {
//     history.push('/add-users');
//     console.log("Clicked");
//   };

//   useEffect(() => {
//     fetchUserDetails();
//   }, [auth]);

//   return (
//     <>
//       <div className={`main-wrapper ${menu ? "slide-nav" : ""}`}>
//         <Header onMenuClick={() => toggleMobileMenu()} />
//         <Sidebar />

//         <div className="page-wrapper">
//           <div className="content container-fluid">
//             {/* Page Header */}
//             <div className="page-header">
//               <div className="content-page-header ">
//                 <h5>Users</h5>
//                 <div className="list-btn">
//                   <ul className="filter-list">
//                     <li>
//                       <Link
//                         to="#"
//                         className="btn btn-filters w-auto popup-toggle me-2"
//                         onClick={() => setShow(!show)}
//                       >
//                         <span className="me-2">
//                            <Tooltip title="Filter">
//                             <img src={filterIcon} alt="filter" />
//                           </Tooltip>
//                         </span>
//                         Filter
//                       </Link>
//                     </li>
//                     <li className="">
//                       <div className="dropdown dropdown-action me-2">
//                         <Link
//                           to="#"
//                           className="btn-filters me-2"
//                           data-bs-toggle="dropdown"
//                           aria-expanded="false"
//                         >
//                           <span>
//                           <Tooltip title="Download" placement="bottom">
//                           <i className="fe fe-download" />
//                         </Tooltip>
//                           </span>
//                         </Link>
//                         <div className="dropdown-menu dropdown-menu-end">
//                           <ul className="d-block">
//                             <li>
//                               <Link
//                                 className="d-flex align-items-center download-item"
//                                 to="#"
//                                 download=""
//                               >
//                                 <i className="far fa-file-pdf me-2" />
//                                 PDF
//                               </Link>
//                             </li>
//                             <li>
//                               <Link
//                                 className="d-flex align-items-center download-item"
//                                 to="#"
//                                 download=""
//                               >
//                                 <i className="far fa-file-text me-2" />
//                                 CVS
//                               </Link>
//                             </li>
//                           </ul>
//                         </div>
//                       </div>
//                     </li>
//                     <li>
//                       <Link className="btn-filters" to="#">
//                         <span>
//                         <Tooltip title="Print" placement="bottom">
//                         <i className="fe fe-printer" />
//                       </Tooltip>
//                         </span>{" "}
//                       </Link>
//                     </li>
//                     <li>
//                       <Link className="btn btn-import" to="#">
//                         <span>
//                           <FeatherIcon icon="check-square" className="me-2" />{" "}
//                           Import
//                         </span>
//                       </Link>
//                     </li>
//                     <li>
//                       {/* <Link
//                         className="btn btn-primary"
//                         to="#"
//                         data-bs-toggle="modal"
//                         data-bs-target="#add_Users"
//                       >
//                         <i
//                           className="fa fa-plus-circle me-2"
//                           aria-hidden="true"
//                         />
//                         Add Users
//                       </Link> */}
//                       <button
//                         className="btn btn-primary"
//                         onClick={handleAddUserClick}
//                       >
//                         <i className="fa fa-plus-circle me-2" aria-hidden="true" />
//                         Add Users
//                       </button>
//                     </li>
//                   </ul>
//                 </div>
//               </div>
//             </div>
//             {/* /Page Header */}
//             {/* Search Filter */}
//             <div id="filter_inputs" className="card filter-card">
//               <div className="card-body pb-0">
//                 <div className="row">
//                   <div className="col-sm-6 col-md-3">
//                     <div className="input-block mb-3">
//                       <label>Name</label>
//                       <input type="text" className="form-control" />
//                     </div>
//                   </div>
//                   <div className="col-sm-6 col-md-3">
//                     <div className="input-block mb-3">
//                       <label>Email</label>
//                       <input type="text" className="form-control" />
//                     </div>
//                   </div>
//                   <div className="col-sm-6 col-md-3">
//                     <div className="input-block mb-3">
//                       <label>Phone</label>
//                       <input type="text" className="form-control" />
//                     </div>
//                   </div>
//                 </div>
//               </div>
//             </div>
//             {/* /Search Filter */}
//             <div className="row">
//               <div className="col-sm-12">
//                 <div className=" card-table">
//                   <div className="card-body vendors">
//                     <div className="table-responsive table-hover table-striped">
//                     <Table
//                         className="table"
//                         pagination={{
//                           total: vendorData.length,
//                           showTotal: (total, range) =>
//                             `Showing ${range[0]} to ${range[1]} of ${total} entries`,
//                           onShowSizeChange: onShowSizeChange,
//                           itemRender: itemRender,
//                         }}
//                         columns={columns}
//                         dataSource={vendorData}
//                         rowKey={(record) => record.id}
//                       />
//                     </div>
//                   </div>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     </>
//   );
// };

// export default UserComponent;
