import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import {
    avatar02,
    avatar05,
    avatar07,
    avatar10,
    avatar13,
    logo,
    logoSmall,
    logoSmallWhite,
    search
} from "../_components/Imagepath";
import { FormControl } from "@mui/material";
import CustomDropdown from "../CustomComponents/CustomDropdown";
import { useAuth } from "../contexts/AuthContext";
import axios from "axios";

const Header = props => {
    const history = useHistory();
    const { auth, logout } = useAuth(); // access authentication status
    const [selectedBranch, setSelectedBranch] = useState("");
    const [branches, setBranches] = useState([]);

    const userDataString = localStorage.getItem("userData");
    const userData = userDataString ? JSON.parse(userDataString) : null;
    const firstName = userData?.contact?.first_name || '';
    const lastName = userData?.contact?.last_name || '';
    const branchId = userData?.branch_id || '';
    const role = userData?.role?.name || '';

    const roleId = sessionStorage.getItem("role_id");


    const handleLogout = async () => {
        try {
            await fetch(`${process.env.REACT_APP_API_V1_BASE_URL}/logout`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `${auth}`
                }
            });
            logout();
            history.push("/");
            // window.location.reload();
        } catch (error) {
            logout();
            history.push("/");
            // window.location.reload();
        }
    };
         const onMenuClik = () => {
       // eslint-disable-next-line react/prop-types
        props.onMenuClick();
    };

    useEffect(() => {
        if (branches.length > 0 && branchId) {
            const selectedBranchObj = branches.find(branch => branch.id === branchId);
            setSelectedBranch(selectedBranchObj ? selectedBranchObj.name : '');
        }
    }, [branches, branchId]);

    useEffect(() => {
        const fetchDropdownData = async () => {
            try {
                const response = await axios.post(
                    `${process.env.REACT_APP_API_V1_BASE_URL}/get_dropdown_data`,
                    { models: [{ model_name: "Branch" }] },
                    {
                        headers: {
                            "Content-Type": "application/json",
                            Authorization: `Bearer ${auth}`
                        }
                    }
                );
                const { Branch } = response.data.content;
                setBranches(Branch || []);

                if (Branch && branchId) {
                    const selectedBranchObj = Branch.find(branch => branch.id === branchId);
                    if (selectedBranchObj) {
                        setSelectedBranch(selectedBranchObj.name);
                    }
                }
            } catch (err) {
                console.error("Failed to load dropdown data:", err);
            }
        };

        if (auth) { // only fetch data if authenticated
            fetchDropdownData();
        }
    }, [auth, roleId, branchId]);

    const handleBranchChange = async branch => {
        setSelectedBranch(branch.name);
        try {
            const response = await axios.patch(
                `${process.env.REACT_APP_API_V1_BASE_URL}/users_with_contact/${branchId}`,
                { user_id: 1 },
               {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${auth}`
                    }
                }
            );

            if (response.status === 200) {
                console.log("Branch updated successfully");
            } else {
                console.error("Failed to update branch");
            }
        } catch (error) {
            console.error("Error updating branch:", error);
        }
    };

    // Conditionally render header and sidebar
    if (!auth) {
        return null; // Do not render if not authenticated
    }


    return (
        <div className="header header-one">
            <Link
                to="index"
                className="d-inline-flex d-sm-inline-flex align-items-center d-md-inline-flex d-lg-none align-items-center device-logo"
            >
                {/* <img src={logo} className="img-fluid logo2" alt="Logo" /> */}
                <img src={logo} className="img-fluid logo2" alt="Logo" />
            </Link>
            <div className="main-logo d-inline float-start d-lg-flex align-items-center d-none d-sm-none d-md-none">
                <div className="logo-white">
                    <Link to="index">
                        <img
                            src={logo}
                            className="img-fluid logo-blue"
                            alt="Logo"
                        />
                        {/* <img src={AiaLogoWithName} className="img-fluid logo-blue" alt="Logo" /> */}
                    </Link>
                    <Link to="index">
                        <img
                            src={logoSmallWhite}
                            className="img-fluid logo-small"
                            alt="Logo"
                        />
                        {/* <Link to="index">
 <img
 src={AiaLogoWithName}
 className="img-fluid logo-small"
 alt="Logo"
 /> */}
                    </Link>
                </div>
                <div className="logo-color">
                    <Link to="index">
                        {/* <img src={logo} className="img-fluid logo-blue" alt="Logo" /> */}
                        <img
                            src={logoSmallWhite}
                            className="img-fluid logo-blue"
                            alt="Logo"
                        />
                    </Link>
                    <Link to="index">
                        <img
                            src={logoSmall}
                            className="img-fluid logo-small"
                            alt="Logo"
                        />
                        {/* <img src={AiaLogoWithName} className="img-fluid logo-small" alt="Logo" /> */}
                    </Link>
                </div>
            </div>
            {/* Sidebar Toggle */}
            <Link to="#" id="toggle_btn" >
                <span className="toggle-bars">
                    <span className="bar-icons" />
                    <span className="bar-icons" />
                    <span className="bar-icons" />
                    <span className="bar-icons" />
                </span>
            </Link>
            {/* /Sidebar Toggle */}
            {/* Search */}
            <div className="top-nav-search">
                <form>
                    <input
                        type="text"
                        className="form-control"
                        placeholder="Search here"
                    />
                    <button className="btn" type="submit">
                        <img src={search} alt="img" />
                    </button>
                </form>
            </div>
            {/* /Search */}
            {/* Mobile Menu Toggle */}
            <Link
                to="#"
                className="mobile_btn"
                id="mobile_btn"
                onClick={() => onMenuClik()}
            >
                <i className="fas fa-bars" />
            </Link>
            {/* /Mobile Menu Toggle */}
            {/* Header Menu */}
            <ul className="nav nav-tabs user-menu">
                {/* Flag */}
                {/* <li className="nav-item dropdown has-arrow flag-nav">
 <Link
 className="nav-link dropdown-toggle"
 data-bs-toggle="dropdown"
 to="#"
 role="button"
 >
 <img src={us1} alt="flag" />
 <span>English</span>
 </Link>
 <div className="dropdown-menu dropdown-menu-end">
 <Link to="#" className="dropdown-item">
 <img src={us} alt="flag" />
 <span>English</span>
 </Link>
 <Link to="#" className="dropdown-item">
 <img src={fr} alt="flag" />
 <span>French</span>
 </Link>
 <Link to="#" className="dropdown-item">
 <img src={es} alt="flag" />
 <span>Spanish</span>
 </Link>
 <Link to="#" className="dropdown-item">
 <img src={de} alt="flag" />
 <span>German</span>
 </Link>
 </div>
 </li> */}

                <FormControl variant="outlined" sx={{ m: 1, minWidth: 120 }}>
                    <CustomDropdown
                        label="Branch"
                        selectedValue= {selectedBranch}//{selectedBranchName}
                        options={branches}
                        onOptionSelect={handleBranchChange}
                    />
                </FormControl>
                {/* /Flag */}
                <li className="nav-item dropdown flag-nav dropdown-heads">
                    <Link
                        className="nav-link"
                        data-bs-toggle="dropdown"
                        to="#"
                        role="button"
                    >
                        <i className="fe fe-bell" />{" "}
                        <span className="badge rounded-pill" />
                    </Link>
                    <div className="dropdown-menu notifications">
                        <div className="topnav-dropdown-header">
                            <div className="notification-title">
                                Notifications{" "}
                                <Link to="/notifications">View all</Link>
                            </div>
                            <Link
                                to="#"
                                className="clear-noti d-flex align-items-center"
                            >
                                Mark all as read{" "}
                                <i className="fe fe-check-circle" />
                            </Link>
                        </div>
                        <div className="noti-content">
                            <ul className="notification-list">
                                <li className="notification-message">
                                    <Link to="/profile">
                                        <div className="d-flex">
                                            <span className="avatar avatar-md active">
                                                <img
                                                    className="avatar-img rounded-circle"
                                                    alt="avatar-img"
                                                    src={avatar02}
                                                />
                                            </span>
                                            <div className="media-body">
                                                <p className="noti-details">
                                                    <span className="noti-title">
                                                        Lex Murphy
                                                    </span>{" "}
                                                    requested access to{" "}
                                                    <span className="noti-title">
                                                        UNIX directory tree
                                                        hierarchy
                                                    </span>
                                                </p>
                                                <div className="notification-btn">
                                                    <span className="btn btn-primary">
                                                        Accept
                                                    </span>
                                                    <span className="btn btn-outline-primary">
                                                        Reject
                                                    </span>
                                                </div>
                                                <p className="noti-time">
                                                    <span className="notification-time">
                                                        Today at 9:42 AM
                                                    </span>
                                                </p>
                                            </div>
                                        </div>
                                    </Link>
                                </li>
                                <li className="notification-message">
                                    <Link to="/profile">
                                        <div className="d-flex">
                                            <span className="avatar avatar-md active">
                                                <img
                                                    className="avatar-img rounded-circle"
                                                    alt="avatar-img"
                                                    src={avatar10}
                                                />
                                            </span>
                                            <div className="media-body">
                                                <p className="noti-details">
                                                    <span className="noti-title">
                                                        Ray Arnold
                                                    </span>{" "}
                                                    left 6 comments{" "}
                                                    <span className="noti-title">
                                                        on Isla Nublar SOC2
                                                        compliance report
                                                    </span>
                                                </p>
                                                <p className="noti-time">
                                                    <span className="notification-time">
                                                        Yesterday at 11:42 PM
                                                    </span>
                                                </p>
                                            </div>
                                        </div>
                                    </Link>
                                </li>
                                <li className="notification-message">
                                    <Link to="/profile">
                                        <div className="d-flex">
                                            <span className="avatar avatar-md">
                                                <img
                                                    className="avatar-img rounded-circle"
                                                    alt="avatar-img"
                                                    src={avatar13}
                                                />
                                            </span>
                                            <div className="media-body">
                                                <p className="noti-details">
                                                    <span className="noti-title">
                                                        Dennis Nedry
                                                    </span>{" "}
                                                    commented on{" "}
                                                    <span className="noti-title">
                                                        {" "}
                                                        Isla Nublar SOC2
                                                        compliance report
                                                    </span>
                                                </p>
                                                <blockquote>
                                                    “Oh, I finished de-bugging
                                                    the phones, but the systems
                                                    compiling for eighteen
                                                    minutes, or twenty. So, some
                                                    minor systems may go on and
                                                    off for a while.”
                                                </blockquote>
                                                <p className="noti-time">
                                                    <span className="notification-time">
                                                        Yesterday at 5:42 PM
                                                    </span>
                                                </p>
                                            </div>
                                        </div>
                                    </Link>
                                </li>
                                <li className="notification-message">
                                    <Link to="/profile">
                                        <div className="d-flex">
                                            <span className="avatar avatar-md">
                                                <img
                                                    className="avatar-img rounded-circle"
                                                    alt="avatar-img"
                                                    src={avatar05}
                                                />
                                            </span>
                                            <div className="media-body">
                                                <p className="noti-details">
                                                    <span className="noti-title">
                                                        John Hammond
                                                    </span>{" "}
                                                    created{" "}
                                                    <span className="noti-title">
                                                        Isla Nublar SOC2
                                                        compliance report
                                                    </span>
                                                </p>
                                                <p className="noti-time">
                                                    <span className="notification-time">
                                                        Last Wednesday at 11:15
                                                        AM
                                                    </span>
                                                </p>
                                            </div>
                                        </div>
                                    </Link>
                                </li>
                            </ul>
                        </div>
                        <div className="topnav-dropdown-footer">
                            <Link to="#">Clear All</Link>
                        </div>
                    </div>
                </li>
                <li className="nav-item has-arrow dropdown-heads ">
                    <Link to="#" className="win-maximize">
                        <i className="fe fe-maximize" />
                    </Link>
                </li>
                {/* User Menu */}
                <li className="nav-item dropdown">
                    <Link
                        to="#"
                        className="user-link nav-link"
                        data-bs-toggle="dropdown"
                    >
                        <span className="user-img">
                            <img
                                src={avatar07}
                                alt="img"
                                className="profilesidebar"
                            />
                            <span className="animate-circle" />
                        </span>
                        <span className="user-content">
                            <span className="user-details">{firstName} {lastName}</span>
                            <span className="user-name">{role}</span>
                        </span>
                    </Link>
                    <div className="dropdown-menu menu-drop-user">
                        <div className="profilemenu">
                            <div className="subscription-menu">
                                <ul>
                                    <li>
                                        <Link
                                            className="dropdown-item"
                                            to="/user-profile"
                                        >
                                            <i
                                                className="fe fe-user"
                                                data-bs-toggle="tooltip"
                                                title="feather-box"
                                            />
                                            <span>Profile</span>
                                        </Link>
                                    </li>

                                    <li>
                                        <Link
                                            className="dropdown-item"
                                            to="/settings"
                                        >
                                            <i
                                                className="fe fe-settings"
                                                data-bs-toggle="tooltip"
                                                title="feather-box"
                                            />
                                            <span>Settings</span>
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                            <div className="subscription-logout">
                                <ul>
                                    <li className="pb-0">
                                        <Link
                                            className="dropdown-item"
                                            to="#"
                                            onClick={handleLogout}
                                        >
                                            <i
                                                className="fe fe-power"
                                                data-bs-toggle="tooltip"
                                                title="feather-box"
                                            />
                                            <span>Log Out</span>
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </li>
                {/* /User Menu */}
            </ul>
            {/* /Header Menu */}
        </div>
    );
};
export default Header;
