import moment from 'moment';
// import currency from 'currency.js';
import numeral from 'numeral';

window.dateFormatOptions = () => {
    return [
        { code: 'YYYY-MM-DD', name: moment().format('YYYY-MM-DD'), datePickerCode: 'yyyy-MM-dd', phpCarbonFormat: 'Y-m-d' },
        { code: 'DD-MM-YYYY', name: moment().format('DD-MM-YYYY'), datePickerCode: 'dd-MM-yyyy', phpCarbonFormat: 'd-m-Y' },
        { code: 'MM/DD/YYYY', name: moment().format('MM/DD/YYYY'), datePickerCode: 'MM/dd/yyyy', phpCarbonFormat: 'm/d/Y' },
        { code: 'DD MMM, YYYY', name: moment().format('DD MMM, YYYY'), datePickerCode: 'dd MMM, yyyy', phpCarbonFormat: 'D M, Y' },
        { code: 'MMMM D, YYYY', name: moment().format('MMMM D, YYYY'), datePickerCode: 'MMMM d, yyyy', phpCarbonFormat: 'M d, Y' },
        { code: 'ddd, MMM D, YYYY', name: moment().format('ddd, MMM D, YYYY'), datePickerCode: 'EEE, MMM d, yyyy', phpCarbonFormat: 'D, M d, Y' },
        { code: 'dddd, MMMM D, YYYY', name: moment().format('dddd, MMMM D, YYYY'), datePickerCode: 'EEEE, MMMM d, yyyy', phpCarbonFormat: 'l, F d, Y' },
        { code: 'D MMMM YYYY', name: moment().format('D MMMM YYYY'), datePickerCode: 'd MMMM yyyy', phpCarbonFormat: 'j F Y' },
        { code: 'YYYY/MM/DD', name: moment().format('YYYY/MM/DD'), datePickerCode: 'yyyy/MM/dd', phpCarbonFormat: 'Y/m/d' },
        { code: 'Do MMMM YYYY', name: moment().format('Do MMMM YYYY'), datePickerCode: 'do MMMM yyyy', phpCarbonFormat: 'jS F Y' }
    ];
};

window.todayDate = (format) => {
    return moment().format(format);
}

window.reqFormatDate = (date, dateFormat = 'MM/DD/YYYY', requiredFormat = 'YYYY-MM-DD') => {
    if (!date) return null;

    let parsedDate;

    // Check if the date is a Date object
    if (date instanceof Date && !isNaN(date.getTime())) {
        parsedDate = moment(date);  // Already a valid Date object
    }
    // If it's a string, parse it with the provided format
    else if (typeof date === 'string') {
        parsedDate = moment(date, dateFormat, true);  // Parse using provided format
    } else {
        console.error('Invalid date type provided.');
        return null;
    }

    // Check if the parsed date is valid
    if (!parsedDate.isValid()) {
        console.error('Invalid date or format provided.');
        return null;
    }

    // Format the parsed date to the required format
    return parsedDate.format(requiredFormat);
};



window.datePickerDateFormat = (date, dateFormat = 'YYYY-MM-DD', requiredFormat = 'MM/DD/YYYY') => {
    if (!date) return null;

    // Parse the date with the provided format first to ensure consistency
    const parsedDate = moment(date, dateFormat, true);

    // Check if the date is valid
    if (!parsedDate.isValid()) {
        console.error('Invalid date or format provided.');
        return null;
    }

    // Format the parsed date to the required format
    return parsedDate.format(requiredFormat);
};

window.reqFormatDateTime = (date, dateFormat = 'YYYY-MM-DDTHH:mm', requiredFormat = 'YYYY-MM-DDTHH:mm') => {
    if (!date) return null;

    // Parse the date with the provided format first to ensure consistency
    const parsedDate = moment(date, dateFormat, true);

    // Check if the parsed date is valid
    if (!parsedDate.isValid()) {
        console.error('Invalid date or format provided.');
        return null;
    }

    // Format the parsed date to the required format
    return parsedDate.format(requiredFormat);
};

// Number formatting functions
window.formatFloat = (value, decimalPlaces = 2) => {
    return numeral(value).format(`0,0.${'0'.repeat(decimalPlaces)}`);
};

window.getDefaultDateFormat = () => {
    // Retrieve the date format from local storage or use a default value
    const userData = JSON.parse(localStorage.getItem('userData'));
    const defaultFormat = userData?.company?.date_format || process.env.REACT_APP_DEFAULT_DATE_FORMAT; // Default format if not found
    const dateFormatOption = window.dateFormatOptions().find(option => option.phpCarbonFormat === defaultFormat);
    // Return the matched datePickerCode or default to 'yyyy-MM-dd' if not found
    return dateFormatOption ? dateFormatOption.code : '';
}

window.getDefaultDateFormatForDatePicker = () => {
    // Retrieve the date format from local storage or use a default value
    const userData = JSON.parse(localStorage.getItem('userData'));
    const defaultFormat = userData?.company?.date_format || process.env.REACT_APP_DEFAULT_DATE_FORMAT;

    // Match the default format to the corresponding datePickerCode
    const dateFormatOption = window.dateFormatOptions().find(option => option.phpCarbonFormat === defaultFormat);
    // Return the matched datePickerCode or default to 'yyyy-MM-dd' if not found
    return dateFormatOption ? dateFormatOption.datePickerCode : '';
};


// Currency formatting functions
// window.formatCurrency = (value, currencyCode = '$', decimalPlaces = 2) => {
//     return currency(value, { symbol: currencyCode, precision: decimalPlaces }).format();
// };