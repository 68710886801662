import React from "react";
import PageComponent from "../../CustomComponents/PageComponent";

const Vendors = () => {
  return (
   <PageComponent listAPI='vendors' label='Vendors' addType='nextPage' addLabel='Vendor' />
    
  )
}

export default Vendors;
