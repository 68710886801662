import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { useAuth } from '../contexts/AuthContext';
import Swal from 'sweetalert2';

const ImportPopup = ({ id, listAPI, label, importDataLoad, addLabel }) => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [selectedFiles, setSelectedFiles] = useState([]); // State to track selected files (array for multiple)

    const { auth } = useAuth();
    const fileInputRef = useRef(null);

    const handleFileChange = (event) => {
        const files = event.target.files;
        setSelectedFiles(files); // Update the state with the selected files
    };

    const handleDownloadSample = async () => {
        setLoading(true);
        setError(null);

        try {
            const sampleFileUrl = `${process.env.REACT_APP_API_BASE_URL}/download-sample/${listAPI}.csv`;

            const response = await axios.get(sampleFileUrl, {
                responseType: 'blob',
            });

            const fileBlob = new Blob([response.data], { type: 'text/csv' });
            const url = window.URL.createObjectURL(fileBlob);

            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${listAPI}.csv`);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);

            window.URL.revokeObjectURL(url);

        } catch (err) {
            setError('Failed to download sample file.');
        } finally {
            setLoading(false);
        }
    };

    const handleUploadFile = async () => {
        if (!selectedFiles.length) {
            setError('No file selected.');
            return;
        }

        setLoading(true);
        setError(null);

        const formData = new FormData();

        // Append either a single file or multiple files based on listAPI
        if (listAPI === 'purchase_invoices') {
            // Multiple file upload
            Array.from(selectedFiles).forEach((file, index) => {
                formData.append(`files[${index}]`, file);
            });
        } else {
            // Single file upload
            formData.append('file', selectedFiles[0]);
        }

        try {
            const response = await axios.post(
                `${process.env.REACT_APP_API_V1_BASE_URL}/${listAPI}/import`,
                formData,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        Authorization: `Bearer ${auth}`,
                    },
                }
            );

            if (response.status === 200) {
                window.$(`#${id}`).modal('hide');
                Swal.fire({
                    title: 'Success!',
                    text: `${addLabel} imported successfully.`,
                    icon: 'success',
                    confirmButtonText: 'OK',
                    customClass: {
                        popup: 'custom-swal-popup',  // Use your class for custom positioning
                        icon: 'custom-swal-icon',    // Use this class to style the icon
                    },
                });

                importDataLoad();
                fileInputRef.current.value = null; 
                setSelectedFiles([]); // Reset the file selection
            } else {
                setError('Unexpected response from the server.');
            }
        } catch (err) {
            Swal.fire({
                title: 'Error!',
                text: 'Failed to upload the file.',
                icon: 'error',
                confirmButtonText: 'OK',
                customClass: {
                    popup: 'custom-swal-popup',  // Use your class for custom positioning
                    icon: 'custom-swal-icon',    // Use this class to style the icon
                },
            });
            setError('Failed to upload file.');
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="modal fade" id={id} tabIndex="-1" role="dialog" aria-labelledby={`${id}Label`} aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered modal-md" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id={`${id}Label`}>Import {label}</h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => fileInputRef.current.value = null}></button>
                    </div>
                    <div className="modal-body">
                        <form>
                            <div className="mb-3">
                                <label htmlFor="fileInput" className="form-label"> {listAPI === 'purchase_invoices' ? 'Import Files' : 'Import File'}</label>
                                <input
                                    type="file"
                                    className="form-control"
                                    id="fileInput"
                                    ref={fileInputRef}
                                    onChange={handleFileChange}
                                    multiple={listAPI === 'purchase_invoices'} // Enable multiple file selection for 'purchase_invoices'
                                />
                            </div>
                            <button 
                                type="button" 
                                className="btn btn-link" 
                                onClick={handleDownloadSample}
                                disabled={loading}
                            >
                                {listAPI !== 'purchase_invoices' && (
                                    loading ? 'Downloading...' : 'Download Sample File'
                                )}
                            </button>
                            {error && <div className="text-danger mt-2">{error}</div>}
                        </form>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn mx-2 btn-secondary" data-bs-dismiss="modal">Close</button>
                        <button 
                            type="button" 
                            className="btn btn-primary" 
                            onClick={handleUploadFile} 
                            disabled={!selectedFiles.length} // Disable button if no file(s) selected
                        >
                            {listAPI === 'purchase_invoices' ? 'Upload Files' : 'Upload File'}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

ImportPopup.propTypes = {
    id: PropTypes.string.isRequired,
    listAPI: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    importDataLoad: PropTypes.func.isRequired,
    addLabel: PropTypes.string.isRequired,
};

export default ImportPopup;


// import React, { useRef, useState } from 'react';
// import PropTypes from 'prop-types';
// import axios from 'axios';
// import { useAuth } from '../contexts/AuthContext';
// import Swal from 'sweetalert2';

// const ImportPopup = ({ id, listAPI, label, importDataLoad, addLabel }) => {
//     const [loading, setLoading] = useState(false);
//     const [error, setError] = useState(null);
//     const [selectedFile, setSelectedFile] = useState(null); // State to track the selected file

//     const { auth } = useAuth();
//     const fileInputRef = useRef(null);

//     const handleFileChange = (event) => {
//         const file = event.target.files[0];
//         setSelectedFile(file); // Update the state with the selected file
//         console.log(file); // You can replace this with your import logic
//     };

//     const handleDownloadSample = async () => {
//         setLoading(true);
//         setError(null);

//         try {
//             const sampleFileUrl = `${process.env.REACT_APP_API_BASE_URL}/download-sample/${listAPI}.csv`;
//             console.log('Sample File URL:', sampleFileUrl);

//             const response = await axios.get(sampleFileUrl, {
//                 responseType: 'blob',
//             });

//             const fileBlob = new Blob([response.data], { type: 'text/csv' });
//             const url = window.URL.createObjectURL(fileBlob);

//             const link = document.createElement('a');
//             link.href = url;
//             link.setAttribute('download', `${listAPI}.csv`);
//             document.body.appendChild(link);
//             link.click();
//             document.body.removeChild(link);

//             window.URL.revokeObjectURL(url);

//         } catch (err) {
//             console.error('Failed to download sample file', err);
//             setError('Failed to download sample file.');
//         } finally {
//             setLoading(false);
//         }
//     };

//     // const handleUploadFile = () => {
//     //     // Handle the file upload logic here
//     //     console.log('Uploading file:', selectedFile); // Implement your upload logic
//     // };
//     const handleUploadFile = async () => {
//         if (!selectedFile) {
//             setError('No file selected.');
//             return;
//         }
    
//         setLoading(true);
//         setError(null);
    
//         const formData = new FormData();
//         formData.append('file', selectedFile);
    
//         try {
//             const response = await axios.post(
//                 `${process.env.REACT_APP_API_V1_BASE_URL}/${listAPI}/import`,
//                 formData,
//                 {
//                     headers: {
//                         'Content-Type': 'multipart/form-data',
//                         Authorization: `Bearer ${auth}`,
//                     },
//                 }
//             );
    
//             console.log('Response data:', response.data);
//             if (response.status === 200) {
                
//                 window.$(`#${id}`).modal('hide');
//                 Swal.fire({
//                     title: 'Success!',
//                     text: `${addLabel} imported successfully.`,
//                     icon: 'success',
//                     confirmButtonText: 'OK'
//                 });

//                 importDataLoad();
//                 fileInputRef.current.value = null; 
//                 setSelectedFile(null);
//             } else {
//                 setError('Unexpected response from the server.');
//             }
//         } catch (err) {
//             Swal.fire({
//                 title: 'Error!',
//                 text: 'Failed to upload the file.',
//                 icon: 'error',
//                 confirmButtonText: 'OK'
//             });
//             setError('Failed to upload file.');
//         } finally {
//             setLoading(false);
//         }
//     };

//     return (
//         <div className="modal fade" id={id} tabIndex="-1" role="dialog" aria-labelledby={`${id}Label`} aria-hidden="true">
//             <div className="modal-dialog modal-dialog-centered modal-md" role="document">
//                 <div className="modal-content">
//                     <div className="modal-header">
//                         <h5 className="modal-title" id={`${id}Label`}>Import {label}</h5>
//                         <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => fileInputRef.current.value = null}></button>
//                     </div>
//                     <div className="modal-body">
//                         <form>
//                             <div className="mb-3">
//                                 <label htmlFor="fileInput" className="form-label">Import File</label>
//                                 <input
//                                     type="file"
//                                     className="form-control"
//                                     id="fileInput"
//                                     ref={fileInputRef}
//                                     onChange={handleFileChange}
//                                 />
//                             </div>
//                             <button 
//                                 type="button" 
//                                 className="btn btn-link" 
//                                 onClick={handleDownloadSample}
//                                 disabled={loading}
//                             >
//                                 {listAPI !== 'purchase_invoices' && (
//                                     loading ? 'Downloading...' : 'Download Sample File'
//                                 )}
//                             </button>
//                             {error && <div className="text-danger mt-2">{error}</div>}
//                         </form>
//                     </div>
//                     <div className="modal-footer">
//                         <button type="button" className="btn mx-2 btn-secondary" data-bs-dismiss="modal">Close</button>
//                         <button 
//                             type="button" 
//                             className="btn btn-primary" 
//                             onClick={handleUploadFile} 
//                             disabled={!selectedFile} // Disable button if no file is selected
//                         >
//                             Upload File
//                         </button>
//                     </div>
//                 </div>
//             </div>
//         </div>
//     );
// };

// ImportPopup.propTypes = {
//     id: PropTypes.string.isRequired,
//     listAPI: PropTypes.string.isRequired,
//     label: PropTypes.string.isRequired,
//     importDataLoad: PropTypes.func.isRequired,
//     addLabel: PropTypes.string.isRequired,
// };

// export default ImportPopup;