import React from 'react';
import PropTypes from 'prop-types';
import QuickBooks from '../assets/img/quickbooks-logo.png';
import Xero from '../assets/img/xero-logo.png';
import { useHistory } from "react-router-dom";

const VerifyPopup = ({ onSkipStartFresh, isOpen, onClose, errorMessage, modalId }) => {
    const history = useHistory();

    // const handleOverlayClick = (e) => {
    //     if (e.target === e.currentTarget) {
    //         onClose();
    //     }
    // };

    const handleQuickBooksClick = () => {
        history.push("/quick-books-integration");
    };

    const handleXeroClick = () => {
        history.push("/xero-integration");
    };

    return (
        <div
          className={`modal custom-modal fade ${isOpen ? "show d-block" : ""}`}
          id={modalId}
          tabIndex="-1"
          role="dialog"
          style={{ backgroundColor: "rgba(0, 0, 0, 0.5)" }}
        >
            <div
              className={`modal-dialog modal-dialog-centered modal-md ${
                isOpen ? "modal-slide-in" : "modal-slide-out"
              }`}
              style={{ maxWidth: "600px" }}
            >
                <div className="modal-content" style={{ height: "450px" }}>
                  <div className="modal-header border-0 pb-0">
                    {/* <div className="form-header modal-header-title text-start mb-0">
                      <h4 className="mb-0">{title}</h4>
                    </div> */}
                    <button
                      type="button"
                      className="btn-close"
                      onClick={onClose}
                      aria-label="Close"
                    ></button>
                  </div>
                  <div
                    className="modal-body"
                    style={{
                      overflowY: "auto",
                      maxHeight: "calc(100% - 50px)",
                      paddingBottom: "20px",
                    }}
                  >
                    <h4 id="aiaModalTitle" className="aia-modal import-data-center">
                        Welcome to {`${process.env.REACT_APP_NAME} - Data Sync Center`}
                    </h4>
                    <div className="input-block mb-3 input_text">
                        <p className="import-data-center">Sync your data from:</p>
                    </div>
                    <div className="input-block mb-3 image-data-center">
                        <div className="logo-container" onClick={handleQuickBooksClick} style={{ cursor: "pointer" }}>
                            <img className="img-fluid logo-dark mb-2" src={QuickBooks} alt="QuickBooks Logo" />
                            <span className="logo-text">QuickBooks</span>
                        </div>
                        <span className="or-text">or</span>
                        <div className="logo-container" onClick={handleXeroClick} style={{ cursor: "pointer" }}>
                            <img className="img-fluid logo-dark mb-2" src={Xero} alt="Xero Logo" />
                            <span className="logo-text">Xero</span>
                        </div>
                    </div>
                    <div className="import-data-center">
                        <button type="button" className="btn btn-secondary" onClick={onClose} aria-label="Close Popup">Close</button>
                        <button type="button" className="btn btn-primary mx-3" onClick={onSkipStartFresh} aria-label="Skip and Start Fresh">Skip & Start Fresh</button>
                        {errorMessage && <p className="error-message">{errorMessage}</p>}
                    </div>
                </div>
            </div>
            </div>
        </div>
        // </div>
        )
    // );
};

VerifyPopup.propTypes = {
    onSkipStartFresh: PropTypes.func.isRequired,
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    errorMessage: PropTypes.string,
    modalId: PropTypes.string.isRequired
};

export default VerifyPopup;
