import React, { useState } from "react";
import PropTypes from "prop-types";
import axios from "axios";
import { useAuth } from "../../contexts/AuthContext";
import { CustomInputField } from "../../CustomComponents/ReusableComponents";

const InviteCompany = ({  isOpen, onClose, onUpdate }) => {
    const { auth } = useAuth();

    const [formData, setFormData] = useState({
        name: "",
    });

    const handleChange = e => {
        const { name, value } = e.target;
        setFormData(prevFormData => ({
            ...prevFormData,
            [name]: value
        }));
    };

    const handleSubmit = async e => {
        e.preventDefault();
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_API_V1_BASE_URL}/clients`,
                { ...formData },
                {
                    headers: {
                        Authorization: `Bearer ${auth}`,
                        "Content-Type": "application/json"
                    }
                }
            );
            console.log("Invite sent successfully", response.data);
            onClose();
            onUpdate();
        } catch (err) {
            console.error("Failed to sent invite", err);
        }
    };

    const handleOverlayClick = e => {
        if (e.target === e.currentTarget) {
            onClose();
        }
    };

    return (
        <>
            {isOpen && (
                <div
                    className="aia-modal-overlay-wrapper "
                    onClick={handleOverlayClick}
                >
                    <div className="aia-model-xl form-header modal-header-title text-start mb-0 aia-modal-card">
                        <h4 className="mb-3">Send Invitation</h4>
                        <CustomInputField
                            inputType="email"
                            placeholder="enter the email to sent invitation"
                            labelText="Email"
                            inputWrapper="col-lg-12"
                            name="name"
                            value={formData.name}
                            onChange={handleChange}
                        />

                        <button
                            type="button"
                            className="btn btn-secondary "
                            onClick={onClose}
                        >
                            Close
                        </button>
                        <button
                            type="button mx-3"
                            className="btn btn-primary mx-2"
                            onClick={handleSubmit}
                        >
                            Save changes
                        </button>
                    </div>
                </div>
            )}
        </>
    );
};

InviteCompany.propTypes = {
    id: PropTypes.string.isRequired,
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onUpdate: PropTypes.func.isRequired
};

export default InviteCompany;
