import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useAuth } from '../contexts/AuthContext';
import axios from 'axios';
import { CustomInputField, CustomDropDownField } from '../CustomComponents/ReusableComponents';
import Swal from 'sweetalert2';
//import { useHistory } from "react-router-dom";

const EditPlaidTransaction = ({ id, isOpen, onClose, onUpdate }) => {
    const { auth } = useAuth();
    const API_BASE_URL = process.env.REACT_APP_API_V1_BASE_URL;
    const [accounts, setAccounts] = useState([]);
    const [bankaccounts, setBankAccounts] = useState([]);
    const [error, setError] = useState(null);
    const [showNewAccountFields, setShowNewAccountFields] = useState(false);
    //const history = useHistory();
    const [accountTypes, setAccountTypes] = useState([]);


    // const handleAddData = () => {
    //     history.push(`/add-accounts`);
    // };

    const [formData, setFormData] = useState({
        name: '',
        bank_account_id: '',
        account_id: '',
        is_debit: false,
        execute_similar: true,  // New checkbox field, default to true
        account_type_id: '',
        account_name: '',
        account_code: '',
    });
    const handleToggle = () => {
        setFormData(prevFormData => ({
            ...prevFormData,
            is_debit: prevFormData.is_debit === 1 ? 0 : 1
        }));
    };

    useEffect(() => {
        console.log(error);

        const fetchTransactionType = async () => {
            try {
                const response = await axios.get(
                    `${API_BASE_URL}/plaid-transactions/${id}`,
                    {
                        headers: {
                            Authorization: `Bearer ${auth}`,
                        }
                    }
                );
                setFormData({
                    ...formData,
                    name: response.data.name,
                    bank_account_id: response.data.bank_account_id,
                });
            } catch (err) {
                console.error("Failed to fetch Transaction Type", err);
            }
        };

        const fetchDropdownData = async () => {
            setError(null);
            try {
                const dropdownRequestData = {
                    models: [
                        { model_name: "Account" },
                        { model_name: "BankAccount" },
                        { model_name: "AccountType" },
                    ]
                };
                const apiReqHeaders = {
                    headers: {
                        Authorization: `Bearer ${auth}`,
                        "Content-Type": "application/json"
                    }
                };

                const response = await axios.post(
                    `${API_BASE_URL}/get_dropdown_data`,
                    dropdownRequestData,
                    apiReqHeaders
                );

                const accountDropdownData = response.data.content.Account || [];
                setAccounts([{ id: 'new', name: 'Add new account' }, ...accountDropdownData]);
                const bankaccountDropdownData = response.data.content.BankAccount || [];
                setBankAccounts(bankaccountDropdownData);
                setAccountTypes(response.data.content.AccountType || []);

            } catch (error) {
                setError("Error fetching dropdown data");
            }
        };

        fetchDropdownData();
        fetchTransactionType();
    }, [id, auth]);

    const handleChange = e => {
        const { name, value, type, checked } = e.target;

        // Show or hide new account fields based on "Add new account" selection
        if (name === 'account_id') {
            setShowNewAccountFields(value === 'new');
        }

        setFormData(prevFormData => ({
            ...prevFormData,
            [name]: type === 'checkbox' ? checked : value
        }));
    };

    const [errors, setErrors] = useState({});

    const validateForm = () => {
        const validationErrors = {};
        if (!formData.name) validationErrors.name = "Add Name";
        if (!formData.account_id) validationErrors.account_id = "Add account";
        if (!formData.bank_account_id) validationErrors.bank_account_id = "Add bank account";
        if (showNewAccountFields) {
            if (!formData.account_type_id) validationErrors.account_type_id = "Select account type";
            if (!formData.account_name) validationErrors.account_name = "Enter account name";
            if (!formData.account_code) validationErrors.account_code = "Enter account code";
        }
        setErrors(validationErrors);
        return Object.keys(validationErrors).length === 0;
    };

    const handleSubmit = async e => {
        e.preventDefault();
        if (!validateForm()) {
            return;
        }
        try {
            const plaidtransactionData = { ...formData };
            const apiReqHeaders = {
                headers: {
                    Authorization: `Bearer ${auth}`,
                    "Content-Type": "application/json"
                }
            };

            await axios.put(
                `${API_BASE_URL}/plaid-transactions/${id}`,
                plaidtransactionData,
                apiReqHeaders
            );
            Swal.fire({
                title: 'Success!',
                text: 'Plaid Transaction updated successfully.',
                icon: 'success',
                confirmButtonText: 'OK',
                customClass: {
                    popup: 'custom-swal-popup',
                    icon: 'custom-swal-icon',
                }
            });
            onClose();
            onUpdate();
        } catch (err) {
            Swal.fire({
                title: 'Error!',
                text: 'Failed to save the Plaid Transaction.',
                icon: 'error',
                confirmButtonText: 'OK',
                customClass: {
                    popup: 'custom-swal-popup',
                    icon: 'custom-swal-icon',
                }
            });
        }
    };

    const handleOverlayClick = (e) => {
        if (e.target === e.currentTarget) {
            onClose();
        }
    };

    return (
        <>
            {isOpen && (
                <div className='aia-modal-overlay-wrapper' onClick={handleOverlayClick}>
                    <div className='aia-modal-card'>
                        <h5>Approve Transaction</h5>
                        <div className="form-check form-switch col-md-1 me-2">
                            <input
                                className="form-check-input"
                                type="checkbox"
                                id="isActiveSwitch"
                                checked={formData.is_debit === 1}
                                onChange={handleToggle}
                            />
                            <label
                                className="form-check-label"
                                htmlFor="isActiveSwitch"
                            >
                                {formData.is_debit === 1 ? "Debit" : "Credit"}
                            </label>
                        </div>
                        <CustomDropDownField
                            dropDownSize="col-lg-12"
                            options={bankaccounts.map(c => ({
                                value: c.id,
                                label: c.name
                            }))}
                            labelText="Bank Accounts "
                            name="bank_account_id"
                            onChange={handleChange}
                            value={formData.bank_account_id}
                            required={true}
                            disabled={true}
                            errorMessage={errors.bank_account_id}
                        />

                        <CustomInputField
                            inputType="text"
                            labelText="Name"
                            inputWrapper="col-lg-12"
                            name="name"
                            value={formData.name}
                            onChange={handleChange}
                            errorMessage={errors.name}
                            required={true}
                            disabled={true}
                            placeholder="E.g Sales, Purchases, Cash"
                        />

                        <CustomDropDownField
                            dropDownSize="col-lg-12"
                            options={accounts.map(c => ({
                                value: c.id,
                                label: c.name,
                                style: c.id === 'new' ? { color: 'blue', textDecoration: 'underline', cursor: 'pointer' } : {}
                            }))}
                            labelText="Select Account "
                            name="account_id"
                            onChange={handleChange}
                            value={formData.account_id}
                            required={true}
                            errorMessage={errors.account_id}
                        />


                        {showNewAccountFields && (
                            <>
                            <CustomDropDownField
                                dropDownSize="col-lg-12"
                                options={accountTypes.map(c => ({
                                    value: c.id,
                                    label: c.name
                                }))}
                                labelText="Account Type"
                                name="account_type_id"
                                onChange={handleChange}
                                required={true}
                                errorMessage={errors.account_type_id}
                            /> 

                                <CustomInputField
                                    inputType="text"
                                    labelText="Account Name"
                                    inputWrapper="col-lg-12"
                                    name="account_name"
                                    value={formData.account_name}
                                    onChange={handleChange}
                                    errorMessage={errors.account_name}
                                    required={true}
                                    placeholder="Enter account name"
                                />

                                <CustomInputField
                                    inputType="text"
                                    labelText="Account Code"
                                    inputWrapper="col-lg-12"
                                    name="account_code"
                                    value={formData.account_code}
                                    onChange={handleChange}
                                    errorMessage={errors.account_code}
                                    required={true}
                                    placeholder="Enter account code"
                                />
                            </>
                        )}

                        <div className="form-check">
                            <input
                                type="checkbox"
                                className="form-check-input"
                                id="execute_similar"
                                name="execute_similar"
                                checked={formData.execute_similar}
                                onChange={handleChange}
                            />
                            <label className="form-check-label" htmlFor="execute_similar">
                                All similar transactions are executed
                            </label>
                        </div>
                        <button type="button" className="btn btn-secondary" onClick={onClose}>Close</button>
                        <button type="button" className="btn btn-primary mx-3" onClick={handleSubmit}>Save changes</button>
                    </div>
                </div>
            )}
        </>
    );
};

EditPlaidTransaction.propTypes = {
    id: PropTypes.number.isRequired,
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onUpdate: PropTypes.func.isRequired,
};

export default EditPlaidTransaction;
