import React from 'react';
import PageComponent from '../CustomComponents/PageComponent';

const BranchList = () => {
  return(
    <PageComponent listAPI='branches' label='Branch' addType='nextPage' addLabel='Branch'/>
  )
};

export default BranchList;
