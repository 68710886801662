import React from 'react';
import PageComponent from '../CustomComponents/PageComponent';

const ItemList = () => {
  return(
    <PageComponent listAPI='items' label='Item' route="items" addType='nextPage' addLabel='Item'/>
  )
};

export default ItemList;
