import React, { useState, useEffect, useRef } from "react";
import "../_components/antd.css";
import { Table, Tooltip } from "antd";
import { Link, useHistory } from "react-router-dom";
// import FeatherIcon from 'feather-icons-react';
import { filterIcon } from "../_components/Imagepath";
import axios from "axios";
import { useAuth } from "../contexts/AuthContext";
// import Sidebar from "../layouts/Sidebar";
// import Header from "../layouts/Header";
import ImportPopup from "../CustomComponents/ImportPopup";
import PropTypes from "prop-types";
import NewFilter from "./NewFilterComponent";
import AddAccountTypes from "../accountsfinance/AddAccountTypes";
import AddJournalTypes from "../journal/addJournalTypes";
import AddTransactionTypes from "../accountsfinance/AddTransactionTypes";
import EditTransactionTypes from "../accountsfinance/EditTransactionTypes";
import EditPlaidTransaction from "../accountsfinance/EditPlaidTransaction";
import EditAccountTypes from "../accountsfinance/EditAccountTypes";
import EditJournalTypes from "../accountsfinance/EditJournalTypes";
import { PlaidLink } from "react-plaid-link";
import InviteCompany from "../cpa/clients/inviteCompany";
import Swal from "sweetalert2";

const PageComponent = ({ listAPI, label, addType, route, addLabel }) => {
    
    useEffect(() => {
        document.title = `${label} List`;
    }, []);

    const { auth } = useAuth();
    const history = useHistory();
    const dynamicTarget = `#add_${listAPI}`;

    // const [menu, setMenu] = useState(false);
    const [showFilter, setShowFilter] = useState(false);
    const [tableData, setTableData] = useState([]);
    const [columns, setColumns] = useState([]);
    const [filterableFields, setFilterableFields] = useState([]);

    // eslint-disable-next-line no-unused-vars
    const [filters, setFilters] = useState({});
    const [loading, setLoading] = useState(false);

    const [fading, setFading] = useState(false);
    const filtersRef = useRef({});

    const [linkToken, setLinkToken] = useState(null);

    //const [plaidItems, setPlaidItems] = useState([]);
    // const [isLoading, setIsLoading] = useState(true);

    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [showDeleteIcon, setShowDeleteIcon] = useState(false);

    const onSelectChange = (newSelectedRowKeys) => {
        setSelectedRowKeys(newSelectedRowKeys);

        if (newSelectedRowKeys.length > 0) {
            setShowDeleteIcon(true);
        } else {
            setShowDeleteIcon(false);
        }
    };
    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };

    const handleDeleteSelected = async () => {
        if (selectedRowKeys.length === 0) {
            Swal.fire("No selection!", "Please select records to delete.", "info");
            return;
        }
    
        const selectedIds = selectedRowKeys.join(","); 
    
        const result = await Swal.fire({
            title: "Are you sure?",
            text: "Do you want to delete all selected records?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#d33",
            cancelButtonColor: "#3085d6",
            confirmButtonText: "Yes, delete all!",
        });
    
        if (result.isConfirmed) {
            try {
                await axios.delete(`${process.env.REACT_APP_API_V1_BASE_URL}/${listAPI}/${selectedIds}`, {
                    headers: { Authorization: `Bearer ${auth}` },
                });
    
                Swal.fire("Deleted!", "The selected records have been deleted.", "success");
    
                await fetchTableDetails();
                setSelectedRowKeys([]); 
                setShowDeleteIcon(false); 
            } catch (error) {
                Swal.fire("Error!", "Failed to delete records. Please try again.", "error");
            }
        }
    };

    const toggleFilter = () => {
        setShowFilter(prevShow => !prevShow);
    };

    const [currentPage, setCurrentPage] = useState(1);

    const [rowsPerPage, setRowsPerPage] = useState(10);

    const [totalRecords, setTotalRecords] = useState(0);

    const [popupEdit, setPopupEdit] = useState(null);

    const [showModal, setShowModal] = useState(false);

    const invoiceTypes = ["purchase_invoices", "sales_invoices"];
    // State for messages
    const [successMessage, setSuccessMessage] = useState("");
    const [errorMessage, setErrorMessage] = useState("");

    const handlePopupEdit = id => {
        setPopupEdit(id);
        setShowModal(true);
    };

    // const toggleMobileMenu = () => {
    //     setMenu(!menu);
    // };

    const [sortField, setSortField] = useState("id");
    const [sortDirection, setSortDirection] = useState("asc");

    const handleSort = field => {
        if (field === "checkbox") {
            // Ignore sorting when the checkbox column is clicked
            return;
        }
        const isAscending = sortField === field && sortDirection === "asc";
        setSortField(field);
        setSortDirection(isAscending ? "desc" : "asc");
        fetchTableDetails();
    };

    // Fetch the link token for connecting a new bank account
    const fetchLinkToken = async () => {
        try {
            const response = await fetch(
                `${process.env.REACT_APP_API_V1_BASE_URL}/plaid/create-link-token`,
                {
                    method: "POST",
                    headers: {
                        Authorization: `Bearer ${auth}`,
                        "Content-Type": "application/json"
                    }
                }
            );
            const data = await response.json();
            setLinkToken(data.link_token);
            fetchTableDetails();
        } catch (error) {
            console.error("Error fetching link token:", error);
        }
    };
    // Handle successful linking of a new bank account
    const handleOnSuccess = async publicToken => {
        try {
            const response = await fetch(
                `${process.env.REACT_APP_API_V1_BASE_URL}/plaid/exchange-token`,
                {
                    method: "POST",
                    headers: {
                        Authorization: `Bearer ${auth}`,
                        "Content-Type": "application/json"
                    },
                    body: JSON.stringify({ public_token: publicToken })
                }
            );
            // const data = await response.json();
            if (response.status === 200) {
                setSuccessMessage("Bank linked successfully!"); // Set success message
                fetchTableDetails();
            } else {
                setErrorMessage("Error while bank linked !");
            }

            // Update the list of linked Plaid items after successful linking
            // fetchPlaidItems();
        } catch (error) {
            console.error("Error exchanging public token:", error);
        }
    };
    const handleRefresh = async () => {
        setFading(true); // Trigger fade-out effect
        setLoading(true); // Start loading spinner

        // Wait for fade-out effect (300ms) before fetching data
        setTimeout(async () => {
            await fetchTableDetails(); // Fetch the table data

            setLoading(false); // Stop loading spinner
            setFading(false); // Trigger fade-in effect
        }, 500); // Duration of the fade-out effect
    };

    const fetchTableDetails = async () => {
        setTimeout(async () => {
            try {
                const response = await axios.get(
                    `${process.env.REACT_APP_API_V1_BASE_URL}/${listAPI}`,
                    {
                        headers: {
                            Authorization: `Bearer ${auth}`
                        },
                        params: {
                            page: currentPage,
                            per_page: rowsPerPage,
                            sort_by: sortField !== "checkbox" ? sortField : null,
                            sort_direction: sortDirection,
                            ...(label === "Bills" && {
                                type: "purchase_invoice"
                            }),
                            ...(label === "Expenses" && { type: "expense" }),
                            ...filtersRef.current
                        }
                    }
                );

                const { records, headers, total_items } = response.data;

                // const checkboxColumn = {
                //     title: (
                //         <Checkbox
                //             checked={
                //                 Object.keys(selectedRows).length === tableData.length &&
                //                 tableData.length > 0 &&
                //                 Object.values(selectedRows).every(isSelected => isSelected)
                //             }
                //             onChange={handleSelectAllChange} 
                //         />
                //     ),
                //     dataIndex: "checkbox",
                //     render: (_, record) => (
                //         <Checkbox
                //             checked={!!selectedRows[record.id]}
                //             onChange={(e) => handleCheckboxChange(record, e)}
                //         />
                //     )
                // };
                const dynamicColumns = headers
                    .filter(header => header.enable === 1)
                    .map(header => ({
                        title: header.display_name,
                        dataIndex: header.field_name,
                        sorter: header.sortable,
                        onHeaderCell: column => ({
                            onClick: () => handleSort(column.dataIndex)
                        }),
                        render: header.display_name === "Actions" ? undefined : (text) => (text == null ? "-" : text),
                    }));

                const statusColumnIndex = dynamicColumns.findIndex(
                    column => column.title === "Status"
                );

                if (statusColumnIndex !== -1) {
                    dynamicColumns[statusColumnIndex].render = (_, record) => {
                        let statusIcon;
                        // switch (record.status)
                        switch (record.status.toLowerCase()) {
                            case "active":
                                statusIcon = (
                                    <span className="badge badge-pill bg-success-light">
                                        Active
                                    </span>
                                );
                                break;
                            case "paid":
                                statusIcon = (
                                    <span className="text-success cursor-sign badge bg-success-light p-2 rounded w-100  d-flex align-items-center fw-bold">
                                        <i
                                            className="fa fa-check mx-1 me-2"
                                            data-bs-toggle="tooltip"
                                            title="fa fa-check"
                                        ></i>
                                        Paid
                                    </span>
                                );
                                break;

                            case "pending":
                                statusIcon = (
                                    <span className="text-orange cursor-sign badge bg-orange-light p-2 rounded w-100 d-flex align-items-center fw-bold">
                                        {/* <i className="fe fe-clock  mx-1" />{" "} */}
                                        <i className="fa-regular fa-clock mx-1 me-2"></i>
                                        Pending
                                    </span>
                                );
                                break;
                            case "cancelled":
                                statusIcon = (
                                    <span className="text-danger badge cursor-sign bg-danger-light p-2 rounded w-100  d-flex align-items-center fw-bold">
                                    <i
                                        className="fa fa-times mx-1 me-2"
                                        data-bs-toggle="tooltip"
                                        title="fa fa-check"
                                    ></i>
                                    Cancelled
                                </span>
                                    // {/* <i className="fa fa-times mx-1" />{" "} */}
                                );
                                break;
                            case "draft":
                                statusIcon = (
                                    <span className="badge bg-primary-light cursor-sign p-2 text-primary-blue d-flex align-items-center rounded w-100 fw-bold">
                                        {/* <i className="fe fe-file-text mx-1"></i> */}
                                        
                                        <i className="fa-regular fa-file-lines ms-1 me-2"></i>
                                        Draft
                                    </span>
                                );
                                break;
                            case "completed":
                                statusIcon = (
                                    <span className="text-primary">
                                        <i className="fas fa-check me-2" />{" "}
                                        Completed
                                    </span>
                                );
                                break;
                            case "failed":
                                statusIcon = (
                                    <span className="badge badge-pill bg-danger-light">
                                        Failed
                                    </span>
                                );
                                break;
                            default:
                                statusIcon = (
                                    <span className="text-secondary">
                                        <i className="fas fa-question-circle me-2" />{" "}
                                        Unknown
                                    </span>
                                );
                                break;
                        }

                        return (
                            <div className="d-flex align-items-center">
                                {statusIcon}
                            </div>
                        );
                    };
                }

                const actionsColumnIndex = dynamicColumns.findIndex(
                    column => column.title === "Actions"
                );

                if (actionsColumnIndex !== -1) {
                    dynamicColumns[actionsColumnIndex].render = (_, record) => (
                        <div className="d-flex align-items-center">
                            {listAPI === "bank_accounts" &&
                                record.source === "Plaid" && (
                                    <button
                                        className="btn btn-action-icon me-2"
                                        onClick={() =>
                                            handletransactionssync(record.id)
                                        }
                                    >
                                        <i className="far fe fe-refresh-ccw me-2" />
                                        Transaction sync
                                    </button>
                                )}
                            {listAPI === "accounts" && (
                                <button
                                    className="btn btn-action-icon me-2"
                                    onClick={() => handleLedger(record.id)}
                                >
                                    <i className="fe fe-file-text me-2" />
                                    Ledger
                                </button>
                            )}
                            {listAPI === "journals" && (
                                <button
                                    className="btn btn-action-icon me-2"
                                    onClick={() =>
                                        handleJournal(record.reference)
                                    }
                                >
                                    <i className="far fa-edit me-2" />
                                    Journal
                                </button>
                            )}
                            {listAPI === "roles" && (
                                <button
                                    onClick={() => handlePermissions(record.id)}
                                    className="btn  me-2"
                                >
                                    <i className="fa fa-shield me-1" />{" "}
                                    Permissions
                                </button>
                            )}
                            {route === "invoices" && (
                                <button
                                    onClick={() => handleView(record.id)}
                                    className="btn  me-2"
                                >
                                    <i className="far fa-eye me-2" /> View
                                </button>
                            )}
                            {route === "customers" && (
                                <button
                                    onClick={() =>
                                        handleLedger(record.account_id)
                                    }
                                    className="btn  me-2"
                                >
                                    <i className="fe fe-file-text me-2" />
                                    Ledger
                                </button>
                            )}
                            {listAPI === "vendors" && (
                                <button
                                    onClick={() =>
                                        handleLedger(record.account_id)
                                    }
                                    className="btn  me-2"
                                >
                                    <i className="fe fe-file-text me-2" />
                                    Ledger
                                </button>
                            )}
                            {listAPI === "transactions" && record.is_reconciled === 0 &&(
                                <>
                                <button
                                    className="btn btn-action-icon me-2"
                                    onClick={() =>
                                        handlePopupEdit(record.id)
                                    }
                                >
                                        <i className="far fe fe-refresh-ccw me-2" />
                                        Execute Transaction
                                </button>
                                </>
                            )}

                            {addType === "nextPage" &&
                                ((label !== "Clients" &&
                                    label !== "Invoices" && label !== "Plaid-Transactions") ||
                                    ((route === "invoices" ||
                                        route === "bills") &&
                                        record.posted !== undefined &&
                                        !record.posted)) && (
                                    <button
                                        className="btn btn-action-icon me-2"
                                        onClick={() => handleEdit(record.id)}
                                    >
                                        <i className="far fa-edit me-2" />
                                        Edit
                                    </button>
                                )}

                            {/* {addType === "nextPage" && label != "Clients" && (
                                    <button
                                        className="btn btn-action-icon me-2"
                                        onClick={() => handleEdit(record.id)}
                                    >
                                        <i className="far fa-edit me-2" />
                                        Edit
                                    </button>
                                )} */}

                            {addType === "popUp" && (
                                <>
                                    <button
                                        className="btn btn-action-icon me-2"
                                        onClick={() =>
                                            handlePopupEdit(record.id)
                                        }
                                    >
                                        <i className="far fa-edit me-2" />
                                        Edit
                                    </button>
                                </>
                            )}
                            {((label !== "Clients" && label !== "Invoices" && label !== "Plaid-Transactions") ||
                                (addType === "nextPage" &&
                                    (route === "invoices" ||
                                        route === "bills") &&
                                    record.posted !== undefined &&
                                    !record.posted)) && (
                                <button
                                    className="btn btn-action-icon"
                                    onClick={() => handleDelete(record.id)}
                                >
                                    <i className="far fa-trash-alt me-2" />
                                    Delete
                                </button>
                            )}

                            {label === "Clients" && (
                                <button
                                    className="btn btn-action-icon"
                                    onClick={() => handlePopupEdit(record.id)}
                                >
                                    Access Login
                                </button>
                            )}

                            {/* {route === "cpa/clients" && (
                                    <button
                                        onClick={() => handleView(record.id)}
                                        className="btn  me-2"
                                    >
                                        <i className="far fa-eye me-2" /> View
                                    </button>
                                )} */}
                        </div>
                    );
                }

                // dynamicColumns.unshift(checkboxColumn);

                setColumns(dynamicColumns);
                setTableData(records);
                setTotalRecords(total_items);

                const filterableFields = headers.filter(
                    header => header.filterable === 1
                );
                setFilterableFields(filterableFields);

                const initialFilters = {};
                filterableFields.forEach(field => {
                    initialFilters[field.field_name] = "";
                });
                setFilters(initialFilters);
            } catch (error) {
                console.error("Error fetching customer details:", error);
            } finally {
                setLoading(false);
                setFading(false);
            }
        }, 300);
    };

    const onShowSizeChange = (current, size) => {
        setCurrentPage(current);
        setRowsPerPage(size);
        fetchTableDetails();
    };

    const handlePermissions = id => {
        history.push(`/permission/${id}`);
    };

    const handleLedger = id => {
        history.push(`/ledger/${id}`);
    };

    const handleJournal = reference => {
        history.push(`/journal/${reference}`);
        //journal/TRX-1
    };
    const handleEdit = id => {
        if (invoiceTypes.includes(listAPI)) {
            history.push(`/edit-${route}/${id}`);
        } else {
            history.push(`/edit-${listAPI}/${id}`);
        }
    };

    const handleView = id => {
        history.push(`/view-${route || listAPI}/${id}`);
    };
    const handleDelete = async (id) => {

        const result = await Swal.fire({
            title: 'Are you sure?',
            text: 'Do you really want to delete this record?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#d33',
            cancelButtonColor: '#3085d6',
            confirmButtonText: 'Yes, delete it!',
            customClass: {
                popup: 'custom-swal-popup',  // Use your class for custom positioning
                icon: 'custom-swal-icon',    // Use this class to style the icon
              }
        });
    
        if (result.isConfirmed) {
            try {
                const response = await axios.delete(
                    `${process.env.REACT_APP_API_V1_BASE_URL}/${listAPI}/${id}`,
                    {
                        headers: {
                            Authorization: `Bearer ${auth}`
                        }
                    }
                );
    
                if (response.status === 200 || response.status === 204) {
                    Swal.fire({
                        title: 'Deleted!',
                        text: 'The record has been deleted.',
                        icon: 'success',
                        customClass: {
                            popup: 'custom-swal-popup',  // Use your class for custom positioning
                            icon: 'custom-swal-icon',    // Use this class to style the icon
                        }
                    });
                    fetchTableDetails();
                }
            } catch (error) {
                console.error(
                    'There was an error deleting the account type:',
                    error.response ? error.response.data : error.message
                );
                Swal.fire({
                    title: 'Error',
                    text: 'There was an issue deleting the record.',
                    icon: 'error',
                    customClass: {
                        popup: 'custom-swal-popup',  // Use your class for custom positioning
                        icon: 'custom-swal-icon',    // Use this class to style the icon
                    }
                });
            }
        }
    };

    const handleAddData = () => {
        if (invoiceTypes.includes(listAPI)) {
            history.push(`/add-${route}`);
        } else {
            history.push(`/add-${listAPI}`);
        }
    };

    const handleAdd = () => {
        fetchTableDetails();
    };

    const importDataLoad = () => {
        fetchTableDetails();
    };

    const handleDownload = async filetype => {
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_API_V1_BASE_URL}/${listAPI}/export`,
                {
                    // Ensure the correct API endpoint
                    headers: {
                        Authorization: `Bearer ${auth}`
                    },
                    params: { filetype },
                    responseType: "blob"
                }
            );

            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute(
                "download",
                `${listAPI}.${filetype === "xlsx" ? "xlsx" : "csv"}`
            );
            document.body.appendChild(link);
            link.click();
            link.remove();

            window.URL.revokeObjectURL(url);
        } catch (error) {
            console.error(
                `Error downloading ${filetype.toUpperCase()}:`,
                error
            );
        }
    };

    // const handleFilterChange = (fieldName, value) => {
    //     setFilters(prev => ({ ...prev, [fieldName]: value }));
    //     filtersRef.current = { ...filtersRef.current, [fieldName]: value };
    //     fetchTableDetails();
    // };
    const handleFilterChange = (fieldName, value) => {
        // if (clear) {
        //     filtersRef.current = {};
        // } else {
        setFilters(prev => ({ ...prev, [fieldName]: value }));
        filtersRef.current = { ...filtersRef.current, [fieldName]: value };
        // }
        fetchTableDetails();
    };

    const onFilterClear = () => {
        filtersRef.current = {};
        fetchTableDetails();
    };

    // const [isAddAccountTypesVisible, setAddAccountTypesVisible] = useState(
    //     false
    // );

    // const handleOpenModal = () => {
    //     setAddAccountTypesVisible(prev => !prev);
    // };
    const handletransactionssync = async id => {
        try {
            const response = await fetch(
                `${process.env.REACT_APP_API_V1_BASE_URL}/plaid/accounts/transactions`,
                {
                    method: "POST",
                    headers: {
                        Authorization: `Bearer ${auth}`,
                        "Content-Type": "application/json"
                    },
                    body: JSON.stringify({ id: id })
                }
            );
            const data = await response.json();

            // Check if the response has an error
            if (response.ok) {
                // Success response
                setSuccessMessage("Transactions imported successfully!"); // Set success message
            } else {
                // Error response
                setErrorMessage(data.error || "Error transactions record."); // Set error message from response or default
            }
        } catch (error) {
            setErrorMessage("Error transactions record."); // Set error message for catch block
        }
    };
    const handleUpdate = () => {
        setShowModal(false);
        fetchTableDetails();
    };
    // Show success and error messages
    useEffect(() => {
        fetchTableDetails();
        if (listAPI === "bank_accounts") {
            fetchLinkToken();
        }
    
        if (successMessage) {
            Swal.fire({
                title: 'Success',
                text: successMessage,
                icon: 'success',
                customClass: {
                    popup: 'custom-swal-popup',  // Custom positioning class
                    icon: 'custom-swal-icon',    // Custom icon class
                }
            });
            setSuccessMessage(""); // Clear message after showing
        }
    
        if (errorMessage) {
            Swal.fire({
                title: 'Error',
                text: errorMessage,
                icon: 'error',
                customClass: {
                    popup: 'custom-swal-popup',  // Custom positioning class
                    icon: 'custom-swal-icon',    // Custom icon class
                }
            });
            setErrorMessage(""); // Clear message after showing
        }
    }, [auth, currentPage, rowsPerPage, successMessage, errorMessage]);

    // useEffect(() => {
    //     ///plaid
    //     if(listAPI === 'bank_accounts'){
    //         fetchLinkToken();
    //     }
    //    // fetchPlaidItems();
    // }, [auth, currentPage, rowsPerPage]);
    // useEffect(() => {

    //   }, []);

    return (
        <>
            <div className={`main-wrapper `}>
                {/* <Header onMenuClick={() => toggleMobileMenu()} /> */}
                {/* <Sidebar /> */}

                <div className="page-wrapper">
                    <div className="content container-fluid">
                        {popupEdit && listAPI === "transaction_types" && (
                            // <EditTransactionTypes id={popupEdit} />
                            <EditTransactionTypes
                                id={popupEdit}
                                isOpen={showModal}
                                onClose={() => setShowModal(false)}
                                onUpdate={handleUpdate}
                                modalId="edit_transaction_types"
                                title="Edit Transaction Type"
                            />
                        )}

                        {popupEdit && listAPI === "account_types" && (
                            <EditAccountTypes
                                id={popupEdit}
                                isOpen={showModal}
                                onClose={() => setShowModal(false)}
                                onUpdate={handleUpdate}
                                modalId="edit_accounts"
                                title="Edit Account"
                            />
                        )}
                        {popupEdit && listAPI === "cpa/clients" && (
                            <InviteCompany
                                id={popupEdit}
                                isOpen={showModal}
                                onClose={() => setShowModal(false)}
                                onUpdate={handleUpdate}
                            />
                        )}

                        {popupEdit && listAPI === "journal_types" && (
                            <EditJournalTypes
                                id={popupEdit}
                                isOpen={showModal}
                                onClose={() => setShowModal(false)}
                                onUpdate={handleUpdate}
                                modalId="edit_journal_types"
                                title="Edit Journal Types"
                            />
                        )}

                        {popupEdit && listAPI === "transactions" && (
                            <EditPlaidTransaction
                            id={popupEdit}
                            isOpen={showModal}
                            onClose={() => setShowModal(false)}
                            onUpdate={handleUpdate}
                            />
                        )}


                        {/* Page Header */}
                        <div className="page-header">
                            <div className="content-page-header ">
                                <h5>{label}</h5>
                                <div className="list-btn">
                                    {label != "Clients" && (
                                        <ul className="filter-list">
                                            <li>
                                                <a
                                                    className="btn-filters"
                                                    href="#"
                                                    data-bs-toggle="tooltip"
                                                    data-bs-placement="bottom"
                                                    title="Refresh"
                                                    onClick={handleRefresh}
                                                >
                                                    <span>
                                                        {/* Show refresh icon or spinner based on loading state */}
                                                        {loading ? (
                                                            <i className="fe fe-refresh-cw spinner"></i> // Spinner icon when loading
                                                        ) : (
                                                            <i className="fe fe-refresh-ccw"></i> // Normal refresh icon
                                                        )}
                                                    </span>
                                                </a>
                                            </li>
                                            <li>
                                                <Link
                                                    to="#"
                                                    className="btn btn-filters w-auto popup-toggle me-2"
                                                    // onClick={() => setShow(!show)}
                                                    onClick={toggleFilter}
                                                >
                                                    <span className="me-2">
                                                        <Tooltip title="Filter">
                                                            <img
                                                                src={filterIcon}
                                                                alt="filter"
                                                            />
                                                        </Tooltip>
                                                    </span>
                                                    Filter
                                                </Link>
                                            </li>
                                            <li className="">
                                                <div className="dropdown dropdown-action me-2">
                                                    <Link
                                                        to="#"
                                                        className="btn-filters me-2"
                                                        data-bs-toggle="dropdown"
                                                        aria-expanded="false"
                                                    >
                                                        <span>
                                                            <Tooltip
                                                                title="Download"
                                                                placement="bottom"
                                                            >
                                                                <i className="fe fe-download" />
                                                            </Tooltip>
                                                        </span>
                                                    </Link>
                                                    <div className="dropdown-menu dropdown-menu-end">
                                                        <ul className="d-block">
                                                            <li>
                                                                <Link
                                                                    className="d-flex align-items-center download-item"
                                                                    to="#"
                                                                    onClick={() =>
                                                                        handleDownload(
                                                                            "xlsx"
                                                                        )
                                                                    }
                                                                >
                                                                    <i className="far fa-file-pdf me-2" />
                                                                    Excel
                                                                </Link>
                                                            </li>
                                                            <li>
                                                                <Link
                                                                    className="d-flex align-items-center download-item"
                                                                    to="#"
                                                                    onClick={() =>
                                                                        handleDownload(
                                                                            "csv"
                                                                        )
                                                                    }
                                                                >
                                                                    <i className="far fa-file-text me-2" />
                                                                    CSV
                                                                </Link>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </li>
                                            <li>
                                                <Link
                                                    className="btn-filters"
                                                    to="#"
                                                >
                                                    <span>
                                                        <Tooltip
                                                            title="Print"
                                                            placement="bottom"
                                                        >
                                                            <i className="fe fe-printer" />
                                                        </Tooltip>
                                                    </span>{" "}
                                                </Link>
                                            </li>
                                            <li>
                                                <Link
                                                    className="btn btn-primary"
                                                    to="#"
                                                    data-bs-toggle="modal"
                                                    data-bs-target={`#import_${listAPI}`}
                                                >
                                                    <i
                                                        className="fa fa-plus-circle me-2"
                                                        aria-hidden="true"
                                                    />
                                                    Import
                                                </Link>
                                            </li>
                                            <ImportPopup
                                                id={`import_${listAPI}`}
                                                listAPI={listAPI}
                                                label={label}
                                                addLabel={addLabel}
                                                importDataLoad={importDataLoad}
                                            />
                                            <li>
                                                {addType === "nextPage" && (
                                                    <>
                                                        <button
                                                            className="btn btn-primary"
                                                            onClick={
                                                                handleAddData
                                                            }
                                                        >
                                                            <i
                                                                className="fa fa-plus-circle me-2"
                                                                aria-hidden="true"
                                                            />
                                                            Add {addLabel}
                                                        </button>
                                                    </>
                                                )}

                                                {listAPI ===
                                                    "bank_accounts" && (
                                                    <>
                                                        {linkToken ? (
                                                            <PlaidLink
                                                                token={
                                                                    linkToken
                                                                }
                                                                onSuccess={
                                                                    handleOnSuccess
                                                                }
                                                            >
                                                                <button className="btn btn-primary">
                                                                    <i
                                                                        className="fa fa-plus-circle me-2"
                                                                        aria-hidden="true"
                                                                    />
                                                                    Connect a
                                                                    bank account
                                                                </button>
                                                            </PlaidLink>
                                                        ) : (
                                                            <p>
                                                                Generating link
                                                                token...
                                                            </p>
                                                        )}
                                                    </>
                                                )}

                                                {addType === "popUp" && (
                                                    <>
                                                        <Link
                                                            className="btn btn-primary"
                                                            to="#"
                                                            data-bs-toggle="modal"
                                                            data-bs-target={
                                                                dynamicTarget
                                                            }
                                                        >
                                                            <i
                                                                className="fa fa-plus-circle me-2"
                                                                aria-hidden="true"
                                                            />
                                                            Add {addLabel}
                                                        </Link>

                                                        {listAPI ===
                                                            "account_types" && (
                                                            <AddAccountTypes
                                                                onAdd={
                                                                    handleAdd
                                                                }
                                                            />
                                                        )}
                                                        {listAPI ===
                                                            "journal_types" && (
                                                            <AddJournalTypes
                                                                onAdd={
                                                                    handleAdd
                                                                }
                                                            />
                                                        )}
                                                        {listAPI ===
                                                            "transaction_types" && (
                                                            <AddTransactionTypes
                                                                onAdd={
                                                                    handleAdd
                                                                }
                                                            />
                                                        )}
                                                    </>
                                                )}
                                            </li>
                                        </ul>
                                    )}
                                    {label === "Clients" && (
                                        <ul className="filter-list">
                                            <li>
                                                <button
                                                    className="btn btn-primary"
                                                    onClick={handlePopupEdit}
                                                >
                                                    <Tooltip title="Inivte Company">
                                                        <span>
                                                            {/* <i className="fe fe-send " ></i> */}
                                                            <i
                                                                className="fa fa-plus-circle me-2"
                                                                aria-hidden="true"
                                                            ></i>
                                                        </span>
                                                        Invite Company
                                                    </Tooltip>
                                                </button>
                                                {/* <i className="fe fe-edit" /> */}

                                                {/* <span className="me-2">
                                                  <Tooltip title="Filter">
                                                      <img
                                                          src={filterIcon}
                                                          alt="filter"
                                                      />
                                                  </Tooltip>
                                              </span> */}
                                            </li>
                                        </ul>
                                    )}
                                </div>
                            </div>
                        </div>
                        {/* <AddAccountTypes /> */}
                        {/* Filter Made */}
                        <NewFilter
                            show={showFilter}
                            setShow={setShowFilter}
                            filterableFields={filterableFields}
                            handleFilterChange={handleFilterChange}
                            onClear={onFilterClear}
                        />

                        <div className="row">
                            <div className="col-sm-12">
                                <div className=" card-table">
                                    <div className="card-body vendors">
                                        <div
                                            className={`table-responsive table-hover table-striped  ${
                                                fading ? "fade-out" : "fade-in"
                                            }`}
                                        >
                                            {showDeleteIcon && (
                                                <button className="btn btn-danger" onClick={handleDeleteSelected} style={{ cursor: "pointer" }}>
                                                    Delete All
                                                </button>
                                            )}
                                            <Table
                                                className="table"
                                                pagination={{
                                                    current: currentPage,
                                                    pageSize: rowsPerPage,
                                                    total: totalRecords,
                                                    showTotal: (total, range) =>
                                                        `Showing ${range[0]} to ${range[1]} of ${total} entries`,
                                                    onChange: onShowSizeChange,
                                                    itemRender: (
                                                        current,
                                                        type,
                                                        originalElement
                                                    ) => {
                                                        if (type === "prev") {
                                                            return (
                                                                <a>Previous</a>
                                                            );
                                                        }
                                                        if (type === "next") {
                                                            return <a>Next</a>;
                                                        }
                                                        if (type === "page") {
                                                            return (
                                                                <a>{current}</a>
                                                            );
                                                        }
                                                        return originalElement;
                                                    },
                                                    showSizeChanger: true, // Enable the dropdown to change page size
                                                    pageSizeOptions: [
                                                        "10",
                                                        "20",
                                                        "50",
                                                        "100"
                                                    ] // Define the page size options
                                                }}
                                                rowSelection={rowSelection}
                                                columns={columns.map(
                                                    column => ({
                                                        ...column,
                                                        sorter: false,
                                                        onHeaderCell: () => ({
                                                            onClick: () =>
                                                                handleSort(
                                                                    column.dataIndex
                                                                ),
                                                                style: { cursor: 'pointer' },
                                                        }),
                                                    })
                                                )}
                                                dataSource={tableData}
                                                rowKey={record => record.id}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

PageComponent.propTypes = {
    label: PropTypes.string.isRequired,
    listAPI: PropTypes.string.isRequired,
    addType: PropTypes.string.isRequired,
    route: PropTypes.string,
    addLabel: PropTypes.string
};

export default PageComponent;
