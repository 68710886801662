import React, { useState, useEffect } from 'react';
import { useAuth } from '../contexts/AuthContext';
import axios from 'axios';
import {
    CustomDropDownField, CustomInputField
} from "../CustomComponents/ReusableComponents";
import PropTypes from 'prop-types';
import Swal from 'sweetalert2';

const AddAccountTypes = ({ onAdd }) => {
    const { auth } = useAuth();
    const [accountTypes, setAccountTypes] = useState([]);

    const [formData, setFormData] = useState({
        parent_id: '',
        name: '',
        description: ''
    });

    useEffect(() => {
        const fetchDropdownData = async () => {
            try {
                const response = await axios.post(
                    `${process.env.REACT_APP_API_V1_BASE_URL}/get_dropdown_data`,
                    {
                        models: [
                            { model_name: "AccountType" }
                        ]
                    },
                    {
                        headers: {
                            Authorization: `Bearer ${auth}`
                        }
                    }
                );
                setAccountTypes(response.data.content.AccountType || []);
            } catch (err) {
                // setError("Failed to load dropdown data");
            }
        };

        fetchDropdownData();
    }, [auth]);

    const handleChange = e => {
        const { name, value } = e.target;
        setFormData(prevFormData => ({
            ...prevFormData,
            [name]: value
        }));
    };

    const [errors, setErrors] = useState({});

    const validateForm = () => {
        const validationErrors = {};
        if (!formData.name) validationErrors.name = "Add Name";
        if (!formData.description) validationErrors.description = "Add Description";
    
        setErrors(validationErrors);
        return Object.keys(validationErrors).length === 0; 
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
    
        if (!validateForm()) {
            return;
        }
    
        try {
            await axios.post(
                `${process.env.REACT_APP_API_V1_BASE_URL}/account_types`,
                { ...formData, items: formData.items },
                {
                    headers: {
                        Authorization: `Bearer ${auth}`,
                        "Content-Type": "application/json"
                    }
                }
            );
            onAdd();
            window.$('#add_account_types').modal('hide');

            Swal.fire({
                title: 'Success!',
                text: 'Account Type created successfully.',
                icon: 'success',
                confirmButtonText: 'OK',
                customClass: {
                    popup: 'custom-swal-popup',  // Use your class for custom positioning
                    icon: 'custom-swal-icon',    // Use this class to style the icon
                  }
            });
    
        } catch (err) {
            Swal.fire({
                title: 'Error!',
                text: 'Failed to create Account Type.',
                icon: 'error',
                confirmButtonText: 'OK',
                customClass: {
                    popup: 'custom-swal-popup',  // Use your class for custom positioning
                    icon: 'custom-swal-icon',    // Use this class to style the icon
                  }
            });
        }
    };

    return(
        <>
        <div
            className="modal fade"
            id="add_account_types"
            tabIndex="-1"
            role="dialog"
            aria-labelledby="addaccountTypesLabel"
            aria-hidden="true"
        >
            <div className="modal-dialog" role="document">
                <div className="modal-content">
                    <div className="modal-header align-items-center justify-content-between">
                        <h5 className="modal-title" id="addaccountTypesLabel">Add Account Type</h5>
                        <button type="button" className="custom-close-btn" data-bs-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <CustomInputField
                            inputType="text"
                            labelText="Name"
                            inputWrapper="col-lg-12"
                            name="name"
                            value={formData.name}
                            onChange={handleChange}
                            errorMessage={errors.name}
                            required={true}
                            placeholder="E.g Asset, Equity"
                        />
                        <CustomInputField
                            inputType="text"
                            labelText="Description"
                            inputWrapper="col-lg-12"
                            name="description"
                            value={formData.description}
                            onChange={handleChange}
                            errorMessage={errors.description}
                            required={true}
                        />
                        <CustomDropDownField
                            options={accountTypes.map(c => ({
                                value: c.id,
                                label: c.name
                            }))}
                            labelText="Parent Account"
                            name="parent_id"
                            onChange={handleChange}
                            dropDownSize="col-lg-12"
                        /> 
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-primary cancel me-3" data-bs-dismiss="modal">Close</button>
                        <button type="button" className="btn btn-primary" onClick={handleSubmit}>Save changes</button>
                    </div>
                </div>
            </div>
        </div>

        </>
    );
};

AddAccountTypes.propTypes = {
    id: PropTypes.string,
    onAdd: PropTypes.func.isRequired, 
  };

  
export default AddAccountTypes;