import React, { useState, useEffect } from "react";

import { useAuth } from "../contexts/AuthContext";
import axios from "axios";
import Swal from 'sweetalert2';
import { useTranslation } from "react-i18next";
import SettingsSidebar from "../layouts/SettingsSidebar";
import {
  CustomDropDownField,
} from "../CustomComponents/ReusableComponents";

const Preferences = () => {
  const { auth } = useAuth();
  const { t } = useTranslation();

  const companyId = 1; //branchId;
  const [isEditing, setIsEditing] = useState(false);
  const [currencyOptions, setCurrencyOptions] = useState([]);
  const [timezoneOptions, setTimezoneOptions] = useState([]);
  const [errors, setErrors] = useState({});
  const [formData, setFormData] = useState({
    'country_id': '',
    'date_format': '',
    'time_format': '',
    'time_zone_id': '',
    'time_zone_code': '',
    'fiscal_start_month': '',
    'language_code': 'en',
  });


  const languageOptions = [
    { code: "en", name: "English" }
  ];

  const handleEditClick = () => {
    setIsEditing(true);
  };
  const dateFormatOptions = window.dateFormatOptions();

  const timeFormatOptions = [
    { code: 'HH:mm', name: '24-Hour Format' },
    { code: 'hh:mm A', name: '12-Hour Format with AM/PM' },
  ];

  const yearOptions = [
    { id: 1, name: "January-December" },
    { id: 2, name: "February-January" },
    { id: 3, name: "March-February" },
  ];

  // Fetch dropdown data when component mounts
  useEffect(() => {
    const fetchCompanyInfo = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_V1_BASE_URL}/companies/${companyId}`,
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${auth}`
            }
          }
        );

        if (response.ok) {
          const data = await response.json();
          setFormData({
            'country_id': data.country_id || "",
            'date_format': data.date_format || "",
            'time_format': data.time_format || "",
            'time_zone_id': data.time_zone_id || "",
            'fiscal_start_month': data.fiscal_start_month || "",
            'language_code': data.language_code || "en",
          });
        } else {
          console.error("Failed to fetch company info");
        }
      } catch (error) {
        console.error("An unexpected error occurred:", error);
      }
    };

    const fetchDropdownData = async () => {

      try {
        const requestData = [
          { model_name: "Country" },
          { model_name: "TimeZone" }
        ];

        const apiReqHeaders = {
          headers: {
            Authorization: `Bearer ${auth}`,
            "Content-Type": "application/json",
          },
        };

        const response = await axios.post(
          `${process.env.REACT_APP_API_V1_BASE_URL}/get_dropdown_data`,
          { models: requestData },
          apiReqHeaders
        );

        const dropdownData = response.data.content;

        // Set dropdown data into states
        setCurrencyOptions(dropdownData.Country || []);
        setTimezoneOptions(dropdownData.TimeZone || []);
      } catch (error) {
        console.error("An unexpected error occurred:", error);
      } finally {
        //Do final action 
      }
    };

    fetchDropdownData();
    fetchCompanyInfo();
  }, []);

  const validateForm = () => {
    const validationErrors = {};
    // You can add more validations here as needed
    setErrors(validationErrors);

    // If there are validation errors, scroll to the first one
    if (Object.keys(validationErrors).length > 0) {
      // Get the name of the first field with an error
      const firstErrorField = Object.keys(validationErrors)[0];
      // Find the input element with the name that matches the first error field
      const errorElement = document.querySelector(
        `[name="${firstErrorField}"]`
      );
      if (errorElement) {
        errorElement.scrollIntoView({
          behavior: "smooth",
          block: "center"
        });
        errorElement.focus();
      }
      return false; // Form is not valid
    }
    return Object.keys(validationErrors).length === 0; // Returns true if no errors
  };


  const handleChange = e => {

    const { name, value } = e.target;
    // Handle other input types (including checkboxes)
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleSave = async () => {
    if (!validateForm()) {
      return;
    }

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_V1_BASE_URL}/companies/${companyId}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${auth}`
          },
          body: JSON.stringify(formData)
        }
      );
      if (response.ok) {
        setIsEditing(false);

        const updatedData = await response.json(); // Get the updated company data directly from response
        // Update localStorage with the new company data
        const userData = JSON.parse(localStorage.getItem('userData')) || {};
        userData.company = updatedData; // Update company data in userData
        localStorage.setItem('userData', JSON.stringify(userData));

      } else {
        Swal.fire({
          title: 'Error!',
          text: 'Failed to Update Company Preferences.',
          icon: 'error',
          confirmButtonText: 'OK'
        });
      }
    } catch (error) {
      Swal.fire({
        title: 'Error!',
        text: 'Failed to Update Company Preferences.',
        icon: 'error',
        confirmButtonText: 'OK'
      });
    }
    setIsEditing(false);
    Swal.fire({
      title: 'Success!',
      text: 'Company preferences updated Successfully',
      icon: 'success',
      confirmButtonText: 'OK'
    });
  };

  return (
      <div className="page-wrapper">
        <div className="content container-fluid">
          <div className="row">
            <div className="col-xl-3 col-md-4">
              <SettingsSidebar />
            </div>
            <div className="col-xl-9 col-md-8">
              <div className="card">
                <div className="card-body w-100">
                  <div className="content-page-header">
                    <h5>Preference Settings</h5>
                  </div>
                  <div className="row">

                    <CustomDropDownField
                      options={currencyOptions.map(
                        type => ({
                          value: type.id,
                          label: `${type.currency_code} (${type.name})`
                        })
                      )}
                      name="country_id"
                      labelText="Currency"
                      value={
                        formData.country_id
                      }
                      onChange={handleChange}
                      disabled={true}
                      required={true}
                      errorMessage={
                        errors.country_id
                      }
                      dropDownSize="col-lg-6"
                    />


                    <CustomDropDownField
                      options={languageOptions.map(
                        type => ({
                          value: type.code,
                          label: type.name
                        })
                      )}
                      name="language_code"
                      labelText="Language"
                      value={
                        formData.language_code
                      }
                      onChange={handleChange}
                      disabled={!isEditing}
                      required={true}
                      errorMessage={
                        errors.language_code
                      }
                      dropDownSize="col-lg-6"
                    />


                    <CustomDropDownField
                      options={timezoneOptions.map(
                        type => ({
                          value: type.id,
                          // label: `${type.code} (${type.name})`
                          label: type.name
                        })
                      )}
                      name="time_zone_id"
                      labelText="Time Zone"
                      value={
                        formData.time_zone_id
                      }
                      onChange={handleChange}
                      disabled={!isEditing}
                      required={true}
                      errorMessage={
                        errors.time_zone_id
                      }
                      dropDownSize="col-lg-6"
                    />


                    <CustomDropDownField
                      options={dateFormatOptions.map(
                        type => ({
                          value: type.phpCarbonFormat,
                          label: type.name
                        })
                      )}
                      name="date_format"
                      labelText="Date Format"
                      value={
                        formData.date_format
                      }
                      onChange={handleChange}
                      disabled={!isEditing}
                      required={true}
                      errorMessage={
                        errors.date_format
                      }
                      dropDownSize="col-lg-6"
                    />

                    <CustomDropDownField
                      options={timeFormatOptions.map(
                        type => ({
                          value: type.code,
                          label: type.name
                        })
                      )}
                      name="time_format"
                      labelText="Time Format"
                      value={
                        formData.time_format
                      }
                      onChange={handleChange}
                      disabled={!isEditing}
                      required={true}
                      errorMessage={
                        errors.time_format
                      }
                      dropDownSize="col-lg-6"
                    />

                    <CustomDropDownField
                      options={yearOptions.map(
                        type => ({
                          value: type.id,
                          label: type.name
                        })
                      )}
                      name="fiscal_start_month"
                      labelText="Financial Year"
                      value={
                        formData.fiscal_start_month
                      }
                      onChange={handleChange}
                      disabled={!isEditing}
                      required={true}
                      errorMessage={
                        errors.fiscal_start_month
                      }
                      dropDownSize="col-lg-6"
                    />

                    <div className="btn-path text-end">
                      {isEditing ? (
                        <>
                          <button
                            type="button"
                            className="btn btn-cancel bg-primary-light me-3"
                            onClick={() =>
                              setIsEditing(
                                false
                              )
                            }
                          >
                            {t("Cancel")}
                          </button>
                          <button
                            type="button"
                            className="btn btn-primary"
                            onClick={handleSave}
                          >
                            {t("Save Changes")}
                          </button>
                        </>
                      ) : (
                        <button
                          type="button"
                          className="btn btn-primary"
                          onClick={
                            handleEditClick
                          }
                        >
                          {t("Edit")}
                        </button>
                      )}
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
  );
};
export default Preferences;
