import React from "react";

import InvoiceViewOne from "./invoice-view-one";
const ShowInvoice = () => {

    return (
        <div className={`main-wrapper `}>
            <div className="page-wrapper">
                <InvoiceViewOne />
            </div>
        </div>
    );
};

export default ShowInvoice;
