import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import TextArea from "antd/es/input/TextArea";
import DatePicker from "react-datepicker";
import Select, { components } from "react-select";
import "react-datepicker/dist/react-datepicker.css";
import InputMask from "react-input-mask";

const CustomInputField = ({
    labelText,
    inputType,
    placeholder,
    className,
    inputWrapper,
    value = "",  // Default value to an empty string
    onChange,
    name,
    required = false,
    errorMessage = "",
    disabled = false
}) => {
    return (
        <div className={`col-lg-4 col-md-6 col-sm-12 ${inputWrapper}`}>
            <div className="input-block mb-3">
                <label>
                    {labelText}
                    {required && <span className="text-danger">*</span>}{" "}
                </label>
                <input
                    type={inputType || "text"}
                    className={`form-control ${className}`}
                    placeholder={placeholder || `Enter ${labelText}`}
                    value={value !== null ? value : ""} 
                    name={name}
                    onChange={onChange}
                    disabled={disabled}
                />
                {errorMessage && (
                    <p className="text-danger mt-0">{errorMessage}</p>
                )}
            </div>
        </div>
    );
};


{
    /* <div className="input-block mb-3">
                        <label>Phone</label>
                        <InputMask
                          className="form-control"
                          mask="(999) 999-9999"
                          name="phone"
                          value={formData.phone}
                          onChange={handleChange}
                        ></InputMask>
                        <span className="form-text text-muted">
                          (999) 999-9999
                        </span>
                      </div> */
}

const CustomPhoneField = ({
    labelText,
    inputWrapper,
    value,
    onChange,
    name,
    required = false,
    errorMessage = "",
    disabled = false,
    placeholder,
}) => {
    return (
        <div className={`col-lg-4 col-md-6 col-sm-12 ${inputWrapper}`}>
            <div className="input-block mb-3">
                <label>
                    {labelText}
                    {required && <span className="text-danger">*</span>}{" "}
                    {/* Indicate mandatory field */}
                </label>
                <InputMask
                    className="form-control"
                    mask="(999) 999-9999"
                    disabled={disabled}
                    value={value}
                    name={name}
                    onChange={onChange}
                    placeholder={placeholder}
                ></InputMask>
                {/* Display error message if the field is not valid */}
                {errorMessage && (
                    <p className="text-danger mt-0">{errorMessage}</p>
                )}
            </div>
        </div>
    );
};

const CustomDatePicker = ({
    labelText,
    placeholder,
    className,
    inputWrapper,
    value,
    onChange,
    name,
    required = false,
    errorMessage = "",
    disabled = false,

}) => {
    const [startDate, setStartDate] = useState(value || null);
    const dateFormat = window.getDefaultDateFormatForDatePicker();
    useEffect(() => {
        // Check if value is a valid date string and set as a Date object
        if (typeof value === "string" && !isNaN(Date.parse(value))) {
            let date = new Date(value);
            setStartDate(date); // Convert string to Date object
            onChange({ target: { name, value: date } });
        }
        // Check if value is already a Date object and valid
        else if (value instanceof Date && !isNaN(value.getTime())) {
            setStartDate(value); // Set directly if valid Date object
            onChange({ target: { name, value: value } });
        }
        // If invalid, set to null
        else {
            setStartDate(null);
            onChange({ target: { name, value: "" } });
        }
    }, [value]);

    const handleDateChange = date => {
        setStartDate(date);
        onChange({ target: { name, value: date } });
    };

    return (
        <div className={`col-lg-4 col-md-6 col-sm-12 ${inputWrapper}`}>
            <div className="input-block mb-3">
                <label>
                    {labelText}
                    {required && <span className="text-danger">*</span>}
                </label>
                <DatePicker
                    selected={startDate} // Will be null if invalid
                    onChange={handleDateChange}
                    dateFormat={dateFormat}
                    placeholderText={placeholder || `Select ${labelText}`}
                    className={`form-control ${className}`}
                    name={name}
                    disabled={disabled}
                />
                {errorMessage && (
                    <p className="text-danger mt-0">{errorMessage}</p>
                )}
            </div>
        </div>
    );
};

export const CustomTextArea = ({
    className,
    rows,
    col,
    placeholder,
    labelText,
    value,
    onChange,
    name,
    required = false,
    errorMessage = ""
}) => {
    return (
        <>
            <label className="input-block ">
                {labelText}
                {required && <span className="text-danger">*</span>}{" "}
                {/* Indicate mandatory field */}
            </label>
            <TextArea
                col={col}
                className={`form-control ${className}`}
                placeholder={placeholder}
                rows={rows}
                value={value}
                name={name}
                onChange={onChange}
            />
            {/* Display error message if the field is not valid */}
            {errorMessage && <p className="text-danger mt-0">{errorMessage}</p>}
        </>
    );
};

const CustomDropDownField = ({
    options = [], // Default to empty array if no options are provided
    name,
    id,
    labelText,
    dropDownSize,
    value,
    onChange,
    required = false, // Default to false if not specified
    errorMessage = "", // Default to empty string if not specified
    disabled = false // Default to false if not specified
}) => {
    // Define custom styles for react-select, specifically for the "Add new account" option
    const customStyles = {
        option: (provided, state) => ({
            ...provided,
            color: state.data.value === 'new' ? 'blue' : 'inherit',
            textDecoration: state.data.value === 'new' ? 'underline' : 'none',
            cursor: state.data.value === 'new' ? 'pointer' : 'default',
        })
    };
    return (
        <div className={`col-lg-4 col-md-6 col-sm-12 ${dropDownSize}`}>
            <div className="input-block mb-3">
                <label>
                    {labelText}
                    {required && <span className="text-danger">*</span>}{" "}
                    {/* Indicate mandatory field */}
                </label>
                <Select
                    name={name}
                    inputId={id} // `react-select` uses `inputId` for input element
                    options={options} // `options` should be in [{ value, label }] format
                    value={options.find(option => option.value === value)} // Find the selected option by value
                    onChange={selectedOption =>
                        onChange({
                            target: { name, value: selectedOption?.value }
                        })
                    } // Adapt onChange to send event-like data
                    isDisabled={disabled} // Use the disabled prop here
                    classNamePrefix="react-select" // Optional: for CSS styling
                    placeholder={`Select ${labelText}`} // Placeholder text
                    styles={customStyles}
                />
                {/* Display error message if the field is not valid */}
                {errorMessage && (
                    <p className="text-danger mt-0">{errorMessage}</p>
                )}
            </div>
        </div>
    );
};

const CustomDropDownFieldWithButton = ({
    options = [],
    name,
    id,
    labelText,
    dropDownSize,
    value,
    onChange,
    required = false,
    errorMessage = "",
    disabled = false,
    path
}) => {
    const addNewOption = { value: 'new', label: `Add New ${labelText}`, isAddNew: true, path };

    const customStyles = {
        option: (provided, state) => ({
            ...provided,
            color: state.data.value === 'new' ? 'blue' : 'inherit',
            textDecoration: state.data.value === 'new' ? 'underline' : 'none',
            cursor: state.data.value === 'new' ? 'pointer' : 'default',
        }),
    };

    const CustomOption = (props) => {
        const { data, innerRef, innerProps } = props;

        if (data.isAddNew) {
            return (
                <div
                    ref={innerRef}
                    {...innerProps}
                    style={{
                        padding: '10px',
                        cursor: 'pointer',
                        color: 'blue',
                        fontWeight: 'bold',
                    }}
                    onClick={data.path}
                >
                    {data.label}
                </div>
            );
        }
        return <components.Option {...props} />;
    };

    CustomOption.propTypes = {
        data: PropTypes.shape({
            isAddNew: PropTypes.bool,
            label: PropTypes.string.isRequired,
            path: PropTypes.func,
        }).isRequired,
        innerRef: PropTypes.oneOfType([
            PropTypes.func,
            PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
        ]),
        innerProps: PropTypes.object.isRequired,
    };

    const CustomMenu = ({ children, ...props }) => {
        return (
            <components.Menu {...props}>
                <CustomOption data={addNewOption} {...props} />
                <hr style={{ margin: '5px 0', border: '1px solid #ccc' }} />
                {children}
            </components.Menu>
        );
    };

    CustomMenu.propTypes = {
        options: PropTypes.arrayOf(
            PropTypes.shape({
                isAddNew: PropTypes.bool,
                label: PropTypes.string,
                path: PropTypes.func,
            })
        ).isRequired,
        children: PropTypes.node.isRequired,
    };

    return (
        <div className={`col-lg-4 col-md-6 col-sm-12 ${dropDownSize}`}>
            <div className="input-block mb-3">
                <label>
                    {labelText}
                    {required && <span className="text-danger">*</span>}{" "}
                </label>
                {/* <div className="d-flex align-items-center" style={{ gap: '5px' }}> */}
                    {/* <div style={{ flex: 10 }}> */}
                        <Select
                            name={name}
                            inputId={id}
                            options={options}
                            value={options.find((option) => option.value === value)}
                            onChange={(selectedOption) => {
                                if (!selectedOption.isAddNew) {
                                    onChange({
                                        target: { name, value: selectedOption?.value },
                                    });
                                }
                            }}
                            isDisabled={disabled}
                            classNamePrefix="react-select"
                            placeholder={`Select ${labelText}`}
                            components={{ Menu: CustomMenu, Option: CustomOption }}
                            styles={customStyles}
                        />
                    {/* </div> */}
                    {/* <div style={{ flex: 2 }}>
                        <button className="btn btn-primary" onClick={path}>
                            <i className="fa fa-plus-circle me-2" aria-hidden="true" />
                        </button>
                    </div> */}
                {/* </div> */}
                {errorMessage && <p className="text-danger mt-0">{errorMessage}</p>}
            </div>
        </div>
    );
};


const NewFooter = () => {
    return (
        <div className="preview-boxs py-3">
            {/* <Link to="/signature-preview-invoice">Preview Invoice</Link> */}
            <button type="reset" className="btn btn-primary cancel me-2">
                Cancel
            </button>
            <button type="reset" className="btn btn-primary cancel me-2">
                Save
            </button>
            <button type="submit" className="btn btn-primary">
                Save & Send
            </button>
        </div>
    );
};

const ReusableButton = (classNames, ButtonTitle, functionOnClick) => {
    <button className={classNames} onClick={functionOnClick}>
        {ButtonTitle}
    </button>;
};

// Define prop types
CustomInputField.propTypes = {
    labelText: PropTypes.string,
    inputType: PropTypes.string,
    placeholder: PropTypes.string,
    className: PropTypes.string,
    inputWrapper: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]), // Add value prop
    name: PropTypes.string, // Add name prop
    onChange: PropTypes.func.isRequired, // Add onChange prop
    required: PropTypes.bool,
    errorMessage: PropTypes.string,
    disabled: PropTypes.bool
};

CustomDropDownField.propTypes = {
    options: PropTypes.arrayOf(
        PropTypes.shape({
            value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]), // Allow string or number
            label: PropTypes.string
        })
    ).isRequired, // Ensure options are provided
    name: PropTypes.string, // Add name prop
    id: PropTypes.string,
    labelText: PropTypes.string,
    dropDownSize: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]), // Add value prop
    onChange: PropTypes.func.isRequired, // Add onChange prop
    required: PropTypes.bool,
    errorMessage: PropTypes.string,
    disabled: PropTypes.bool
};

CustomDropDownFieldWithButton.propTypes = {
    options: PropTypes.arrayOf(
        PropTypes.shape({
            value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
            label: PropTypes.string,
        })
    ).isRequired,
    name: PropTypes.string,
    id: PropTypes.string,
    labelText: PropTypes.string,
    dropDownSize: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    onChange: PropTypes.func.isRequired,
    required: PropTypes.bool,
    errorMessage: PropTypes.string,
    disabled: PropTypes.bool,
    path: PropTypes.func.isRequired,
};

CustomDatePicker.propTypes = {
    labelText: PropTypes.string,
    inputType: PropTypes.string,
    placeholder: PropTypes.string,
    className: PropTypes.string,
    inputWrapper: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]), // Corrected value prop
    name: PropTypes.string, // Add name prop
    onChange: PropTypes.func.isRequired, // Add onChange prop
    required: PropTypes.bool,
    errorMessage: PropTypes.string,
    disabled: PropTypes.bool
};

CustomPhoneField.propTypes = {
    value: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    name: PropTypes.string,
    placeholder: PropTypes.string,
    className: PropTypes.string,
    inputWrapper: PropTypes.string,
    required: PropTypes.bool,
    errorMessage: PropTypes.string,
    disabled: PropTypes.bool,
    labelText: PropTypes.string
};

CustomTextArea.propTypes = {
    rows: PropTypes.number,
    col: PropTypes.number,
    className: PropTypes.string,
    placeholder: PropTypes.string,
    labelText: PropTypes.string,
    value: PropTypes.string, // Add value prop
    name: PropTypes.string, // Add name prop
    onChange: PropTypes.func.isRequired, // Add onChange prop
    required: PropTypes.bool,
    errorMessage: PropTypes.string
};

export {
    CustomInputField,
    CustomDropDownField,
    CustomDropDownFieldWithButton,
    NewFooter,
    ReusableButton,
    CustomDatePicker,
    CustomPhoneField
};
