import img5 from "../assets/img/profiles/avatar-05.jpg";
import img6 from "../assets/img/profiles/avatar-06.jpg";
import img7 from "../assets/img/profiles/avatar-07.jpg";
import img8 from "../assets/img/profiles/avatar-08.jpg";
import img9 from "../assets/img/profiles/avatar-09.jpg";

const recentestimates = [
  {
    id: 1,
    key: 1,
    customer_name: "Greg Lynch",
    image: img5,
    amount: "$175",
    expiry_date: "5 Nov 2020",
    status: "Sent",
  },
  {
    id: 2,
    key: 2,
    customer_name: "Karlene Chaidez",
    image: img6,
    amount: "$1500",
    expiry_date: "28 Oct 2020",
    status: "Expired",
  },
  {
    id: 3,
    key: 3,
    customer_name: "John Blair",
    image: img7,
    amount: "$2350",
    expiry_date: "17 Oct 2020",
    status: "Accepted",
  },
  {
    id: 4,
    key: 4,
    customer_name: "Russell Copeland",
    image: img8,
    amount: "$1890",
    expiry_date: "8 Oct 2020",
    status: "Accepted",
  },
  {
    id: 5,
    key: 5,
    customer_name: "Leatha Bailey",
    image: img9,
    amount: "$785",
    expiry_date: "30 Sep 2020",
    status: "Accepted",
  },
];

export default recentestimates;
