import PageComponent from "../CustomComponents/PageComponent"
import React from 'react';
const TransactionComponent = () => {
  return(
    <PageComponent listAPI='transactions' route="transactions" label='Transactions' addType='nextPage' addLabel='Transaction'/>
  )
}
export default TransactionComponent

// import React, { useEffect, useState } from 'react'
// import Header from '../layouts/Header'
// import Sidebar from '../layouts/Sidebar'
// import MainContainer from '../CustomComponents/MainContainer'
// import SectionHeader from '../CustomComponents/SectionHeader'
// import { Link } from 'react-router-dom/cjs/react-router-dom.min'
// import { Table } from "antd";
// import { itemRender, onShowSizeChange } from '../_components/paginationfunction'
// import axios from "axios";
// import { useAuth } from '../contexts/AuthContext'
// const TransactionComponent = () => {



//   const [transactionData, setTransactionData] = useState([]);
//   // const [vendorData, setVendorData] = useState([]);
//   const [columns, setColumns] = useState([]);
//   // const [show, setShow] = useState(false);
//   const [menu, setMenu] = useState(false);
//   const { auth } = useAuth();

//   const handleEdit = (id) => {
//     history.push(`/edit-vendor/${id}`);
//   };

//   const toggleMobileMenu = () => {
//     setMenu(!menu);
//   };

//   const handleDelete = () => {
//     console.log("delete!!");
//   }
//   const handleLedger = (id) => {
//     console.log("id", id);
//     history.push(`/ledger/${id}`);
//   }


//   const fetchTransactionDetails = async () => {
//     try {
//       const response = await axios.get(`${process.env.REACT_APP_API_V1_BASE_URL}/transactions`, {
//         headers: {
//           Authorization: `Bearer ${auth}`,
//         },
//       });
//       console.log(response.data);
      
//       const records = response.data.records;
//       const headers = response.data.headers;

//       const dynamicColumns = headers.map(header => ({
//         title: header.display_name,
//         dataIndex: header.field_name,
//         sorter: header.sortable ? (a, b) => a[header.field_name]?.localeCompare(b[header.field_name]) : null,
//         render: (text) => text,
//       }));

//       dynamicColumns.push({
//         title: 'Actions',
//         dataIndex: 'actions',
//         render: (_, record) => (
//           <div className="d-flex align-items-center">
//             <button 
//               className="btn btn-action-icon me-2" 
//               onClick={() => handleLedger(record.id)}
//             >
//               <i className="far fa-edit me-2" />
//               {/* Ledger */}
//             </button>
//             <button 
//               className="btn btn-action-icon me-2" 
//               onClick={() => handleEdit(record.id)}
//             >
//               <i className="far fa-edit me-2" />
//               {/* Edit */}
//             </button>
//             <button 
//               className="btn btn-action-icon" 
//               onClick={() => handleDelete(record.id)}
//             >
//               <i className="far fa-trash-alt me-2" />
//               {/* Delete */}
//             </button>
//           </div>
//         ),
//       });

//       setColumns(dynamicColumns);
//       setTransactionData(records);
//     } catch (error) {
//       console.error("Error fetching customer details:", error);
//     }
//   };


//   useEffect(() => {
//     fetchTransactionDetails();
//   }, [auth]);

//   return (
//     <>
//     <Header onMenuClick={() => toggleMobileMenu()}/>
//     <Sidebar/>
//     <MainContainer>
//       <div className="d-flex justify-content-between">
//       <SectionHeader sectionHeading='Transactions this is'/>
//       <span>
//         <Link to="/transactionsForm" className="btn btn-primary  me-2">
//           Add Transaction 
//         </Link>
//       </span>
//       </div>

//  {/* /Search Filter */}
//  <div className="row">
//               <div className="col-sm-12">
//                 <div className=" card-table">
//                   <div className="card-body vendors">
//                     <div className="table-responsive table-hover table-striped">
//                     <Table
//                         className="table"
//                         pagination={{
//                           total: transactionData.length,
//                           showTotal: (total, range) =>
//                             `Showing ${range[0]} to ${range[1]} of ${total} entries`,
//                           onShowSizeChange: onShowSizeChange,
//                           itemRender: itemRender,
//                         }}
//                         columns={columns}
//                         dataSource={transactionData}
//                         rowKey={(record) => record.id}
//                       />
//                     </div>
//                   </div>
//                 </div>
//               </div>
//             </div>
//     </MainContainer>
//     </>
//   )
// }

// export default TransactionComponent