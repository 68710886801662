import React, { useState, useEffect } from "react";
import SettingsSidebar from "../../layouts/SettingsSidebar";
import { useTranslation } from "react-i18next";
import { useAuth } from "../../contexts/AuthContext";
import axios from "axios";
import { useLocation } from "react-router-dom";
import { CustomDropDownField } from "../../CustomComponents/ReusableComponents";

const QuickBooksIntegration = () => {
    const { t } = useTranslation();
    const location = useLocation();

    const { auth: authToken, branchId } = useAuth();
    const [step, setStep] = useState(1);
    const [authUrl, setAuthUrl] = useState(null);
    const [isAuthorized, setIsAuthorized] = useState(false);
    const [syncing, setSyncing] = useState(false);
    const [error, setError] = useState(null);
    const [successMessages, setSuccessMessages] = useState([]); // Array to hold success messages
    // eslint-disable-next-line no-unused-vars
    const [financialYear, setFinancialYear] = useState("");
    // eslint-disable-next-line no-unused-vars
    const [yearOptions, setYearOptions] = useState([]); // Options for financial year dropdown
    // eslint-disable-next-line no-unused-vars
    const [financialStartMonth, setFinancialStartMonth] = useState("");

    // eslint-disable-next-line no-unused-vars
    const yearStartOptions = [
        { id: 1, name: "January-December" },
        { id: 2, name: "February-January" },
        { id: 3, name: "March-February" },
    ];

    const queryParams = new URLSearchParams(location.search);
    const code = queryParams.get("code");
    const state = queryParams.get("state");
    const realmId = queryParams.get("realmId");
    // Utility to calculate financial years
    const generateFinancialYears = (currentYear, range = 10) => {
        const years = [];
        for (let i = 0; i <= range; i++) {
            const startYear = currentYear - i;
            const endYear = startYear + 1;
            years.push({
                id: startYear.toString(),
                name: `${startYear}-${endYear}`,
            });
        }
        return years;
    };

    // Dynamically calculate financial year options
    useEffect(() => {
        const currentYear = new Date().getFullYear();
        const financialYears = generateFinancialYears(currentYear, 10);
        setYearOptions(financialYears);
    }, []);
    useEffect(() => {
        if (code && state && realmId) {
            setIsAuthorized(true);

            const callQuickBooksCallback = async () => {
                try {
                    const response = await axios.get(
                        `${process.env.REACT_APP_API_V1_BASE_URL}/quickbooks/callback`,
                        {
                            params: { code, state, realmId },
                            headers: {
                                Authorization: `Bearer ${authToken}`
                            }
                        }
                    );

                    if (response.status === 200) {
                        setStep(2); // Proceed to the next step after authorization
                        setSuccessMessages(prevMessages => [...prevMessages, "QuickBooks successfully authorized!"]);
                    } else {
                        setIsAuthorized(false);
                        // setError("Failed to complete QuickBooks authorization.");
                    }
                } catch (error) {
                    setIsAuthorized(false);
                    // console.error("An unexpected error occurred:", error);
                    // setError("An unexpected error occurred during authorization.");
                }
            };

            callQuickBooksCallback();
        }

        const fetchQuickBooksAuthUrl = async () => {
            try {
                const response = await axios.get(
                    `${process.env.REACT_APP_API_V1_BASE_URL}/quickbooks/auth-url`,
                    {
                        headers: {
                            Authorization: `Bearer ${authToken}`
                        }
                    }
                );

                if (response.status === 200) {
                    setAuthUrl(response.data.auth_url);
                    if (response.data.token_exists) {
                        setStep(2); // Move to the next step
                    }
                } else {
                    setError("Failed to fetch QuickBooks authorization URL.");
                }
            } catch (error) {
                console.error("An unexpected error occurred:", error);
                setError("An unexpected error occurred while fetching auth URL.");
            }
        };

        fetchQuickBooksAuthUrl();

    }, [authToken, code, state, realmId]);

    const handleQuickBooksSync = async (syncType) => {
        setSyncing(true);
        setError(null);
        if ((syncType == 'invoices' || syncType == 'bills') && (!financialYear || !financialStartMonth)) {
            setSyncing(false);
            setError("Financial Year and Financial Start Month are required for syncing.");
            return;
        }

        try {
            const payload = { branchId };
            if (financialYear) {
                payload.financial_year = financialYear; // Include financial year in the payload
            }

            if (financialStartMonth) {
                payload.financial_start_month = financialStartMonth; // Include financial year in the payload
            }

            const response = await axios.post(
                `${process.env.REACT_APP_API_V1_BASE_URL}/quickbooks/sync-${syncType}`,
                payload,
                {
                    headers: {
                        Authorization: `Bearer ${authToken}`,
                        "Content-Type": "application/json",
                    },
                }
            );

            if (response.status === 200) {
                setSuccessMessages(prevMessages => [
                    `Successfully synced ${syncType}.`, ...prevMessages
                ]);
                setStep(prevStep => prevStep + 1); // Move to the next step
            } else if (response.status === 401) {
                setStep(1);
                setError("Authentication failed. Please authorize again.");
            } else {
                setError(`Failed to sync ${syncType}.`);
            }
        } catch (err) {
            if (err.response) {
                // If response exists, log status code and message
                if (err.response.status === 401) {
                    setStep(1);
                    setError("Authentication failed. Please authorize again.");
                } else {
                    setError(`An error occurred while syncing ${syncType}.`);
                }
            } else {
                // If no response, log general error
                setError(`An error occurred while syncing ${syncType}.`);
            }
        } finally {
            setSyncing(false);
        }
    };

    const syncSteps = [
        { title: "Sync Customers", syncType: "customers" },
        { title: "Sync Vendors", syncType: "vendors" },
        { title: "Sync Accounts", syncType: "accounts" },
        { title: "Sync Items", syncType: "items" },
        { title: "Sync Invoices", syncType: "invoices" },
        { title: "Sync Bills", syncType: "bills" },
    ];

    const renderStepContent = () => {
        switch (step) {
            case 1:
                return (
                    <div>
                        <div className="d-flex align-items-center justify-content-between w-100">
                            <h5>{t("Authorize QuickBooks")}</h5>
                            {!isAuthorized && authUrl && (
                                <a
                                    href={authUrl}
                                    className="btn btn-primary"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    {t("Authorize Now")}
                                </a>
                            )}
                        </div>
                        {successMessages.map((message, index) => (
                            <div key={index} className="alert alert-success">{message}</div>
                        ))}
                        {error && <div className="alert alert-danger">{error}</div>}
                    </div>
                );
            default:
                return (
                    <div>
                        <h5>{t("QuickBooks Integration Completed")}</h5>
                        <p>{t("All data has been successfully synced.")}</p>

                        {successMessages.map((message, index) => (
                            <div key={index} className="alert alert-success">{message}</div>
                        ))}
                    </div>
                );
        }
    };

    const renderDiffStep = () => {
        const currentStep = syncSteps[step - 2];
        if (!currentStep) return null;

        return (
            <>
                {(currentStep.syncType === "invoices" || currentStep.syncType === "bills") ? (
                    <div>
                        <div className="page-title" style={{ marginBottom: '22px' }}>
                            <h5>{t(currentStep.title)}</h5>
                        </div>
                        <div className="d-flex align-items-center justify-content-between w-100">
                            {(currentStep.syncType === "invoices" || currentStep.syncType === "bills") && (
                                <>
                                    <CustomDropDownField
                                        options={yearStartOptions.map(
                                            type => ({
                                                value: type.id,
                                                label: type.name
                                            })
                                        )}
                                        name="fiscal_start_month"
                                        labelText="Financial Period"
                                        value={
                                            financialStartMonth
                                        }
                                        onChange={e => setFinancialStartMonth(e.target.value)}
                                        required={true}
                                        errorMessage={
                                            error && !financialStartMonth ? t("Financial start month is required") : null
                                        }
                                        dropDownSize="col-lg-3"
                                    />
                                    <CustomDropDownField
                                        options={yearOptions.map(year => ({
                                            value: year.id,
                                            label: year.name,
                                        }))}
                                        name="fiscal_start_month"
                                        labelText={t("Financial Year")}
                                        value={financialYear}
                                        onChange={e => setFinancialYear(e.target.value)}
                                        required={true}
                                        errorMessage={
                                            error && !financialYear ? t("Financial year is required") : null
                                        }
                                        dropDownSize="col-lg-3"
                                    />
                                </>
                            )}
                            <button
                                onClick={() => handleQuickBooksSync((currentStep.syncType))}
                                className="btn btn-block btn-outline-primary active mb-3"
                                disabled={syncing}
                            >
                                {syncing ? t("Syncing...") : t(currentStep.title)}
                            </button>
                        </div>

                    </div>
                ) : (
                    <div className="d-flex align-items-center justify-content-between w-100">
                        <h5>{t(currentStep.title)}</h5>
                        <button
                            onClick={() => handleQuickBooksSync((currentStep.syncType))}
                            className="btn btn-block btn-outline-primary active mb-3"
                            disabled={syncing}
                        >
                            {syncing ? t("Syncing...") : t(currentStep.title)}
                        </button>
                    </div>
                )}
                {successMessages.map((message, index) => (
                    <div key={index} className="alert alert-success">{message}</div>
                ))}
                {error && <div className="alert alert-danger">{error}</div>}
            </>
        );
    }

    return (
        <div className="page-wrapper">
            <div className="content container-fluid">
                <div className="row">
                    <div className="col-xl-3 col-md-4">
                        <SettingsSidebar />
                    </div>
                    <div className="col-xl-9 col-md-8">
                        <div className="card">
                            <div className="content-page-header card-header mb-0">
                                <h3 className="page-title">{t("QuickBooks Integration")}</h3>
                            </div>
                            <div className="card-body w-100">
                                {step >= 2 && step <= 7 ? renderDiffStep() : renderStepContent()}
                            </div>
                            <div className="card-body w-100">
                                <div className="btn-path text-end">
                                    <button
                                        onClick={() => setStep(step - 1)}
                                        disabled={step === 1}
                                        className="btn btn btn-primary previous me-2"
                                    >
                                        {t("Previous")}
                                    </button>
                                    <button
                                        onClick={() => setStep(step + 1)}
                                        disabled={syncing || step === 7} // Prevent going beyond the last step
                                        className="btn btn btn-primary next"
                                    >
                                        {t("Next")}
                                    </button>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default QuickBooksIntegration;