import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
// import Header from "../layouts/Header";
// import Sidebar from "../layouts/Sidebar";
import { useAuth } from "../contexts/AuthContext";
import ReactSelect from "react-select";
// import WIthHeaderSidebar from "../CustomComponents/WIthHeaderSidebar";

const LedgerComponent = () => {
    const { auth: authToken } = useAuth();
    const { id } = useParams();
    console.log("Ledger ID:", id); // Add this line to check the id

    const [ledgersData, setLedgersData] = useState({});
    const [accountOptions, setAccountOptions] = useState([]);
    const [entries, setEntries] = useState([]);
    const [data, setData] = useState([]);
    const [error, setError] = useState(null);
    const [selectedAccount, setSelectedAccount] = useState(null);

    const fetchLedgerData = async accountId => {
        const apiReqHeaders = {
            headers: {
                Authorization: `Bearer ${authToken}`,
                "Content-Type": "application/json"
            }
        };

        try {
            const response = await axios.get(
                `${process.env.REACT_APP_API_V1_BASE_URL}/ledgers/${accountId}`,
                apiReqHeaders
            );
            if (
                response &&
                response.data &&
                Object.keys(response.data).length > 0
            ) {
                setData(response.data);
                setLedgersData(response.data);
                setEntries(response.data.entries || []);
            } else {
                setData([]);
                setLedgersData({});
                setEntries([]);
            }
        } catch (error) {
            setError("Failed to load ledger data.");
        }
    };

    useEffect(() => {
        const fetchDropdownData = async () => {
            try {
                const response = await axios.post(
                    `${process.env.REACT_APP_API_V1_BASE_URL}/get_dropdown_data`,
                    {
                        models: [{ model_name: "Account" }]
                    },
                    {
                        headers: {
                            Authorization: `Bearer ${authToken}`
                        }
                    }
                );
                // setAccountOptions(response.data.content.Account || []);
                const options = response.data.content.Account || [];
                setAccountOptions(options);

                // Set selected account if id exists
                if (id) {
                    const selected = options.find(
                        option => option.id === parseInt(id)
                    );
                    if (selected) {
                        setSelectedAccount({
                            value: selected.id,
                            label: selected.name
                        });
                        fetchLedgerData(selected.id); // Fetch ledger data for the selected account
                    }
                } else if (options.length > 0) {
                    // Select the first account if no id is present
                    const firstAccount = options[0];
                    console.info("firstAccount ", firstAccount);
                    setSelectedAccount({
                        value: firstAccount.id,
                        label: firstAccount.name
                    });
                    fetchLedgerData(firstAccount.id); // Fetch ledger data for the first account
                }
            } catch (err) {
                console.log("Failed to load dropdown data");
                setError("Failed to load account options.");
            }
        };
        fetchDropdownData();
    }, [authToken]);

    const handleAccountChange = selectedOption => {
        if (selectedOption) {
            const accountId = selectedOption.value;
            setSelectedAccount(selectedOption);
            fetchLedgerData(accountId);
        }
    };

    return (
        <>
        
            {/* <MainContainer> */}
                <div className="page-wrapper">
                    {data ? (
                        <div className="content container-fluid">
                            <div className="page-header">
                                <div className="content-page-header">
                                    <h5>Account Ledger</h5>
                                </div>
                            </div>

                            {error && (
                                <div className="alert alert-danger">
                                    {error}
                                </div>
                            )}

                            <div className="row">
                                <div className="col-lg-2 col-md-6 col-sm-12">
                                    <div className="input-block mb-3">
                                        <label>Account</label>
                                        <ul className="form-group">
                                            <li>
                                                <ReactSelect
                                                    value={selectedAccount}
                                                    onChange={
                                                        handleAccountChange
                                                    }
                                                    options={accountOptions.map(
                                                        c => ({
                                                            value: c.id,
                                                            label: c.name
                                                        })
                                                    )}
                                                    placeholder="Select Account"
                                                />
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>

                            <div className="card p-4 ledger-list">
                                <div className="d-flex align-items-center justify-content-between">
                                    <div className="ledger-info mb-4">
                                        <h2>
                                            Account :{" "}
                                            {ledgersData.account || ""}
                                        </h2>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-sm-12">
                                        <div className="card-table">
                                            <div className="card-body">
                                                <div className="table-responsive">
                                                    <table className="table table-striped table-hover">
                                                        <thead className="thead-light">
                                                            <tr>
                                                                <th>Date</th>
                                                                <th>
                                                                    Transaction
                                                                </th>
                                                                <th>
                                                                    Transaction
                                                                    type
                                                                </th>
                                                                <th>
                                                                    Particular
                                                                </th>
                                                                <th>Debits</th>
                                                                <th>Credits</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {entries.map(
                                                                (
                                                                    entry,
                                                                    index
                                                                ) => (
                                                                    <tr
                                                                        key={
                                                                            index
                                                                        }
                                                                    >
                                                                        <td>
                                                                            {entry.date ||
                                                                                "N/A"}
                                                                        </td>
                                                                        <td>
                                                                            {entry.transaction ||
                                                                                "N/A"}
                                                                        </td>
                                                                        <td>
                                                                            <span className="badge bg-success-light">
                                                                                {entry.transaction_type ||
                                                                                    "N/A"}
                                                                            </span>
                                                                        </td>
                                                                        <td>
                                                                            {entry.particular ||
                                                                                "N/A"}
                                                                        </td>
                                                                        <td>
                                                                            <span>
                                                                                {entry.is_debit ===
                                                                                1
                                                                                    ? entry.amount
                                                                                    : "-"}
                                                                            </span>
                                                                        </td>
                                                                        <td>
                                                                            <span>
                                                                                {entry.is_debit ===
                                                                                0
                                                                                    ? entry.amount
                                                                                    : "-"}
                                                                            </span>
                                                                        </td>
                                                                    </tr>
                                                                )
                                                            )}
                                                            <tr>
                                                                <td colSpan={4}>
                                                                    <p>
                                                                        Closing
                                                                        Balance
                                                                    </p>
                                                                    <span>
                                                                        Payment
                                                                        In
                                                                    </span>
                                                                </td>
                                                                <td>
                                                                    <span className="text-success fw-600">
                                                                        {
                                                                            ledgersData.debitTotal
                                                                        }
                                                                    </span>
                                                                </td>
                                                                <td colSpan={2}>
                                                                    <span className="text-primary fw-600">
                                                                        {
                                                                            ledgersData.creditTotal
                                                                        }
                                                                    </span>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ) : (
                        "Not found"
                    )}
                </div>
            {/* </MainContainer> */}
        </>
    );
};

export default LedgerComponent;

//ToDO: Need to remove below commented code once confirm above work fine
// import React, { useState, useEffect } from "react";
// import axios from 'axios';
// import { Link } from "react-router-dom";
// import Header from "../layouts/Header";
// import Sidebar from "../layouts/Sidebar";
// import { useAuth } from '../contexts/AuthContext';
// import ReactSelect from "react-select";

// const LedgerComponent = () => {

//   // const { auth } = useAuth();
//   const { auth: authToken } = useAuth();

//   const [ledgersData, setledgersData] = useState({});
//   const [accountOptions, setAccountOptions] = useState([]);
//   const [entries, setEntries] = useState([]);
//   const [data, setData] = useState([]);

//   useEffect(() => {
//     const apiReqHeaders = {
//       headers: {
//         Authorization: `Bearer ${authToken}`,
//         'Content-Type': 'application/json',
//       },
//     };

//     // Fetch journal data and its entries
//     axios.get(`${process.env.REACT_APP_API_V1_BASE_URL}/ledgers/${id}`, apiReqHeaders)
//       .then(response => {

//         // Log the response for debugging
//         console.log('Full Response:', response);

//         // Check if response exists and has data
//         if (response && response.data && response.data.length !== 0) {
//           console.log('Data found');
//           setData(response.data || []);  // Set the journal data
//           setledgersData(response.data || []);  // Set ledger data
//           setEntries(response.data.entries || []);  // Set entries
//         } else {
//           console.log('Response is empty');
//           setData([]);  // Set an empty array if no data
//           setledgersData([]);  // Clear ledger data
//           setEntries([]);  // Clear entries
//         }
//       })
//       .catch(error => {
//         console.error('Error fetching journal data:', error);
//       });
//   }, [id]);
//   return (
//     <>
//       <>
//         <div>
//           <Header />
//           <Sidebar />
//           {/* Page Wrapper */}
//           <div className="page-wrapper">
//             {data ?
//               <div className="content container-fluid">
//                 {/* Page Header */}
//                 <div className="page-header">
//                   <div className="content-page-header">
//                     <h5>Account Ledger</h5>
//                   </div>
//                 </div>

//                 <div className="row">

//                   <div className="col-lg-2 col-md-6 col-sm-12">
//                     <div className="input-block mb-3">
//                       <label>Period</label>
//                       <ul className="form-group">
//                         <li>
//                           <ReactSelect
//                             value={id}
//                             options={accountOptions}
//                             placeholder="Select Account"
//                           />
//                         </li>
//                       </ul>
//                     </div>
//                   </div>
//                 </div>
//                 {/* /Page Header */}
//                 <div className="card p-4 ledger-list">
//                   <div className="d-flex align-items-center justify-content-between">
//                     <div className="ledger-info mb-4">
//                       <div className="d-flex align-items-center">
//                         <h2>
//                           Account : {ledgersData.account || ''}
//                         </h2>
//                       </div>
//                     </div>
//                     <div className="list-btn">
//                       <ul className="filter-list">
//                         <li>
//                           <div
//                             className="dropdown dropdown-action"
//                             data-bs-toggle="tooltip"
//                             data-bs-placement="top"
//                             title="Download"
//                           >
//                             <Link
//                               href="#"
//                               className="btn-filters"
//                               data-bs-toggle="dropdown"
//                               aria-expanded="false"
//                             >
//                               <span>
//                                 {/* <FeatherIcon icon="download" /> */}
//                               </span>
//                             </Link>
//                             <div className="dropdown-menu dropdown-menu-end">
//                               <ul className="d-block">
//                                 <li>
//                                   <Link
//                                     className="d-flex align-items-center download-item"
//                                     href="#"
//                                     download=""
//                                   >
//                                     <i className="far fa-file-pdf me-2" />
//                                     PDF
//                                   </Link>
//                                 </li>
//                                 <li>
//                                   <Link
//                                     className="d-flex align-items-center download-item"
//                                     href="#"
//                                     download=""
//                                   >
//                                     <i className="far fa-file-text me-2" />
//                                     CVS
//                                   </Link>
//                                 </li>
//                               </ul>
//                             </div>
//                           </div>
//                         </li>
//                         <li>
//                           <Link
//                             className="btn-filters"
//                             href="#"
//                             data-bs-toggle="tooltip"
//                             data-bs-placement="bottom"
//                             title="print"
//                           >
//                             <span>
//                               {/* <FeatherIcon icon="printer" /> */}
//                             </span>{" "}
//                           </Link>
//                         </li>
//                       </ul>
//                     </div>
//                   </div>
//                   {/* Table */}
//                   <div className="row">
//                     <div className="col-sm-12">
//                       <div className="card-table">
//                         <div className="card-body">
//                           <div className="table-responsive">
//                             <table className="table table-stripped table-hover">
//                               <thead className="thead-light">
//                                 <tr>
//                                   <th>Date</th>
//                                   <th>Transaction</th>
//                                   <th>Transaction type</th>
//                                   <th>Particular</th>
//                                   <th>Debits</th>
//                                   <th>Credits</th>
//                                 </tr>
//                               </thead>
//                               <tbody>
//                                 {entries.map((entry, index) => (
//                                   <tr key={index}>
//                                     <td>
//                                       <h2 className="ledger">
//                                         {entry.date || 'N/A'}
//                                       </h2>
//                                     </td>
//                                     <td>{entry.transaction || 'N/A'}</td>
//                                     <td><span className="badge bg-success-light">{entry.transaction_type || 'N/A'} </span></td>
//                                     <td>{entry.particular || 'N/A'}</td>
//                                     <td>
//                                       <span>{entry.is_debit == 1 ? entry.amount : '-'}</span>
//                                     </td>
//                                     <td>
//                                       <span>{entry.is_debit == 0 ? entry.amount : '-'}</span>
//                                     </td>
//                                   </tr>
//                                 ))}
//                                 <tr>
//                                   <td colSpan={4}>
//                                     <p>Closing Balance</p>
//                                     <span>Payment In</span>
//                                   </td>
//                                   <td>
//                                     <span className="text-success fw-600">
//                                       {ledgersData.debitTotal}
//                                     </span>
//                                   </td>
//                                   <td colSpan={2}>
//                                     <span className="text-primary fw-600">
//                                       {ledgersData.creditTotal}
//                                     </span>
//                                   </td>
//                                 </tr>
//                               </tbody>
//                             </table>
//                             <div className="mt-4 float-end">
//                             </div>
//                           </div>
//                         </div>
//                       </div>
//                     </div>
//                   </div>
//                   {/* /Table */}
//                 </div>
//                 <>
//                 </>
//               </div>
//               : 'Not found'
//             }
//           </div>
//           {/* <LedgerModal/> */}
//           {/* /Page Wrapper */}
//         </div>
//       </>
//     </>
//   );
// };

// export default LedgerComponent;
