import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useAuth } from '../contexts/AuthContext';
import axios from 'axios';
import { CustomInputField } from '../CustomComponents/ReusableComponents';
import Swal from 'sweetalert2';

const EditJournalTypes = ({ id, isOpen, onClose, onUpdate, title, modalId }) => {
    const { auth } = useAuth();
    const API_BASE_URL = process.env.REACT_APP_API_V1_BASE_URL;

    const [formData, setFormData] = useState({
        name: '',
        description: ''
    });
    
    useEffect(() => {
        const fetchTransactionType = async () => {
            try {
                const response = await axios.get(
                    `${process.env.REACT_APP_API_V1_BASE_URL}/journal_types/${id}`,
                    {
                        headers: {
                            Authorization: `Bearer ${auth}`,
                        }
                    }
                );
                setFormData({
                    name: response.data.name,
                    description: response.data.description,
                });
            } catch (err) {
                console.error("Failed to fetch Transaction Type", err);
            }
        };

        fetchTransactionType();
    }, [id, auth]);

    const handleChange = e => {
        const { name, value } = e.target;
        setFormData(prevFormData => ({
            ...prevFormData,
            [name]: value
        }));
    };

    const [errors, setErrors] = useState({});

    const validateForm = () => {
        const validationErrors = {};
        if (!formData.name) validationErrors.name = "Add Name";
        if (!formData.description) validationErrors.description = "Add Description";
    
        setErrors(validationErrors);
        return Object.keys(validationErrors).length === 0; 
    };

    const handleSubmit = async e => {
        e.preventDefault();
        if (!validateForm()) {
            return; 
        }
        try {
            const journalTypesData = { ...formData };
            const apiReqHeaders = {
                headers: {
                    Authorization: `Bearer ${auth}`,
                    "Content-Type": "application/json"
                }
            };

            await axios.put(
                `${API_BASE_URL}/journal_types/${id}`,
                journalTypesData,
                apiReqHeaders
            );
            Swal.fire({
                title: 'Success!',
                text: 'Journal Type updated successfully.',
                icon: 'success',
                confirmButtonText: 'OK',
                customClass: {
                    popup: 'custom-swal-popup',  // Use your class for custom positioning
                    icon: 'custom-swal-icon',    // Use this class to style the icon
                  }
            });
            onClose();
            onUpdate();

        } catch (err) {
            Swal.fire({
                title: 'Error!',
                text: 'Failed to save the Journal Type.',
                icon: 'error',
                confirmButtonText: 'OK',
                customClass: {
                    popup: 'custom-swal-popup',  // Use your class for custom positioning
                    icon: 'custom-swal-icon',    // Use this class to style the icon
                  }
            });
        }
    };

    return (
        <>
            <div
              className={`modal custom-modal fade ${isOpen ? "show d-block" : ""}`}
              id={modalId}
              tabIndex="-1"
              role="dialog"
              style={{ backgroundColor: "rgba(0, 0, 0, 0.5)" }}
            >
                <div
                  className={`modal-dialog modal-dialog-centered modal-md ${
                    isOpen ? "modal-slide-in" : "modal-slide-out"
                  }`}
                  style={{ maxWidth: "600px" }}
                >
                    <div className="modal-content" style={{ height: "300px" }}>
                      <div className="modal-header border-0 pb-0">
                        <div className="form-header modal-header-title text-start mb-0">
                          <h4 className="mb-0">{title}</h4>
                        </div>
                        <button
                          type="button"
                          className="btn-close"
                          onClick={onClose}
                          aria-label="Close"
                        ></button>
                      </div>
                      <div
                        className="modal-body"
                        style={{
                          overflowY: "auto",
                          maxHeight: "calc(100% - 50px)",
                          paddingBottom: "20px",
                        }}
                      >
                        <CustomInputField
                            inputType="text"
                            labelText="Name"
                            inputWrapper="col-lg-12"
                            name="name"
                            value={formData.name}
                            onChange={handleChange}
                            errorMessage={errors.name}
                            required={true}
                            placeholder="E.g Sales, Purchases, Cash"
                        />
                        <CustomInputField
                            inputType="text"
                            labelText="Desctiption"
                            inputWrapper="col-lg-12"
                            name="description"
                            value={formData.description}
                            onChange={handleChange}
                            errorMessage={errors.description}
                            required={true}
                        />
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" onClick={onClose}>Close</button>
                            <button type="button" className="btn btn-primary mx-3" onClick={handleSubmit}>Save changes</button>
                        </div>
                    </div>
                </div>
                </div>
        </div>
                
        </>
    );
};

EditJournalTypes.propTypes = {
    id: PropTypes.string.isRequired,
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onUpdate: PropTypes.func.isRequired, 
    title: PropTypes.string.isRequired,
    modalId: PropTypes.string.isRequired
};

export default EditJournalTypes;