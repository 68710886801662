import React, { useState, useEffect } from "react";
import {
    CustomDropDownField,
    CustomInputField
} from "../CustomComponents/ReusableComponents";
import axios from "axios";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import SettingsSidebar from "../layouts/SettingsSidebar";
import { useAuth } from "../contexts/AuthContext";
import Swal from "sweetalert2";

const AccountSettings = () => {
    const { auth } = useAuth(); // Retrieve auth token from AuthContext
    const { t } = useTranslation();
    const API_BASE_URL = process.env.REACT_APP_API_V1_BASE_URL;
    const extractedData = JSON.parse(localStorage.getItem("userData"));
    const id = extractedData?.contact?.id;

    const [formData, setFormData] = useState({
        address_line1: "",
        address_line2: "",
        city: "",
        contact_type: "",
        country_code: "",
        email: "",
        name: "",
        first_name: "",
        last_name: "",
        phone: "",
        secondary_phone: "",
        social_security_number: "",
        state: "",
        tax_id: "",
        updated_at: ""
    });

    const [isEditing, setIsEditing] = useState(false);
    const [canEdit, setCanEdit] = useState(false);
    const [errors, setErrors] = useState({});
    const [error, setError] = useState("");
    const [loading, setLoading] = useState(true);
    
    const [states, setStates] = useState([]); // State for currencies

    const handleEditClick = () => {
        setIsEditing(true);
        setCanEdit(true); // Allow editing
    };

    const handleChange = e => {
        if (!canEdit) return; // Prevent changes if not in edit mode
        const { name, value } = e.target;

        setFormData(prevData => ({
            ...prevData,
            [name]: value,
            name:
                name === "first_name" || name === "last_name"
                    ? `${name === "first_name" ? value : prevData.first_name} ${
                          name === "last_name" ? value : prevData.last_name
                      }`
                    : prevData.name
        }));
    };
    const validateForm = async () => {
        const validationErrors = {};

        if (!formData.name) validationErrors.name = "name is required.";
        if (!formData.email) validationErrors.email = "email is required.";

        setErrors(validationErrors);

        // If there are validation errors, scroll to the first one
        if (Object.keys(validationErrors).length > 0) {
            // Get the name of the first field with an error
            const firstErrorField = Object.keys(validationErrors)[0];
            // Find the input element with the name that matches the first error field
            const errorElement = document.querySelector(
                `[name="${firstErrorField}"]`
            );
            if (errorElement) {
                errorElement.scrollIntoView({
                    behavior: "smooth",
                    block: "center"
                });
                errorElement.focus();
            }
            return false; // Form is not valid
        }
        return Object.keys(validationErrors).length === 0; // Returns true if no errors
    };

    const handleSave = async e => {
        e.preventDefault();
        if (!validateForm()) {
            return; // Stop submission if validation fails
        }
        try {
            const response = await axios.put(
                `${API_BASE_URL}/users_with_contact/${id}`,
                formData,
                {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${auth}`
                    }
                }
            );
            if (response.status === 200) {
                setIsEditing(false);
                Swal.fire({
                    title: "Success!",
                    text: "Company details updated successfully",
                    icon: "success",
                    confirmButtonText: "OK"
                });
            }
        } catch (error) {
            Swal.fire({
                title: "Error!",
                text: "Failed to update company details.",
                icon: "error",
                confirmButtonText: "OK"
            });
            console.error("Failed to update company info", error);
        }
    };

    useEffect(() => {
        const fetchDropdownData = async () => {
            try {
                const dropdownRequestData = {
                    models: [{ model_name: "Country" }, { model_name: "State" }]
                }; // Define your dropdown request data as needed
                const apiReqHeaders = {
                    headers: {
                        Authorization: `Bearer ${auth}`,
                        "Content-Type": "application/json"
                    }
                };

                const response = await axios.post(
                    `${API_BASE_URL}/get_dropdown_data`,
                    dropdownRequestData,
                    apiReqHeaders
                );

                

                const { State } = response.data.content;
                setStates(State || []);
            } catch (error) {
                setError("Error fetching dropdown data");
                // console.error("Error fetching dropdown data:", error);
            }
        };
        axios
            .get(`${API_BASE_URL}/users_with_contact/${id}`, {
                headers: {
                    Authorization: `Bearer ${auth}`,
                    "Content-Type": "application/json"
                }
            })
            .then(response => {
                const userData = response.data.user;
                const contactData = userData.contact;

                setFormData({
                    name: `${contactData.first_name ||
                        ""} ${contactData.last_name || ""}`, // Concatenate here
                    address_line1: contactData.address_line1 || "",
                    address_line2: contactData.address_line2 || "",
                    city: contactData.city || "",
                    contact_type: contactData.contact_type || "",
                    country_code: contactData.country_code || "",
                    email: contactData.email || "",
                    first_name: contactData.first_name || "",
                    last_name: contactData.last_name || "",
                    phone: contactData.phone || "",
                    secondary_phone: contactData.secondary_phone || "",
                    social_security_number:
                        contactData.social_security_number || "",
                    state: contactData.state || "",
                    tax_id: contactData.tax_id || "",
                    updated_at: contactData.updated_at || ""
                });
            })
            .catch(error => {
                console.error(
                    "There was an error fetching the profile data!",
                    error
                );
            });
            fetchDropdownData();
            setLoading(false);
    }, [API_BASE_URL, auth, id]);

    return (
        <>
            <div className="page-wrapper">
                <div className="content container-fluid">
                    <div className="row">
                        <div className="col-xl-3 col-md-4">
                            <SettingsSidebar />
                        </div>
                        <div className="col-xl-9 col-md-8">
                            <div className="card">
                                <div className="content-page-header card-header mb-0">
                                    <h5>Profile Page</h5>
                                    <div className="">
                                        {isEditing ? (
                                            <>
                                                <button
                                                    type="button"
                                                    className="btn btn-primary cancel me-2"
                                                    onClick={() =>
                                                        setIsEditing(false)
                                                    }
                                                >
                                                    {t("Cancel")}
                                                </button>
                                                <button
                                                    type="button"
                                                    className="btn btn-primary"
                                                    onClick={handleSave}
                                                >
                                                    {t("Save Changes")}
                                                </button>
                                            </>
                                        ) : (
                                            <button
                                                type="button"
                                                className="btn btn-primary"
                                                onClick={handleEditClick}
                                            >
                                                {t("Edit")}
                                            </button>
                                        )}
                                    </div>
                                </div>
                                <div className="card-body w-100 pt-0">
                                    <div className="row">
                                        <div className="useDetails">
                                            <div className="row">
                                                <CustomInputField
                                                    value={formData.first_name}
                                                    labelText="First Name"
                                                    name="first_name"
                                                    onChange={handleChange}
                                                    disabled={!isEditing}
                                                    required={true}
                                                    errorMessage={
                                                        errors.first_name
                                                    }
                                                />
                                                <CustomInputField
                                                    value={formData.last_name}
                                                    labelText="Last Name"
                                                    name="last_name"
                                                    onChange={handleChange}
                                                    disabled={!isEditing}
                                                    required={true}
                                                    errorMessage={
                                                        errors.last_name
                                                    }
                                                />
                                                <CustomInputField
                                                    value={formData.email}
                                                    labelText="Email"
                                                    name="email"
                                                    onChange={handleChange}
                                                    required={true}
                                                    errorMessage={errors.email}
                                                    disabled={!!id}
                                                />

                                                <CustomInputField
                                                    value={formData.phone}
                                                    labelText="Phone Number"
                                                    name="phone"
                                                    onChange={handleChange}
                                                    disabled={!isEditing}
                                                    required={true}
                                                    errorMessage={errors.phone}
                                                />
                                                <CustomInputField
                                                    value={
                                                        formData.secondary_phone
                                                    }
                                                    labelText="Secondary Phone"
                                                    name=""
                                                    onChange={handleChange}
                                                    disabled={!isEditing}
                                                />

                                                <CustomInputField
                                                    value={
                                                        formData.social_security_number
                                                    }
                                                    labelText="Social Security Number"
                                                    name="social_security_number"
                                                    onChange={handleChange}
                                                    disabled={!isEditing}
                                                />

                                                {/* <CustomInputField
                                                    value={formData.state}
                                                    labelText="State"
                                                    name=""
                                                    onChange={handleChange}
                                                    disabled={!isEditing}
                                                /> */}

                                                <CustomDropDownField
                                                    dropDownSize="col-lg-4"
                                                    options={states.map(b => ({
                                                        value: b.state_abr,
                                                        label: b.name
                                                    }))}
                                                    labelText="State"
                                                    name="state" // ensure this matches the formData key
                                                    value={formData.state}
                                                    onChange={handleChange}
                                                    disabled={!isEditing}
                                                />

                                                <CustomInputField
                                                    value={formData.city}
                                                    labelText="City"
                                                    name=""
                                                    onChange={handleChange}
                                                    disabled={!isEditing}
                                                />
                                                <CustomInputField
                                                    value={
                                                        formData.address_line1 ||
                                                        "N/A"
                                                    }
                                                    labelText="Address Line 1"
                                                    name="ddress_line1"
                                                    onChange={handleChange}
                                                    disabled={!isEditing}
                                                />

                                                <CustomInputField
                                                    value={
                                                        formData.address_line2
                                                    }
                                                    labelText="Address Line 2"
                                                    name="address_line2"
                                                    onChange={handleChange}
                                                    disabled={!isEditing}
                                                />

                                                <CustomInputField
                                                    value={
                                                        formData.contact_type
                                                    }
                                                    labelText="Contact Type"
                                                    name="contact_type"
                                                    onChange={handleChange}
                                                    disabled={!isEditing}
                                                />
                                                <CustomInputField
                                                    value={formData.tax_id}
                                                    labelText="Tax ID"
                                                    name="tax_id"
                                                    onChange={handleChange}
                                                    disabled={!isEditing}
                                                />
                                            </div>
                                            {loading && <p>Loading...</p>}
                                            {error && <p className="text-danger">{error}</p>}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

AccountSettings.propTypes = {
    contactId: PropTypes.string
};

export default AccountSettings;
