import React from "react";
import { Logo2, qr } from "../../_components/Imagepath";
// import FeatherIcon from 'feather-icons-react/build/FeatherIcon'

const CustomInvoiceTwo = () => {
    const style = {
        invoiceHeader: {
            borderBottom: "1px solid #F2F2F2",
            background:
                "var(--color-gradient, linear-gradient(320deg, #DDCEFF 0%, #DBECFF 100%))",
            padding: "46px 24px",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between"
        },
        invHeaderLeft: {
            fontSize: "48px",
            color: "#3F4254"
        },
        logoSize: {
            maxWidth: "200px"
        },
        mainContainer: {
            // maxWidth:'80dvw',
            maxWidth: "1300px",
            margin: "20px auto",
            backgroundColor: "#fff",
            width: "100%",
            padding: "30px"
        },
        cellLeft: {
            width: "80%"
        },
        w100: {
            width: "100%"
        },
        w20:{
            width:'20%'
        },
        w10:{
            width:'10%'
        },
        alignRight: {
            textAlign: "right"
        },
        margin30:{
            margin:'25px',
        },
        px15:{
            padding:'15px 0'
        },
        px10:{
            padding:'5px 0'
        },
        borderBottomDesign:{
            borderBottom:'1px solid #000',
        },
        borderLight:{
            borderColor:'#b9b9b966',
        },
        broder1:{
            border:'1px solid black',
        },
        p1:{
            // padding:'0.5rem',
            padding: '0.5rem 0.75rem',
        },
        tableHeading:{
            padding:'1rem',
        },

        calculatedAmountTotal:{
            maxWidth: '570px',
            margin: '10px 10px 0px auto',
            width: '100%',
        },
        tableListNumCell:{
            width:'5%'
        },
        mainTableCell:{
            width:'30%'
        },
        relativeCellWidth:{
            width: '12.5%'
        },
        w75:{
            width: '7.5%'   
        },
        textCenter:{
            textAlign:'center'
        },
        fontBlack:{
            color:'#000',
        },
        companyTitle:{
            fontSize:'18px',
        },

        my2:{
            margin:'20px 0px'
        },
        addressTitle:{
            fontSize:'16px',
        },
        mb1:{
            marginBottom:'10px',
        },
        headingTitleStyle:{
            color:'#888a99',
            fontWeight:600,
        },
        globalFontSize:{
            fontSize:'16px',
            linHeight:'21px'
        },

    };
    return (
        <table style={{...style.mainContainer,...style.fontBlack,...style.globalFontSize}}>
            <tbody>
                <tr>
                    <td>
                        <table style={style.margin30}>
                        <tr>
                    <td colSpan="3">
                        <div style={style.invoiceHeader}>
                            <div>
                                <h2 style={style.invHeaderLeft}>Invoice</h2>
                            </div>
                            <div>
                                <a href="#">
                                    <img
                                        src={Logo2}
                                        style={style.logoSize}
                                        alt="Logo"
                                    />
                                    {/* <img src={WhiteLogo} alt="Logo" /> */}
                                </a>
                            </div>
                        </div>
                    </td>
                </tr>
                            <tr style={{...style.borderBottomDesign ,...style.borderLight}}>
                                <td colSpan={2}
                                //  style={[...style.cellLeft , ...style.px15]}
                                style={{
                                    ...style.cellLeft,
                                    ...style.px15
                                }}
                                 >
                                    <p style={{ ...style.companyTitle}}>
                                        Dreamguys Technologies PVT Ltd
                                    </p>
                                        <span>
                                            Address: 15 Hodges Mews, High Wycomb
                                            HP123JL, United Kingdom
                                        </span>
                                    
                                </td>
                                <td>
                                    <h6>
                                        Invoice No: <span>#005</span>
                                    </h6>
                                    <h6>
                                        Invoice Date: <span>07-10-2023</span>
                                    </h6>
                                </td>
                            </tr>

                            {/* Customer Info */}
                            <tr>
                                <td colSpan="2" style={style.px15}>
                                    <h5>Customer Info</h5>
                                </td>
                            </tr>
                            <tr>
                                <td style={style.tableHeading}>
                                    <p style={{...style.addressTitle, ...style.mb1 ,...style.headingTitleStyle}}>Invoice To:</p>
                                    <p style={{...style.globalFontSize}}>
                                        Walter Roberson
                                        <br />
                                        299 Star Trek Drive, Panama City,
                                        Florida,<br />  32405, <br /> USA.
                                    </p>
                                </td>
                                <td style={style.tableHeading}>
                                <p style={{...style.addressTitle, ...style.mb1,...style.headingTitleStyle}}>
                                Pay To:
                                </p> 
                                <p style={{...style.globalFontSize}}>
                                        Walter Roberson
                                        <br />
                                        299 Star Trek Drive, Panama City, <br />
                                        Florida, 32405, <br /> USA.
                                    </p>
                                </td>
                                <td style={style.tableHeading}>
                                    <span>Due Date:</span>
                                    <h6>07/23/2023</h6>
                                    <br />
                                    <span>Payment Status:</span>
                                    NOT PAID
                                </td>
                            </tr>

                            {/* Invoice Table */}
                            <tr>
                                <td colSpan="3">
                                    <table style={{...style.w100 ,...style.broder1,...style.my2}} >
                                        <thead>
                                            <tr style={style.borderBottomDesign}>
                                                <th style={{...style.tableHeading, ...style.tableListNumCell}}>#</th>
                                                <th style={{...style.tableHeading, ...style.mainTableCell}}>Item</th>
                                                <th style={{...style.tableHeading,...style.w75}}>Quantity</th>
                                                <th style={{...style.tableHeading,...style.w10}}>Unit Price</th>
                                                <th style={{...style.tableHeading,...style.w10}}>Discount %</th>
                                                <th style={{...style.tableHeading,...style.relativeCellWidth,...style.px15}}>Discounted Value</th>
                                                <th style={{...style.tableHeading,...style.w10}}> Final Amount</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr style={style.borderBottomDesign}>
                                                <td style={style.p1}>1</td>
                                                <td style={style.p1}>
                                                    Accounting Software
                                                    Maintenance
                                                </td>
                                                <td style={style.p1}>3</td>
                                                <td style={style.p1}>  
                                                    $500{" "}
                                                </td>
                                                <td style={style.p1}>10%</td>
                                                <td style={style.p1}>
                                                <b>$450.00</b>
                                                </td>
                                                <td style={style.p1}>$1350</td>
                                            </tr>
                                            <tr style={style.borderBottomDesign}>
                                                <td style={style.p1}>2</td>
                                                <td style={style.p1}>Man Power Support</td>
                                                <td style={style.p1}>1</td>
                                                <td style={style.p1}>$100</td>
                                                <td style={style.p1}>0%</td>
                                                <td style={style.p1}>0%</td>
                                                <td style={style.p1}>$100</td>
                                            </tr>
                                            <tr style={style.borderBottomDesign}>
                                                <td style={style.p1}>3</td>
                                                <td style={style.p1}>Transportation Fee</td>
                                                <td style={style.p1}>2</td>
                                                <td style={style.p1}>
                                                    $200{" "}
                                                </td>
                                                <td style={style.p1}>5%</td>
                                                <td style={style.p1}>
                                                <b> $450.00</b>
                                                </td>
                                                <td style={style.p1}>$380</td>
                                            </tr>
                                            <tr style={style.borderBottomDesign}>
                                                <td style={style.p1}>4</td>
                                                <td style={style.p1}>
                                                    Spares Replacement Charges
                                                </td>
                                                <td style={style.p1}>5</td>
                                                <td style={style.p1}>$500</td>
                                                <td style={style.p1}>0%</td>
                                                <td style={style.p1}>$2500</td>
                                            </tr>
                                            <tr >
                                                <td style={style.p1}>5</td>
                                                <td style={style.p1}>Materials Handling</td>
                                                <td style={style.p1}>1</td>
                                                <td style={style.p1}>
                                                    $500{" "}
                                                </td>
                                                <td style={style.p1}>25%</td>
                                                <td style={style.p1}> <b> $450.00</b></td>
                                                <td style={style.p1}>$375</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </td>
                            </tr>

                            <tr>
                                <td colSpan={3}>
                                    <table
                                        style={style.calculatedAmountTotal}
                                    >
                                        {/* Invoice Footer */}
                                        <tr>
                                            <td style={style.px10}>
                                                <span>Taxable Amount</span>
                                            </td>
                                            <td>$4705.00</td>
                                        </tr>
                                        <tr>
                                            <td style={style.px10}>
                                                <span>IGST 18.0%</span>
                                            </td>
                                            <td>$846.90</td>
                                        </tr>
                                        <tr>
                                            <td style={style.px10}>
                                                <span>
                                                    Extra Discount (Promo - 5%)
                                                </span>
                                            </td>
                                            <td>$235.25</td>
                                        </tr>
                                        <tr>
                                            <td style={style.px10}>
                                                <span>Round Off</span>
                                            </td>
                                            <td>-$0.65</td>
                                        </tr>

                                        {/* Total Amount */}
                                      
                                    </table>
                                </td>
                            </tr>
                            <tr>
                                <td colSpan={3}>
                                <hr/>
<h1 style={style.w100}> Total Amount </h1>
<hr/>
                                </td>
                            </tr>

                            {/* Bank Details */}
                            <tr>
                                <td colSpan="2">
                                    <img src={qr} alt="QR Code" />
                                    <h6>Scan to View Receipt</h6>
                                    <div>
                                        <span>Debit Card:</span> 465
                                        *************645
                                    </div>
                                    <div>
                                        <span>Amount:</span> $1,815
                                    </div>
                                </td>
                                <td>
                                    <div>
                                        <span>Terms & Conditions:</span>
                                        <ol>
                                            <li>
                                                This is a GST based invoice
                                                bill, which is applicable for
                                                TDS Deduction
                                            </li>
                                            <li>
                                                We are not the manufacturers,
                                                the company will stand for
                                                warranty as per their terms and
                                                conditions.
                                            </li>
                                        </ol>
                                    </div>
                                </td>
                            </tr>

                            {/* Thank You Message */}
                            <tr>
                                <td colSpan="2" className="text-center">
                                    Thanks for your Business
                                </td>
                            </tr>
                        </table>
                    </td>
                </tr>
            </tbody>
        </table>
    );
};

export default CustomInvoiceTwo;
