import React, { useState, useEffect } from "react";
import {
    CustomDatePicker,
    CustomDropDownField,
    CustomInputField,
    CustomTextArea
} from "../CustomComponents/ReusableComponents";

import axios from "axios";
import { useAuth } from "../contexts/AuthContext";
// import { useTranslation } from "react-i18next";
import { useParams, useHistory } from "react-router-dom";
import Swal from 'sweetalert2';

const TransactionForm = () => {
    const { auth } = useAuth();
    // const { t } = useTranslation();
    const { id } = useParams();

    const navigate = useHistory();
    const API_BASE_URL = process.env.REACT_APP_API_V1_BASE_URL;

    const [formData, setFormData] = useState({
        transaction_type_id: "",
        from_account_id: "",
        to_account_id: "",
        amount: "",
        transaction_date: new Date(),
        description: "",
        status: "",
        is_reconciled: false,
        is_debit: false,
        customer_id: "",
        vendor_id: "",
        contact_id: "",
        bank_account: ""
    });

    const [transactionTypes, setTransactionTypes] = useState([]);
    // const [status,setStatus] = useState([statusOptions])
    const [accounts, setAccounts] = useState([]);
    const [customers, setCustomers] = useState([]);
    const [vendors, setVendors] = useState([]);
    const [loading, setLoading] = useState(true);
    /* eslint-disable-next-line */
    const [error, setError] = useState(null);
    const [errors, setErrors] = useState({});

    const statusOptions = [
        { value: "pending", label: "pending" },
        { value: "completed", label: "completed" },
        { value: "void", label: "void" }
    ];
    const handleToggle = () => {
        setFormData(prevFormData => ({
            ...prevFormData,
            is_debit: prevFormData.is_debit === "1" ? "0" : "1"
        }));
    };

    const handleToggleRecline = () => {
        setFormData(prevFormData => ({
            ...prevFormData,
            is_reconciled: prevFormData.is_reconciled === "1" ? "0" : "1"
        }));
    };

    useEffect(() => {

        const fetchDropdownData = async () => {
            setLoading(true);
            setError(null);
            try {
                const dropdownRequestData = {
                    models: [
                        { model_name: "TransactionType" },
                        { model_name: "Account" },
                        { model_name: "Customer" },
                        { model_name: "Vendor" }
                    ]
                }; // Define your dropdown request data as needed
                const apiReqHeaders = {
                    headers: {
                        Authorization: `Bearer ${auth}`,
                        "Content-Type": "application/json"
                    }
                };

                const response = await axios.post(
                    `${API_BASE_URL}/get_dropdown_data`,
                    dropdownRequestData,
                    apiReqHeaders
                );

                const transactionTypeDropdownData =
                    response.data.content.TransactionType || [];
                setTransactionTypes(transactionTypeDropdownData);

                const accountDropdownData = response.data.content.Account || [];
                setAccounts(accountDropdownData);

                const customerDropdownData =
                    response.data.content.Customer || [];
                setCustomers(customerDropdownData);

                const vendorDropdownData = response.data.content.Vendor || [];
                setVendors(vendorDropdownData);

                // setCountries(Currency || [1,2,3]);
            } catch (error) {
                setError("Error fetching dropdown data");
                // console.error("Error fetching dropdown data:", error);
            }
        };

        const fetchTransactionData = async () => {
            if (id) {
                try {
                    const apiReqHeaders = {
                        headers: {
                            Authorization: `Bearer ${auth}`,
                            "Content-Type": "application/json"
                        }
                    };

                    const response = await axios.get(
                        `${API_BASE_URL}/transactions/${id}`,
                        apiReqHeaders
                    );
                    setFormData({
                        ...formData,
                        ...response.data
                    });
                } catch (error) {
                    setError("Error fetching customer data");
                    console.error("Error fetching customer data:", error);
                }
                console.log(loading);
            }
        };

        fetchDropdownData();
        fetchTransactionData();
        setLoading(false);
    }, [API_BASE_URL, auth, id]);

    const handleChange = e => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const validateForm = () => {
        const validationErrors = {};
        if (!formData.transaction_type_id) {
            validationErrors.transaction_type_id = "Select type of transaction";
        }
        if (!formData.from_account_id) {
            validationErrors.from_account_id =
                "Select an Account from which transaction has to be mention";
        }
        if (!formData.to_account_id) {
            validationErrors.to_account_id =
                "Select an Account to which transaction has to be mention";
        }
        if (!formData.status) {
            validationErrors.status = "Select a status";
        }

        if (!formData.amount) {
            validationErrors.amount = "Enter an amount";
        }
        if (!formData.transaction_date) {
            validationErrors.transaction_date = "Select transaction date";
        }
        if (!formData.customer_id) {
            validationErrors.customer_id = "Select customer account";
        }
        if (!formData.vendor_id) {
            validationErrors.vendor_id = "Select vendor account";
        }
        if (!formData.description){
            validationErrors.description = "Give a Decription";
        }
        if (!formData.bank_account) {
            validationErrors.bank_account = "Mention a bank account";
        } else if (formData.bank_account.length<4 || formData.bank_account.length>12 ){
            validationErrors.bank_account = "Enter a Valid Account Details";
        }
        setErrors(validationErrors);
        return Object.keys(validationErrors).length === 0;
    };
    const handleSubmit = async e => {
        e.preventDefault();
        if (!validateForm()) {
            return;
        }
        try {

            formData.transaction_date = window.reqFormatDate(formData.transaction_date);
            
            const apiReqHeaders = {
                headers: {
                    Authorization: `Bearer ${auth}`,
                    "Content-Type": "application/json"
                }
            };

            if (id) {
                await axios.put(
                    `${API_BASE_URL}/transactions/${id}`,
                    formData,
                    apiReqHeaders
                );
                Swal.fire({
                    title: 'Success!',
                    text: 'Transaction updated successfully.',
                    icon: 'success',
                    confirmButtonText: 'OK',
                    customClass: {
                        popup: 'custom-swal-popup',  // Use your class for custom positioning
                        icon: 'custom-swal-icon',    // Use this class to style the icon
                      }
                });
            } else {
                await axios.post(
                    `${API_BASE_URL}/transactions`,
                    formData,
                    apiReqHeaders
                );
                Swal.fire({
                    title: 'Success!',
                    text: 'Account created successfully.',
                    icon: 'success',
                    confirmButtonText: 'OK',
                    customClass: {
                        popup: 'custom-swal-popup',  // Use your class for custom positioning
                        icon: 'custom-swal-icon',    // Use this class to style the icon
                      }
                });
            }

            navigate.push("/transactions");
        } catch (error) {
            Swal.fire({
                title: 'Error!',
                text: 'Failed to save the Transaction.',
                icon: 'error',
                confirmButtonText: 'OK',
                customClass: {
                    popup: 'custom-swal-popup',  // Use your class for custom positioning
                    icon: 'custom-swal-icon',    // Use this class to style the icon
                  }
            });
        }
    };

    const cancelAction = () => {
        navigate.goBack();
    };

    return (
        <>
<div className="page-wrapper">
<div className="content  container-fluid">
<div className="card mb-0">
<>

<div className="page-header">
<div className="card-body">

                <div className="content-page-header">
                    <h5>{id ? "Edit Transaction" : "Add Transaction"}</h5>
                    {/* <h5>Add Invoice</h5> */}
                </div>
                <div>
                    <div className="d-flex mb-2">
                        <div className="form-check form-switch col-md-1 me-2">
                            <input
                                className="form-check-input"
                                type="checkbox"
                                id="isActiveSwitch"
                                checked={formData.is_debit === "1"}
                                onChange={handleToggle}
                            />
                            <label
                                className="form-check-label"
                                htmlFor="isActiveSwitch"
                            >
                                {formData.is_debit === "1" ? "Debit" : "Credit"}
                            </label>
                        </div>

                        <div className="form-check form-switch me-2">
                            <input
                                className="form-check-input"
                                type="checkbox"
                                id="isActiveSwitch"
                                checked={formData.is_reconciled === "1"}
                                onChange={handleToggleRecline}
                            />
                            <label
                                className="form-check-label"
                                htmlFor="isActiveSwitch"
                            >
                                {formData.is_reconciled === "1"
                                    ? "Reconciled"
                                    : "Not Reconciled"}
                            </label>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <CustomDropDownField
                        options={transactionTypes.map(c => ({
                            value: c.id,
                            label: c.name
                        }))}
                        labelText="Transaction Type"
                        onChange={handleChange}
                        name="transaction_type_id"
                        value={formData.transaction_type_id}
                        required={true}
                        errorMessage={errors.transaction_type_id}
                    />

                    <CustomDropDownField
                        options={accounts.map(c => ({
                            value: c.id,
                            label: c.name
                        }))}
                        labelText=" From Account "
                        name="from_account_id"
                        onChange={handleChange}
                        value={formData.from_account_id}
                        required={true}
                        errorMessage={errors.from_account_id}
                    />

                    <CustomDropDownField
                        options={accounts.map(c => ({
                            value: c.id,
                            label: c.name
                        }))}
                        labelText=" To Account "
                        name="to_account_id"
                        onChange={handleChange}
                        value={formData.to_account_id}
                        required={true}
                        errorMessage={errors.to_account_id}
                    />

                    <CustomDropDownField
                        options={statusOptions.map(c => ({
                            value: c.value, // Correctly map value
                            label: c.label // Correctly map label
                        }))}
                        labelText="Status"
                        name="status"
                        onChange={handleChange}
                        value={formData.status}
                        required={true}
                        errorMessage={errors.status}
                    />

                    <CustomDropDownField
                        options={customers.map(c => ({
                            value: c.id,
                            label: c.name
                        }))}
                        labelText="Customer Account "
                        name="customer_id"
                        onChange={handleChange}
                        value={formData.customer_id}
                        required={true}
                        errorMessage={errors.customer_id}
                    />
                    <CustomDropDownField
                        options={vendors.map(c => ({
                            value: c.id,
                            label: c.name
                        }))}
                        labelText="Vendor Account "
                        name="vendor_id"
                        onChange={handleChange}
                        value={formData.vendor_id}
                        required={true}
                        errorMessage={errors.vendor_id}
                    />

                    <CustomInputField
                        labelText="Bank Account"
                        placeholder="E.g., 2264 6890 9082 3456" 
                        value={formData.bank_account}
                        inputType="number"
                        name="bank_account"
                        onChange={handleChange}
                        required={true}
                        errorMessage={errors.bank_account}
                    />

                    <CustomInputField
                        labelText="Amount"
                        placeholder="E.g., 3450"
                        value={formData.amount}
                        inputType="number"
                        name="amount"
                        onChange={handleChange}
                        required={true}
                        errorMessage={errors.amount}
                    />
                    {/* <CustomInputField
                        labelText="Transaction Date"
                        inputType="date"
                        name="transaction_date"
                        value={formData.transaction_date}
                        onChange={handleChange}
                        required={true}
                        errorMessage={errors.transaction_date}
                    /> */}
                    <CustomDatePicker
                     labelText="Transaction Date"
                     inputType="date"
                     name="transaction_date"
                     value={formData.transaction_date}
                     onChange={handleChange}
                     required={true}
                     errorMessage={errors.transaction_date}
                    />
                    <div className="col-lg-8">
                        <CustomTextArea
                            labelText="Description"
                            placeholder="Enter Description Here ..."
                            name="description"
                            value={formData.description}
                            onChange={handleChange}
                            required={true}
                            errorMessage={errors.description}
                        />
                    </div>
                </div>

                <div className=" d-flex justify-content-end my-3">
                    <div className="preview-boxs py-3">
                        {/* <Link to="/signature-preview-invoice">Preview Invoice</Link> */}
                        <button
                            type="reset"
                            className="btn btn-primary cancel me-2"
                            onClick={cancelAction}
                        >
                            Cancel
                        </button>
                        <button
                            type="reset"
                            onClick={handleSubmit}
                            className="btn btn-primary  me-2"
                        >
                            Save
                        </button>
                        {/* <button type="submit" className="btn btn-primary">
                Save & Send
            </button> */}
                    </div>
                </div>
</div>
</div>
</>
    </div> 
</div>
</div>
        </>
    );
};

export default TransactionForm;
