import React from "react";
import PropTypes from "prop-types";

const ModalOnPopup = ({ modalId, title, modal, isOpen, onClose }) => {
    return (
        <div
          className={`modal custom-modal fade ${isOpen ? "show d-block" : ""}`}
          id={modalId}
          tabIndex="-1"
          role="dialog"
          style={{ backgroundColor: "rgba(0, 0, 0, 0.5)" }}
        >
            <div
              className={`modal-dialog modal-dialog-centered modal-md ${
                isOpen ? "modal-slide-in" : "modal-slide-out"
              }`}
              style={{ maxWidth: "600px" }}
            >
                <div className="modal-content" style={{ height: "600px" }}>
                  <div className="modal-header border-0 pb-0">
                    <div className="form-header modal-header-title text-start mb-0">
                      <h4 className="mb-0">{title}</h4>
                    </div>
                    <button
                      type="button"
                      className="btn-close"
                      onClick={onClose}
                      aria-label="Close"
                    ></button>
                  </div>
                  <div
                    className="modal-body"
                    style={{
                      overflowY: "auto",
                      maxHeight: "calc(100% - 50px)",
                      paddingBottom: "20px",
                    }}
                  >
                    {modal}
                  </div>
                </div>
            </div>
        </div>
    );  
};

ModalOnPopup.propTypes = {
  modalId: PropTypes.string.isRequired,
  title: PropTypes.string,
  modal: PropTypes.node.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

ModalOnPopup.defaultProps = {
  title: "Modal Title",
};

export default ModalOnPopup;

// import React from 'react';
// import PropTypes from "prop-types";

// const ModalOnPopup = ({ modal, isOpen, onClose }) => {
//     const handleOverlayClick = e => {
//         if (e.target === e.currentTarget) {
//             onClose();
//         }
//     };
//     return(
//         <>
//             {isOpen && (
//                 <div
//                     className="aia-modal-overlay-wrapper"
//                     onClick={handleOverlayClick}
//                     style={{
//                         position: "fixed",
//                         top: 0,
//                         left: 0,
//                         width: "100%",
//                         height: "100%",
//                         backgroundColor: "rgba(50, 50, 50, 0.8)",
//                         backdropFilter: "none",
//                         display: "flex",
//                         alignItems: "flex-start",
//                         justifyContent: "center",
//                         zIndex: 9999,
//                         paddingTop: "50px",
//                     }}
//                 >
//                     <div
//                         className="aia-modal-content-wrapper"
//                         style={{
//                             backgroundColor: "#fff",
//                             borderRadius: "8px",
//                             maxWidth: "80%", 
//                             maxHeight: "80%", 
//                             overflowY: "auto", 
//                             padding: "20px",
//                             boxShadow: "0 2px 10px rgba(0, 0, 0, 0.2)",
//                             width: "100%",
//                             display: "flex",
//                             flexDirection: "column", 
//                             position: "relative",
//                         }}
//                     >
//                         <button
//                             className="btn-close"
//                             onClick={onClose}
//                             style={{
//                                 position: "absolute",
//                                 top: "10px",
//                                 right: "10px",
//                                 background: "none",
//                                 border: "none",
//                                 fontSize: "1.5rem",
//                                 cursor: "pointer",
//                             }}
//                         >
//                             &times;
//                         </button>
                        
//                         <div style={{ flex: 1 }}>
//                             {modal}
//                         </div>
//                     </div>
//                 </div>
//             )}
//         </>
//     )
// }

// ModalOnPopup.propTypes = {
//     modal: PropTypes.node,
//     isOpen: PropTypes.bool.isRequired,
//     onClose: PropTypes.func.isRequired,
// };

// export default ModalOnPopup;

// import React from 'react';
// import PropTypes from "prop-types";

// const ModalOnPopup = ({ modal, isOpen, onClose }) => {
//     const handleOverlayClick = (e) => {
//         if (e.target === e.currentTarget) {
//             onClose();
//         }
//     };

//     return (
//         <>
//             {isOpen && (
//                 <div
//                     className="aia-modal-overlay-wrapper"
//                     onClick={handleOverlayClick}
//                     style={{
//                         position: "fixed",
//                         top: 0,
//                         left: 0,
//                         width: "100%",
//                         height: "100%",
//                         backgroundColor: "rgba(50, 50, 50, 0.8)",
//                         backdropFilter: "none",
//                         display: "flex",
//                         alignItems: "flex-start", 
//                         justifyContent: "center",
//                         zIndex: 9999,
//                         paddingTop: "50px", 
//                     }}
//                 >
//                     <div
//                         className="aia-modal-content-wrapper"
//                         style={{
//                             backgroundColor: "#fff",
//                             borderRadius: "8px",
//                             maxWidth: "1000px", 
//                             maxHeight: "80%",
//                             overflowY: "auto",
//                             padding: "20px",
//                             boxShadow: "0 2px 10px rgba(0, 0, 0, 0.2)",
//                             position: "relative",
//                         }}
//                     >
//                         <button
//                             className="btn-close"
//                             onClick={onClose}
//                             style={{
//                                 position: "absolute",
//                                 top: "10px",
//                                 right: "10px",
//                                 background: "none",
//                                 border: "none",
//                                 fontSize: "1.5rem",
//                                 color: "#6c757d", 
//                                 cursor: "pointer",
//                             }}
//                         >
//                             &times;
//                         </button>

//                         <div>
//                             {modal}
//                         </div>
//                     </div>
//                 </div>
//             )}
//         </>
//     );
// };

// ModalOnPopup.propTypes = {
//     modal: PropTypes.node,
//     isOpen: PropTypes.bool.isRequired,
//     onClose: PropTypes.func.isRequired,
// };

// export default ModalOnPopup;