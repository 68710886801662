import React, { createContext, useState, useContext, useEffect } from 'react';
import PropTypes from 'prop-types'; 
// import axios from 'axios';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const [auth, setAuth] = useState(localStorage.getItem('token') || null);

    const login = async (token, extractedData, permissionData) => {
        setAuth(token);
        localStorage.setItem('token', token);
        localStorage.setItem('userData', JSON.stringify(extractedData));
        localStorage.setItem('permissionData', JSON.stringify(permissionData))
    };

    const logout = () => {
        setAuth(null);
        localStorage.removeItem('token');
        localStorage.removeItem('userData');
        localStorage.removeItem('permissionData');
    };

    useEffect(() => {
        const token = localStorage.getItem('token');
        if (token) {
            setAuth(token);
        }
    }, []);

    return (
        <AuthContext.Provider
            value={{ auth, login, logout, }}
        >
            {children}
        </AuthContext.Provider>
    );
};

AuthProvider.propTypes = {
    children: PropTypes.node.isRequired,
};

export const useAuth = () => useContext(AuthContext);
