

import React from 'react';
import PageComponent from '../../CustomComponents/PageComponent';
// import MainContainer from '../../CustomComponents/MainContainer';
const Customers = () => {
  return (
    // <MainContainer>
      <PageComponent listAPI='customers' label='Customers' route="customers" addType='nextPage' addLabel='Customer' />
    // </MainContainer>
  )
}

export default Customers;