import React, { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import axios from "axios";
// import { TextField, MenuItem, Button, Container, Grid, Alert, Typography } from '@mui/material';
import Header from "../layouts/Header";
import Sidebar from "../layouts/Sidebar";
import {
    CustomDatePicker,
    CustomDropDownField,
    CustomDropDownFieldWithButton,
    CustomInputField,
    CustomTextArea
} from "../CustomComponents/ReusableComponents";
import { useAuth } from "../contexts/AuthContext";
import Swal from "sweetalert2";
import { DropIcon } from "../_components/Imagepath";

const AddPurchaseInvoice = () => {
    const { auth } = useAuth();
    const { id } = useParams();
    
    const [menu, setMenu] = useState(false);
    const [errors, setErrors] = useState({});

    const API_BASE_URL = process.env.REACT_APP_API_V1_BASE_URL;

    const toggleMobileMenu = () => {
        setMenu(!menu);
    };
    const history = useHistory();

    // const userDataString = localStorage.getItem("userData");
    // const userData = JSON.parse(userDataString);

    // const branchId = userData.branch_id;

    // const getCurrentDate = (daysToAdd = 0) => {
    //     const currentDate = new Date();
    //     currentDate.setDate(currentDate.getDate() + daysToAdd);
    //     return currentDate; // Return Date object instead of formatted string
    // };

    const [formData, setFormData] = useState({
        type: 'purchase_invoice',
        // branch_id: branchId || "",
        vendor_id: "",
        account_id: "",
        paid_through_account_id: "",
        invoice_date: new Date(),
        invoice_due_date: new Date(),
        //invoice_number: "",
        description: "",
        amount: "",
        order_number: "",
        subject: "",
        vendor_notes: "",
        terms_conditions: "",
        currency_id: "",
        status: "pending"
    });

    const [accounts, setAccounts] = useState([]);
    // const [currencies, setCurrencies] = useState([]);
    const [vendors, setVendors] = useState([]);
    // const [error, setError] = useState(null);
    // const [validationError, setValidationError] = useState(null);
    const [paidthroughAcc, setPaidthroughAcc] = useState([]);
    const [selectedFiles, setSelectedFiles] = useState([]);

    const status = [
        { value: "paid", label: "Paid" },
        { value: "pending", label: "Pending" },
        { value: "cancelled", label: "Cancelled" }
    ];
    useEffect(() => {
        const fetchDropdownData = async () => {
            try {
                const response = await axios.post(
                    `${process.env.REACT_APP_API_V1_BASE_URL}/get_dropdown_data`,
                    {
                        models: [
                            { model_name: "Vendor" },
                            { model_name: "Vendor" },
                            { model_name: "Account" },
                            { model_name: "Currency" }
                        ]
                    },
                    {
                        headers: {
                            Authorization: `Bearer ${auth}`
                        }
                    }
                );
                setAccounts(response.data.content.Account || []);
                setPaidthroughAcc(response.data.content.Account || []);
                setCurrency(response.data.content.Currency || []);
                setVendors(response.data.content.Vendor || []);
            } catch (err) {
                // setError('Failed to load dropdown data');
            }
        };

        const fetchInvoiceData = async () => {
            if (id) {
                try {
                    const response = await axios.get(
                        `${process.env.REACT_APP_API_V1_BASE_URL}/purchase_invoices/${id}`,
                        {
                            headers: {
                                Authorization: `Bearer ${auth}`
                            }
                        }
                    );

                    setFormData(response.data);
                } catch (err) {
                    // setError('Failed to load invoice data');
                }
            }
        };

        fetchDropdownData();
        fetchInvoiceData();
    }, [id, auth]);

    const [currency, setCurrency] = useState([]);
    // Handle vendor change
    const handleVendorChange = e => {
        const selectedVendorId = e.target.value;
        const selectedVendor = vendors.find(
            vendor => vendor.id === parseInt(selectedVendorId)
        );
        const selectedCustomerCurrencyCode = selectedVendor
            ? selectedVendor.currency_code
            : "";
        const selectedCurrency = currency.find(
            cur => cur.code === selectedCustomerCurrencyCode
        );
        const selectedCurrencyId = selectedCurrency
            ? selectedCurrency.id
            : null;

        setFormData({
            ...formData,
            vendor_id: selectedVendorId,
            account_id: selectedVendor ? selectedVendor.account_id : "",
            currency_id: selectedCurrencyId
        });
    };

    const handleVendorNavigation = () => {
        history.push("/add-vendors");
    }

    const handleAccountNavigation = () => {
        history.push("/add-accounts");
    }

    const handleChange = e => {
        const { name, value, type } = e.target;
    
        if (type === "file") {
            // Handle file input separately
            const files = Array.from(e.target.files);
            setSelectedFiles(files); // Store the selected files in state
    
            setFormData(prevFormData => {
                return {
                    ...prevFormData,
                    [name]: files // Store files in form data
                };
            });
        } else {
            // Handle other input types (e.g., text, date)
            setFormData(prevFormData => {
                // Initialize the object to update with the new form data
                const updatedFormData = {
                    ...prevFormData,
                    [name]: value
                };
    
                // If the field being changed is the invoice_date, calculate the due date
                if (name === "invoice_date") {
                    const invoiceDate = new Date(value);
                    const dueDate = new Date(invoiceDate);
                    dueDate.setDate(invoiceDate.getDate() + 30); // Add 30 days to the selected date
    
                    // Add the calculated due date to the form data
                    updatedFormData.invoice_due_date = dueDate
                        .toISOString()
                        .split("T")[0]; // Format as YYYY-MM-DD
                }
    
                return updatedFormData;
            });
        }
    
        // Optional: Reset validation errors if any
        // setValidationError(null);
    };
    

    const validateForm = () => {
        const validationErrors = {};
        if (!formData.account_id) validationErrors.account_id = "Add Account";
        if (!formData.amount) validationErrors.amount = "Add Amount";
        // if (!formData.currency_id) validationErrors.currency_id = "Select Currency";
        // if (!formData.invoice_number)
        //     validationErrors.invoice_number = "Add Bill Number";
        if (!formData.vendor_id) validationErrors.vendor_id = "Add Vendor";
        if (!formData.paid_through_account_id) validationErrors.paid_through_account_id = "Select an Account here";
        if (!formData.invoice_date) validationErrors.invoice_date = "Select an invoice date";
        if (!formData.invoice_due_date) validationErrors.invoice_due_date = "Select an invoice due date";

        // invoice_date
        setErrors(validationErrors);
        return Object.keys(validationErrors).length === 0;
    };
    // const handleSubmit = async e => {
    const handleSubmit = async (e, isPublished = 1) => {
        e.preventDefault();

        if (!validateForm()) {
            return;
        }
        try {
            const billsData = { ...formData };
            billsData.invoice_date = window.reqFormatDate(
                billsData.invoice_date
            );
            billsData.invoice_due_date = window.reqFormatDate(
                billsData.invoice_due_date
            );
            billsData.posted = isPublished;

            const apiReqHeaders = {
                headers: {
                    Authorization: `Bearer ${auth}`,
                    "Content-Type": "application/json"
                }
            };

            if (id) {
                await axios.put(
                    `${API_BASE_URL}/purchase_invoices/${id}`,
                    billsData,
                    apiReqHeaders
                );
                Swal.fire({
                    title: "Success!",
                    text: "Bill updated successfully.",
                    icon: "success",
                    confirmButtonText: "OK",
                    customClass: {
                        popup: 'custom-swal-popup',  // Use your class for custom positioning
                        icon: 'custom-swal-icon',    // Use this class to style the icon
                      }
                });
            } else {
                await axios.post(
                    `${API_BASE_URL}/purchase_invoices`,
                    billsData,
                    apiReqHeaders
                );
                Swal.fire({
                    title: "Success!",
                    text: "Bill created successfully.",
                    icon: "success",
                    confirmButtonText: "OK",
                    customClass: {
                        popup: 'custom-swal-popup',  // Use your class for custom positioning
                        icon: 'custom-swal-icon',    // Use this class to style the icon
                      }
                });
            }

            history.push("/bills");
        } catch (error) {
            Swal.fire({
                title: "Error!",
                text: "Failed to save the Bill.",
                icon: "error",
                confirmButtonText: "OK",
                customClass: {
                    popup: 'custom-swal-popup',  // Use your class for custom positioning
                    icon: 'custom-swal-icon',    // Use this class to style the icon
                  }
            });
        }
    };

    const handleCancel = () => {
        history.push("/bills");
    };

    return (
        <>
            <div className={`main-wrapper ${menu ? "slide-nav" : ""}`}>
                <Header onMenuClick={toggleMobileMenu} />
                <Sidebar />
                <div className="page-wrapper">
                    <div className="content container-fluid">
                        <div className="card mb-0">
                            <div className="card-body">
                                <div className="page-header">
                                    <div className="content-page-header">
                                        <h5>
                                            {id ? "Edit Bills" : "Add Bills"}
                                        </h5>
                                    </div>
                                </div>

                                <form onSubmit={handleSubmit}>
                                    <div className="row">
                                        {/* <CustomDropDownField
                                            options={vendors.map(c => ({
                                                value: c.id,
                                                label: c.name
                                            }))}
                                            labelText="Vendor"
                                            name="vendor_id"
                                            value={formData.vendor_id}
                                            onChange={handleVendorChange}
                                            errorMessage={errors.vendor_id}
                                            required={true}
                                        /> */}

                                        <CustomDropDownFieldWithButton
                                            options={vendors.map(c => ({
                                                value: c.id,
                                                label: c.name
                                            }))}
                                            labelText="Vendor"
                                            name="vendor_id"
                                            value={formData.vendor_id}
                                            onChange={handleVendorChange}
                                            errorMessage={errors.vendor_id}
                                            required={true}
                                            path={handleVendorNavigation}
                                        />

                                        <CustomDropDownFieldWithButton
                                            options={accounts.map(c => ({
                                                value: c.id,
                                                label: c.name
                                            }))}
                                            labelText="Account"
                                            name="account_id"
                                            value={formData.account_id}
                                            onChange={handleChange}
                                            errorMessage={errors.account_id}
                                            required={true}
                                            path={handleAccountNavigation}
                                        />

                                        <CustomDropDownField
                                            options={paidthroughAcc.map(c => ({
                                                value: c.id,
                                                label: c.name
                                            }))}
                                            labelText="Paid Through Account"
                                            name="paid_through_account_id"
                                            value={
                                                formData.paid_through_account_id
                                            }
                                            onChange={handleChange}
                                            errorMessage={errors.paid_through_account_id}
                                            required={true}
                                        />
                                        {/* </div> */}
                                        {/* <div className="row"> */}
                                        {/* <CustomInputField
                                            labelText="Bill Date"
                                            inputType="date"
                                            name="invoice_date"
                                            value={formData.invoice_date}
                                            onChange={handleChange}
                                        /> */}
                                        <CustomDatePicker
                                            labelText="Bill Date"
                                            inputType="date"
                                            name="invoice_date"
                                            value={formData.invoice_date}
                                            onChange={handleChange}
                                            className="custom-focus"
                                            required={true}
                                            errorMessage={errors.invoice_date}
                                        />
                                        {/* <CustomInputField
                                            labelText="Bill Due Date"
                                            inputType="date"
                                            name="invoice_due_date"
                                            value={formData.invoice_due_date}
                                            onChange={handleChange}
                                        /> */}

                                        <CustomDatePicker
                                            labelText="Bill Due Date"
                                            inputType="date"
                                            name="invoice_due_date"
                                            value={formData.invoice_due_date}
                                            onChange={handleChange}
                                            className="custom-focus"
                                            required={true}
                                            errorMessage={errors.invoice_due_date}
                                        />

                                        {/* </div> */}
                                        {/* Date Picker */}
                                        {/* <div className="row">
                                        <div className="col-lg-4 col-md-6 col-sm-12">
                                            <div className="input-block mb-3">
                                              <label>Purchases Date</label>
                                              <DatePicker
                                                className="datetimepicker form-control"
                                                selected={startDate}
                                                onChange={(date) => setStartDate(date)}
                                              ></DatePicker>
                                            </div>
                                        </div>

                                        <div className="col-lg-4 col-md-6 col-sm-12">
                                            <div className="input-block mb-3">
                                              <label>Purchases Date</label>
                                              <DatePicker
                                                className="datetimepicker form-control"
                                                selected={startDate}
                                                onChange={(date) => setStartDate(date)}
                                              ></DatePicker>
                                            </div>
                                        </div>
                                    </div> */}

                                        {/* <div className="row"> */}
                                        {/* <CustomInputField
                                            inputType="text"
                                            labelText="Bill Number"
                                            name="invoice_number"
                                            value={formData.invoice_number}
                                            onChange={handleChange}
                                            errorMessage={errors.invoice_number}
                                            required={true}
                                            placeholder="E.g 123"
                                            className="custom-focus"
                                        /> */}

                                        <CustomInputField
                                            inputType="number"
                                            labelText="Amount"
                                            name="amount"
                                            value={formData.amount}
                                            onChange={handleChange}
                                            errorMessage={errors.amount}
                                            required={true}
                                            placeholder="E.g 100"
                                            inputWrapper="mb-3"
                                            className="custom-focus"
                                        />
                                        <CustomInputField
                                            inputType="text"
                                            labelText="Order Number"
                                            name="order_number"
                                            value={formData.order_number}
                                            onChange={handleChange}
                                            placeholder="E.g 1, 2, 3"
                                            inputWrapper="mb-3"
                                            className="custom-focus"
                                        />
                                        {/* <div className="row align-items-center"> */}
                                        {/* <div className="col-lg-4"> */}
                                        {/* <CustomDropDownField
                                            options={currencies.map(c => ({
                                                value: c.id,
                                                label: c.name
                                            }))}
                                            labelText="Currency"
                                            name="currency_id"
                                            value={formData.currency_id}
                                            onChange={handleChange}
                                            // dropDownSize="col-lg-12"
                                            errorMessage={errors.currency_id}
                                            required={true}
                                        /> */}

                                        <CustomDropDownField
                                            options={status.map(c => ({
                                                value: c.value,
                                                label: c.label
                                            }))}
                                            labelText="Status"
                                            name="status"
                                            value={formData.status}
                                            onChange={handleChange}
                                            dropDownSize="mb-3"
                                        />
                                        {/* </div> */}
                                        <div className="col-xl-3 col-lg-3 col-md-6 col-12 ">
                                            <div className="input-block mb-3">
                                                <label>Upload receipt </label>
                                                <div className="input-block service-upload mb-0">
                                                    <span>
                                                        <img
                                                            src={DropIcon}
                                                            alt="upload"
                                                        />
                                                    </span>
                                                    <h6 className="drop-browse align-center">
                                                        Drop your files here or
                                                        <span className="text-primary ms-1">
                                                            browse
                                                        </span>
                                                    </h6>
                                                    <p className="text-muted">
                                                        Maximum size: 50MB
                                                    </p>
                                                    <input
                                                        type="file"
                                                        multiple
                                                        id="image_sign"
                                                        onChange={
                                                            handleChange
                                                        }
                                                    />
                                                    <div id="frames" />
                                                </div>
                                                {selectedFiles.length > 0 && (
                                                    <div className="selected-files my-2">
                                                        <p>Selected file(s):</p>
                                                        <ul>
                                                            {selectedFiles.map(
                                                                (
                                                                    file,
                                                                    index
                                                                ) => (
                                                                    <li
                                                                        key={
                                                                            index
                                                                        }
                                                                    >
                                                                        {
                                                                            file.name
                                                                        }
                                                                    </li>
                                                                )
                                                            )}
                                                        </ul>
                                                    </div>
                                                )}
                                            </div>
                                        </div>

                                        <div className="col-xl-3 col-lg-3 col-md-6 col-12 ">
                                            <CustomTextArea
                                                rows={6}
                                                name="terms_conditions"
                                                value={
                                                    formData.terms_conditions
                                                }
                                                onChange={handleChange}
                                                labelText="Terms & Conditions"
                                                placeholder="Terms & Conditions"
                                            />
                                        </div>

                                        <div className="col-xl-3 col-lg-3 col-md-6 col-12 mb-4">
                                            <CustomTextArea
                                                rows={6}
                                                name="description"
                                                labelText="Description"
                                                value={formData.description}
                                                onChange={handleChange}
                                                placeholder="Description"
                                            />
                                        </div>

                                        <div className="col-xl-3 col-lg-3 col-md-6 col-12 mb-4">
                                            <CustomTextArea
                                                rows={6}
                                                labelText="Vendor Notes"
                                                name="vendor_notes"
                                                value={formData.vendor_notes}
                                                onChange={handleChange}
                                                placeholder="Vendor Notes"
                                            />
                                        </div>
                                    </div>

                                    <div className="text-end">
                                        <button
                                            type="reset"
                                            className="btn btn-primary cancel me-2"
                                            onClick={handleCancel}
                                        >
                                            Cancel
                                        </button>
                                        <button
                                            type="submit"
                                            className="btn btn-primary"
                                        >
                                            Save
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default AddPurchaseInvoice;
