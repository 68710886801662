import React, { useState, useEffect } from "react";
import SettingsSidebar from "../../layouts/SettingsSidebar";
import { useTranslation } from "react-i18next";
import { useAuth } from "../../contexts/AuthContext";
import axios from "axios";
import { useLocation } from "react-router-dom";

const XeroIntegration = () => {
    const { t } = useTranslation();
    const location = useLocation();

    const { auth: authToken, branchId } = useAuth();
    const [step, setStep] = useState(1);
    const [authUrl, setAuthUrl] = useState(null);
    const [isAuthorized, setIsAuthorized] = useState(false);
    const [syncing, setSyncing] = useState(false);
    const [error, setError] = useState(null);
    const [successMessages, setSuccessMessages] = useState([]); // Array to hold success messages

    const queryParams = new URLSearchParams(location.search);
    const code = queryParams.get("code");
    const state = queryParams.get("state");

    useEffect(() => {
        if (code && state) {
            setIsAuthorized(true);

            const callXeroCallback = async () => {
                try {
                    const response = await axios.get(
                        `${process.env.REACT_APP_API_V1_BASE_URL}/xero/callback`,
                        {
                            params: { code, state },
                            headers: {
                                Authorization: `Bearer ${authToken}`
                            }
                        }
                    );

                    if (response.status === 200) {
                        setStep(2); // Proceed to the next step after authorization
                        setSuccessMessages(prevMessages => [...prevMessages, "QuickBooks successfully authorized!"]);
                    } else {
                        setIsAuthorized(false);
                        // setError("Failed to complete QuickBooks authorization.");
                    }
                } catch (error) {
                    setIsAuthorized(false);
                    // console.error("An unexpected error occurred:", error);
                    // setError("An unexpected error occurred during authorization.");
                }
            };

            callXeroCallback();
        }

        const fetchXeroAuthUrl = async () => {
            try {
                const response = await axios.get(
                    `${process.env.REACT_APP_API_V1_BASE_URL}/xero/auth-url`,
                    {
                        headers: {
                            Authorization: `Bearer ${authToken}`
                        }
                    }
                );

                if (response.status === 200) {
                    setAuthUrl(response.data.auth_url);
                    if (response.data.token_exists) {
                        setStep(2); // Move to the next step
                    }
                } else {
                    setError("Failed to fetch QuickBooks authorization URL.");
                }
            } catch (error) {
                console.error("An unexpected error occurred:", error);
                setError("An unexpected error occurred while fetching auth URL.");
            }
        };

        fetchXeroAuthUrl();

    }, [authToken, code, state]);

    const handleXeroSync = async (syncType) => {
        setSyncing(true);
        setError(null);

        try {
            const response = await axios.post(
                `${process.env.REACT_APP_API_V1_BASE_URL}/xero/sync-${syncType}`,
                { branchId },
                {
                    headers: {
                        Authorization: `Bearer ${authToken}`,
                        "Content-Type": "application/json",
                    },
                }
            );

            if (response.status === 200) {
                setSuccessMessages(prevMessages => [
                    `Successfully synced ${syncType}.`, ...prevMessages
                ]);
                setStep(prevStep => prevStep + 1); // Move to the next step
            } else if (response.status === 401) {
                setStep(1);
                setError("Authentication failed. Please authorize again.");
            } else {
                setError(`Failed to sync ${syncType}.`);
            }
        } catch (err) {
            if (err.response) {
                // If response exists, log status code and message
                if (err.response.status === 401) {
                    setStep(1);
                    setError("Authentication failed. Please authorize again.");
                } else {
                    setStep(1);
                    setError(`An error occurred while syncing ${syncType}.`);
                }
            } else {
                setStep(1);
                // If no response, log general error
                setError(`An error occurred while syncing ${syncType}.`);
            }
        } finally {
            setSyncing(false);
        }
    };

    const renderStepContent = () => {
        switch (step) {
            case 1:
                return (
                    <div>
                        <div className="d-flex align-items-center justify-content-between w-100">
                            <h5>{t("Authorize Xero")}</h5>
                            {!isAuthorized && authUrl && (
                                <a
                                    href={authUrl}
                                    className="btn btn-primary"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    {t("Authorize Now")}
                                </a>
                            )}
                        </div>
                        {successMessages.map((message, index) => (
                            <div key={index} className="alert alert-success">{message}</div>
                        ))}
                        {error && <div className="alert alert-danger">{error}</div>}
                    </div>
                );
            case 2:
                return (
                    <div>
                        <div className="d-flex align-items-center justify-content-between w-100">
                            <h5>{t("Sync Customers")}</h5>

                            <button
                                onClick={() => handleXeroSync("contacts")}
                                className="btn btn-primary"
                                disabled={syncing}
                            >
                                {syncing ? t("Syncing...") : t("Sync Contacts")}
                            </button>

                        </div>
                        {successMessages.map((message, index) => (
                            <div key={index} className="alert alert-success">{message}</div>
                        ))}
                        {error && <div className="alert alert-danger">{error}</div>}
                    </div>
                );
            case 3:
                return (
                    <div>
                        <div className="d-flex align-items-center justify-content-between w-100">
                            <h5>{t("Sync Accounts")}</h5>
                            <button
                                onClick={() => handleXeroSync("accounts")}
                                className="btn btn-primary mb-3"
                                disabled={syncing}
                            >
                                {syncing ? t("Syncing...") : t("Sync Accounts")}
                            </button>
                        </div>
                        {successMessages.map((message, index) => (
                            <div key={index} className="alert alert-success">{message}</div>
                        ))}
                        {error && <div className="alert alert-danger">{error}</div>}
                    </div>
                );
            case 4:
                return (
                    <div>
                        <div className="d-flex align-items-center justify-content-between w-100">
                            <h5>{t("Sync Items")}</h5>
                            <button
                                onClick={() => handleXeroSync("items")}
                                className="btn btn-primary mb-3"
                                disabled={syncing}
                            >
                                {syncing ? t("Syncing...") : t("Sync Items")}
                            </button>
                        </div>
                        {successMessages.map((message, index) => (
                            <div key={index} className="alert alert-success">{message}</div>
                        ))}
                        {error && <div className="alert alert-danger">{error}</div>}
                    </div>
                );
            case 5:
                return (
                    <div>
                        <div className="d-flex align-items-center justify-content-between w-100">
                            <h5>{t("Sync Invoices")}</h5>

                            <button
                                onClick={() => handleXeroSync("invoices")}
                                className="btn btn-primary mb-3"
                                disabled={syncing}
                            >
                                {syncing ? t("Syncing...") : t("Sync Invoices")}
                            </button>
                        </div>
                        {successMessages.map((message, index) => (
                            <div key={index} className="alert alert-success">{message}</div>
                        ))}
                        {error && <div className="alert alert-danger">{error}</div>}
                    </div>
                );
            case 6:
                return (
                    <div>
                        <div className="d-flex align-items-center justify-content-between w-100">
                            <h5>{t("Sync Bills")}</h5>
                            <button
                                onClick={() => handleXeroSync("bills")}
                                className="btn btn-primary mb-3"
                                disabled={syncing}
                            >
                                {syncing ? t("Syncing...") : t("Sync Bills")}
                            </button>

                        </div>
                        {successMessages.map((message, index) => (
                            <div key={index} className="alert alert-success">{message}</div>
                        ))}
                        {error && <div className="alert alert-danger">{error}</div>}
                    </div>
                );
            default:
                return (
                    <div>
                        <h5>{t("Xero Integration Completed")}</h5>
                        <p>{t("All data has been successfully synced.")}</p>

                        {successMessages.map((message, index) => (
                            <div key={index} className="alert alert-success">{message}</div>
                        ))}
                    </div>
                );
        }
    };

    return (
        <div className="page-wrapper">
            <div className="content container-fluid">
                <div className="row">
                    <div className="col-xl-3 col-md-4">
                        <SettingsSidebar />
                    </div>
                    <div className="col-xl-9 col-md-8">
                        <div className="card">
                            <div className="content-page-header card-header mb-0">
                                <h3 className="page-title">{t("Xero Integration")}</h3>
                            </div>
                            <div className="card-body w-100">
                                {renderStepContent()}
                            </div>
                            <div className="card-body w-100">
                                <div className="btn-path text-end">
                                    <button
                                        onClick={() => setStep(step - 1)}
                                        disabled={step === 1}
                                        className="btn btn btn-primary previous me-2"
                                    >
                                        {t("Previous")}
                                    </button>
                                    <button
                                        onClick={() => setStep(step + 1)}
                                        disabled={syncing || step === 7} // Prevent going beyond the last step
                                        className="btn btn btn-primary next"
                                    >
                                        {t("Next")}
                                    </button>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default XeroIntegration;