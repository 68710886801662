import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useAuth } from "../contexts/AuthContext";
import axios from "axios";
import {
    CustomInputField,
    CustomDropDownField,
    CustomTextArea
} from "../CustomComponents/ReusableComponents";
import Swal from 'sweetalert2';

const EditAccountTypes = ({ id, isOpen, onClose, onUpdate, title, modalId }) => {
    const { auth } = useAuth();
    const API_BASE_URL = process.env.REACT_APP_API_V1_BASE_URL;

    const [accountTypes, setAccountTypes] = useState([]);

    const [formData, setFormData] = useState({
        parent_id: "",
        name: "",
        description: ""
    });

    useEffect(() => {
        const fetchDropdownData = async () => {
            try {
                const response = await axios.post(
                    `${process.env.REACT_APP_API_V1_BASE_URL}/get_dropdown_data`,
                    {
                        models: [{ model_name: "AccountType" }]
                    },
                    {
                        headers: {
                            Authorization: `Bearer ${auth}`
                        }
                    }
                );
                setAccountTypes(response.data.content.AccountType || []);
            } catch (err) {
                console.error("Failed to load dropdown data", err);
            }
        };

        fetchDropdownData();
    }, [auth]);

    useEffect(() => {
        const fetchAccountType = async () => {
            try {
                const response = await axios.get(
                    `${process.env.REACT_APP_API_V1_BASE_URL}/account_types/${id}`,
                    {
                        headers: {
                            Authorization: `Bearer ${auth}`
                        }
                    }
                );
                setFormData({
                    name: response.data.name,
                    description: response.data.description,
                    parent_id: response.data.parent_id || ""
                });
            } catch (err) {
                console.error("Failed to fetch Transaction Type", err);
            }
        };

        fetchAccountType();
    }, [id, auth]);

    const handleChange = e => {
        const { name, value } = e.target;
        setFormData(prevFormData => ({
            ...prevFormData,
            [name]: value
        }));
    };

    const [errors, setErrors] = useState({});

    const validateForm = () => {
        const validationErrors = {};
        if (!formData.name) validationErrors.name = "Add Name";
        if (!formData.description) validationErrors.description = "Add Description";
    
        setErrors(validationErrors);
        return Object.keys(validationErrors).length === 0; 
    };

    const handleSubmit = async e => {
        e.preventDefault();
        if (!validateForm()) {
            return; 
        }
        try {
            const accountTypesData = { ...formData };
            const apiReqHeaders = {
                headers: {
                    Authorization: `Bearer ${auth}`,
                    "Content-Type": "application/json"
                }
            };

            await axios.put(
                `${API_BASE_URL}/account_types/${id}`,
                accountTypesData,
                apiReqHeaders
            );
            Swal.fire({
                title: 'Success!',
                text: 'Account Type updated successfully.',
                icon: 'success',
                confirmButtonText: 'OK',
                customClass: {
                    popup: 'custom-swal-popup', 
                    icon: 'custom-swal-icon',   
                  }
            });
            onClose();
            onUpdate();

        } catch (err) {
            Swal.fire({
                title: 'Error!',
                text: 'Failed to save the Account Type.',
                icon: 'error',
                confirmButtonText: 'OK',
                customClass: {
                    popup: 'custom-swal-popup',
                    icon: 'custom-swal-icon',  
                  }
            });
        }
    };

    return (
        <>
        <div
          className={`modal custom-modal fade ${isOpen ? "show d-block" : ""}`}
          id={modalId}
          tabIndex="-1"
          role="dialog"
          style={{ backgroundColor: "rgba(0, 0, 0, 0.5)" }}
        >
            <div
              className={`modal-dialog modal-dialog-centered modal-md ${
                isOpen ? "modal-slide-in" : "modal-slide-out"
              }`}
              style={{ maxWidth: "600px" }}
            >
                <div className="modal-content" style={{ height: "400px" }}>
                  <div className="modal-header border-0 pb-0">
                    <div className="form-header modal-header-title text-start mb-0">
                      <h4 className="mb-0">{title}</h4>
                    </div>
                    <button
                      type="button"
                      className="btn-close"
                      onClick={onClose}
                      aria-label="Close"
                    ></button>
                  </div>
                  <div
                    className="modal-body"
                    style={{
                      overflowY: "auto",
                      maxHeight: "calc(100% - 50px)",
                      paddingBottom: "20px",
                    }}
                  >
                       <CustomInputField
                            inputType="text"
                            labelText="Name"
                            inputWrapper="col-lg-12"
                            name="name"
                            value={formData.name}
                            onChange={handleChange}
                            errorMessage={errors.name}
                            required={true}
                            placeholder="E.g Asset, Equity"
                        />
                        <CustomTextArea
                            labelText="Description"
                            name="description"
                            value={formData.description}
                            onChange={handleChange}
                            errorMessage={errors.description}
                            required={true}
                        />
                        <CustomDropDownField
                            options={accountTypes.map(c => ({
                                value: c.id,
                                label: c.name
                            }))}
                            labelText="Parent account"
                            name="parent_id"
                            value={formData.parent_id}
                            onChange={handleChange}
                            dropDownSize="col-lg-12"
                        />
                        <div className="modal-footer">
                            <button
                                type="button"
                                className="btn btn-secondary"
                                onClick={onClose}
                            >
                                Close
                            </button>
                            <button
                                type="button"
                                className="btn btn-primary mx-3"
                                onClick={handleSubmit}
                            >
                                Save changes
                            </button>
                        </div>
                    </div>
                  </div>
                </div>
        </div>
        </>
        // <>
        //     {isOpen && (
        //         <div
        //             className="aia-modal-overlay-wrapper"
        //             //style={modalOverlayStyle}
        //             onClick={handleOverlayClick}
        //         >
        //             <div
        //                 // style={modalContentStyle}
        //                 className="aia-modal-card"
        //             >
        //                 <h5>Edit Types</h5>
        //                 <CustomInputField
        //                     inputType="text"
        //                     labelText="Name"
        //                     inputWrapper="col-lg-12"
        //                     name="name"
        //                     value={formData.name}
        //                     onChange={handleChange}
        //                     errorMessage={errors.name}
        //                     required={true}
        //                     placeholder="E.g Asset, Equity"
        //                 />
        //                 <CustomTextArea
        //                     labelText="Description"
        //                     name="description"
        //                     value={formData.description}
        //                     onChange={handleChange}
        //                     errorMessage={errors.description}
        //                     required={true}
        //                 />
        //                 <CustomDropDownField
        //                     options={accountTypes.map(c => ({
        //                         value: c.id,
        //                         label: c.name
        //                     }))}
        //                     labelText="Parent account"
        //                     name="parent_id"
        //                     value={formData.parent_id}
        //                     onChange={handleChange}
        //                     dropDownSize="col-lg-12"
        //                 />
        //                 <button
        //                     type="button"
        //                     className="btn btn-secondary"
        //                     onClick={onClose}
        //                 >
        //                     Close
        //                 </button>
        //                 <button
        //                     type="button"
        //                     className="btn btn-primary mx-3"
        //                     onClick={handleSubmit}
        //                 >
        //                     Save changes
        //                 </button>
        //             </div>
        //         </div>
        //     )}
        // </>
    );
};

EditAccountTypes.propTypes = {
    id: PropTypes.string.isRequired,
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onUpdate: PropTypes.func.isRequired,
    title: PropTypes.string.isRequired,
    modalId: PropTypes.string.isRequired
};

export default EditAccountTypes;

// import React, { useState, useEffect } from 'react';
// import { useAuth } from '../contexts/AuthContext';
// import axios from 'axios';
// import {
//     CustomDropDownField, CustomInputField
// } from "../CustomComponents/ReusableComponents";
// import CustomPopup from '../CustomComponents/CustomPopup';
// import PropTypes from 'prop-types';

// const EditAccountTypes = ({ id }) => {
//     const { auth } = useAuth();
//     const [accountTypes, setAccountTypes] = useState([]);

//     const [formData, setFormData] = useState({
//         parent_id: '',
//         name: '',
//         description: ''
//     });

//     useEffect(() => {
//         const fetchAccountType = async () => {
//             try {
//                 const response = await axios.get(
//                     `${process.env.REACT_APP_API_V1_BASE_URL}/account_types/${id}`,
//                     {
//                         headers: {
//                             Authorization: `Bearer ${auth}`
//                         }
//                     }
//                 );
//                 const accountTypeData = response.data;
//                 setFormData({
//                     parent_id: accountTypeData.parent_id || '',
//                     name: accountTypeData.name || '',
//                     description: accountTypeData.description || ''
//                 });

//                 // Console log the fetched data
//                 console.log("Fetched Account Type Data:", accountTypeData);
//             } catch (err) {
//                 console.error('Failed to fetch account type', err);
//             }
//         };

//         fetchAccountType();
//     }, [id, auth]);

//     // Fetch dropdown data
//     useEffect(() => {
//         const fetchDropdownData = async () => {
//             try {
//                 const response = await axios.post(
//                     `${process.env.REACT_APP_API_V1_BASE_URL}/get_dropdown_data`,
//                     {
//                         models: [{ model_name: "AccountType" }]
//                     },
//                     {
//                         headers: {
//                             Authorization: `Bearer ${auth}`
//                         }
//                     }
//                 );
//                 setAccountTypes(response.data.content.AccountType || []);
//             } catch (err) {
//                 console.error('Failed to load dropdown data', err);
//             }
//         };

//         fetchDropdownData();
//     }, [auth]);

//     const handleChange = e => {
//         const { name, value } = e.target;
//         setFormData(prevFormData => ({
//             ...prevFormData,
//             [name]: value
//         }));
//     };

//     const handleSubmit = async e => {
//         e.preventDefault();
//         try {
//             // Console log the data being submitted
//             console.log("Submitting Account Type Data:", formData);

//             const response = await axios.put( // Use PUT for updating
//                 `${process.env.REACT_APP_API_V1_BASE_URL}/account_types/${id}`, // Endpoint for updating
//                 { ...formData },
//                 {
//                     headers: {
//                         Authorization: `Bearer ${auth}`,
//                         "Content-Type": "application/json"
//                     }
//                 }
//             );
//             console.log("Account Type updated successfully", response.data);
//         } catch (err) {
//             console.error("Failed to update Account Type", err);
//         }
//     };

//     return (
//         <CustomPopup label="Edit Account Type" id="edit_account" handleSubmit={handleSubmit}>
//             <CustomInputField
//                 inputType="text"
//                 labelText="Name"
//                 inputWrapper="col-lg-12"
//                 name="name"
//                 value={formData.name}
//                 onChange={handleChange}
//             />
//             <CustomInputField
//                 inputType="text"
//                 labelText="Description"
//                 inputWrapper="col-lg-12"
//                 name="description"
//                 value={formData.description}
//                 onChange={handleChange}
//             />
//             <CustomDropDownField
//                 options={accountTypes.map(c => ({
//                     value: c.id,
//                     label: c.name
//                 }))}
//                 labelText="Account Type"
//                 name="parent_id"
//                 value={formData.parent_id}
//                 onChange={handleChange}
//                 dropDownSize="col-lg-12"
//             />
//         </CustomPopup>
//     );
// };

// EditAccountTypes.propTypes = {
//     id: PropTypes.string.isRequired,
// };

// export default EditAccountTypes;
