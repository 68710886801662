import React from 'react';
import PageComponent from '../CustomComponents/PageComponent';

const PurchaseInvoice = () => {
  return(
    <PageComponent listAPI='purchase_invoices' label='Bills' addType='nextPage' addLabel='Bill' route='bills'/>
  )
};

export default PurchaseInvoice;
