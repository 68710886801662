import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import SettingsSidebar from "../layouts/SettingsSidebar";
import axios from "axios";
import { useAuth } from "../contexts/AuthContext";
import Swal from 'sweetalert2';

const BillSetting = () => {
  const [billPrefix, setBillPrefix] = useState("");
  const [expensePrefix, setExpensePrefix] = useState("");
  const { auth } = useAuth();

  useEffect(() => {
    const fetchSettings = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_V1_BASE_URL}/general-settings?group=bill-expense`,
          {
            headers: {
              Authorization: `Bearer ${auth}`,
            },
          }
        );

        const data = response.data.settings;
        setBillPrefix(data.bill_prefix || "");
        setExpensePrefix(data.expense_prefix || "");
      } catch (error) {
        console.error("Error fetching settings:", error);
      }
    };

    fetchSettings();
  }, [auth]);

  const handleSubmit = async () => {
    try {
      const formData = new FormData();
      formData.append("group", "bill-expense");
      formData.append("bill_prefix", billPrefix);
      formData.append("expense_prefix", expensePrefix);

      const response = await axios.post(
        `${process.env.REACT_APP_API_V1_BASE_URL}/general-settings`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${auth}`,
          },
        }
      );
console.log(response);
      Swal.fire({
        title: 'Success!',
        text: 'Bill-Expense settings updated successfully.',
        icon: 'success',
        confirmButtonText: 'OK'
      });

    } catch (error) {
      Swal.fire({
        title: 'Error!',
        text: 'Failed to save the Bill-Expense settings.',
        icon: 'error',
        confirmButtonText: 'OK'
      });
      console.error("Error updating settings:", error);
    }
  };



  return (
    <>
      <div className="page-wrapper">
        <div className="content container-fluid">
          <div className="row">
            <div className="col-xl-3 col-md-4">
              <SettingsSidebar />
            </div>
            <div className="col-xl-9 col-md-8">
              <div className="card">
                <div className="card-body w-100">
                  <div className="content-page-header">
                    <h5>Bill-Expense Settings</h5>
                  </div>
                  <div className="row">
                    <div className="col-lg-6 col-12">
                      <div className="input-block mb-3">
                        <label>Bill Prefix</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter Bill Prefix"
                          value={billPrefix}
                          onChange={(e) => setBillPrefix(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="col-lg-6 col-12">
                      <div className="input-block mb-3">
                        <label>Expense Prefix</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter Bill Prefix"
                          value={expensePrefix}
                          onChange={(e) => setExpensePrefix(e.target.value)}
                        />
                      </div>
                    </div>

                    <div className="col-lg-12">
                      <div className="btn-path text-end">
                        <Link
                          to="to"
                          className="btn btn-cancel bg-primary-light me-3"
                        >
                          Cancel
                        </Link>
                        <button
                          onClick={handleSubmit}
                          className="btn btn-primary"
                        >
                          Save Changes
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BillSetting;
