import React, { useState, useEffect } from 'react';
import Sidebar from '../layouts/Sidebar';
import Header from '../layouts/Header';
import axios from 'axios';
import {
    CustomDropDownField, CustomInputField,
} from "../CustomComponents/ReusableComponents";
import { useAuth } from '../contexts/AuthContext';
import { useHistory, useLocation
    //  useParams
} from "react-router-dom";
import Swal from 'sweetalert2';

const AddTransactionRuleComponent = () => {
    const { auth } = useAuth();
    const history = useHistory();
    const [isEditing, setIsEditing] = useState(false);
    const location = useLocation();
    const [id, setId] = useState(null);


    useEffect(() => {
        const extractedId = location.pathname.split("/").pop();
        setId(extractedId !== "add-transaction-rules" ? extractedId : null);
        setIsEditing(extractedId !== "add-transaction-rules" ? false : true);

    }, [location]);

    const API_BASE_URL = process.env.REACT_APP_API_V1_BASE_URL;

    const [menu, setMenu] = useState(false);
    const [accounts, setAccounts] = useState([]);
    const [bankaccounts, setBankAccounts] = useState([]);
    const [errors, setErrors] = useState({});
    
    const [formData, setFormData] = useState({
        account_type_id: '',
        description: '',
        name: '',
        is_debit: false,
    })
    
    const toggleMobileMenu = () => {
        setMenu(!menu);
    };


    const handleChange = e => {
        const { name, value } = e.target;
        setFormData(prevFormData => ({
            ...prevFormData,
            [name]: value
        }));
    };
    const handleToggle = () => {
        setFormData(prevFormData => ({
            ...prevFormData,
            is_debit: prevFormData.is_debit === 1 ? 0 : 1
        }));
    };


    useEffect(() => {
        const fetchDropdownData = async () => {
            try {
                const response = await axios.post(
                    `${process.env.REACT_APP_API_V1_BASE_URL}/get_dropdown_data`,
                    {
                        models: [
                            { model_name: "Account" },
                            { model_name: "BankAccount" },
                        ]
                    },
                    {
                        headers: {
                            Authorization: `Bearer ${auth}`
                        }
                    }
                );
                const accountDropdownData = response.data.content.Account || [];
                setAccounts(accountDropdownData);

                const bankaccountDropdownData = response.data.content.BankAccount || [];
                setBankAccounts(bankaccountDropdownData);
                //setAccountTypes(response.data.content.AccountType || []);
            } catch (err) {
                // setError("Failed to load dropdown data");
            }
        };

        const fetchAccountData = async () => {
            if (id) {
                try {
                    const response = await axios.get(
                        `${process.env.REACT_APP_API_V1_BASE_URL}/transaction-rules/${id}`,
                        {
                            headers: {
                                Authorization: `Bearer ${auth}`
                            }
                        }
                    );
                    setFormData(response.data);
                } catch (err) {
                    // Handle error
                }
            }
        };

        fetchDropdownData();
        fetchAccountData();
    }, [auth, id]);

    const validateForm = () => {
        const validationErrors = {};

        if (!formData.account_id) {
            validationErrors.account_id = "Select an account";
        }
        if (!formData.bank_account_id) {
            validationErrors.bank_account_id = "Select bank account";
        }

        if (!formData.name) {
            validationErrors.name = "Enter a name";
        }

        setErrors(validationErrors);
        return Object.keys(validationErrors).length === 0;
    };


    const handleSubmit = async e => {
        e.preventDefault();

        if (!validateForm()) {
            return;
        }
        try {
            const accountsData = { ...formData };
            const apiReqHeaders = {
                headers: {
                    Authorization: `Bearer ${auth}`,
                    "Content-Type": "application/json"
                }
            };
            if (id) {
                await axios.put(
                    `${API_BASE_URL}/transaction-rules/${id}`,
                    accountsData,
                    apiReqHeaders
                );
                Swal.fire({
                    title: 'Success!',
                    text: 'Account updated successfully.',
                    icon: 'success',
                    confirmButtonText: 'OK',
                    customClass: {
                        popup: 'custom-swal-popup',  // Use your class for custom positioning
                        icon: 'custom-swal-icon',    // Use this class to style the icon
                      }
                });
            } else {
                await axios.post(
                    `${API_BASE_URL}/transaction-rules`,
                    accountsData,
                    apiReqHeaders
                );
                Swal.fire({
                    title: 'Success!',
                    text: 'Account created successfully.',
                    icon: 'success',
                    confirmButtonText: 'OK',
                    customClass: {
                        popup: 'custom-swal-popup',  // Use your class for custom positioning
                        icon: 'custom-swal-icon',    // Use this class to style the icon
                      }
                });
            }

            history.push("/transaction-rules");
        } catch (error) {
            Swal.fire({
                title: 'Error!',
                text: 'Failed to save the transaction-rules.',
                icon: 'error',
                confirmButtonText: 'OK',
                customClass: {
                    popup: 'custom-swal-popup',  // Use your class for custom positioning
                    icon: 'custom-swal-icon',    // Use this class to style the icon
                  }
            });
        }
    };

    const handleCancel = () => {
        history.push('/transaction-rules');
    };

    return (
        <>
            <div className={`main-wrapper ${menu ? "slide-nav" : ""}`}>
                <Header onMenuClick={toggleMobileMenu} />
                <Sidebar />
                <div className="page-wrapper">
                    <div className="content container-fluid">
                        <div className="card mb-0">
                            <div className="card-body">
                                <div className="page-header">
                                    <div className="content-page-header">
                                        <h5>{id ? 'Edit Transaction Rules' : 'Add Transaction Rules'}</h5>
                                    </div>
                                </div>

                                <form onSubmit={handleSubmit}>
                                <div className="form-check form-switch col-md-1 me-2">
                                    <input
                                        className="form-check-input"
                                        type="checkbox"
                                        id="isActiveSwitch"
                                        checked={formData.is_debit === 1}
                                        onChange={handleToggle}
                                    />
                                    <label
                                        className="form-check-label"
                                        htmlFor="isActiveSwitch"
                                    >
                                        {formData.is_debit === 1 ? "Debit" : "Credit"}
                                    </label>
                                </div>
                                    <div className="row">
                                        <CustomInputField
                                            inputType="text"
                                            labelText="Name"
                                            placeholder="E.g John Doe"
                                            name="name"
                                            value={formData.name}
                                            onChange={handleChange}
                                            required={true}
                                            disabled={!isEditing}
                                            errorMessage={errors.name}

                                        />
                                        <CustomDropDownField
                                            dropDownSize="col-lg-12"
                                            options={bankaccounts.map(c => ({
                                                value: c.id,
                                                label: c.name
                                            }))}
                                            labelText="Bank Accounts "
                                            name="bank_account_id"
                                            onChange={handleChange}
                                            value={formData.bank_account_id}
                                            required={true}
                                            disabled={!isEditing}
                                            errorMessage={errors.bank_account_id}
                                        />
                                        <CustomDropDownField
                                            dropDownSize="col-lg-12"
                                            options={accounts.map(c => ({
                                                value: c.id,
                                                label: c.name,
                                                style: c.id === 'new' ? { color: 'blue', textDecoration: 'underline', cursor: 'pointer' } : {}
                                            }))}
                                            labelText="Select Account "
                                            name="account_id"
                                            onChange={handleChange}
                                            value={formData.account_id}
                                            required={true}
                                            errorMessage={errors.account_id}
                                        />
                                        </div>




                                    <div className="text-end">
                                        <button
                                            type="reset"
                                            className="btn btn-primary cancel me-2"
                                            onClick={handleCancel}
                                        >
                                            Cancel
                                        </button>
                                        <button
                                            type="submit"
                                            className="btn btn-primary"
                                        >
                                            Save
                                        </button>
                                    </div>

                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default AddTransactionRuleComponent;