import React, { useState, useEffect } from "react";
import axios from "axios";
import { useAuth } from "../contexts/AuthContext";
import Sidebar from "../layouts/Sidebar";
import Header from "../layouts/Header";
import { useHistory, useParams } from "react-router-dom";
import {
    CustomDropDownField,
    CustomInputField,
} from "../CustomComponents/ReusableComponents";
import Swal from 'sweetalert2';

const AddBankAccount = () => {
    const { id } = useParams();
    const { auth } = useAuth();
    const history = useHistory();

    const API_BASE_URL = process.env.REACT_APP_API_V1_BASE_URL;

    const [menu, setMenu] = useState(false);

    const accountTypes = [
        { name: "Checking" },
        { name: "Savings" },
        { name: "Money Market" },
        { name: "Joint" },
        { name: "Custodial" },
        { name: "Trust" },
    ];

    const [formData, setFormData] = useState({
        account_holder_name: "",
        bank_name: "",
        account_number: "",
        account_type: process.env.REACT_APP_ACCOUNT_ID_BANK_ACCOUNT,
        is_active: true
    });

    useEffect(() => {
        const fetchBankAccountData = async () => {
            if (id) {
                try {
                    const response = await axios.get(
                        `${API_BASE_URL}/bank_accounts/${id}`,
                        {
                            headers: {
                                Authorization: `Bearer ${auth}`
                            }
                        }
                    );
                    setFormData(response.data);
                } catch (err) {
                    console.error("Failed to fetch bank account data:", err);
                }
            }
        };

        fetchBankAccountData();
    }, [auth, id]);

    const toggleMobileMenu = () => {
        setMenu(!menu);
    };

    const handleToggle = () => {
        setFormData(prevFormData => ({
            ...prevFormData,
            is_active: prevFormData.is_active === "1" ? "0" : "1"
        }));
    };

    const handleChange = e => {
        const { name, value } = e.target;
        setFormData(prevFormData => ({
            ...prevFormData,
            [name]: value
        }));
    };

    const handleSubmit = async e => {
        e.preventDefault();

        try {
            const bankAccountsData = { ...formData };
            const apiReqHeaders = {
                headers: {
                    Authorization: `Bearer ${auth}`,
                    "Content-Type": "application/json"
                }
            };

            if (id) {
                // Update bank account
                await axios.put(
                    `${API_BASE_URL}/bank_accounts/${id}`,
                    bankAccountsData,
                    apiReqHeaders
                );
                Swal.fire({
                    title: 'Success!',
                    text: 'Bank Account updated successfully.',
                    icon: 'success',
                    confirmButtonText: 'OK',
                    customClass: {
                        popup: 'custom-swal-popup',  // Use your class for custom positioning
                        icon: 'custom-swal-icon',    // Use this class to style the icon
                      }
                });
            } else {
                // Create a new bank account
                await axios.post(
                    `${API_BASE_URL}/bank_accounts`,
                    bankAccountsData,
                    apiReqHeaders
                );
                Swal.fire({
                    title: 'Success!',
                    text: 'Bank Account created successfully.',
                    icon: 'success',
                    confirmButtonText: 'OK',
                    customClass: {
                        popup: 'custom-swal-popup',  // Use your class for custom positioning
                        icon: 'custom-swal-icon',    // Use this class to style the icon
                      }
                });
            }

            history.push("/bank_accounts");
        } catch (error) {
            Swal.fire({
                title: 'Error!',
                text: 'Failed to save the Bank Account.',
                icon: 'error',
                confirmButtonText: 'OK',
                customClass: {
                    popup: 'custom-swal-popup',  // Use your class for custom positioning
                    icon: 'custom-swal-icon',    // Use this class to style the icon
                  }
            });
        }
    };

    const handleCancel = () => {
        history.push("/bank_accounts");
    };

    return (
        <>
            <div className={`main-wrapper ${menu ? "slide-nav" : ""}`}>
                <Header onMenuClick={toggleMobileMenu} />
                <Sidebar />
                <div className="page-wrapper">
                    <div className="content container-fluid">
                        <div className="card mb-0">
                            <div className="card-body">
                                <div className="page-header">
                                    <div className="content-page-header">
                                        <h5>
                                            {id ? "Edit Bank Account" : "Add Bank Account"}
                                        </h5>
                                    </div>
                                </div>

                                <form onSubmit={handleSubmit}>
                                    <div className="row">
                                        <CustomInputField
                                            inputType="text"
                                            labelText="Account Holder Name"
                                            name="account_holder_name"
                                            value={formData.account_holder_name}
                                            onChange={handleChange}
                                        />
                                        <CustomInputField
                                            inputType="number"
                                            labelText="Account Number"
                                            name="account_number"
                                            value={formData.account_number}
                                            onChange={handleChange}
                                        />
                                        <CustomDropDownField
                                            options={accountTypes.map(c => ({
                                                value: c.name,
                                                label: c.name
                                            }))}
                                            labelText="Account Type"
                                            name="account_type"
                                            value={formData.account_type}
                                            onChange={handleChange}
                                        />
                                    </div>

                                    <div className="col-lg-6 my-3">
                                        <CustomInputField
                                            inputType="text"
                                            labelText="Bank Name"
                                            name="bank_name"
                                            value={formData.bank_name}
                                            onChange={handleChange}
                                        />
                                    </div>

                                    <div className="mb-3">
                                        <label className="form-label">
                                            Active
                                        </label>
                                        <div className="form-check form-switch">
                                            <input
                                                className="form-check-input"
                                                type="checkbox"
                                                id="isActiveSwitch"
                                                checked={formData.is_active === "1"}
                                                onChange={handleToggle}
                                            />
                                            <label
                                                className="form-check-label"
                                                htmlFor="isActiveSwitch"
                                            >
                                                {formData.is_active === "1" ? "Active" : "Inactive"}
                                            </label>
                                        </div>
                                    </div>

                                    <div className="text-end">
                                        <button
                                            type="reset"
                                            className="btn btn-primary cancel me-2"
                                            onClick={handleCancel}
                                        >
                                            Cancel
                                        </button>
                                        <button
                                            type="submit"
                                            className="btn btn-primary"
                                        >
                                            Save
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default AddBankAccount;