import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
    CustomDatePicker,
    // CustomDatePicker,
    CustomDropDownField,
    CustomDropDownFieldWithButton,
    CustomInputField,
    CustomTextArea
} from "../../CustomComponents/ReusableComponents";
import axios from "axios";
import { useHistory, useParams } from "react-router-dom";
import { useAuth } from "../../contexts/AuthContext";
import Select from "react-select";
import Swal from 'sweetalert2';
// import MainContainer from "../../CustomComponents/MainContainer";
import ModalOnPopup from "../../CustomComponents/ModalOnPopup";
import AddItems from "../../items/addItems";
import AddCustomerForm from "../../CustomComponents/AddCustomerForm";

const AddInvoice = () => {
    const { auth } = useAuth();

    const DISCOUNT_TYPES = {
        PERCENT: "percentage",
        AMOUNT: "amount"
    };
    const { id } = useParams();
    const navigate = useHistory();

    const handleCancel = () => {
        navigate.push("/invoices");
    };

    const [ showItem, setShowItem ] = useState(false);
    const [ showCustomer, setShowCustomer ] = useState(false);
    
    const fetchNewItemData = async () => {
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_API_V1_BASE_URL}/get_dropdown_data`,
                {
                    models: [ { model_name: "Item" } ]
                },
                {
                    headers: {
                        Authorization: `Bearer ${auth}`
                    }
                }
            );
            setItems(response.data.content.Item || []);
        } catch (err) {
            console.log("Failed to load dropdown data");
            setError(" ");
        }
    };

    const fetchNewCustomerData = async () => {
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_API_V1_BASE_URL}/get_dropdown_data`,
                {
                    models: [ { model_name: "Customer" } ]
                },
                {
                    headers: {
                        Authorization: `Bearer ${auth}`
                    }
                }
            );
            setsales_invoices(response.data.content.Customer || []);
        } catch (err) {
            console.log("Failed to load dropdown data");
            setError(" ");
        }
    };

    const saveItemModal = () => {
        fetchNewItemData();
        setShowItem(false);
    }

    const saveCustomerModal = () => {
        fetchNewCustomerData();
        setShowCustomer(false);
    }

    const handleItemNavigation = () => {
        // navigate.push("/add-items");
        setShowItem(true);
    }
    const handleCustomerNavigation = () => {
        setShowCustomer(true);
    }
    const getCurrentDate = (daysToAdd = 0) => {
        const currentDate = new Date();
        currentDate.setDate(currentDate.getDate() + daysToAdd);
        return currentDate; // Return Date object instead of formatted string
    };

    const [formData, setFormData] = useState({
        customer_id: null,
        account_id: null,
        invoice_date: new Date(),
        invoice_due_date: getCurrentDate(30),
        description: "",
        amount: null,
        order_number: null,
        // subject: "",
        customer_notes: "",
        terms_conditions: "",
        currency_id: null,
        status: "pending",
        items: [
            {
                item_id: null,
                quantity: null,
                price: 0,
                discount_type: "percentage",
                discount_val: 0
            }
        ],
        shipping_charges: 0,
        additional_charges: 0,
    });
    const [currency, setCurrency] = useState([]);
    // const [accountTypes, setAccountTypes] = useState([])
    const [sales_invoices, setsales_invoices] = useState([]);

    const [error, setError] = useState("");
    const [errors, setErrors] = useState({});
    const [items, setItems] = useState([]);



    const calculateTotal = () => {
        const itemsTotal = formData.items.reduce((acc, item) => {
            const price = parseFloat(item.price) || 0;
            const quantity = parseFloat(item.quantity) || 0;

            // Calculate total price for the item before discount
            const totalPrice = price * quantity;

            // Calculate the discount based on type
            const discount =
                item.discount_type === "percentage"
                    ? (totalPrice * parseFloat(item.discount_val || 0)) / 100 // Apply percentage on the total price
                    : parseFloat(item.discount_val || 0); // Apply amount-based discount directly on the total price

            // Subtract discount from total price
            return acc + (totalPrice - discount);
        }, 0);

        const shippingCharges = parseFloat(formData.shipping_charges) || 0;
        const additionalCharges = parseFloat(formData.additional_charges) || 0;

        return itemsTotal + shippingCharges + additionalCharges;
    };

    const calculateItemTotal = item => {
        const price = parseFloat(item.price) || 0;
        const quantity = parseFloat(item.quantity) || 0;

        // Calculate total price for the item before discount
        const totalPrice = price * quantity;

        // Calculate discount based on type
        const discount =
            item.discount_type === "percentage"
                ? (totalPrice * parseFloat(item.discount_val || 0)) / 100 // Apply percentage-based discount on total price
                : parseFloat(item.discount_val || 0); // Apply amount-based discount on the total price

        // Return the total price after discount
        return totalPrice - discount;
    };

    const handleCustomerChange = e => {
        const selectedCustomerId = e.target.value;
        const selectedCustomer = sales_invoices.find(
            customer => customer.id === parseInt(selectedCustomerId)
        );
        console.log("test",selectedCustomerId);

        const selectedCustomerCurrencyCode = selectedCustomer
            ? selectedCustomer.currency_code
            : "";

        // Find the corresponding currency based on the currency_code
        const selectedCurrency = currency.find(
            cur => cur.code === selectedCustomerCurrencyCode
        );

        const selectedCurrencyId = selectedCurrency
            ? selectedCurrency.id
            : null;

        const selectedCurrencySymbol = selectedCustomer
            ? selectedCustomer.currency_symbol
            : "$"; // Default symbol if no currency found

            console.log('currency_symbol',selectedCustomer.currency_symbol);
            console.log('decimal_point',selectedCustomer.decimal_point);
        const selectedDecimalPoint = selectedCustomer
            ? selectedCustomer.decimal_point
            : 2; // Default to 2 decimal points if not provided

        setFormData({
            ...formData,
            customer_id: selectedCustomerId,
            account_id: selectedCustomer ? selectedCustomer.account_id : "",
            currency_id: selectedCurrencyId, // Set the currency_id
            currency_symbol: selectedCurrencySymbol,
            decimal_point: selectedDecimalPoint,
        });
    };

    const handleItemChange = (index, e) => {
        const { name, value } = e.target;
        const updatedItems = [...formData.items];

        updatedItems[index][name] = value;

        if (name === "item_id") {
            const selectedItem = items.find(item => item.id === Number(value));
            if (selectedItem) {
                updatedItems[index].price = selectedItem.selling_price;
                updatedItems[index].quantity = selectedItem.quantity || 1;
            }

        }
        setFormData({
            ...formData,
            amount: calculateTotal(),
            items: updatedItems
        });
    };

    const addItem = () => {
        setFormData({
            ...formData,
            items: [
                ...formData.items,
                {
                    item_id: "",
                    quantity: 1,
                    price: 0,
                    discount_type: DISCOUNT_TYPES.PERCENT,
                    discount_val: 0,
                    invoice_date: "",
                    due_date: ""
                }
            ]
        });
    };

    const removeItem = index => {
        const updatedItems = formData.items.filter((_, i) => i !== index);
        setFormData({ ...formData, items: updatedItems });
    };

    const validateForm = () => {
        const validationErrors = {};
        // if (!formData.invoice_number) validationErrors.invoice_number = "Add Invoice number";
        if (!formData.customer_id)
            validationErrors.customer_id = "Select customer";
        // if (!formData.currency_id) validationErrors.currency_id = "Select Currency";
        if (!formData.status) validationErrors.status = "Select Status";
        if (!formData.description)
            validationErrors.description = "Add Description";
        setErrors(validationErrors);
        return Object.keys(validationErrors).length === 0;
    };
    const handleSaveAsDraft = (e) => {
        
        e.preventDefault(); // Prevent the default form submission
        handleSubmit(e, 0); // Pass false to indicate it's a draft save
       
    };
    // const handleSubmit = async e => {
        const handleSubmit = async (e, isPublished = 1) => {
            e.preventDefault();
            if (!validateForm()) {
                return;
            }
        
            try {
                const invoiceData = { ...formData };
        
                // Format invoice and due dates
                invoiceData.invoice_date = window.reqFormatDate(invoiceData.invoice_date);
                invoiceData.invoice_due_date = window.reqFormatDate(invoiceData.invoice_due_date);
        
                // Set the posted status (1 for published, 0 for draft)
                invoiceData.posted = isPublished;
        
                if (id) {
                    // Update existing invoice
                    await axios.put(
                        `${process.env.REACT_APP_API_V1_BASE_URL}/sales_invoices/${id}`,
                        invoiceData,
                        {
                            headers: {
                                Authorization: `Bearer ${auth}`,
                                "Content-Type": "application/json",
                            },
                        }
                    );
                } else {
                    // Create new invoice
                    await axios.post(
                        `${process.env.REACT_APP_API_V1_BASE_URL}/sales_invoices`,
                        invoiceData,
                        {
                            headers: {
                                Authorization: `Bearer ${auth}`,
                                "Content-Type": "application/json",
                            },
                        }
                    );
                }
        
                // Show success message based on isPublished flag
                Swal.fire({
                    title: 'Success!',
                    text: isPublished ? 'Invoice added successfully.' : 'Invoice drafted successfully.',
                    icon: 'success',
                    confirmButtonText: 'OK',
                    customClass: {
                        popup: 'custom-swal-popup',  // Use your class for custom positioning
                        icon: 'custom-swal-icon',    // Use this class to style the icon
                    },
                });
        
                // Navigate back after success
                navigate.goBack();
        
            } catch (error) {
                // Handle errors
                Swal.fire({
                    title: 'Error!',
                    text: 'Failed to Save the Item.',
                    icon: 'error',
                    confirmButtonText: 'OK',
                    customClass: {
                        popup: 'custom-swal-popup',  // Use your class for custom positioning
                        icon: 'custom-swal-icon',    // Use this class to style the icon
                    },
                });
            }
        };

    useEffect(() => {
        const fetchDropdownData = async () => {
            try {
                const response = await axios.post(
                    `${process.env.REACT_APP_API_V1_BASE_URL}/get_dropdown_data`,
                    {
                        models: [
                            { model_name: "Customer" },
                            { model_name: "Account" },
                            { model_name: "Currency" },
                            { model_name: "Item" }
                        ]
                    },
                    {
                        headers: {
                            Authorization: `Bearer ${auth}`
                        }
                    }
                );
                // setAccountTypes(response.data.content.Account || []);
                setsales_invoices(response.data.content.Customer || []);
                setCurrency(response.data.content.Currency || []);
                // setItems(response.data.content.Item || []);
                setItems(response.data.content.Item || []);
                // setCurrency(response.data).content.currency || []);
            } catch (err) {
                console.log("Failed to load dropdown data");
                setError(" ");
            }
        };
        const fetchInvoiceData = async () => {
            if (id) {
                try {
                    const response = await axios.get(
                        `${process.env.REACT_APP_API_V1_BASE_URL}/sales_invoices/${id}`,
                        {
                            headers: {
                                Authorization: `Bearer ${auth}`
                            }
                        }
                    );

                    let invoiceData = JSON.parse(JSON.stringify(response.data));
                    setFormData(invoiceData);
                } catch (err) {
                    // Handle error
                }
            }
        };
        fetchInvoiceData();
        fetchDropdownData();
    }, [id, auth]);

    const handleChange = e => {
        const { name, value } = e.target;

        setFormData(prevFormData => {
            // Create a copy of the previous form data
            const updatedFormData = { ...prevFormData, [name]: value };

            // If the name is 'invoice_date', calculate and set the due date
            if (name === "invoice_date") {
                const invoiceDate = new Date(value);
                const dueDate = new Date(invoiceDate);
                dueDate.setDate(invoiceDate.getDate() + 30);

                // Update the invoice due date immediately
                updatedFormData.invoice_due_date = dueDate
                    .toISOString()
                    .slice(0, 16);
            }

            return updatedFormData;
        });
    };
    // console.log(formData.items);
    return (
        <>
        {/* <MainContainer> */}
            <div className={`main-wrapper`}>
                <div className="page-wrapper">
                    <div className="content container-fluid">
                        <div className="card mb-0">
                            <div className="card-body">
                                <div className="page-header">
                                    <div className="content-page-header">
                                        <h5>{id ? "Edit Invoice" : "Add Invoice"}</h5>
                                    </div>
                                </div>
                                <form onSubmit={handleSubmit}>
                                <div className="form-group-customer">
                                    <div className="row">
                                        <CustomDropDownFieldWithButton
                                            options={[
                                                // Add a blank option
                                                ...sales_invoices.map(c => ({
                                                    value: c.id,
                                                    label: c.name
                                                }))
                                            ]}
                                            labelText="Customer"
                                            name="customer_id"
                                            onChange={handleCustomerChange}
                                            value={formData.customer_id}
                                            errorMessage={errors.customer_id}
                                            required={true}
                                            path={handleCustomerNavigation}
                                        />
                                        

                                        <CustomDatePicker
                                            inputType="date"
                                            labelText="Invoice Date"
                                            name="invoice_date"
                                            value={formData.invoice_date} // A valid Date object
                                            onChange={handleChange}
                                            required={true}
                                        />
                                        <CustomDatePicker
                                            labelText="Invoice Due Date"
                                            name="invoice_due_date"
                                            value={formData.invoice_due_date} // A valid Date object (30 days ahead)
                                            onChange={handleChange}
                                        />
                                        {/* <CustomInputField
                                            inputType="text"
                                            labelText="Invoice Number"
                                            name="invoice_number"
                                            value={formData.invoice_number}
                                            onChange={handleChange}
                                            errorMessage={errors.invoice_number}
                                            required={true}
                                            placeholder="E.g. Inv-101"
                                        /> */}
                                        <div className="row">
                                            <div className="col-lg-6 my-3">
                                                <label className="input-block">
                                                    {" "}
                                                    Customer Notes
                                                </label>
                                                <CustomTextArea
                                                    rows={5}
                                                    name="customer_notes"
                                                    value={
                                                        formData.customer_notes
                                                    }
                                                    onChange={handleChange}
                                                    placeholder=" Add Customer Notes here ..."
                                                />
                                            </div>

                                            <div className="col-lg-6 my-3">
                                                <label className="input-block">
                                                    {" "}
                                                    Description
                                                </label>
                                                <CustomTextArea
                                                    rows={5}
                                                    name="description"
                                                    value={formData.description}
                                                    onChange={handleChange}
                                                    placeholder="Mention Description ..."
                                                    errorMessage={
                                                        errors.description
                                                    }
                                                    required={true}
                                                />
                                            </div>

                                            {error && (
                                                <p className="text-danger">
                                                    {error}
                                                </p>
                                            )}
                                        </div>
                                        <div className="d-flex justify-content-end py-2 px-3 mt-2">
                                            <button
                                                type="button"
                                                className="btn btn-primary"
                                                onClick={addItem}
                                            >
                                                <i
                                                    className="fa fa-plus-circle me-2"
                                                    aria-hidden="true"
                                                ></i>
                                                Add Item
                                            </button>
                                        </div>
                                        <div className="form-group-item">
                                            <div className="card-table">
                                                <div className="card-body">
                                                    <div className="table-responsive no-pagination px-3">
                                                        <table className="table table-center table-hover datatable custom-table">
                                                            <thead className="thead-light">
                                                                <tr className="text-center">
                                                                    <th>
                                                                        Item
                                                                    </th>
                                                                    <th>
                                                                        Unit
                                                                    </th>
                                                                    <th>
                                                                        Rate
                                                                    </th>
                                                                    <th>
                                                                        Discount
                                                                        Type
                                                                    </th>
                                                                    <th>
                                                                        Discount
                                                                    </th>
                                                                    <th>
                                                                        Amount
                                                                    </th>
                                                                    <th className="no-sort">
                                                                        Action
                                                                    </th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {formData.items.map(
                                                                    (
                                                                        item,
                                                                        index
                                                                    ) => (
                                                                        <tr
                                                                            key={
                                                                                index
                                                                            }
                                                                        >
                                                                            {/* Dropdown for Item selection */}
                                                                            <td className="w-20 px-2">
                                                                                {/* <select
                                                                                    placeholder="Select Item"
                                                                                    className="form-control"
                                                                                    name="item_id"
                                                                                    value={
                                                                                        item.item_id
                                                                                    }
                                                                                    onChange={e =>
                                                                                        handleItemChange(
                                                                                            index,
                                                                                            e
                                                                                        )
                                                                                    }
                                                                                    required
                                                                                >
                                                                                    <option value="">
                                                                                        Select
                                                                                        Item
                                                                                    </option>
                                                                                    {items.map(
                                                                                        itemOption => (
                                                                                            <option
                                                                                                key={
                                                                                                    itemOption.id
                                                                                                }
                                                                                                value={
                                                                                                    itemOption.id
                                                                                                }
                                                                                            >
                                                                                                {
                                                                                                    itemOption.name
                                                                                                }
                                                                                            </option>
                                                                                        )
                                                                                    )}
                                                                                </select> */}

                                                                                {/* <CustomDropDownField
                                                                                    name="item_id"
                                                                                    id={`item_id_${index}`} // Unique ID for accessibility
                                                                                    labelText="Item" // Label for the dropdown
                                                                                    dropDownSize="col-lg-12" // Adjust if needed for your layout
                                                                                    options={items.map(
                                                                                        itemOption => ({
                                                                                            value:
                                                                                                itemOption.id,
                                                                                            label:
                                                                                                itemOption.name
                                                                                        })
                                                                                    )} // Map your items array to match the expected format
                                                                                    value={
                                                                                        item.item_id
                                                                                    } // Pass the selected item's value
                                                                                    onChange={e =>
                                                                                        handleItemChange(
                                                                                            index,
                                                                                            e
                                                                                        )
                                                                                    } // Handle change event
                                                                                    required={
                                                                                        true
                                                                                    } // Make it required
                                                                                    disabled={
                                                                                        false
                                                                                    } // Adjust if you need to disable it
                                                                                /> */}

                                                                                {/* <Select
                                                                                    placeholder="Select Item"
                                                                                    menuPortalTarget={
                                                                                        document.body
                                                                                    }
                                                                                    styles={{
                                                                                        menuPortal: base => ({
                                                                                            ...base,
                                                                                            zIndex: 9999
                                                                                        }) // Ensure it's on top
                                                                                    }}
                                                                                    classNamePrefix="custom-select" // Use a unique prefix
                                                                                    name="item_id"
                                                                                    value={items.find(
                                                                                        option =>
                                                                                            option.id ===
                                                                                            item.item_id
                                                                                    )}
                                                                                    onChange={selectedOption =>
                                                                                        handleItemChange(
                                                                                            index,
                                                                                            {
                                                                                                target: {
                                                                                                    name:
                                                                                                        "item_id",
                                                                                                    value:
                                                                                                        selectedOption?.value
                                                                                                }
                                                                                            }
                                                                                        )
                                                                                    }
                                                                                    options={items.map(
                                                                                        itemOption => ({
                                                                                            value:
                                                                                                itemOption.id,
                                                                                            label:
                                                                                                itemOption.name
                                                                                        })
                                                                                    )}
                                                                                    isClearable
                                                                                    isSearchable
                                                                                /> */}
                                                                                    <Select
                                                                                        placeholder="Select Item"
                                                                                        menuPortalTarget={
                                                                                            document.body
                                                                                        }
                                                                                        styles={{
                                                                                            menuPortal: (base) => ({
                                                                                                ...base,
                                                                                                zIndex: 9999,
                                                                                            }),
                                                                                            option: (provided, state) => ({
                                                                                                ...provided,
                                                                                                color: state.data.value === 'new' ? 'blue' : 'inherit',
                                                                                                textDecoration: state.data.value === 'new' ? 'underline' : 'none',
                                                                                                cursor: state.data.value === 'new' ? 'pointer' : 'default',
                                                                                            }),
                                                                                        }}
                                                                                        classNamePrefix="custom-select"
                                                                                        name="item_id"
                                                                                        value={
                                                                                            items.find((option) => option.id === item.item_id)
                                                                                                ? {
                                                                                                      value: item.item_id,
                                                                                                      label: items.find((option) => option.id === item.item_id)?.name,
                                                                                                  }
                                                                                                : null 
                                                                                        }
                                                                                        onChange={(selectedOption) => {
                                                                                            if (selectedOption?.value === 'new') {
                                                                                                handleItemNavigation();
                                                                                            } else {
                                                                                                handleItemChange(index, {
                                                                                                    target: {
                                                                                                        name: "item_id",
                                                                                                        value: selectedOption?.value,
                                                                                                    },
                                                                                                });
                                                                                            }
                                                                                        }}
                                                                                        options={[
                                                                                            { value: 'new', label: 'Add New Item', isAddNew: true },
                                                                                            ...items.map((itemOption) => ({
                                                                                                value: itemOption.id,
                                                                                                label: itemOption.name,
                                                                                            })),
                                                                                        ]}
                                                                                        isClearable
                                                                                        isSearchable
                                                                                    />
                                                                            </td>

                                                                            {/* Input for Quantity */}
                                                                            <td className="px-2">
                                                                                <input
                                                                                    type="number"
                                                                                    className="form-control"
                                                                                    name="quantity"
                                                                                    step="0.01"
                                                                                    value={
                                                                                        item.quantity ||
                                                                                        0
                                                                                    }
                                                                                    onChange={e =>
                                                                                        handleItemChange(
                                                                                            index,
                                                                                            e
                                                                                        )
                                                                                    }
                                                                                    required
                                                                                />
                                                                            </td>

                                                                            {/* Input for Price */}
                                                                            <td className="no-controls px-2">
                                                                                <input
                                                                                    required
                                                                                    type="number"
                                                                                    className="form-control"
                                                                                    name="price"
                                                                                    step="0.01"
                                                                                    value={
                                                                                        item.price
                                                                                    }
                                                                                    onChange={e =>
                                                                                        handleItemChange(
                                                                                            index,
                                                                                            e
                                                                                        )
                                                                                    }
                                                                                />
                                                                            </td>

                                                                            {/* Dropdown for Discount Type */}
                                                                            <td className="px-2">
                                                                                <select
                                                                                    className="form-control"
                                                                                    name="discount_type"
                                                                                    value={
                                                                                        item.discount_type
                                                                                    }
                                                                                    onChange={e =>
                                                                                        handleItemChange(
                                                                                            index,
                                                                                            e
                                                                                        )
                                                                                    }
                                                                                >
                                                                                    <option value="percentage">
                                                                                        %
                                                                                    </option>
                                                                                    <option value="amount">
                                                                                        $
                                                                                    </option>
                                                                                </select>
                                                                            </td>

                                                                            {/* Input for Discount Value */}
                                                                            <td className="px-2">
                                                                                <input
                                                                                    type="number"
                                                                                    className="form-control"
                                                                                    name="discount_val"
                                                                                    value={
                                                                                        item.discount_val
                                                                                    }
                                                                                    onChange={e =>
                                                                                        handleItemChange(
                                                                                            index,
                                                                                            e
                                                                                        )
                                                                                    }
                                                                                />
                                                                            </td>

                                                                            {/* Amount */}
                                                                            <td className="px-2 text-center">
                                                                                <p>
                                                                                    {calculateItemTotal(
                                                                                        item
                                                                                    ).toFixed(formData.decimal_point
                                                                                    )}
                                                                                </p>
                                                                            </td>

                                                                            {/* Remove Item Button */}
                                                                            <td>
                                                                                <div className="d-flex align-items-center justify-content-evenly">
                                                                                    <button
                                                                                        type="button"
                                                                                        className="table_action_button"
                                                                                        onClick={() =>
                                                                                            removeItem(
                                                                                                index
                                                                                            )
                                                                                        }
                                                                                    >
                                                                                        <i className="fe fe-trash-2" />
                                                                                    </button>
                                                                                </div>
                                                                            </td>
                                                                        </tr>
                                                                    )
                                                                )}

                                                                {/* Total Calculation Row */}
                                                                <tr>
                                                                    <td
                                                                        colSpan="5"
                                                                        className="text-end fw-bold"
                                                                    >
                                                                        Total
                                                                    </td>
                                                                    <td
                                                                        colSpan="2"
                                                                        className="fw-bold"
                                                                    >
                                                                        {formData.currency_symbol} {/* Dynamic currency symbol */}
                                                                        {calculateTotal().toFixed(formData.decimal_point)} {/* Dynamic decimal point */}
    
                                                                        {/* $
                                                                        {calculateTotal().toFixed(
                                                                            2
                                                                        )} */}
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <CustomInputField
                                                labelText="Order No"
                                                inputType="number"
                                                name="order_number"
                                                value={formData.order_number}
                                                onChange={handleChange}
                                                placeholder="E.g 112"
                                                inputWrapper="col-lg-3"
                                                errorMessage={errors.order_number}
                                                required={false}
                                            />

                                            <div className="col-lg-4">
                                                <CustomDropDownField
                                                    labelText="Status"
                                                    options={[
                                                        {
                                                            value: "pending",
                                                            label: "Pending"
                                                        },
                                                        {
                                                            value: "paid",
                                                            label: "paid"
                                                        },
                                                        {
                                                            value: "cancelled",
                                                            label: "cancelled"
                                                        }
                                                    ]}
                                                    name="status"
                                                    value={formData.status}
                                                    onChange={handleChange}
                                                    dropDownSize="col-lg-12"
                                                    errorMessage={errors.status}
                                                    required={true}
                                                />
                                            </div>
                                            <div className="col-lg-12 input-block mb-3">
                                                <label>
                                                    {" "}
                                                    Terms & conditions
                                                </label>
                                                <CustomTextArea
                                                    rows={5}
                                                    name="terms_conditions"
                                                    value={
                                                        formData.terms_conditions
                                                    }
                                                    onChange={handleChange}
                                                    placeholder="Terms & Conditions"
                                                />
                                            </div>
                                            <div className="col-lg-4">
                                                <span className=" mt-2 d-flex align-items-center">
                                                    <h5 className="">
                                                        Total Amount :
                                                    </h5>
                                                    <p className="px-3">
                                                        $
                                                        {calculateTotal().toFixed(
                                                            2
                                                        )}
                                                    </p>
                                                </span>
                                            </div>
                                            <div className="col-lg-12"></div>
                                        </div>
                                    </div>
                                </div>
                                    <div className="text-end pt-3">
                                        <button
                                            type="reset"
                                            className="btn btn-primary cancel me-2"
                                            onClick={handleCancel}
                                        >
                                            Cancel
                                        </button>
                                        <button
                                            type="reset"
                                            className="btn btn-primary me-2"
                                            onClick={handleSaveAsDraft}
                                        >
                                            Save as Draft
                                        </button>
                                        <button
                                            type="submit"
                                            className="btn btn-primary"
                                        >
                                            Save
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        {/* </MainContainer> */}
        {showItem && (
            // <ModalOnPopup modal={<AddItems hideLayout={true} closeItemModal={saveItemModal}/>} 
            // isOpen={showItem} onClose={() => setShowItem(false)}
            // />
            <ModalOnPopup modalId="add_item"
            title="Add Item"
            isOpen={showItem}
            onClose={() => setShowItem(false)} 
            modal={<AddItems hideLayout={true} closeItemModal={saveItemModal}/>}
            />

        )}
        {showCustomer && (
            <ModalOnPopup modalId="add_customer"
            title="Add Customer"
            isOpen={showCustomer}
            onClose={() => setShowCustomer(false)} 
            modal={<AddCustomerForm hideLayout={true} closeCustomerModal={saveCustomerModal}/>}
            />
            // <ModalOnPopup modal={<AddCustomerForm hideLayout={true} closeCustomerModal={saveCustomerModal}/>} 
            // isOpen={showCustomer} onClose={() => setShowCustomer(false)}
            // />
        )}
        </>
    );
};

AddInvoice.propTypes = {
    auth: PropTypes.string
};

export default AddInvoice;
