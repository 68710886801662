import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { imgdrop } from "../_components/Imagepath";
import SettingsSidebar from "../layouts/SettingsSidebar";
import axios from "axios";
import { useAuth } from "../contexts/AuthContext";
import Swal from 'sweetalert2';

const InvoiceSetting = () => {
  const [invoicePrefix, setInvoicePrefix] = useState("");
  const [digitalSignatureName, setDigitalSignatureName] = useState("");
  const [invoiceLogo, setInvoiceLogo] = useState(null);
  const [digitalSignatureImage, setDigitalSignatureImage] = useState(null);
  const [invoiceLogoUrl, setInvoiceLogoUrl] = useState("");
  const [digitalSignatureImageUrl, setDigitalSignatureImageUrl] = useState("");
  const { auth } = useAuth();

  useEffect(() => {
    const fetchSettings = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_V1_BASE_URL}/general-settings?group=invoice`,
          {
            headers: {
              Authorization: `Bearer ${auth}`,
            },
          }
        );

        const data = response.data.settings;
        setInvoicePrefix(data.invoice_prefix || "");
        setDigitalSignatureName(data.invoice_digital_sign_name || "");
        setInvoiceLogoUrl(data.invoice_logo ? `${data.invoice_logo}` : "");
        setDigitalSignatureImageUrl(data.invoice_digital_sign_image ? `${process.env.REACT_APP_API_V1_BASE_URL}/${data.invoice_digital_sign_image}` : "");
      } catch (error) {
        console.error("Error fetching settings:", error);
      }
    };

    fetchSettings();
  }, [auth]);

  const handleSubmit = async () => {
    try {
      const formData = new FormData();
      formData.append("group", "invoice");
      formData.append("invoice_prefix", invoicePrefix);
      formData.append("invoice_digital_sign_name", digitalSignatureName);
      if (invoiceLogo) formData.append("invoice_logo", invoiceLogo);
      if (digitalSignatureImage) formData.append("invoice_digital_sign_image", digitalSignatureImage);

      const response = await axios.post(
        `${process.env.REACT_APP_API_V1_BASE_URL}/general-settings`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${auth}`,
          },
        }
      );
console.log(response);
      Swal.fire({
        title: 'Success!',
        text: 'Invoice settings updated successfully.',
        icon: 'success',
        confirmButtonText: 'OK'
      });

    } catch (error) {
      Swal.fire({
        title: 'Error!',
        text: 'Failed to save the Invoice settings.',
        icon: 'error',
        confirmButtonText: 'OK'
      });
      console.error("Error updating settings:", error);
    }
  };

  const handleLogoChange = (event) => {
    setInvoiceLogo(event.target.files[0]);
    setInvoiceLogoUrl(URL.createObjectURL(event.target.files[0]));
  };

  const handleSignatureImageChange = (event) => {
    setDigitalSignatureImage(event.target.files[0]);
    setDigitalSignatureImageUrl(URL.createObjectURL(event.target.files[0]));
  };

  return (
    <>
      <div className="page-wrapper">
        <div className="content container-fluid">
          <div className="row">
            <div className="col-xl-3 col-md-4">
              <SettingsSidebar />
            </div>
            <div className="col-xl-9 col-md-8">
              <div className="card">
                <div className="card-body w-100">
                  <div className="content-page-header">
                    <h5>Invoice Settings</h5>
                  </div>
                  <div className="row">
                    <div className="col-lg-6 col-12">
                      <div className="input-block mb-3">
                        <label>Invoice Prefix</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter Invoice Prefix"
                          value={invoicePrefix}
                          onChange={(e) => setInvoicePrefix(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="col-lg-6 col-12">
                      <div className="input-block mb-3">
                        <label>Digital Signature Name</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter Digital Signature Name"
                          value={digitalSignatureName}
                          onChange={(e) => setDigitalSignatureName(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="col-xl-6 col-lg-6 col-md-6 col-12">
                      <div className="input-block mb-3">
                        <label>Invoice Logo</label>
                        <div className="input-block service-upload logo-upload mb-0">
                          <span>
                            <img src={invoiceLogoUrl || imgdrop} alt="Invoice Logo" />
                          </span>
                          <div className="drag-drop">
                            <h6 className="drop-browse align-center">
                              <span className="text-info me-1">
                                Click to Replace{" "}
                              </span>{" "}
                              or Drag and Drop
                            </h6>
                            <p className="text-muted">SVG, PNG, JPG</p>
                            <input
                              type="file"
                              id="invoice_logo"
                              onChange={handleLogoChange}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-6 col-lg-6 col-md-6 col-12">
                      <div className="input-block mb-3">
                        <label>Digital Signature Image</label>
                        <div className="input-block service-upload logo-upload mb-0">
                          <span>
                            <img src={digitalSignatureImageUrl || imgdrop} alt="Digital Signature" />
                          </span>
                          <div className="drag-drop">
                            <h6 className="drop-browse align-center">
                              <span className="text-info me-1">
                                Click to Replace{" "}
                              </span>{" "}
                              or Drag and Drop
                            </h6>
                            <p className="text-muted">SVG, PNG, JPG</p>
                            <input
                              type="file"
                              id="digital_signature_image"
                              onChange={handleSignatureImageChange}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-12">
                      <div className="btn-path text-end">
                        <Link
                          to="to"
                          className="btn btn-cancel bg-primary-light me-3"
                        >
                          Cancel
                        </Link>
                        <button
                          onClick={handleSubmit}
                          className="btn btn-primary"
                        >
                          Save Changes
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default InvoiceSetting;
