import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
    CustomDropDownField, CustomInputField,
    CustomPhoneField,
    CustomTextArea
} from "../CustomComponents/ReusableComponents";
import { useAuth } from '../contexts/AuthContext';
import { useHistory, useParams } from "react-router-dom";

const AddUserComponent = () => {
    const { auth } = useAuth();
    const history = useHistory();
    const { id } = useParams();

    // const [countries, setCountries] = useState([]);
    const [states, setStates] = useState([]); 
    const [roles, setRoles] = useState([]);
    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(true);
const [error, setError] = useState("");
    const [formData, setFormData] = useState({
        role_id: '',
        is_active: '1',
        name: '',
        first_name: "",
        last_name: "",
        secondary_phone: "",
        address_line1: "",
        address_line_2: "",
        phone: null,
        city: "",
        state: "",
        postal_code: "",
        email: "",
        country_code: "",
        contact_type: "",
        notes: " ",
        tax_id: " ",
        social_security_number: " "
    })
    const handleBlur = e => {
        const { name, value } = e.target;

        if (name === "email") {
            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            if (!emailRegex.test(value) && value !== "") {
                setErrors(prevErrors => ({
                    ...prevErrors,
                    email: "Invalid email format."
                }));
            } else {
                setErrors(prevErrors => ({
                    ...prevErrors,
                    email: "" // Clear error if valid
                }));
            }
        }
    };

    const handleChange = e => {
        const { name, value } = e.target;
        setFormData(prevFormData => ({
            ...prevFormData,
            [name]: value
        }));
    };
    const handleCancel = () => {
        history.push('/users');
    };

    useEffect(() => {
        const fetchDropdownData = async () => {
            try {
                const response = await axios.post(
                    `${process.env.REACT_APP_API_V1_BASE_URL}/get_dropdown_data`,
                    {
                        models: [
                            { model_name: "Role" },
                            { model_name: "Country" },
                            { model_name: "State" }
                        ]
                    },
                    {
                        headers: {
                            Authorization: `Bearer ${auth}`
                        }
                    }
                );
                setRoles(response.data.content.Role	 || []);
                // const { Country } = response.data.content;
                // setCountries(Country || []);

                const { State } = response.data.content;
                setStates(State || []);
            } catch (err) {
                setError("Failed to load dropdown data");
            }
        };

        const fetchUserData = async () => {
            if (id) {
                try {
                    const response = await axios.get(
                        `${process.env.REACT_APP_API_V1_BASE_URL}/users/${id}`,
                        {
                            headers: {
                                Authorization: `Bearer ${auth}`
                            }
                        }
                    );
                    setFormData(response.data);
                } catch (err) {
                    // Handle error
                }
            }
        };
        setLoading(false);
        fetchDropdownData();
        fetchUserData();
    }, [auth, id]);
    const handleSubmit = async e => {
        e.preventDefault();
        try {
            const response = id
                ? await axios.put(
                    `${process.env.REACT_APP_API_V1_BASE_URL}/users/${id}`,
                    formData,
                    {
                        headers: {
                            Authorization: `Bearer ${auth}`,
                            "Content-Type": "application/json"
                        }
                    }
                )
                : await axios.post(
                    `${process.env.REACT_APP_API_V1_BASE_URL}/users`,
                    formData,
                    {
                        headers: {
                            Authorization: `Bearer ${auth}`,
                            "Content-Type": "application/json"
                        }
                    }
                );

            console.log("User saved successfully", response.data);
            history.push('/users');
        } catch (err) {
            console.error("Failed to save User", err);
            // Handle error
        }
    };

    return(
        <>
            <div className={`main-wrapper`}>
                <div className="page-wrapper">
                    <div className="content container-fluid">
                        <div className="card mb-0">
                            <div className="card-body">
                                <div className="page-header">
                                    <div className="content-page-header">
                                    <h5>{id ? 'Edit User' : 'Add User'}</h5>
                                    </div>
                                </div>

                                <form onSubmit={handleSubmit}>
                                    <div className="row">
                                        <CustomDropDownField
                                                options={roles.map(c => ({
                                                    value: c.id,
                                                    label: c.name
                                                }))}
                                                labelText="Role Type"
                                                name="role_id"
                                                value={formData.role_id}
                                                onChange={handleChange}
                                            />
                                    </div>

                                    <div className="row">
                                        <CustomInputField
                                            inputType="text"
                                            labelText="User Name"
                                            name="name"
                                            value={formData.name}
                                            onChange={handleChange}
                                        />
                                        <CustomInputField
                                            inputType="text"
                                            labelText="Email "
                                            name="email"
                                            value={formData.email}
                                            onChange={handleChange}
                                        />
                                    </div>

                                    <div className="row">
                                        <CustomInputField
                                            labelText="First Name"
                                            name="first_name"
                                            placeholder="E.g. John"
                                            value={formData.first_name}
                                            onChange={handleChange}
                                            errorMessage={errors.first_name}
                                            required={true}
                                        />

                                        <CustomInputField
                                            labelText="Last Name"
                                            name="last_name"
                                            placeholder="E.g. Doe"
                                            value={formData.last_name}
                                            onChange={handleChange}
                                            errorMessage={errors.last_name}
                                            required={true}
                                        />

                                        <CustomInputField
                                            inputType="email"
                                            labelText="Email"
                                            placeholder="E.g. johndoe@example.com"
                                            name="email"
                                            value={formData.email}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            errorMessage={errors.email}
                                            required={true}
                                        />

                                        {/* <CustomInputField
                            inputType="number"
                            labelText="Phone Number"
                            placeholder="E.g. 1234567890"
                            name="phone"
                            value={formData.phone}
                            onChange={handleChange}
                            errorMessage={errors.phone}
                            required={true}
                        /> */}
                                        {/* <CustomPhoneField
                        name="phone"
                        value={formData.phone}
                        onChange={handleChange}
                       /> */}

                                        <CustomPhoneField
                                            name="phone"
                                            value={formData.phone}
                                            onChange={handleChange}
                                            labelText="Phone Number"
                                            placeholder="E.g 987-565-8967"
                                            required={true}
                                            errorMessage={errors.phone}
                                        />

                                        <CustomPhoneField
                                            name="secondary_phone"
                                            value={formData.secondary_phone}
                                            onChange={handleChange}
                                            labelText="Secondary phone"
                                            placeholder="E.g 987-565-8967"
                                            required={true}
                                            errorMessage={errors.phone}
                                        />

                                        {/* <CustomInputField
                            inputType="number"
                            labelText="Secondary phone"
                            placeholder="E.g. 1234567890"
                            name="secondary_phone"
                            value={formData.secondary_phone}
                            onChange={handleChange}
                        /> */}

                                        {/* <CustomDropDownField
                                            options={countries.map(b => ({
                                                value: b.country_code_2,
                                                label: b.name
                                            }))}
                                            labelText="Country"
                                            name="country_code_2"
                                            value={formData.country_code}
                                            onChange={handleChange}
                                            required={true}
                                            // errorMessage={errors.country_code}
                                            disabled={!!id} // Disable if id is present
                                        /> */}

                                        <CustomInputField
                                            labelText="Address Line 1"
                                            placeholder="E.g. 123 Main St"
                                            name="address_line1"
                                            value={formData.address_line1}
                                            onChange={handleChange}
                                            inputWrapper="col-lg-4"
                                            errorMessage={errors.address_line1}
                                            required={true}
                                        />
                                        <CustomInputField
                                            labelText="Address Line 2"
                                            placeholder="E.g. Apt 4B"
                                            name="address_line_2"
                                            value={formData.address_line_2}
                                            onChange={handleChange}
                                            inputWrapper="col-lg-4"
                                        />

                                        <CustomInputField
                                            labelText="Payment Terms"
                                            name="contact_type"
                                            value={formData.contact_type}
                                            onChange={handleChange}
                                        />

                                        <CustomDropDownField
                                            dropDownSize="col-lg-4"
                                            options={states.map(b => ({
                                                value: b.state_abr,
                                                label: b.name
                                            }))}
                                            labelText="State"
                                            name="state" // ensure this matches the formData key
                                            value={formData.state} // Use the correct value for country
                                            onChange={handleChange}
                                        />

                                        <CustomInputField
                                            labelText="City"
                                            name="city"
                                            placeholder="E.g. Chicago"
                                            value={formData.city}
                                            onChange={handleChange}
                                            inputWrapper="col-lg-4"
                                        />

                                        <CustomInputField
                                            labelText="Zip Code"
                                            name="postal_code"
                                            placeholder="E.g.90210"
                                            value={formData.postal_code}
                                            onChange={handleChange}
                                            inputWrapper="col-lg-4"
                                        />

                                        <div className="col-lg-12">
                                            <CustomTextArea
                                                labelText="Note"
                                                name="notes"
                                                value={formData.notes}
                                                onChange={handleChange}
                                            />
                                        </div>
                                        {loading && <p>Loading...</p>}
                                        {error && (
                                            <p className="text-danger mt-2">
                                                {error}
                                            </p>
                                        )}
                                        <div className="row">
                                            <div className="col-lg-6 col-md-6 col-sm-12 my-4">
                                                <div className="add-contacts-btns">
                                                    <button
                                                        type="submit"
                                                        className="btn btn-primary me-2"
                                                        disabled={loading}
                                                    >
                                                        {loading
                                                            ? "Saving..."
                                                            : "Save"}
                                                    </button>

                                                    <button
                                                        type="reset"
                                                        className="btn btn-primary cancel me-2"
                                                        onClick={handleCancel}
                                                    >
                                                        Cancel
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
{loading && <p>Loading...</p>}

                                    <div className="text-end">
                                        <button
                                            type="reset"
                                            className="btn btn-primary cancel me-2"
                                            onClick={handleCancel}
                                        >
                                            Cancel
                                        </button>
                                        <button
                                            type="submit"
                                            className="btn btn-primary"
                                        >
                                            Save
                                        </button>
                                    </div>

                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default AddUserComponent;