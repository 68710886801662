import React, { useState, useEffect } from "react";

import {
    CustomDropDownField,
    CustomInputField,
    CustomPhoneField,
    CustomTextArea
} from "./ReusableComponents";
import axios from "axios";
import PropTypes from "prop-types";
import { useParams, useHistory } from "react-router-dom";
import { useAuth } from "../contexts/AuthContext";
import Swal from "sweetalert2";

const AddContacts = () => {
    const {id} = useParams();

    useEffect(() => {
        document.title = `Add contacts`;
    }, []);

    const { auth } = useAuth();
    const API_BASE_URL = process.env.REACT_APP_API_V1_BASE_URL;

    // const { id } = useParams();
    const navigate = useHistory();
    const handleCancel = () => {
        navigate.push("/contacts"); // Replace with the actual path to your Contacts list page
    };

    const [formData, setFormData] = useState({
        first_name: "",
        last_name: "",
        secondary_phone: "",
        address_line1: "",
        address_line_2: "",
        phone: null,
        city: "",
        state: "",
        postal_code: "",
        email: "",
        country_code: "",
        contact_type: "",
        notes: " ",
        tax_id: " ",
        social_security_number: " "
    });

    const [countries, setCountries] = useState([]);

    const [states, setStates] = useState([]); // State for currencies
    const [loading, setLoading] = useState(true);
    const [errors, setErrors] = useState({});
    const [error, setError] = useState("");
    useEffect(() => {
        const fetchDropdownData = async () => {
            try {
                const dropdownRequestData = {
                    models: [
                        { model_name: "Currency" },
                        { model_name: "Country" },
                        { model_name: "State" }
                    ]
                }; // Define your dropdown request data as needed
                const apiReqHeaders = {
                    headers: {
                        Authorization: `Bearer ${auth}`,
                        "Content-Type": "application/json"
                    }
                };

                const response = await axios.post(
                    `${API_BASE_URL}/get_dropdown_data`,
                    dropdownRequestData,
                    apiReqHeaders
                );

                const { Country } = response.data.content;
                setCountries(Country || []);

                const { State } = response.data.content;
                setStates(State || []);
            } catch (error) {
                setError("Error fetching dropdown data");
            }
        };

        const fetchContactsData = async () => {
            if (id) {
                try {
                    const apiReqHeaders = {
                        headers: {
                            Authorization: `Bearer ${auth}`,
                            "Content-Type": "application/json"
                        }
                    };

                    const response = await axios.get(
                        `${API_BASE_URL}/contacts/${id}`,
                        apiReqHeaders
                    );
                    let contactsData = JSON.parse(JSON.stringify(response.data));
                    // setFormData({
                    //     ...formData,
                    //     ...response.data
                    // });
                    setFormData(contactsData);
                } catch (error) {
                    setError("Error fetching contacts data");
                    console.error("Error fetching contacts data:", error);
                }
            }
        };

        fetchDropdownData();
        fetchContactsData();
        setLoading(false);
    }, [API_BASE_URL, auth, id]);

    const handleBlur = e => {
        const { name, value } = e.target;

        if (name === "email") {
            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            if (!emailRegex.test(value) && value !== "") {
                setErrors(prevErrors => ({
                    ...prevErrors,
                    email: "Invalid email format."
                }));
            } else {
                setErrors(prevErrors => ({
                    ...prevErrors,
                    email: "" // Clear error if valid
                }));
            }
        }
    };

    const handleChange = e => {
        const { name, value, type, checked } = e.target;

        if (name === "phone" || name === "secondary_phone") {
            const numericValue = value.replace(/\D/g, ""); // Only allow digits
            if (numericValue.length <= 10) {
                setFormData(prevFormData => ({
                    ...prevFormData,
                    [name]: numericValue
                }));
            }
        } else if (name === "email") {
            setFormData(prevFormData => ({
                ...prevFormData,
                [name]: value
            }));

            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            if (!emailRegex.test(value) && value !== "") {
                setErrors(prevErrors => ({
                    ...prevErrors,
                    email: "Invalid email format."
                }));
            } else {
                setErrors(prevErrors => ({
                    ...prevErrors,
                    email: ""
                }));
            }
        } else if (name === "postal_code") {
            const numericZip = value.replace(/\D/g, "");
            if (numericZip.length <= 5) {
                setFormData(prevFormData => ({
                    ...prevFormData,
                    [name]: numericZip
                }));
            }
        } else if (type === "checkbox") {
            setFormData(prevFormData => ({
                ...prevFormData,
                [name]: checked
            }));
        } else {
            setFormData(prevFormData => ({
                ...prevFormData,
                [name]: value
            }));
        }
    };

    

    const validateEmailExistence = async email => {
        try {
            // Replace with your API call to check email existence
            const response = await axios.post(
                `${API_BASE_URL}/contacts`,
                email
            );
            return response.data.exists; // Assuming the API returns { exists: true/false }
        } catch (error) {
            console.error("Error checking email existence:", error);
            return false; // Assume email doesn't exist if API fails (or handle differently)
        }
    };

    const validateForm = async () => {
        const validationErrors = {};

        // Basic validations
        if (!formData.first_name)
            validationErrors.first_name = "First name is required.";
        if (!formData.last_name)
            validationErrors.last_name = "Last name is required.";
        if (!formData.email) validationErrors.email = "Email is required.";
        else {
            // Regex for basic email format validation
            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            if (!emailRegex.test(formData.email)) {
                validationErrors.email = "Invalid email format.";
            } else {
                // Asynchronous validation to check email existence
                const emailExists = await validateEmailExistence(
                    formData.email
                );
                if (emailExists) {
                    validationErrors.email = "Email already exists.";
                }
            }
        }

        if (!formData.phone) validationErrors.phone = "Phone is required.";
        if (!formData.address_line1)
            validationErrors.address_line1 = "Address is required.";

        setErrors(validationErrors);

        if (Object.keys(validationErrors).length > 0) {
            const firstErrorField = Object.keys(validationErrors)[0];
            const errorElement = document.querySelector(
                `[name="${firstErrorField}"]`
            );
            if (errorElement) {
                errorElement.scrollIntoView({
                    behavior: "smooth",
                    block: "center"
                });
                errorElement.focus();
            }
            return false; // Form is not valid
        }
        return Object.keys(validationErrors).length === 0; // Returns true if no errors
    };

    const handleSubmit = async e => {
        e.preventDefault();
        if (!validateForm()) {
            return; // Stop submission if validation fails
        }
        try {
            const ContactsData = { ...formData };
            const apiReqHeaders = {
                headers: {
                    Authorization: `Bearer ${auth}`,
                    "Content-Type": "application/json"
                }
            };

            if (id) {
                await axios.put(
                    `${API_BASE_URL}/contacts/${id}`,
                    ContactsData,
                    apiReqHeaders
                );
                Swal.fire({
                    title: "Success!",
                    text: "Contact updated successfully.",
                    icon: "success",
                    confirmButtonText: "OK",
                    customClass: {
                        popup: 'custom-swal-popup',  // Use your class for custom positioning
                        icon: 'custom-swal-icon',    // Use this class to style the icon
                      }
                });
            } else {
                await axios.post(
                    `${API_BASE_URL}/contacts`,
                    ContactsData,
                    apiReqHeaders
                );
                Swal.fire({
                    title: "Success!",
                    text: "Contact created successfully.",
                    icon: "success",
                    confirmButtonText: "OK",
                    customClass: {
                        popup: 'custom-swal-popup',  // Use your class for custom positioning
                        icon: 'custom-swal-icon',    // Use this class to style the icon
                      }
                });
            }

            navigate.push("/contacts");
        } catch (error) {
            if (
                error.response &&
                error.response.data &&
                error.response.data.email
            ) {
                setErrors(prevErrors => ({
                    ...prevErrors,
                    email: error.response.data.email[0] // Set the email-specific error message
                }));
            } else {
                Swal.fire({
                    title: "Error!",
                    text: "Failed to save the contact.",
                    icon: "error",
                    confirmButtonText: "OK",
                    customClass: {
                        popup: 'custom-swal-popup',  // Use your class for custom positioning
                        icon: 'custom-swal-icon',    // Use this class to style the icon
                      }
                });
                setError("Failed to save contact.");
            }
        }
    };

    return (
        <>
                <div className="page-wrapper">
                    <div className="content container-fluid">
                        <div className="card mb-0">
                            <div className="card-body">
                                <div className="content-page-header">
                                    <h5>
                                        {id ? "Edit Contacts" : "Add Contacts"}
                                    </h5>
                                </div>

                                <form onSubmit={handleSubmit}>
                                    <div className="row">
                                        <CustomInputField
                                            labelText="First Name"
                                            name="first_name"
                                            placeholder="E.g. John"
                                            value={formData.first_name}
                                            onChange={handleChange}
                                            errorMessage={errors.first_name}
                                            required={true}
                                        />

                                        <CustomInputField
                                            labelText="Last Name"
                                            name="last_name"
                                            placeholder="E.g. Doe"
                                            value={formData.last_name}
                                            onChange={handleChange}
                                            errorMessage={errors.last_name}
                                            required={true}
                                        />

                                        <CustomInputField
                                            inputType="email"
                                            labelText="Email"
                                            placeholder="E.g. johndoe@example.com"
                                            name="email"
                                            value={formData.email}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            errorMessage={errors.email}
                                            required={true}
                                        />

                                        {/* <CustomInputField
                            inputType="number"
                            labelText="Phone Number"
                            placeholder="E.g. 1234567890"
                            name="phone"
                            value={formData.phone}
                            onChange={handleChange}
                            errorMessage={errors.phone}
                            required={true}
                        /> */}
                                        {/* <CustomPhoneField
                        name="phone"
                        value={formData.phone}
                        onChange={handleChange}
                       /> */}

                                        <CustomPhoneField
                                            name="phone"
                                            value={formData.phone}
                                            onChange={handleChange}
                                            labelText="Phone Number"
                                            placeholder="E.g 987-565-8967"
                                            required={true}
                                            errorMessage={errors.phone}
                                        />

                                        <CustomPhoneField
                                            name="secondary_phone"
                                            value={formData.secondary_phone}
                                            onChange={handleChange}
                                            labelText="Secondary phone"
                                            placeholder="E.g 987-565-8967"
                                            required={true}
                                            errorMessage={errors.phone}
                                        />

                                        {/* <CustomInputField
                            inputType="number"
                            labelText="Secondary phone"
                            placeholder="E.g. 1234567890"
                            name="secondary_phone"
                            value={formData.secondary_phone}
                            onChange={handleChange}
                        /> */}

                                        <CustomDropDownField
                                            options={countries.map(b => ({
                                                value: b.country_code_2,
                                                label: b.name
                                            }))}
                                            labelText="Country"
                                            name="country_code_2"
                                            value={formData.country_code}
                                            onChange={handleChange}
                                            required={true}
                                            // errorMessage={errors.country_code}
                                            disabled={!!id} // Disable if id is present
                                        />

                                        <CustomInputField
                                            labelText="Address Line 1"
                                            placeholder="E.g. 123 Main St"
                                            name="address_line1"
                                            value={formData.address_line1}
                                            onChange={handleChange}
                                            inputWrapper="col-lg-4"
                                            errorMessage={errors.address_line1}
                                            required={true}
                                        />
                                        <CustomInputField
                                            labelText="Address Line 2"
                                            placeholder="E.g. Apt 4B"
                                            name="address_line_2"
                                            value={formData.address_line_2}
                                            onChange={handleChange}
                                            inputWrapper="col-lg-4"
                                        />

                                        <CustomInputField
                                            labelText="Payment Terms"
                                            name="contact_type"
                                            value={formData.contact_type}
                                            onChange={handleChange}
                                        />

                                        <CustomDropDownField
                                            dropDownSize="col-lg-4"
                                            options={states.map(b => ({
                                                value: b.state_abr,
                                                label: b.name
                                            }))}
                                            labelText="State"
                                            name="state" // ensure this matches the formData key
                                            value={formData.state} // Use the correct value for country
                                            onChange={handleChange}
                                        />

                                        <CustomInputField
                                            labelText="City"
                                            name="city"
                                            placeholder="E.g. Chicago"
                                            value={formData.city}
                                            onChange={handleChange}
                                            inputWrapper="col-lg-4"
                                        />

                                        <CustomInputField
                                            labelText="Zip Code"
                                            name="postal_code"
                                            placeholder="E.g.90210"
                                            value={formData.postal_code}
                                            onChange={handleChange}
                                            inputWrapper="col-lg-4"
                                        />

                                        <div className="col-lg-12">
                                            <CustomTextArea
                                                labelText="Note"
                                                name="notes"
                                                value={formData.notes}
                                                onChange={handleChange}
                                            />
                                        </div>
                                        {loading && <p>Loading...</p>}
                                        {error && (
                                            <p className="text-danger mt-2">
                                                {error}
                                            </p>
                                        )}
                                        <div className="row">
                                            <div className="col-lg-6 col-md-6 col-sm-12 my-4">
                                                <div className="add-contacts-btns">
                                                    <button
                                                        type="submit"
                                                        className="btn btn-primary me-2"
                                                        disabled={loading}
                                                    >
                                                        {loading
                                                            ? "Saving..."
                                                            : "Save"}
                                                    </button>

                                                    <button
                                                        type="reset"
                                                        className="btn btn-primary cancel me-2"
                                                        onClick={handleCancel}
                                                    >
                                                        Cancel
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
        </>
    );
};

AddContacts.propTypes = {
    auth: PropTypes.string,
};

export default AddContacts;
