import React, { useState, useEffect } from 'react';
import Header from '../layouts/Header';
import Sidebar from '../layouts/Sidebar';
import { useHistory, useParams } from 'react-router-dom';
import { CustomDatePicker, CustomDropDownField, CustomInputField, CustomTextArea } from '../CustomComponents/ReusableComponents';
import axios from 'axios';
import { useAuth } from '../contexts/AuthContext';
import Swal from "sweetalert2";

const AddExpenses = () => {
    const { auth } = useAuth();
    const { id } = useParams();

    const API_BASE_URL = process.env.REACT_APP_API_V1_BASE_URL;
    const navigate = useHistory();

    const [menu, setMenu] = useState(false);
    const toggleMobileMenu = () => {
        setMenu(!menu);
    };

    const [errors, setErrors] = useState({});

    const [expenses, setExpenses] = useState([]);
    const [paisThrough, setPaidThrough] = useState([]);

    const [formData, setFormData] = useState({
        //invoice_number: "",
        type: "expense",
        order_number: "",
        amount: null,
        paid_through_account_id: "",
        // payment_mode: "cheque",
        invoice_date: new Date(),
        status: "pending",
        description: "",
        // invoice_due_date: getCurrentDate(30),
    });

    const status = [
        { value: "paid", label: "Paid" },
        { value: "pending", label: "Pending" },
        { value: "cancelled", label: "Cancelled" }
    ];

    const handleCancel = () => {
        navigate.goBack()
    };

    useEffect(() => {
        const fetchDropdownData = async () => {
            try{
                const response = await axios.post(
                    `${process.env.REACT_APP_API_V1_BASE_URL}/get_dropdown_data`,
                    {
                        models: [
                            { model_name: "Account", params: "expense"},
                        ]
                    },
                    {
                        headers: {
                            Authorization: `Bearer ${auth}`
                        }
                    }
                );
                setExpenses(response.data.content.Account || []);
            }
            catch (err) {
                console.log("Failed to load dropdown data");
            }
        };
        const fetchDropdownData2 = async () => {
            try{
                const response = await axios.post(
                    `${process.env.REACT_APP_API_V1_BASE_URL}/get_dropdown_data`,
                    {
                        models: [
                            { model_name: "Account", params: "paid_through"}
                        ]
                    },
                    {
                        headers: {
                            Authorization: `Bearer ${auth}`
                        }
                    }
                );
                setPaidThrough(response.data.content.Account || []);
            }
            catch (err) {
                console.log("Failed to load dropdown data");
            }
        };
        const fetchExpensesData = async () => {
            if (id) {
                console.log("id in add/edit form", id);
                try {
                    const response = await axios.get(
                        `${process.env.REACT_APP_API_V1_BASE_URL}/purchase_invoices/${id}`,
                        {
                            headers: {
                                Authorization: `Bearer ${auth}`
                            }
                        }
                    );

                    let expensesData = JSON.parse(JSON.stringify(response.data));
                    setFormData(expensesData);
                } catch (err) {
                    // Handle error
                }
            }
        };
        fetchDropdownData();
        fetchDropdownData2();
        fetchExpensesData();
    }, [id, auth]);

    const handleChange = e => {
        const { name, value } = e.target;

        setFormData(prevFormData => {
            const updatedFormData = { ...prevFormData, [name]: value };

            if (name === "invoice_date") {
                const expensesData = new Date(value);
                const dueDate = new Date(expensesData);
                dueDate.setDate(expensesData.getDate() + 30);

                updatedFormData.invoice_due_date = dueDate
                    .toISOString()
                    .slice(0, 16);
            }

            return updatedFormData;
        });
    };

    const validateForm = () => {
        const validationErrors = {};
        if (!formData.account_id) validationErrors.account_id = "Add Account";
        if (!formData.amount) validationErrors.amount = "Add Amount";
        // if (!formData.invoice_number)
        //     validationErrors.invoice_number = "Add Expense Number";
        if (!formData.paid_through_account_id) validationErrors.paid_through_account_id = "Select an Account here";
        if (!formData.invoice_date) validationErrors.invoice_date = "Select an invoice date";
        
        setErrors(validationErrors);
        return Object.keys(validationErrors).length === 0;
    };

    const handleSubmit = async (e, isPublished=1) => { 
        e.preventDefault();
        if (!validateForm()) {
            return;
        }
        try {
            const expensesData = { ...formData };

            expensesData.posted = isPublished;
            expensesData.invoice_date = window.reqFormatDate(
                expensesData.invoice_date
            );

            const apiReqHeaders = {
                headers: {
                    Authorization: `Bearer ${auth}`,
                    "Content-Type": "application/json"
                },
                    params: {
                    type: "expense"
                }
            };

            // if (id) {

            //     await axios.put(
            //          `${process.env.REACT_APP_API_V1_BASE_URL}/purchase_invoices/${id}`,
            //          expensesData,
            //          {
            //              headers: {
            //                  Authorization: `Bearer ${auth}`,
            //                  "Content-Type": "application/json"
            //              },
            //              params: {
            //                 type: "expense"
            //              }
            //          }
            //      )
            // } else {

            //     await axios.post(
            //          `${process.env.REACT_APP_API_V1_BASE_URL}/purchase_invoices`,
            //          expensesData,
            //          {
            //              headers: {
            //                  Authorization: `Bearer ${auth}`,
            //                  "Content-Type": "application/json"
            //              },
            //              params: {
            //                 type: "expense"
            //              }
            //          }
            //      );
            // }

            if (id) {
                await axios.put(
                    `${API_BASE_URL}/purchase_invoices/${id}`,
                    expensesData,
                    apiReqHeaders
                );
                Swal.fire({
                    title: "Success!",
                    text: "Bill updated successfully.",
                    icon: "success",
                    confirmButtonText: "OK",
                    customClass: {
                        popup: 'custom-swal-popup',  // Use your class for custom positioning
                        icon: 'custom-swal-icon',    // Use this class to style the icon
                      }
                });
            } else {
                await axios.post(
                    `${API_BASE_URL}/purchase_invoices`,
                    expensesData,
                    apiReqHeaders
                );
                Swal.fire({
                    title: "Success!",
                    text: "Bill created successfully.",
                    icon: "success",
                    confirmButtonText: "OK",
                    customClass: {
                        popup: 'custom-swal-popup',  // Use your class for custom positioning
                        icon: 'custom-swal-icon',    // Use this class to style the icon
                      }
                });
            }

            navigate.goBack();
        } catch (error) {
            Swal.fire({
                title: "Error!",
                text: "Failed to Create an invoice.",
                icon: "error",
                confirmButtonText: "OK",
                customClass: {
                    popup: 'custom-swal-popup',  // Use your class for custom positioning
                    icon: 'custom-swal-icon',    // Use this class to style the icon
                  }
            });
            // setError("Failed to save customer.");
        }
    };

    // const handleSubmit = () => {
    //     console.log("Expense Added Successfully");
    //     // window.$('#add_expenses').modal('hide');
    //     onAdd();
    // }
    // console.log("onAdd", onAdd());
    return(
        <>
            <div className={`main-wrapper ${menu ? "slide-nav" : ""}`}>
                <Header onMenuClick={toggleMobileMenu} />
                <Sidebar />
                <div className="page-wrapper">
                    <div className="content container-fluid">
                        <div className="card mb-0">
                            <div className="card-body">
                                <div className="page-header">
                                    <div className="content-page-header">
                                        <h5>{id ? 'Edit Expense' : 'Add Expense'}</h5>
                                    </div>
                                </div>

                                <form onSubmit={handleSubmit}>
                                    {/* <div className="col-lg-4 "> */}
                                        <div className="row">
                                            {/* <CustomInputField
                                                inputType="number"
                                                labelText="Expense ID"
                                                placeholder="E.g 123"
                                                name="invoice_number"
                                                value={formData.invoice_number}
                                                onChange={handleChange}
                                                required={true}
                                                errorMessage={errors.invoice_number}
                                            /> */}
                                            <CustomInputField
                                                inputType="text"
                                                labelText="Reference"
                                                placeholder="E.g 1, 2, 3"
                                                name="order_number"
                                                value={formData.order_number}
                                                onChange={handleChange}
                                                // required={true}
                                                // errorMessage={errors.order_number}
                                            />
                                            <CustomInputField
                                                inputType="number"
                                                labelText="Amount"
                                                placeholder="E.g 100"
                                                name="amount"
                                                value={formData.amount}
                                                onChange={handleChange}
                                                required={true}
                                                errorMessage={errors.amount}
                                            />
                                        </div>

                                        <div className="row">
                                            <CustomDropDownField
                                                options={expenses.map(c => ({
                                                    value: c.id,
                                                    label: c.name
                                                }))}
                                                labelText="Expense Account"
                                                name="account_id"
                                                value={
                                                    formData.account_id
                                                }
                                                onChange={handleChange}
                                                required={true}
                                                errorMessage={errors.account_id}
                                            />

                                            <CustomDropDownField
                                                options={paisThrough.map(c => ({
                                                    value: c.id,
                                                    label: c.name
                                                }))}
                                                labelText="Payment Mode"
                                                name="paid_through_account_id"
                                                value={
                                                    formData.paid_through_account_id
                                                }
                                                onChange={handleChange}
                                                required={true}
                                                errorMessage={errors.paid_through_account_id}
                                            />
                                            {/* <CustomDropDownField
                                                labelText="Payment Mode"
                                                options={[
                                                    {
                                                        value: "cheque",
                                                        label: "Cheque"
                                                    },
                                                    {
                                                        value: "cash",
                                                        label: "Cash"
                                                    }
                                                ]}
                                                name="payment_mode"
                                                value={formData.payment_mode}
                                                onChange={handleChange}
                                                dropDownSize="col-lg-12"
                                                // errorMessage={errors.payment_mode}
                                                // required={true}
                                            /> */}
                                            <CustomDatePicker
                                                labelText="Expense Date"
                                                name="invoice_date"
                                                value={formData.invoice_date}
                                                onChange={handleChange}
                                                errorMessage={errors.invoice_date}
                                                required={true}
                                            />
                                            <CustomDropDownField
                                                options={status.map(c => ({
                                                    value: c.value,
                                                    label: c.label
                                                }))}
                                                labelText="Status"
                                                name="status"
                                                value={formData.status}
                                                onChange={handleChange}
                                                dropDownSize="mb-3"
                                            />
                                            <CustomTextArea
                                                rows={6}
                                                labelText="Description"
                                                name="description"
                                                value={formData.description}
                                                onChange={handleChange}
                                                placeholder="Description"
                                            />
                                        </div>
                                        <div className="text-end">
                                        <button
                                            type="reset"
                                            className="btn btn-primary cancel me-2"
                                            onClick={handleCancel}
                                        >
                                            Cancel
                                        </button>
                                        <button
                                            type="submit"
                                            className="btn btn-primary"
                                        >
                                            Save
                                        </button>
                                    </div>
                                    {/* </div> */}
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default AddExpenses;