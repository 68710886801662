import { company_01, company_02, company_03, company_04, company_05, company_06, company_07, company_08, company_09, company_10} from "../../_components/Imagepath"


export default {
    companiesdata : [
    
        {
            "id": "1",
            "Name": "Hermann Groups",
            "img": company_01,
            "Email": "info@example.com",
            "accounturl": "hru.example.com",
            "Plan": "Advanced  (Monthly)",
            "ChangePlan": "Change",
            "createdOn": "19 Jan 2024",
            "Status": "Active",
            "Action": ""
          },
          {
            "id": "2",
            "Name": "Skiles LLC",
            "img": company_02,
            "Email": "sales@example.com",
            "accounturl": "sk.example.com",
            "Plan": "Basic (Yearly)",
            "ChangePlan": "Change",
            "createdOn": "15 Jan 2024",
            "Status": "Active",
            "Action": ""    },
          {
            "id": "3",
            "Name": "Kerluke Group",
            "img": company_03,
            "Email": "info@example.com",
            "accounturl": "kerluke.example.com",
            "Plan": "Enterprise (Monthly)",
            "ChangePlan": "Change",
            "createdOn": "04 Jan 2024",
            "Status": "Active",
            "Action": ""    },
          {
            "id": "4",
            "Name": "Schowalter Group",
            "img": company_04,
            "Email": "user233@example.com",
            "accounturl": "schowalter.example.com",
            "Plan": "Advanced (Monthly)",
            "ChangePlan": "Change",
            "createdOn": "28 Feb 2024",
            "Status": "Active",
            "Action": ""    },
          {
            "id": "5",
            "Name": "Accentric Global",
            "img": company_05,
            "Email": "accounts@example.com",
            "accounturl": "accentric.example.com",
            "Plan": "Advanced (Monthly)",
            "ChangePlan": "Change",
            "createdOn": "20 Feb 2024",
            "Status": "Active",
            "Action": ""          },
          {
            "id": "6",
            "Name": "Dexter Matrix",
            "img": company_06,
            "Email": "sales@example.com",
            "accounturl": "dexter.example.com",
            "Plan": "Premium  (Yearly)",
            "ChangePlan": "Change",
            "createdOn": "15 Feb 2024",
            "Status": "Active",
            "Action": ""    },
          {
            "id": "7",
            "Name": "Emporis Technologies",
            "img": company_07,
            "Email": "info@example.com",
            "accounturl": "emporis.example.com",
            "Plan": "Free (Monthly)",
            "ChangePlan": "Change",
            "createdOn": "15 Feb 2024",
            "Status": "Active",
            "Action": ""    },
          {
            "id": "8",
            "Name": "Beacon Softwares",
            "img": company_08,
            "Email": "business@example.com",
            "accounturl": "beacon.example.com",
            "Plan": "Enterprise (Monthly)",
            "ChangePlan": "Change",
            "createdOn": "15 Feb 2024",
            "Status": "Active",
            "Action": ""    },
          {
            "id": "9",
            "Name": "Global tech",
            "img": company_09,
            "Email": "user234@example.com",
            "accounturl": "global.example.com",
            "Plan": "Free (Monthly)",
            "ChangePlan": "Change",
            "createdOn": "15 Feb 2024",
            "Status": "Active",
            "Action": ""    },
          {
            "id": "10",
            "Name": "High Tech Lead",
            "img": company_10,
            "Email": "accounts@example.com",
            "accounturl": "high.example.com",
            "Plan": "Enterprise (Monthly)",
            "ChangePlan": "Change",
            "createdOn": "15 Feb 2024",
            "Status": "Inactive",
            "Action": ""    }
    ]
}
