import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useHistory, useParams } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import { CustomInputField, CustomDropDownField, CustomDatePicker } from '../CustomComponents/ReusableComponents';
import Sidebar from '../layouts/Sidebar';
import Header from '../layouts/Header';
import Swal from 'sweetalert2';

const AddBranch = () => {
    const { auth } = useAuth();
    const navigate = useHistory();
    const { id } = useParams();
    
    const API_BASE_URL = process.env.REACT_APP_API_V1_BASE_URL;

    const [menu, setMenu] = useState(false);

        const [formData, setFormData] = useState({
            name: '',
            contact_number: '',
            email: '',
            website: '',
            address: '',
            city: '',
            state: '',
            state_abbr: '',
            zip_code: '',
            country_code_2: '',
            currency_id: '',
            fiscal_year_start: new Date(),
            fiscal_year_end: '',
        });
        const toggleMobileMenu = () => {
            setMenu(!menu);
        };

    // const [loading, setLoading] = useState(true);
    // const [error, setError] = useState(null);
    const [countries, setCountries] = useState([]);
    // const [states, setStates] = useState([]);

    useEffect(() => {
        const fetchDropdownData = async () => {
            // setLoading(true);
            // setError(null);

            try {
                const requestData = [
                    { model_name: 'Country', is_active: 1 },
                    { model_name: 'State', country_code: formData.country_code_2 },
                ];

                const apiReqHeaders = {
                    headers: {
                        Authorization: `Bearer ${auth}`,
                        'Content-Type': 'application/json',
                    }
                };

                const response = await axios.post(
                    `${API_BASE_URL}/get_dropdown_data`,
                    { models: requestData },
                    apiReqHeaders
                );

                const dropdownData = response.data.content;

                setCountries(dropdownData.Country || []);
                // setStates(dropdownData.State || []);
            } catch (err) {
                // setError('Failed to load dropdown data');
            } finally {
                // setLoading(false);
            }
        };

        const fetchBranchData = async () => {
            if (id) {
                try {
                    const response = await axios.get(`${API_BASE_URL}/branches/${id}`, {
                        headers: {
                            Authorization: `Bearer ${auth}`,
                        },
                    });
                    const branchData = response.data;

                    setFormData({
                        name: branchData.name || '',
                        contact_number: branchData.contact_number || '',
                        email: branchData.email || '',
                        website: branchData.website || '',
                        address: branchData.address || '',
                        city: branchData.city || '',
                        state: branchData.state || '',
                        state_abbr: branchData.state_abbr || '',
                        zip_code: branchData.zip_code || '',
                        country_code_2: branchData.country_code_2 || '',
                        currency_id: branchData.currency_id || '',
                        fiscal_year_start: branchData.fiscal_year_start || '',
                        fiscal_year_end: branchData.fiscal_year_end || ''
                    });
                } catch (error) {
                    // setError('Failed to fetch branch data');
                }
            }
        };

        fetchDropdownData();
        fetchBranchData();
    }, [API_BASE_URL, auth, id]);

    const handleCurrencyChange = e => {
        const selectedCountryCode = e.target.value;
        const selectedCountry = countries.find(
            country => country.country_code_2 === selectedCountryCode
        );

        if (!selectedCountry) {
            console.warn("Country not found.");
            return;
        }

        // Find the corresponding currency based on country_code_2
        const selectedCurrency = countries.find(
            countries => countries.country_code_2 === selectedCountryCode
        );

        const selectedCurrencyCode = selectedCurrency
            ? selectedCurrency.currency_code
            : "";

        setFormData(prevData => ({
            ...prevData,
            country_code_2: selectedCountryCode,
            currency_code: selectedCurrencyCode
        }));
    };

    const handleCurrencySelection = e => {
        const selectedCurrencyCode = e.target.value;

        // Find the corresponding currency based on the selected currency code
        const selectedCurrency = countries.find(
            currency => currency.currency_code === selectedCurrencyCode
        );

        if (!selectedCurrency) {
            console.warn("Currency not found.");
            return;
        }

        const selectedCountryCode = selectedCurrency.country_code_2;

        
        setFormData(prevData => ({
            ...prevData,
            country_code_2: selectedCountryCode, // Update with the selected country's code
            currency_code: selectedCurrencyCode // Update with the selected currency code
        }));
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            if (id) {
                await axios.put(`${API_BASE_URL}/branches/${id}`, formData, {
                    headers: {
                        Authorization: `Bearer ${auth}`,
                        'Content-Type': 'application/json',
                    },
                });
                Swal.fire({
                    title: 'Success!',
                    text: 'Branch updated successfully.',
                    icon: 'success',
                    confirmButtonText: 'OK'
                });
            } else {
                await axios.post(`${API_BASE_URL}/branches`, formData, {
                    headers: {
                        Authorization: `Bearer ${auth}`,
                        'Content-Type': 'application/json',
                    },
                });
                Swal.fire({
                    title: 'Success!',
                    text: 'Branch created successfully.',
                    icon: 'success',
                    confirmButtonText: 'OK'
                });
            }

            navigate.push('/branches');
        } catch (error) {
            Swal.fire({
                title: 'Error!',
                text: 'Failed to save the Branch.',
                icon: 'error',
                confirmButtonText: 'OK'
            });
        }
    };

    const handleCancel = () => {
        navigate.push('/branches');
    };

    return (
        <>
            <div className={`main-wrapper ${menu ? "slide-nav" : ""}`}>
                <Header onMenuClick={toggleMobileMenu} />
                <Sidebar />
                <div className="page-wrapper">
                    <div className="content container-fluid">
                        <div className="card mb-0">
                            <div className="card-body">
                                <div className="page-header">
                                    <div className="content-page-header">
                                        <h5>{id ? 'Edit Branch' : 'Add Branch'}</h5>
                                    </div>
                                </div>

                                <form onSubmit={handleSubmit}>
                                    <div className="row">
                                        <CustomInputField
                                            inputType="text"
                                            labelText="Branch Name"
                                            name="name"
                                            value={formData.name}
                                            onChange={handleChange}
                                        />

                                        <CustomInputField
                                            inputType="number"
                                            labelText="Contact Number"
                                            name="contact_number"
                                            value={formData.contact_number}
                                            onChange={handleChange}
                                        />
                                    </div>
                                    <div className="row">
                                        <CustomInputField
                                            inputType="text"
                                            labelText="Email"
                                            name="email"
                                            value={formData.email}
                                            onChange={handleChange}
                                        />

                                        <CustomInputField
                                            inputType="text"
                                            labelText="Website"
                                            name="website"
                                            value={formData.website}
                                            onChange={handleChange}
                                        />
                                    </div>
                                    <div className="row">
                                        <CustomInputField
                                            inputType="text"
                                            labelText="Address"
                                            name="address"
                                            value={formData.address}
                                            onChange={handleChange}
                                        />
                                    </div>
                                    <div className="row">
                                        <CustomInputField
                                            inputType="text"
                                            labelText="City"
                                            name="city"
                                            value={formData.city}
                                            onChange={handleChange}
                                        />
                                        {/* <CustomDropDownField
                                            options={states.map(c => ({
                                                value: c.name,
                                                label: c.name
                                            }))}
                                            labelText="State"
                                            name="state"
                                            value={formData.state}
                                            onChange={handleChange}
                                        /> */}
                                        <CustomInputField
                                            inputType="text"
                                            labelText="State"
                                            name="state"
                                            value={formData.state}
                                            onChange={handleChange}
                                        />
                                        <CustomInputField
                                            inputType="text"
                                            labelText="State Abbreviation"
                                            name="state_abbr"
                                            value={formData.state_abbr}
                                            onChange={handleChange}
                                        />
                                    </div>
                                    <div className="row">
                                        <CustomDropDownField
                                            options={countries.map(b => ({
                                                value: b.country_code_2,
                                                label: b.name
                                            }))}
                                            labelText="Country"
                                            name="country_code_2"
                                            value={formData.country_code_2}
                                            onChange={handleCurrencyChange}
                                            required={true}
                                            disabled={!!id} // Disable if id is present
                                        />

                                        <CustomDropDownField
                                            options={countries.map(c => ({
                                                value: c.currency_code, // Ensure that 'value' is the currency code, not ID
                                                label: `${c.currency_code} (${c.name})`
                                            }))}
                                            labelText="Currency Code"
                                            name="currency_code"
                                            value={formData.currency_code} // Ensure this uses currency_code
                                            onChange={handleCurrencySelection}
                                            required={true}
                                            disabled={!!id} // Disable if id is present
                                        />
                                        <CustomInputField
                                            inputType="number"
                                            labelText="Zip Code"
                                            name="zip_code"
                                            value={formData.zip_code}
                                            onChange={handleChange}
                                        />
                                    </div>
                                    <div className="row">
                                        <CustomDatePicker
                                          inputType="date"
                                          labelText="Financial Year Start"
                                          name="fiscal_year_start"
                                          value={formData.fiscal_year_start}
                                          onChange={handleChange}
                                        />
                                        {/* <CustomInputField
                                            inputType="date"
                                            labelText="Financial Year Start"
                                            name="fiscal_year_start"
                                            value={formData.fiscal_year_start}
                                            onChange
                                            
                                            ={handleChange}
                                        /> */}
                                        {/* <CustomInputField
                                            inputType="date"
                                            labelText="Financial Year End"
                                            name="fiscal_year_end"
                                            value={formData.fiscal_year_end}
                                            onChange={handleChange}
                                        /> */}

                                        <CustomDatePicker
                                         inputType="date"
                                         labelText="Financial Year End"
                                         name="fiscal_year_end"
                                         value={formData.fiscal_year_end}
                                         onChange={handleChange}
                                         placeholder="MM/DD/YYYY"
                                        />
                                        
                                    </div>

                                    <div className="text-end">
                                        <button
                                            type="reset"
                                            className="btn btn-primary cancel me-2"
                                            onClick={handleCancel}
                                        >
                                            Cancel
                                        </button>
                                        <button
                                            type="submit"
                                            className="btn btn-primary"
                                        >
                                            Save
                                        </button>
                                    </div>

                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default AddBranch;

// {
//     "id": 1,
//     "company_id": 1,
//     "name": "Acme Corp",
//     "contact_number": "1234567890",
//     "email": "info@acme.com",
//     "website": "www.acme.com",
//     "address": "123 Corporate St",
//     "city": "Metropolis",
//     "state": "NY",
//     "state_abbr": "NY",
//     "zip_code": "10001",
//     "country": "USA",
//     "fiscal_year_start": "2024-01-01",
//     "fiscal_year_end": "2024-12-31",
//     "currency_id": 1,
//     "created_by": null,
//     "updated_by": null,
//     "created_at": "2024-09-25T05:11:01.000000Z",
//     "updated_at": "2024-09-25T05:11:01.000000Z",
//     "deleted_at": null
// }