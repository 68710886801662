import React, { useState, useEffect } from "react";
import {
    CustomDropDownField,
    CustomInputField,
    CustomTextArea
} from "../../CustomComponents/ReusableComponents";
import axios from "axios";
import PropTypes from "prop-types";
import { useParams, useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useAuth } from "../../contexts/AuthContext";
import Header from "../../layouts/Header";
import Sidebar from "../../layouts/Sidebar";
import Swal from 'sweetalert2';

const AddVendorForm = () => {
    useEffect(() => {
        document.title = `Add Vendor`;
    }, []);

    const { auth } = useAuth();
    const API_BASE_URL = process.env.REACT_APP_API_V1_BASE_URL;

    const { id } = useParams();

    const navigate = useHistory();

    const handleCancel = () => {
        navigate.push("/vendors"); // Replace with the actual path to your customer list page
    };

    const [formData, setFormData] = useState({
        first_name: "",
        last_name: "",
        name_suffix: "",
        ven_display_name: "",
        company_name: "",
        vendor_type: "Business",
        registration_number: "",
        phone: "",
        whatsapp: "",
        email: "",
        currency_code: "",
        payment_terms: "",
        address_line_1: "",
        address_line_2: "",
        city: "",
        state_abbr: "",
        zip_code: "",
        country_code_2: "",
        shipping_address_line_1: "",
        shipping_address_line_2: "",
        shipping_city: "",
        shipping_state_abbr: "",
        shipping_zip_code: "",
        shipping_country_code_2: "",
        internal_notes: "",
        create_chart_of_account: "",
    });

    const [countries, setCountries] = useState([]);
    const [shippingCountries, setShippingCountries] = useState([]);
    const [states, setStates] = useState([]); // State for currencies
    const [shippingStates, setShippingStates] = useState([]); // State for currencies
    const [sameAddress, setSameAddress] = useState(true);
    const [loading, setLoading] = useState(true);
    const [errors, setErrors] = useState({});
    const [error, setError] = useState("");
    const [menu, setMenu] = useState(false);
    const nameSuffix = ["Mr.", "Ms.", "Mrs."];

    const [chartOfAccount, setChartOfAccount] = useState(0);

    const handleCOAChange = () => {
        setChartOfAccount((prev) => !prev);
        setFormData((prev) => ({
            ...prev,
            create_chart_of_account: !chartOfAccount ? 1 : 0,
        }));
    };

    useEffect(() => {
        const fetchDropdownData = async () => {
            try {
                const dropdownRequestData = {
                    models: [
                        { model_name: "Country" },
                        { model_name: "State" }
                    ]
                }; // Define your dropdown request data as needed
                const apiReqHeaders = {
                    headers: {
                        Authorization: `Bearer ${auth}`,
                        "Content-Type": "application/json"
                    }
                };

                const response = await axios.post(
                    `${API_BASE_URL}/get_dropdown_data`,
                    dropdownRequestData,
                    apiReqHeaders
                );

                const { Country } = response.data.content;
                setCountries(Country || []);
                setShippingCountries(Country || []);

                const { State } = response.data.content;
                setStates(State || []);
                setShippingStates(State || []);

                // setCountries(Currency || [1,2,3]);
            } catch (error) {
                setError("Error fetching dropdown data");
                // console.error("Error fetching dropdown data:", error);
            }
        };

        const fetchCustomerData = async () => {
            if (id) {
                try {
                    const apiReqHeaders = {
                        headers: {
                            Authorization: `Bearer ${auth}`,
                            "Content-Type": "application/json"
                        }
                    };

                    const response = await axios.get(
                        `${API_BASE_URL}/vendors/${id}`,
                        apiReqHeaders
                    );
                    setFormData({
                        ...formData,
                        ...response.data
                    });
                    setSameAddress(
                        response.data.address_line_1 ===
                        response.data.shipping_address &&
                        response.data.city ===
                        response.data.shipping_city &&
                        response.data.state ===
                        response.data.shipping_state &&
                        response.data.state_abbr ===
                        response.data.shipping_state_abbr &&
                        response.data.zip_code ===
                        response.data.shipping_zip_code &&
                        response.data.country_id ===
                        response.data.shipping_country
                    );
                } catch (error) {
                    setError("Error fetching customer data");
                    console.error("Error fetching customer data:", error);
                }
            }
        };

        fetchDropdownData();
        fetchCustomerData();
        setLoading(false);
    }, [API_BASE_URL, auth, id]);

    const handleChange = e => {
        const { name, value, type, checked } = e.target;

        // Validate phone number
        if (name === "phone" || name === "whatsapp") {
            // Remove non-numeric characters and limit to 10 digits
            const numericValue = value.replace(/\D/g, ""); // Removes all non-numeric characters

            // Set the value if it is 10 digits or less
            if (numericValue.length <= 10) {
                setFormData({
                    ...formData,
                    [name]: numericValue
                });
            }
        } else if (name === "zip_code") {
            // Remove non-numeric characters and limit to 9 digits
            const numericValue = value.replace(/\D/g, ""); // Removes all non-numeric characters
            // Set the value if it is 10 digits or less
            if (numericValue.length <= 9) {
                setFormData({
                    ...formData,
                    [name]: numericValue
                });
            }
        } else if (name === "shipping_zip_code") {
            // Remove non-numeric characters and limit to 9 digits
            const numericValue = value.replace(/\D/g, ""); // Removes all non-numeric characters
            // Set the value if it is 10 digits or less
            if (numericValue.length <= 9) {
                setFormData({
                    ...formData,
                    [name]: numericValue
                });
            }
        } else {
            setFormData({
                ...formData,
                [name]: type === "checkbox" ? checked : value
            });
        }

        if (name === "first_name" || name === "last_name") {
            setFormData(prevData => ({
                ...prevData,
                ven_display_name: `${prevData.first_name} ${prevData.last_name}`
            }));
        }

        if (
            sameAddress && // Only trigger if "Same as Billing Address" is checked
            (name === "address_line_1" ||
                name === "address_line_2" ||
                name === "city" ||
                name === "state_abbr" ||
                name === "zip_code" ||
                name === "country_code_2")
        ) {
            setFormData(prevData => ({
                ...prevData,
                shipping_address_line_1: prevData.address_line_1,
                shipping_address_line_2: prevData.address_line_2,
                shipping_city: prevData.city,
                shipping_state_abbr: prevData.state_abbr,
                shipping_zip_code: prevData.zip_code,
                shipping_country_code_2: prevData.country_code_2
            }));
        }
    };
    const handleRadioChange = e => {
        setFormData({
            ...formData,
            vendor_type: e.target.value
        });
    };

    const toggleMobileMenu = () => {
        setMenu(!menu);
    };

    const handleSameAddressChange = e => {
        const { checked } = e.target;
        setSameAddress(checked);
        if (checked) {
            setFormData(prevData => ({
                ...prevData,
                shipping_address_line_1: prevData.address_line_1,
                shipping_address_line_2: prevData.address_line_2,
                shipping_city: prevData.city,
                shipping_state_abbr: prevData.state_abbr,
                shipping_zip_code: prevData.zip_code,
                shipping_country_code_2: prevData.country_code_2
            }));
        }
    };

    const validateForm = () => {
        const validationErrors = {};
        const phonePattern = /^\d{10}$/; // Exactly 10 digits
        const zipCodePattern = /^\d{1,9}$/; // Between 1 and 9 digits
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; // Basic email validation pattern

        // Basic required field validations
        if (!formData.first_name)
            validationErrors.first_name = "First name is required.";
        if (!formData.last_name)
            validationErrors.last_name = "Last name is required.";
        if (!formData.ven_display_name)
            validationErrors.ven_display_name =
                "Vendor display name is required.";
        if (!formData.currency_code)
            validationErrors.currency_code = "Select currency.";
        if (!formData.country_code_2)
            validationErrors.country_code_2 = "Select Country.";

        // Phone number validation
        if (!formData.phone) {
            validationErrors.phone = "Phone number is required.";
        } else if (!phonePattern.test(formData.phone)) {
            validationErrors.phone = "Phone number must be exactly 10 digits.";
        }

        // Email validation
        if (!formData.email) {
            validationErrors.email = "Email is required.";
        } else if (!emailPattern.test(formData.email)) {
            validationErrors.email = "Invalid email format.";
        }

        // WhatsApp number validation
        if (formData.whatsapp && !phonePattern.test(formData.whatsapp)) {
            validationErrors.whatsapp =
                "WhatsApp number must be exactly 10 digits.";
        }

        // Zip code validation (max 9 digits)
        if (formData.zip_code && !zipCodePattern.test(formData.zip_code)) {
            validationErrors.zip_code =
                "Zip code must be between 1 and 9 digits.";
        }

        // Shipping zip code validation (max 9 digits)
        if (
            formData.shipping_zip_code &&
            !zipCodePattern.test(formData.shipping_zip_code)
        ) {
            validationErrors.shipping_zip_code =
                "Shipping zip code must be between 1 and 9 digits.";
        }

        setErrors(validationErrors);

        // If there are validation errors, scroll to the first one
        if (Object.keys(validationErrors).length > 0) {
            // Get the name of the first field with an error
            const firstErrorField = Object.keys(validationErrors)[0];
            // Find the input element with the name that matches the first error field
            const errorElement = document.querySelector(
                `[name="${firstErrorField}"]`
            );
            if (errorElement) {
                errorElement.scrollIntoView({
                    behavior: "smooth",
                    block: "center"
                });
                errorElement.focus();
            }
            return false; // Form is not valid
        }

        return Object.keys(validationErrors).length === 0; // Returns true if no errors
    };

    const handleCurrencyChange = e => {
        const selectedCountryCode = e.target.value;
        const selectedCountry = countries.find(
            country => country.country_code_2 === selectedCountryCode
        );

        if (!selectedCountry) {
            console.warn("Country not found.");
            return;
        }

        // Find the corresponding currency based on country_code_2
        const selectedCurrency = countries.find(
            countries => countries.country_code_2 === selectedCountryCode
        );

        const selectedCurrencyCode = selectedCurrency
            ? selectedCurrency.currency_code
            : "";

        setFormData(prevData => ({
            ...prevData,
            country_code_2: selectedCountryCode,
            currency_code: selectedCurrencyCode
        }));
    };
    const handleCurrencySelection = e => {
        const selectedCurrencyCode = e.target.value;

        // Find the corresponding currency based on the selected currency code
        const selectedCurrency = countries.find(
            currency => currency.currency_code === selectedCurrencyCode
        );

        if (!selectedCurrency) {
            console.warn("Currency not found.");
            return;
        }

        const selectedCountryCode = selectedCurrency.country_code_2;

        // Update form data with selected country and currency
        setFormData(prevData => ({
            ...prevData,
            country_code_2: selectedCountryCode, // Update with the selected country's code
            currency_code: selectedCurrencyCode // Update with the selected currency code
        }));
    };

    const handleSubmit = async e => {
        e.preventDefault();

        if (!validateForm()) {
            return;
        }
        try {
            const customerData = { ...formData };
            const apiReqHeaders = {
                headers: {
                    Authorization: `Bearer ${auth}`,
                    "Content-Type": "application/json"
                }
            };

            if (id) {
                await axios.put(
                    `${API_BASE_URL}/vendors/${id}`,
                    customerData,
                    apiReqHeaders
                );
                Swal.fire({
                    title: 'Success!',
                    text: 'Vendor Updated successfully',
                    icon: 'success',
                    confirmButtonText: 'OK',
                    customClass: {
                        popup: 'custom-swal-popup',  // Use your class for custom positioning
                        icon: 'custom-swal-icon',    // Use this class to style the icon
                      }
                });
            } else {
                await axios.post(
                    `${API_BASE_URL}/vendors`,
                    customerData,
                    apiReqHeaders
                );
                Swal.fire({
                    title: 'Success!',
                    text: 'Vendor Created successfully',
                    icon: 'success',
                    confirmButtonText: 'OK',
                    customClass: {
                        popup: 'custom-swal-popup',  // Use your class for custom positioning
                        icon: 'custom-swal-icon',    // Use this class to style the icon
                      }
                });
            }


        } catch (error) {
            Swal.fire({
                title: 'Error!',
                text: 'Failed to Create Vendor.',
                icon: 'error',
                confirmButtonText: 'OK',
                customClass: {
                    popup: 'custom-swal-popup',  // Use your class for custom positioning
                    icon: 'custom-swal-icon',    // Use this class to style the icon
                  }
            });
        }
        navigate.goBack();
    };

    return (
        <>
            <Header onMenuClick={toggleMobileMenu} />
            <Sidebar />
            <div className="page-wrapper">
                    <div className="content container-fluid">
                        <div className="card mb-0">
                            <div className="card-body">
                            <div className="content-page-header">
                    <h5>{id ? "Edit Vendor" : "Add Vendor"}</h5>
                </div>
                
                {!formData.account && 
                <div style={{ display: "flex", alignItems: "center",  marginBottom: "16px" }}>
                    <input
                        type="checkbox"
                        checked={formData.create_chart_of_account === 1}
                        onChange={handleCOAChange}
                        style={{ cursor: "pointer" }}
                    />
                    
                    
                    <span style={{ marginLeft: "8px" }}>Create Chart of Account</span>
                </div>
                }
                    
                {/* </div> */}
                <div className="">
                    <span className="custom-radio">
                        <input
                            type="radio"
                            name="vendor_type"
                            value="Business"
                            checked={formData.vendor_type === "Business"}
                            onChange={handleRadioChange}
                            className="radio-input"
                            disabled={!!id}
                        />
                        <span className="radio-custom"></span>
                        <label className="p-1 radioHeading">Business</label>
                    </span>
                    <span className="custom-radio">
                        <input
                            type="radio"
                            name="vendor_type"
                            value="Individual"
                            checked={formData.vendor_type === "Individual"}
                            onChange={handleRadioChange}
                            className="radio-input"
                            disabled={!!id}
                        />
                        <span className="radio-custom"></span>
                        <label className="p-1 radioHeading">Individual</label>
                    </span>
                </div>

                {loading && <p>Loading...</p>}
                {error && <p className="text-danger">{error}</p>}
            <form onSubmit={handleSubmit}>
                <div className='form-group-customer'>
                    <h5 className="form-title">Basic Details</h5>
                    <div className="row">
                        <CustomDropDownField
                            options={nameSuffix.map(c => ({
                                value: c,
                                label: c
                            }))}
                            labelText="Name Suffix"
                            name="name_suffix" // make sure it matches the formData key
                            value={formData.name_suffix} // Use the correct value for currency
                            onChange={handleChange}
                        />
                        <CustomInputField
                            labelText="First Name"
                            placeholder="E.g. John"
                            name="first_name"
                            value={formData.first_name}
                            onChange={handleChange}
                            errorMessage={errors.first_name}
                            required={true}
                        />
                        <CustomInputField
                            labelText="Last Name"
                            placeholder="E.g. Doe"
                            name="last_name"
                            value={formData.last_name}
                            onChange={handleChange}
                            errorMessage={errors.last_name}
                            required={true}
                        />
                        <CustomInputField
                            labelText="Display Name"
                            placeholder="E.g. John Doe Corp"
                            name="ven_display_name"
                            value={formData.ven_display_name}
                            onChange={handleChange}
                            errorMessage={errors.ven_display_name}
                            required={true}
                        />
                        <CustomInputField
                            inputType="email"
                            labelText="Email"
                            placeholder="E.g. johndoe@example.com"
                            name="email"
                            value={formData.email}
                            onChange={handleChange}
                            errorMessage={errors.email}
                            required={true}
                        />
                        <CustomInputField
                            inputType="text"
                            labelText="Phone Number"
                            placeholder="E.g. 1234567890"
                            name="phone"
                            value={formData.phone}
                            onChange={handleChange}
                            errorMessage={errors.phone}
                            required={true}
                        />
                        <CustomInputField
                            inputType="text"
                            labelText="Whatsapp Number"
                            placeholder="E.g. 1234567890"
                            name="whatsapp"
                            value={formData.whatsapp}
                            onChange={handleChange}
                        />
                        <CustomInputField
                            labelText="Company Name"
                            placeholder="E.g. Doe Enterprises"
                            name="company_name"
                            value={formData.company_name}
                            onChange={handleChange}
                        />
                        <CustomInputField
                            labelText="Registration Number"
                            placeholder="E.g. 12345678"
                            name="registration_number"
                            inputType="text"
                            value={formData.registration_number}
                            onChange={handleChange}
                        />

                        <CustomDropDownField
                            options={countries.map(b => ({
                                value: b.country_code_2,
                                label: b.name
                            }))}
                            labelText="Country"
                            name="country_code_2" // ensure this matches the formData key
                            value={formData.country_code_2} // Use the correct value for country
                            onChange={handleCurrencyChange}
                            required={true}
                            errorMessage={errors.country_code_2}
                            disabled={!!id} //
                        />

                        <CustomDropDownField
                            options={countries.map(c => ({
                                value: c.currency_code,
                                label: `${c.currency_code} (${c.name})`
                            }))}
                            labelText="Currency Code"
                            name="currency_code" // make sure it matches the formData key
                            value={formData.currency_code} // Use the correct value for currency
                            onChange={handleCurrencySelection}
                            required={true}
                            errorMessage={errors.currency_code}
                            disabled={!!id} //
                        />

                        <CustomInputField
                            labelText="Payment Terms"
                            name="payment_terms"
                            placeholder="E.g. Net 30 days"
                            value={formData.payment_terms}
                            onChange={handleChange}
                        />
                        <div className="row">
                            <div className="col-lg-6 col-md-6 col-sm-12 my-4">
                            <h5 className="form-title">Billing Address</h5>
                                <>
                                    <CustomInputField
                                        labelText="Address Line 1"
                                        placeholder="E.g. 123 Main St"
                                        name="address_line_1"
                                        value={formData.address_line_1}
                                        onChange={handleChange}
                                        inputWrapper="col-lg-10"
                                    />
                                    <CustomInputField
                                        labelText="Address Line 2"
                                        placeholder="E.g. Apt 4B"
                                        name="address_line_2"
                                        value={formData.address_line_2}
                                        onChange={handleChange}
                                        inputWrapper="col-lg-10"
                                    />
                                    <CustomInputField
                                        labelText="City"
                                        name="city"
                                        placeholder="E.g. Chicago"
                                        value={formData.city}
                                        onChange={handleChange}
                                        inputWrapper="col-lg-10"
                                    />

                                    <CustomDropDownField
                                        dropDownSize="col-lg-10"
                                        options={states.map(b => ({
                                            value: b.state_abr,
                                            label: b.name
                                        }))}
                                        labelText="State"
                                        name="state_abbr" // ensure this matches the formData key
                                        value={formData.state_abbr} // Use the correct value for country
                                        onChange={handleChange}
                                    />

                                    <CustomInputField
                                        labelText="Zip Code"
                                        name="zip_code"
                                        value={formData.zip_code}
                                        onChange={handleChange}
                                        inputWrapper="col-lg-10"
                                        placeholder="E.g.90210"
                                    />

                                    <CustomDropDownField
                                        dropDownSize="col-lg-10"
                                        options={countries.map(b => ({
                                            value: b.country_code_2,
                                            label: b.name
                                        }))}
                                        labelText="Country"
                                        name="country_code_2" // ensure this matches the formData key
                                        value={formData.country_code_2} // Use the correct value for country
                                        onChange={handleChange}
                                    />
                                </>
                            </div>

                            <div className="col-lg-6 col-md-6 col-sm-12 my-4">
                                <div className="d-flex align-items-baseline mb-2">
                                <h5 className="form-title">Shipping Address</h5>
                                    <span>
                                        <span className="d-inline-block ms-2 me-1">
                                            <input
                                                type="checkbox"
                                                name="sameAsBilling"
                                                checked={sameAddress}
                                                onChange={
                                                    handleSameAddressChange
                                                }
                                                style={{ cursor: "pointer" }}
                                            />
                                        </span>
                                        <span>Same as billing address</span>
                                    </span>
                                </div>
                                <>
                                    <CustomInputField
                                        labelText="Address Line 1"
                                        placeholder="E.g. 123 Main St"
                                        name="shipping_address_line_1"
                                        value={formData.shipping_address_line_1}
                                        onChange={handleChange}
                                        inputWrapper="col-lg-10"
                                    />
                                    <CustomInputField
                                        labelText="Address Line 2"
                                        placeholder="E.g. Apt 4B"
                                        name="shipping_address_line_2"
                                        value={formData.shipping_address_line_2}
                                        onChange={handleChange}
                                        inputWrapper="col-lg-10"
                                    />
                                    <CustomInputField
                                        labelText="City"
                                        name="shipping_city"
                                        placeholder="E.g. Chicago"
                                        value={formData.shipping_city}
                                        onChange={handleChange}
                                        inputWrapper="col-lg-10"
                                    />

                                    <CustomDropDownField
                                        dropDownSize="col-lg-10"
                                        options={shippingStates.map(b => ({
                                            value: b.state_abr,
                                            label: b.name
                                        }))}
                                        labelText="State"
                                        name="shipping_state_abbr" // ensure this matches the formData key
                                        value={formData.shipping_state_abbr} // Use the correct value for country
                                        onChange={handleChange}
                                    />

                                    <CustomInputField
                                        labelText="Zip Code"
                                        name="shipping_zip_code"
                                        value={formData.shipping_zip_code}
                                        onChange={handleChange}
                                        inputWrapper="col-lg-10"
                                        placeholder="E.g.90210"
                                    />

                                    <CustomDropDownField
                                        dropDownSize="col-lg-10"
                                        options={shippingCountries.map(b => ({
                                            value: b.country_code_2,
                                            label: b.name
                                        }))}
                                        labelText="Country"
                                        name="shipping_country_code_2" // ensure this matches the formData key
                                        value={formData.shipping_country_code_2} // Use the correct value for country
                                        onChange={handleChange}
                                    />
                                </>
                            </div>

                            <div className="col-lg-12">
                                <CustomTextArea
                                    labelText="Note"
                                    name="internal_notes"
                                    value={formData.internal_notes}
                                    onChange={handleChange}
                                />
                            </div>
                        </div>
                        {/* <div className="row">
                            <div className="col-lg-6 col-md-6 col-sm-12 my-4">
                                <div className="add-customer-btns">
                                    <button
                                        type="submit"
                                        className="btn btn-primary me-2"
                                        disabled={loading}
                                    >
                                        {loading ? "Saving..." : "Save"}
                                    </button>

                                    <button
                                        type="reset"
                                        className="btn btn-primary cancel me-2"
                                        onClick={handleCancel}
                                    >
                                        Cancel
                                    </button>
                                </div>
                            </div>
                        </div> */}
                    </div>
                </div>
                <div className="modal-footer">
                        <button
                            type="reset"
                            className="btn btn-primary cancel me-2"
                            onClick={handleCancel}
                        >
                            Cancel
                        </button>
                        <button
                            type="submit"
                            className="btn btn-primary"
                        >
                            Save
                        </button>
                    </div>
            </form>
        </div>
    </div>
    </div>
    </div>
           
           
        </>
    );
};

AddVendorForm.propTypes = {
    auth: PropTypes.string,
};

export default AddVendorForm;
