import React, { useEffect, useState } from "react";
import axios from "axios";
import Header from "../../layouts/Header";
import Sidebar from "../../layouts/Sidebar";
import { useAuth } from "../../contexts/AuthContext";
import { useHistory,useParams } from "react-router-dom";



const Permission = () => {
  const { auth } = useAuth();
  const history = useHistory();
  const { id } = useParams();
  
  //const { authToken } = useAuth();
  const API_BASE_URL = process.env.REACT_APP_API_V1_BASE_URL;
  const [menu, setMenu] = useState(false);
  const [permissions, setPermissions] = useState({});
  const [roleName, setroleName] = useState(null);
  //const [selectedRole, setSelectedRole] = useState("testing-role"); // Assuming a role
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [selectAll, setSelectAll] = useState(false);

  const toggleMobileMenu = () => {
    setMenu(!menu);
  };
  //console.log("authToken",auth);

  // Fetch permissions for the selected role
  useEffect(() => {

    const apiReqHeaders = {
      headers: {
        Authorization: `Bearer ${auth}`,
        "Content-Type": "application/json",
      },
    };

    axios
      .get(`${API_BASE_URL}/roles/${id}/permissions`, apiReqHeaders)
      .then((response) => {
        const fetchedPermissions = response.data.permissions;
        const roleName = response.data.role_name;
        const transformedPermissions = fetchedPermissions.reduce(
          (acc, permission) => {
            const model = Object.keys(permission)[0];
            acc[model] = permission[model];
            return acc;
          },
          {}
        );
        setroleName(roleName);
        setPermissions(transformedPermissions);
      })
      .catch((error) => {
        setError("Error fetching permissions");
        console.error("Error fetching permissions:", error);
      });
  }, [auth]);

  const handleCheckboxChange = (model, action) => {
    setPermissions((prevPermissions) => ({
      ...prevPermissions,
      [model]: {
        ...prevPermissions[model],
        [action]: !prevPermissions[model][action],
      },
    }));
  };

  const handleModelCheckboxChange = (model) => {
    const allChecked = Object.values(permissions[model]).every((value) => value);
    const newPermissions = Object.keys(permissions[model]).reduce(
      (acc, action) => {
        acc[action] = !allChecked;
        return acc;
      },
      {}
    );

    setPermissions((prevPermissions) => ({
      ...prevPermissions,
      [model]: newPermissions,
    }));
  };

  const handleSelectAllChange = () => {
    const allChecked = Object.values(permissions).every((modelPerm) =>
      Object.values(modelPerm).every((val) => val)
    );
    const newPermissions = Object.keys(permissions).reduce((acc, model) => {
      acc[model] = Object.keys(permissions[model]).reduce((modelAcc, action) => {
        modelAcc[action] = !allChecked;
        return modelAcc;
      }, {});
      return acc;
    }, {});
    setPermissions(newPermissions);
    setSelectAll(!allChecked);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const updatedPermissions = [];
    Object.keys(permissions).forEach((model) => {
      Object.keys(permissions[model]).forEach((action) => {
        if (permissions[model][action]) {
          updatedPermissions.push({ model, action });
        }
      });
    });

    axios
      .post(
        `${API_BASE_URL}/roles/${id}/update-permissions`,
        { updatedPermissions },
        {
          headers: {
            Authorization: `Bearer ${auth}`,
            "Content-Type": "application/json",
          },
        }
      )
      .then(() => {
        setSuccess("Permissions updated successfully");
        setError(null);
        window.scrollTo(0, 0);
      })
      .catch((error) => {
        setError("Error updating permissions");
        setSuccess(null);
        window.scrollTo(0, 0);
        console.error("Error updating permissions:", error);
      });
      history.push('/roles_permission');

  };

  return (
    <>
      <div className={`main-wrapper ${menu ? "slide-nav" : ""}`}>
        <Header onMenuClick={toggleMobileMenu} />
        <Sidebar />

        <div className="page-wrapper">
          <div className="content container-fluid">
            {/* Page Header */}
            <div className="page-header">
              <div className="content-page-header">
                <h5>Permission</h5>
              </div>
              <div className="role-testing d-flex align-items-center justify-content-between">
                <h6>
                  Role Name:<span className="ms-1">{roleName}</span>
                </h6>
                <p>
                  <label className="custom_check">
                    <input
                      type="checkbox"
                      checked={selectAll}
                      onChange={handleSelectAllChange}
                    />
                    <span className="checkmark" />
                  </label>
                  Allow All Modules
                </p>
              </div>
            </div>
            {/* /Page Header */}
            {/* Table */}
            <div className="row">
              <div className="col-sm-12">
                <div className="card-table">
                  <div className="card-body">
                    <div className="table-responsive">
                      <table className="table table-stripped table-hover">
                        <thead className="thead-light">
                          <tr>
                            <th>#</th>
                            <th>Modules</th>
                            <th>Create</th>
                            <th>Edit</th>
                            <th>Delete</th>
                            <th>View</th>
                            <th>Import</th>
                            <th>Export</th>
                            <th>Allow All</th>
                          </tr>
                        </thead>
                        <tbody>
                          {Object.keys(permissions).map((model, index) => (
                            <tr key={index}>
                              <td>{index + 1}</td>
                              <td>{model}</td>
                              {["add", "edit", "delete", "show", "import", "export"].map((action) => (
                                Object.prototype.hasOwnProperty.call(permissions[model], action) ? (  // Corrected here
                                  <td key={action}>
                                    <label className="custom_check">
                                      <input
                                        type="checkbox"
                                        checked={permissions[model][action] || false}
                                        onChange={() => handleCheckboxChange(model, action)}
                                      />
                                      <span className="checkmark" />
                                    </label>
                                  </td>
                                ) : (
                                  <td key={action}></td>  // Render an empty cell if action is missing
                                )
                              ))}
                              <td>
                                <label className="custom_check">
                                  <input
                                    type="checkbox"
                                    onChange={() => handleModelCheckboxChange(model)}
                                    checked={Object.values(permissions[model]).every(val => val)}
                                  />
                                  <span className="checkmark" />
                                </label>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                      {error && <p className="text-danger">{error}</p>}
                      {success && <p className="text-success">{success}</p>}
                      <button className="btn btn-primary" onClick={handleSubmit}>
                        Save Permissions
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Permission;
// import React, { useState } from "react";
// import Header from "../../layouts/Header";
// import Sidebar from "../../layouts/Sidebar";

// const Permission = () => {
//   const [menu, setMenu] = useState(false);

//   const toggleMobileMenu = () => {
//     setMenu(!menu);
//   };

//   return (
//     <>
//       <div className={`main-wrapper ${menu ? "slide-nav" : ""}`}>
//         <Header onMenuClick={() => toggleMobileMenu()} />
//         <Sidebar />

//         <div className="page-wrapper">
//           <div className="content container-fluid">
//             {/* Page Header */}
//             <div className="page-header">
//               <div className="content-page-header">
//                 <h5>Permission</h5>
//               </div>
//               <div className="role-testing d-flex align-items-center justify-content-between">
//                 <h6>
//                   Role Name:<span className="ms-1">Testings</span>
//                 </h6>
//                 <p>
//                   <label className="custom_check">
//                     <input type="checkbox" name="invoice" />
//                     <span className="checkmark" />
//                   </label>
//                   Allow All Modules
//                 </p>
//               </div>
//             </div>
//             {/* /Page Header */}
//             {/* Table */}
//             <div className="row">
//               <div className="col-sm-12">
//                 <div className="card-table">
//                   <div className="card-body">
//                     <div className="table-responsive">
//                       <table className="table table-stripped table-hover">
//                         <thead className="thead-light">
//                           <tr>
//                             <th>#</th>
//                             <th>Modules</th>
//                             <th>Sub Modules</th>
//                             <th>Create</th>
//                             <th>Edit</th>
//                             <th>Delete</th>
//                             <th>View</th>
//                             <th>Allow all</th>
//                           </tr>
//                         </thead>
//                         <tbody>
//                           <tr>
//                             <td>1</td>
//                             <td className="role-data">Dashboard</td>
//                             <td>Dashboard</td>
//                             <td>
//                               <label className="custom_check">
//                                 <input type="checkbox" name="invoice" />
//                                 <span className="checkmark" />
//                               </label>
//                             </td>
//                             <td>
//                               <label className="custom_check">
//                                 <input type="checkbox" name="invoice" />
//                                 <span className="checkmark" />
//                               </label>
//                             </td>
//                             <td>
//                               <label className="custom_check">
//                                 <input type="checkbox" name="invoice" />
//                                 <span className="checkmark" />
//                               </label>
//                             </td>
//                             <td>
//                               <label className="custom_check">
//                                 <input type="checkbox" name="invoice" />
//                                 <span className="checkmark" />
//                               </label>
//                             </td>
//                             <td>
//                               <label className="custom_check">
//                                 <input type="checkbox" name="invoice" />
//                                 <span className="checkmark" />
//                               </label>
//                             </td>
//                           </tr>
//                           <tr>
//                             <td>2</td>
//                             <td className="role-data">Appointments</td>
//                             <td>Appointments</td>
//                             <td>
//                               <label className="custom_check">
//                                 <input type="checkbox" name="invoice" />
//                                 <span className="checkmark" />
//                               </label>
//                             </td>
//                             <td>
//                               <label className="custom_check">
//                                 <input type="checkbox" name="invoice" />
//                                 <span className="checkmark" />
//                               </label>
//                             </td>
//                             <td>
//                               <label className="custom_check">
//                                 <input type="checkbox" name="invoice" />
//                                 <span className="checkmark" />
//                               </label>
//                             </td>
//                             <td>
//                               <label className="custom_check">
//                                 <input type="checkbox" name="invoice" />
//                                 <span className="checkmark" />
//                               </label>
//                             </td>
//                             <td>
//                               <label className="custom_check">
//                                 <input type="checkbox" name="invoice" />
//                                 <span className="checkmark" />
//                               </label>
//                             </td>
//                           </tr>
//                           <tr>
//                             <td>3</td>
//                             <td className="role-data">Specialization</td>
//                             <td>Specialization</td>
//                             <td>
//                               <label className="custom_check">
//                                 <input type="checkbox" name="invoice" />
//                                 <span className="checkmark" />
//                               </label>
//                             </td>
//                             <td>
//                               <label className="custom_check">
//                                 <input type="checkbox" name="invoice" />
//                                 <span className="checkmark" />
//                               </label>
//                             </td>
//                             <td>
//                               <label className="custom_check">
//                                 <input type="checkbox" name="invoice" />
//                                 <span className="checkmark" />
//                               </label>
//                             </td>
//                             <td>
//                               <label className="custom_check">
//                                 <input type="checkbox" name="invoice" />
//                                 <span className="checkmark" />
//                               </label>
//                             </td>
//                             <td>
//                               <label className="custom_check">
//                                 <input type="checkbox" name="invoice" />
//                                 <span className="checkmark" />
//                               </label>
//                             </td>
//                           </tr>
//                           <tr>
//                             <td>4</td>
//                             <td className="role-data">Sub Categories</td>
//                             <td>Sub Categories</td>
//                             <td>
//                               <label className="custom_check">
//                                 <input type="checkbox" name="invoice" />
//                                 <span className="checkmark" />
//                               </label>
//                             </td>
//                             <td>
//                               <label className="custom_check">
//                                 <input type="checkbox" name="invoice" />
//                                 <span className="checkmark" />
//                               </label>
//                             </td>
//                             <td>
//                               <label className="custom_check">
//                                 <input type="checkbox" name="invoice" />
//                                 <span className="checkmark" />
//                               </label>
//                             </td>
//                             <td>
//                               <label className="custom_check">
//                                 <input type="checkbox" name="invoice" />
//                                 <span className="checkmark" />
//                               </label>
//                             </td>
//                             <td>
//                               <label className="custom_check">
//                                 <input type="checkbox" name="invoice" />
//                                 <span className="checkmark" />
//                               </label>
//                             </td>
//                           </tr>
//                           <tr>
//                             <td>5</td>
//                             <td className="role-data">Clients</td>
//                             <td>Clients</td>
//                             <td>
//                               <label className="custom_check">
//                                 <input type="checkbox" name="invoice" />
//                                 <span className="checkmark" />
//                               </label>
//                             </td>
//                             <td>
//                               <label className="custom_check">
//                                 <input type="checkbox" name="invoice" />
//                                 <span className="checkmark" />
//                               </label>
//                             </td>
//                             <td>
//                               <label className="custom_check">
//                                 <input type="checkbox" name="invoice" />
//                                 <span className="checkmark" />
//                               </label>
//                             </td>
//                             <td>
//                               <label className="custom_check">
//                                 <input type="checkbox" name="invoice" />
//                                 <span className="checkmark" />
//                               </label>
//                             </td>
//                             <td>
//                               <label className="custom_check">
//                                 <input type="checkbox" name="invoice" />
//                                 <span className="checkmark" />
//                               </label>
//                             </td>
//                           </tr>
//                           <tr>
//                             <td>6</td>
//                             <td className="role-data">Payment Request</td>
//                             <td>Payment Request</td>
//                             <td>
//                               <label className="custom_check">
//                                 <input type="checkbox" name="invoice" />
//                                 <span className="checkmark" />
//                               </label>
//                             </td>
//                             <td>
//                               <label className="custom_check">
//                                 <input type="checkbox" name="invoice" />
//                                 <span className="checkmark" />
//                               </label>
//                             </td>
//                             <td>
//                               <label className="custom_check">
//                                 <input type="checkbox" name="invoice" />
//                                 <span className="checkmark" />
//                               </label>
//                             </td>
//                             <td>
//                               <label className="custom_check">
//                                 <input type="checkbox" name="invoice" />
//                                 <span className="checkmark" />
//                               </label>
//                             </td>
//                             <td>
//                               <label className="custom_check">
//                                 <input type="checkbox" name="invoice" />
//                                 <span className="checkmark" />
//                               </label>
//                             </td>
//                           </tr>
//                           <tr>
//                             <td>7</td>
//                             <td className="role-data">Reviews</td>
//                             <td>Reviews</td>
//                             <td>
//                               <label className="custom_check">
//                                 <input type="checkbox" name="invoice" />
//                                 <span className="checkmark" />
//                               </label>
//                             </td>
//                             <td>
//                               <label className="custom_check">
//                                 <input type="checkbox" name="invoice" />
//                                 <span className="checkmark" />
//                               </label>
//                             </td>
//                             <td>
//                               <label className="custom_check">
//                                 <input type="checkbox" name="invoice" />
//                                 <span className="checkmark" />
//                               </label>
//                             </td>
//                             <td>
//                               <label className="custom_check">
//                                 <input type="checkbox" name="invoice" />
//                                 <span className="checkmark" />
//                               </label>
//                             </td>
//                             <td>
//                               <label className="custom_check">
//                                 <input type="checkbox" name="invoice" />
//                                 <span className="checkmark" />
//                               </label>
//                             </td>
//                           </tr>
//                           <tr>
//                             <td>8</td>
//                             <td className="role-data">Subscription</td>
//                             <td>Subscription</td>
//                             <td>
//                               <label className="custom_check">
//                                 <input type="checkbox" name="invoice" />
//                                 <span className="checkmark" />
//                               </label>
//                             </td>
//                             <td>
//                               <label className="custom_check">
//                                 <input type="checkbox" name="invoice" />
//                                 <span className="checkmark" />
//                               </label>
//                             </td>
//                             <td>
//                               <label className="custom_check">
//                                 <input type="checkbox" name="invoice" />
//                                 <span className="checkmark" />
//                               </label>
//                             </td>
//                             <td>
//                               <label className="custom_check">
//                                 <input type="checkbox" name="invoice" />
//                                 <span className="checkmark" />
//                               </label>
//                             </td>
//                             <td>
//                               <label className="custom_check">
//                                 <input type="checkbox" name="invoice" />
//                                 <span className="checkmark" />
//                               </label>
//                             </td>
//                           </tr>
//                           <tr>
//                             <td>9</td>
//                             <td className="role-data">Settings</td>
//                             <td>Settings</td>
//                             <td>
//                               <label className="custom_check">
//                                 <input type="checkbox" name="invoice" />
//                                 <span className="checkmark" />
//                               </label>
//                             </td>
//                             <td>
//                               <label className="custom_check">
//                                 <input type="checkbox" name="invoice" />
//                                 <span className="checkmark" />
//                               </label>
//                             </td>
//                             <td>
//                               <label className="custom_check">
//                                 <input type="checkbox" name="invoice" />
//                                 <span className="checkmark" />
//                               </label>
//                             </td>
//                             <td>
//                               <label className="custom_check">
//                                 <input type="checkbox" name="invoice" />
//                                 <span className="checkmark" />
//                               </label>
//                             </td>
//                             <td>
//                               <label className="custom_check">
//                                 <input type="checkbox" name="invoice" />
//                                 <span className="checkmark" />
//                               </label>
//                             </td>
//                           </tr>
//                           <tr>
//                             <td>10</td>
//                             <td className="role-data">Email Templates</td>
//                             <td>Email Templates</td>
//                             <td>
//                               <label className="custom_check">
//                                 <input type="checkbox" name="invoice" />
//                                 <span className="checkmark" />
//                               </label>
//                             </td>
//                             <td>
//                               <label className="custom_check">
//                                 <input type="checkbox" name="invoice" />
//                                 <span className="checkmark" />
//                               </label>
//                             </td>
//                             <td>
//                               <label className="custom_check">
//                                 <input type="checkbox" name="invoice" />
//                                 <span className="checkmark" />
//                               </label>
//                             </td>
//                             <td>
//                               <label className="custom_check">
//                                 <input type="checkbox" name="invoice" />
//                                 <span className="checkmark" />
//                               </label>
//                             </td>
//                             <td>
//                               <label className="custom_check">
//                                 <input type="checkbox" name="invoice" />
//                                 <span className="checkmark" />
//                               </label>
//                             </td>
//                           </tr>
//                         </tbody>
//                       </table>
//                     </div>
//                   </div>
//                 </div>
//               </div>
//             </div>

//             {/* /Table */}
//             <div className="btn-center my-4">
//               <button type="submit" className="btn btn-primary cancel me-2">
//                 Back
//               </button>
//               <button type="submit" className="btn btn-primary">
//                 Update
//               </button>
//             </div>
//           </div>
//         </div>
//       </div>
//     </>
//   );
// };

// export default Permission;
