import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import { AiaLogoWithName } from "../_components/Imagepath";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { emailrgx } from "../assets/constant";
import { useAuth } from "../contexts/AuthContext";

const schema = yup.object({
  email: yup
    .string()
    .matches(emailrgx, "Invalid email address")
    .required("Email is required")
    .trim(),
  password: yup
    .string()
    .min(6, "Password must be at least 6 characters")
    .required("Password is required")
    .trim(),
});

const Login = () => {
  const [eye, seteye] = useState(true);
  const [companyName, setCompanyName] = useState(''); // Add state for company name

  const history = useHistory();
  // const navigate = useNavigate();
  const { login } = useAuth();

  const {
    handleSubmit,
    control,
    setError,
    // clearErrors,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      email: "",
      password: "",
    },
  });

  // Check if token exists and redirect to index page if logged in
  useEffect(() => {
    const checkSubdomain = async () => {
      try {
        const hostname = window.location.hostname; // Get current hostname
        const parts = hostname.split('.'); // Split the hostname into parts
        const subdomain = parts.length > 2 ? parts[0] : null; // Extract the subdomain if it exists

        const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/domains/check-subdomain?subdomain=${subdomain}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
        });

        const responseData = await response.json();

        if (response.ok && !responseData.exists) {
          // Check if the current port is not the default (80 for HTTP and 443 for HTTPS)
          const port = window.location.port ? `:${window.location.port}` : '';

          // If subdomain does not exist, redirect to the main domain
          const mainDomain = `${window.location.protocol}//${parts.slice(-2).join('.')}`; // Get the main domain

          // Redirect to main domain with port using href
          window.location.href = mainDomain + port; // Redirect to main domain with port
        }
      } catch (error) {
        console.error('Error checking subdomain:', error);
      }
    };

    checkSubdomain();
    // Fetch company information when the component mounts
    const fetchCompany = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/company`);
        const responseData = await response.json();

        if (response.ok) {
          setCompanyName(responseData.organization_name); // Set the company name
        } else {
          console.error('Failed to fetch company info');
        }
      } catch (error) {
        console.error('Error fetching company info:', error);
      }
    };

    fetchCompany();
  }, [history]);

  const onSubmit = async (data) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/login`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          email: data.email,
          password: data.password,
        }),
      });

      const responseData = await response.json();

      if (response.ok) {
        const token = `Bearer ${responseData.content.access_token}`;
        const userData = responseData.content.user;
        const companyData = responseData.content.company;
        const extractedData = {
          branch_id: responseData.content.branch_id,
          contact: {
            id: userData.contact.id,
            first_name: userData.contact.first_name,
            last_name: userData.contact.last_name,
            state: userData.contact.state,
            country_code: userData.contact.country_code,
            email: userData.contact.email,
          },
          role: {
            id: userData.roles[0].id,
            name: userData.roles[0].name,
            role_id: userData.roles[0].pivot.role_id,
          },
          company: companyData
        };

        const permissionsResponse = await fetch(
          `${process.env.REACT_APP_API_V1_BASE_URL}/roles/${responseData.content.role_id}/permissions`,
          {
            method: 'GET',
            headers: {
              'Authorization': token,
              'Content-Type': 'application/json',
            },
          }
        );

        if (permissionsResponse.ok) {
          const permissionsData = await permissionsResponse.json();

          login(token, extractedData, permissionsData);
          history.push('/index');
          // window.location.reload();
        } else {
          console.error('Failed to fetch permissions');
        }
      } else {
        // Display the API error message
        if (responseData.message) {
          setError("apiError", { message: responseData.message });
        } else {
          setError("apiError", { message: "Verification failed" });
        }
      }
    } catch (error) {
      console.error('Login failed:', error);
      setError("apiError", { message: 'Login failed. Please try again later.' });
    }
  };

  const onEyeClick = () => {
    seteye(!eye);
  };

  return (
    <>
      <div className="main-wrapper login-body">
        <div className="login-wrapper">
          <div className="container">
            <img
              className="img-fluid logo-dark mb-2"
              src={AiaLogoWithName}
              alt="Logo"
            />

            <div className="loginbox">
              <div className="login-right">
                <div className="login-right-wrap">
                  <h1>Login</h1>
                  <p className="account-subtitle">{companyName}</p>
                  <div>
                    <form onSubmit={handleSubmit(onSubmit)}>
                      <div className="input-block mb-3 input_text">
                        <label className="form-control-label">Email Address</label>
                        <Controller
                          name="email"
                          control={control}
                          render={({ field: { value, onChange } }) => (
                            <input
                              className={`form-control ${errors?.email ? "error-input" : ""}`}
                              type="text"
                              value={value}
                              onChange={onChange}
                              autoComplete="false"
                            />
                          )}
                        />
                        <small>{errors?.email?.message}</small>
                      </div>
                      <div className="input-block mb-3 input_text login-eye">
                        <label className="form-control-label">Password</label>
                        <Controller
                          name="password"
                          control={control}
                          render={({ field: { value, onChange } }) => (
                            <div className="pass-group">
                              <input
                                type={eye ? "password" : "text"}
                                className={`form-control ${errors?.password ? "error-input" : ""}`}
                                value={value}
                                onChange={onChange}
                                autoComplete="false"
                              />
                              <span
                                onClick={onEyeClick}
                                className={`fas toggle-password ${eye ? "fa-eye-slash" : "fa-eye"}`}
                              />
                            </div>
                          )}
                        />
                        <small>{errors?.password?.message}</small>
                      </div>
                      <div className="input-block mb-3">
                        <div className="row">
                          <div className="col-6">
                            <div className="custom-control custom-checkbox">
                              <input
                                type="checkbox"
                                className="form-check-input"
                                id="cb1"
                              />
                              <label
                                className="custom-control-label ms-1"
                                htmlFor="cb1"
                              >
                                Remember me
                              </label>
                            </div>
                          </div>
                          <div className="col-6 text-end">
                            <Link className="forgot-link" to="/forgot-password">
                              Forgot Password ?
                            </Link>
                          </div>

                          {/* Display API Error */}
                          {errors.apiError && (
                            <div className="alert alert-danger">
                              {errors.apiError.message}
                            </div>
                          )}
                        </div>
                      </div>
                      <button
                        className="btn btn-lg btn-block w-100 btn-primary"
                        type="submit"
                      >
                        Login
                      </button>
                    </form>
                    <div className="login-or">
                      <span className="or-line" />
                      <span className="span-or">or</span>
                    </div>
                    {/* Social Login */}
                    <div className="social-login mb-3">
                      <span>Login with</span>
                      <Link to="#" className="facebook">
                        <i className="fab fa-facebook-f" />
                      </Link>
                      <Link to="#" className="google">
                        <i className="fab fa-google" />
                      </Link>
                    </div>
                    {/* /Social Login */}
                    <div className="text-center dont-have">
                      {/* Don't have an account yet?  */}
                      <Link to="/register">Register</Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;