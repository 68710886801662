import React from 'react';
import PageComponent from '../CustomComponents/PageComponent';

const ExpensesList = () => {
  return(
    <PageComponent listAPI='purchase_invoices' label='Expenses' addType='nextPage' addLabel='Expense' route='expenses' />
  )
};

export default ExpensesList;
