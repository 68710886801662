import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { AiaLogoWithName } from "../_components/Imagepath";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { emailrgx } from "../assets/constant";
import { useAuth } from "../contexts/AuthContext";

const schema = yup.object({
    email: yup
        .string()
        .matches(emailrgx, "Invalid email address")
        .required("Email is required")
        .trim(),
    email_token: yup
        .string()
        .required("Email token is required")
        .trim(),
    phone_token: yup
        .string()
        .required("Phone token is required")
        .trim(),
});

const RegistrationVerificationForm = () => {
    const history = useHistory();
    const { login } = useAuth();
    const queryParams = new URLSearchParams(window.location.search);
    const emailFromUrl = queryParams.get("email") || "";

    const {
        handleSubmit,
        control,
        setError,
        formState: { errors },
        setValue, // <-- Use setValue to set initial value
    } = useForm({
        resolver: yupResolver(schema),
        defaultValues: {
            email: emailFromUrl,
            email_token: "",
            phone_token: "",
        },
    });
    // Optional: Set the email value if it changes on component mount
    useEffect(() => {
        if (emailFromUrl) {
            setValue("email", emailFromUrl);
        }
    }, [emailFromUrl, setValue]);


    const onSubmit = async (data) => {
        try {
            if (!data.email || !data.email_token || !data.phone_token) {
                setError("apiError", { message: "Please provide email, email token, and phone token." });
                return;
            }

            const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/verify_register`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    email: data.email,
                    email_token: data.email_token,
                    phone_token: data.phone_token
                }),
            });

            const responseData = await response.json(); // Parse the response to JSON

            if (response.ok && responseData.status !== "error") {
                const token = `Bearer ${responseData.content.access_token}`;
                const userData = responseData.content.user;
                const companyData = responseData.content.company;
                const extractedData = {
                    branch_id: responseData.content.branch_id,
                    contact: {
                        id: userData.contact.id,
                        first_name: userData.contact.first_name,
                        last_name: userData.contact.last_name,
                        state: userData.contact.state,
                        country_code: userData.contact.country_code,
                        email: userData.contact.email,
                    },
                    role: {
                        id: userData.roles[0].id,
                        name: userData.roles[0].name,
                        role_id: userData.roles[0].pivot.role_id,
                    },
                    company: companyData
                };

                // Fetch permissions
                const permissionsResponse = await fetch(
                    `${process.env.REACT_APP_API_V1_BASE_URL}/roles/${responseData.content.role_id}/permissions`,
                    {
                        method: 'GET',
                        headers: {
                            'Authorization': token,
                            'Content-Type': 'application/json',
                        },
                    }
                );

                if (permissionsResponse.ok) {
                    const permissionsData = await permissionsResponse.json();
                    login(token, extractedData, permissionsData);
                    history.push('/index?flag=showIntegrationPopup');
                } else {
                    console.error('Failed to fetch permissions');
                }
            } else {
                // Display the API error message
                if (responseData.content?.message) {
                    setError("apiError", { message: responseData.content.message });
                } else {
                    setError("apiError", { message: "Verification failed" });
                }
            }
        } catch (error) {
            console.error('Verification failed:', error);
            setError("apiError", { message: 'Verification failed. Please try again later.' });
        }
    };

    return (
        <>
            <div className="main-wrapper login-body">
                <div className="login-wrapper">
                    <div className="container">
                        <img
                            className="img-fluid logo-dark mb-2"
                            src={AiaLogoWithName}
                            alt="Logo"
                        />
                        <div className="loginbox">
                            <div className="login-right">
                                <div className="login-right-wrap">
                                    <h1>Verify</h1>
                                    <p className="account-subtitle">Verify your account</p>
                                    <div>
                                        <form onSubmit={handleSubmit(onSubmit)}>
                                            <div className="input-block mb-3 input_text">
                                                <label className="form-control-label">Email Address</label>
                                                <Controller
                                                    name="email"
                                                    control={control}
                                                    render={({ field: { value, onChange } }) => (
                                                        <input
                                                            className={`form-control ${errors?.email ? "error-input" : ""}`}
                                                            type="text"
                                                            value={value}
                                                            onChange={onChange}
                                                            autoComplete="false"
                                                        />
                                                    )}
                                                />
                                                <small>{errors?.email?.message}</small>
                                            </div>
                                            <div className="input-block mb-3 input_text">
                                                <label className="form-control-label">Email Token</label>
                                                <Controller
                                                    name="email_token"
                                                    control={control}
                                                    render={({ field: { value, onChange } }) => (
                                                        <input
                                                            className={`form-control ${errors?.email_token ? "error-input" : ""}`}
                                                            type="text"
                                                            value={value}
                                                            onChange={onChange}
                                                            autoComplete="false"
                                                        />
                                                    )}
                                                />
                                                <small>{errors?.email_token?.message}</small>
                                            </div>
                                            <div className="input-block mb-3 input_text">
                                                <label className="form-control-label">Phone Token</label>
                                                <Controller
                                                    name="phone_token"
                                                    control={control}
                                                    render={({ field: { value, onChange } }) => (
                                                        <input
                                                            className={`form-control ${errors?.phone_token ? "error-input" : ""}`}
                                                            type="text"
                                                            value={value}
                                                            onChange={onChange}
                                                            autoComplete="false"
                                                        />
                                                    )}
                                                />
                                                <small>{errors?.phone_token?.message}</small>
                                            </div>

                                            {/* Display API Error */}
                                            {errors.apiError && (
                                                <div className="alert alert-danger">
                                                    {errors.apiError.message}
                                                </div>
                                            )}

                                            <button
                                                className="btn btn-lg btn-block w-100 btn-primary"
                                                type="submit"
                                            >
                                                Verify
                                            </button>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    );
};

export default RegistrationVerificationForm;