export default {
  Data: [
    {
      sNo: "1",
      Key: "1",
      item: "Lenovo 3rd Generation",
      code: "P125389",
      units: "Inches",
      quantity: "2",
      sales: "$253.00",
      purchase: "$248.00",
    },
    {
      sNo: "2",
      Key: "2",
      item: "Nike Jordan",
      code: "P125390",
      units: "Pieces",
      quantity: "4",
      sales: "$360.00",
      purchase: "$350.00",
    },
    {
      sNo: "3",
      Key: "3",
      item: "Apple Series 5 Watch",
      code: "P125391",
      units: "Inches",
      quantity: "7",
      sales: "$724.00",
      purchase: "$700.00",
    },
    {
      sNo: "4",
      Key: "4",
      item: "Amazon Echo Dot",
      code: "P125392",
      units: "Box",
      quantity: "3",
      sales: "$210.00",
      purchase: "$200.00",
    },
    {
      sNo: "5",
      Key: "5",
      item: "Lobar Handy",
      code: "P125393",
      units: "Kilograms",
      quantity: "1",
      sales: "$155.00",
      purchase: "$150.00",
    },
    {
      sNo: "6",
      Key: "6",
      item: "Woodcraft Sandal",
      code: "P125389",
      units: "Inches",
      quantity: "2",
      sales: "$253.00",
      purchase: "$248.00",
    },
  ],
};
