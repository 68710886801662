import React, { useState, useEffect } from 'react';
import Sidebar from '../layouts/Sidebar';
import Header from '../layouts/Header';
import axios from 'axios';
import { CustomInputField } from "../CustomComponents/ReusableComponents";
import { useAuth } from '../contexts/AuthContext';
import { useHistory, useParams } from "react-router-dom";

const AddRoleComponent = () => {
    const { auth } = useAuth();
    const history = useHistory();
    const { id } = useParams();

    const [menu, setMenu] = useState(false);

    const [formData, setFormData] = useState({
        name: '',
    })
    const toggleMobileMenu = () => {
        setMenu(!menu);
    };

    const handleChange = e => {
        const { name, value } = e.target;
        setFormData(prevFormData => ({
            ...prevFormData,
            [name]: value
        }));
    };
    const handleCancel = () => {
        history.push('/roles_permission');
    };


    useEffect(() => {

        const fetchRoleData = async () => {
            if (id) {
                try {
                    const response = await axios.get(
                        `${process.env.REACT_APP_API_V1_BASE_URL}/roles/${id}`,
                        {
                            headers: {
                                Authorization: `Bearer ${auth}`
                            }
                        }
                    );
                    setFormData(response.data);
                } catch (err) {
                    // Handle error
                }
            }
        };

        fetchRoleData();
    }, [auth, id]);

    const handleSubmit = async e => {
        e.preventDefault();
        try {
            const response = id
                ? await axios.put(
                    `${process.env.REACT_APP_API_V1_BASE_URL}/roles/${id}`,
                    formData,
                    {
                        headers: {
                            Authorization: `Bearer ${auth}`,
                            "Content-Type": "application/json"
                        }
                    }
                )
                : await axios.post(
                    `${process.env.REACT_APP_API_V1_BASE_URL}/roles`,
                    formData,
                    {
                        headers: {
                            Authorization: `Bearer ${auth}`,
                            "Content-Type": "application/json"
                        }
                    }
                );

            console.log("Role saved successfully", response.data);
            history.push('/roles_permission');
        } catch (err) {
            console.error("Failed to save Role", err);
            // Handle error
        }
    };

    return(
        <>
            <div className={`main-wrapper ${menu ? "slide-nav" : ""}`}>
                <Header onMenuClick={toggleMobileMenu} />
                <Sidebar />
                <div className="page-wrapper">
                    <div className="content container-fluid">
                        <div className="card mb-0">
                            <div className="card-body">
                                <div className="page-header">
                                    <div className="content-page-header">
                                    <h5>{id ? 'Edit Role' : 'Add Role'}</h5>
                                    </div>
                                </div>

                                <form onSubmit={handleSubmit}>
                                    <div className="row">
                                        <CustomInputField
                                            inputType="text"
                                            labelText="Role Name"
                                            name="name"
                                            value={formData.name}
                                            onChange={handleChange}
                                        />
                                    </div>

                                    <div className="text-end">
                                        <button
                                            type="reset"
                                            className="btn btn-primary cancel me-2"
                                            onClick={handleCancel}
                                        >
                                            Cancel
                                        </button>
                                        <button
                                            type="submit"
                                            className="btn btn-primary"
                                        >
                                            Save
                                        </button>
                                    </div>

                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default AddRoleComponent;