import React from 'react';
import PageComponent from '../CustomComponents/PageComponent';

const JournalList = () => {
  return(
    <PageComponent listAPI='journals' label='Journal' addType='nextPage' addLabel='Journal'/>
  )
};

export default JournalList;
