import React, { useEffect, useState } from "react";
// import Header from "../layouts/Header";
// import Sidebar from "../layouts/Sidebar";
import SettingsSidebar from "../layouts/SettingsSidebar";
import { useTranslation } from "react-i18next";
import { useAuth } from "../contexts/AuthContext";
import axios from "axios";
import Swal from 'sweetalert2';

import {
    CustomDropDownField,
    CustomInputField
} from "../CustomComponents/ReusableComponents";

const AccountSettings = () => {
    const { t } = useTranslation();
    const { auth: authToken, branchId } = useAuth();
    const [companyInfo, setCompanyInfo] = useState(null);
    const [isEditing, setIsEditing] = useState(false);
    const [canEdit, setCanEdit] = useState(false);
    const [errors, setErrors] = useState({});

    // const companyId = sessionStorage.getItem('branch_id');
    const companyId = 1; //branchId;

    const [formData, setFormData] = useState({
        name: "",
        organization_name: "",
        industry_type_id: "",
        company_type_id: "",
        currency_id: "",
        fiscal_start_month: 3,
        address_line_1: "",
        address_line_2: "",
        city: "",
        state: "",
        zip_code: "",
        website: "",
        report_basis: "",
        us_company_id: "",
        tax_type: "",
        tax_id_num: ""
    });


    const [industryTypes, setIndustryTypes] = useState([]);
    const [companyTypes, setCompanyTypes] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchCompanyInfo = async () => {
            try {
                const response = await fetch(
                    `${process.env.REACT_APP_API_V1_BASE_URL}/companies/${companyId}`,
                    {
                        method: "GET",
                        headers: {
                            Authorization: `Bearer ${authToken}`
                        }
                    }
                );

                if (response.ok) {
                    const data = await response.json();
                    setCompanyInfo(data);
                    console.log("data", data);
                    setFormData({
                        name: data.name || "",
                        organization_name: data.organization_name || "",
                        industry_type_id: data.industry_type_id || "",
                        company_type_id: data.company_type_id || "",
                        currency_id: data.currency_id || "",
                        fiscal_start_month: data.fiscal_start_month || "",
                        address_line_1: data.address_line_1 || "",
                        address_line_2: data.address_line_2 || "",
                        city: data.city || "",
                        state: data.state || "",
                        zip_code: data.zip_code || "",
                        website: data.website || "",
                        report_basis: data.report_basis || "Accrual",
                        us_company_id: data.us_company_id || "",
                        tax_type: data.tax_type || "",
                        tax_id_num: data.tax_id_num || ""
                    });
                } else {
                    console.error("Failed to fetch company info");
                    setError("Failed to fetch company info");
                }
            } catch (error) {
                console.error("An unexpected error occurred:", error);
                setError("An unexpected error occurred");
            }
        };

        const fetchDropdownData = async () => {
            setLoading(true);
            setError(null);

            try {
                const requestData = [
                    { model_name: "IndustryType" },
                    { model_name: "CompanyType" },
                ];

                const apiReqHeaders = {
                    headers: {
                        Authorization: `Bearer ${authToken}`,
                        "Content-Type": "application/json"
                    }
                };

                const response = await axios.post(
                    `${process.env.REACT_APP_API_V1_BASE_URL}/get_dropdown_data`,
                    { models: requestData },
                    apiReqHeaders
                );

                const dropdownData = response.data.content;

                setIndustryTypes(dropdownData.IndustryType || []);
                setCompanyTypes(dropdownData.CompanyType || []);
            } catch (error) {
                console.error("An unexpected error occurred:", error);
                setError("Failed to load dropdown data");
            } finally {
                setLoading(false);
            }
        };

        fetchCompanyInfo();
        fetchDropdownData();
    }, [companyId, authToken, branchId]);

    const handleChange = e => {
        if (!canEdit) return; // Prevent changes if not in edit mode
        const { name, value } = e.target;
        setFormData(prevData => ({
            ...prevData,
            [name]: value
        }));
    };

    const handleEditClick = () => {
        setIsEditing(true);
        setCanEdit(true); // Allow editing
    };

    const validateForm = () => {
        const validationErrors = {};

        if (!formData.name) {
            validationErrors.name = "Enter a name";
        } else if (formData.name.length < 3 || formData.name.length > 20) {
            validationErrors.name = "Enter a  valid name";
        }

        if (!formData.organization_name) {
            validationErrors.organization_name = "Enter a name";
        } else if (
            formData.organization_name.length < 3 ||
            formData.organization_name.length > 20
        ) {
            validationErrors.organization_name =
                "Enter a valid organization name";
        }

        if (!formData.industry_type_id) {
            validationErrors.industry_type_id = "Select an industry type";
        }

        if (!formData.company_type_id) {
            validationErrors.company_type_id = "Select a company type";
        }
        
        if (!formData.website) {
            validationErrors.website = "enter a website";
        }
        if (!formData.report_basis) {
            validationErrors.report_basis = "select any option";
        }

        setErrors(validationErrors);
        return Object.keys(validationErrors).length === 0;
    };

    const handleSave = async () => {
        if (!validateForm()) {
            return;
        }

        try {
            const response = await fetch(
                `${process.env.REACT_APP_API_V1_BASE_URL}/companies/${companyId}`,
                {
                    method: "PUT",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${authToken}`
                    },
                    body: JSON.stringify(formData)
                }
            );
            if (response.ok) {
                const updatedCompany = await response.json();
                setCompanyInfo(updatedCompany);
                setIsEditing(false);
                setErrors({});
            } else {
                Swal.fire({
                    title: 'Error!',
                    text: 'Failed to Update Company Details.',
                    icon: 'error',
                    confirmButtonText: 'OK'
                });
                setError("Failed to update company info");
            }
        } catch (error) {
            Swal.fire({
                title: 'Error!',
                text: 'Failed to Update Company Details.',
                icon: 'error',
                confirmButtonText: 'OK'
            });
            setError("An unexpected error occurred");
        }
        setIsEditing(false);
        Swal.fire({
            title: 'Success!',
            text: 'Company Details updated Successfully',
            icon: 'success',
            confirmButtonText: 'OK'
        });
    };

    const reportBasisOptions = [
        { value: "Accrual", label: t("Accrual") },
        { value: "Cash", label: t("Cash") }
    ];

    // const apiEndpoint = `${process.env.REACT_APP_API_V1_BASE_URL}/get_dropdown_data`;
    // Check if the endpoint is correct

    return (
        <>
            <div className="page-wrapper">
                <div className="content container-fluid">
                    <div className="row">
                        <div className="col-xl-3 col-md-4">
                            <SettingsSidebar />
                        </div>
                        <div className="col-xl-9 col-md-8">
                            <div className="card">
                                <div className="content-page-header card-header mb-0">
                                    <h5 className="setting-menu">
                                        {t("Account Settings ")}
                                    </h5>
                                </div>
                                <div className="card-body w-100">
                                    {loading ? (
                                        <div>Loading...</div>
                                    ) : error ? (
                                        <div className="error">{error}</div>
                                    ) : companyInfo ? (
                                        <div className="row">
                                            <div className="form-title col-lg-12">
                                                <h5>
                                                    {t("General Informations ")}
                                                </h5>
                                            </div>
                                            <CustomInputField
                                                inputSize="col-lg-6"
                                                value={formData.name}
                                                onChange={handleChange}
                                                name="name"
                                                labelText={t("Company Name")}
                                                inputType="text"
                                                placeholder={t(
                                                    "Enter company Name"
                                                )}
                                                disabled={!isEditing}
                                                required={true}
                                                errorMessage={errors.name}
                                            />
                                            <CustomInputField
                                                inputSize="col-lg-6"
                                                value={
                                                    formData.organization_name
                                                }
                                                onChange={handleChange}
                                                name="organization_name"
                                                labelText={t(
                                                    "Organization Name"
                                                )}
                                                inputType="text"
                                                placeholder={t(
                                                    "Acme Corporation"
                                                )}
                                                disabled={!isEditing}
                                                required={true}
                                                errorMessage={
                                                    errors.organization_name
                                                }
                                            />
                                            <CustomDropDownField
                                                options={industryTypes.map(
                                                    type => ({
                                                        value: type.id,
                                                        label: type.name
                                                    })
                                                )}
                                                name="industry_type_id"
                                                labelText={t("Industry Type")}
                                                value={
                                                    formData.industry_type_id
                                                }
                                                onChange={handleChange}
                                                disabled={!isEditing}
                                                required={true}
                                                errorMessage={
                                                    errors.industry_type_id
                                                }
                                            />
                                            <CustomDropDownField
                                                options={companyTypes.map(
                                                    type => ({
                                                        value: type.id,
                                                        label: type.name
                                                    })
                                                )}
                                                name="company_type_id"
                                                labelText={t("Company Type")}
                                                value={formData.company_type_id}
                                                onChange={handleChange}
                                                disabled={!isEditing}
                                                required={true}
                                                errorMessage={
                                                    errors.company_type_id
                                                }
                                            />
                                            {/* <CustomInputField
                                                inputSize="col-lg-6"
                                                value={
                                                    formData.fiscal_start_month
                                                }
                                                onChange={handleChange}
                                                name="fiscal_start_month"
                                                labelText={t(
                                                    "Fiscal Year Start"
                                                )}
                                                inputType="date"
                                                disabled={!isEditing}
                                                required={true}
                                                errorMessage={errors.fiscal_start_month}
                                            /> */}
                                           

                                            <CustomInputField
                                                inputSize="col-lg-6"
                                                value={formData.website}
                                                onChange={handleChange}
                                                name="website"
                                                labelText={t("Website")}
                                                inputType="text"
                                                placeholder={t("Website URL")}
                                                disabled={!isEditing}
                                                required={true}
                                                errorMessage={errors.website}
                                            />
                                            <CustomDropDownField
                                                options={reportBasisOptions}
                                                name="report_basis"
                                                labelText={t("Report Basis")}
                                                value={formData.report_basis}
                                                onChange={handleChange}
                                                disabled={!isEditing}
                                                required={true}
                                                errorMessage={
                                                    errors.report_basis
                                                }
                                            />
                                            <CustomInputField
                                                inputSize="col-lg-6"
                                                value={formData.us_company_id}
                                                onChange={handleChange}
                                                name="us_company_id"
                                                labelText={t("US Company ID")}
                                                inputType="text"
                                                disabled={!isEditing}
                                            />
                                            <CustomInputField
                                                inputSize="col-lg-6"
                                                value={formData.tax_type}
                                                onChange={handleChange}
                                                name="tax_type"
                                                labelText={t("Tax Type")}
                                                inputType="text"
                                                disabled={!isEditing}
                                            />
                                            <CustomInputField
                                                inputSize="col-lg-6"
                                                value={formData.tax_id_num}
                                                onChange={handleChange}
                                                name="tax_id_num"
                                                labelText={t("Tax ID Number")}
                                                inputType="number"
                                                disabled={!isEditing}
                                            />

                                            <div className="col-lg-12">
                                                <div className="form-title pt-3">
                                                    <h5>Address Information</h5>
                                                </div>
                                            </div>

                                            <>
                                                <CustomInputField
                                                    inputSize="col-lg-6"
                                                    value={formData.address_line_1}
                                                    name="address_line_1"
                                                    onChange={handleChange}
                                                    labelText={t("Adress Line 1")}
                                                    inputType="text"
                                                    placeholder={t("Enter Adress Line 1")}
                                                    disabled={!isEditing}
                                                />
                                                <CustomInputField
                                                    inputSize="col-lg-6"
                                                    value={formData.address_line_2}
                                                    name="address_line_2"
                                                    onChange={handleChange}
                                                    labelText={t("Adress Line 2")}
                                                    inputType="text"
                                                    placeholder={t("Enter Adress Line 2")}
                                                    disabled={!isEditing}
                                                />

                                                <CustomInputField
                                                    inputSize="col-lg-6"
                                                    value={formData.state}
                                                    name="state"
                                                    onChange={handleChange}
                                                    labelText={t("State")}
                                                    inputType="text"
                                                    placeholder={t("State")}
                                                    disabled={!isEditing}
                                                />

                                                <CustomInputField
                                                    inputSize="col-lg-6"
                                                    value={formData.city}
                                                    name="city"
                                                    onChange={handleChange}
                                                    labelText={t("City")}
                                                    inputType="text"
                                                    placeholder={t("City")}
                                                    disabled={!isEditing}
                                                />
                                                
                                                <CustomInputField
                                                    inputSize="col-lg-6"
                                                    value={formData.zip_code}
                                                    onChange={handleChange}
                                                    name="zip_code"
                                                    labelText={t("Zip Code")}
                                                    inputType="number"
                                                    placeholder={t("Zip Code")}
                                                    disabled={!isEditing}
                                                />
                                            </>
                                            <div className="btn-path text-end">
                                                {isEditing ? (
                                                    <>
                                                        <button
                                                            type="button"
                                                            className="btn btn-cancel bg-primary-light me-3"
                                                            onClick={() =>
                                                                setIsEditing(
                                                                    false
                                                                )
                                                            }
                                                        >
                                                            {t("Cancel")}
                                                        </button>
                                                        <button
                                                            type="button"
                                                            className="btn btn-primary"
                                                            onClick={handleSave}
                                                        >
                                                            {t("Save Changes")}
                                                        </button>
                                                    </>
                                                ) : (
                                                    <button
                                                        type="button"
                                                        className="btn btn-primary"
                                                        onClick={
                                                            handleEditClick
                                                        }
                                                    >
                                                        {t("Edit")}
                                                    </button>
                                                )}
                                            </div>
                                        </div>
                                    ) : (
                                        <div>
                                            {t(
                                                "No company information available"
                                            )}
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default AccountSettings;
