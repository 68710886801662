import React from 'react';
import PageComponent from '../../CustomComponents/PageComponent';

const CPAClientList = () => {
    return (
        <PageComponent listAPI='cpa/clients' label='Clients' addType='popup' addLabel='Client' />
    )

};

export default CPAClientList;
